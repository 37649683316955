import React, { useEffect } from "react";
import { Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getShavers, updateFilter } from "../../../actions/shavers";

import CanViewPage from "../../../role-based-access/CanViewPage";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import Shaverstable from "./ShaversTable";
import Filters from "./Filters";

function Shavers({
  getShavers,
  updateFilter,
  isLoading,
  filters,
  total,
  shavers,
}) {
  const { itemsPerPage, currentPage } = filters;

  useEffect(() => getShavers(filters), [filters]);

  return (
    <CanViewPage path="/settings/shavers/list">
      <main className="page-wrapper a-wrapper">
        <div className="flex--left">
          <Header>Shaver list</Header>
          <span className="mr--1 ml--1" style={{ display: "block" }}>
            {" "}
            |{" "}
          </span>
          <Link to="/settings/shavers/settings">Settings</Link>
        </div>

        <Filters filters={filters} updateFilter={updateFilter} />

        <div className="flex--right">
          <ItemsPerPage
            total={total}
            value={itemsPerPage}
            handleChange={(e, { value }) =>
              updateFilter({ itemsPerPage: value, currentPage: 1 })
            }
          />
        </div>
        <Shaverstable
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          isLoading={isLoading}
          shavers={shavers}
        />
        {total > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            total={total}
            updateFilter={updateFilter}
          />
        )}
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  shavers: reduxState.shavers.list,
  filters: reduxState.shavers.filters,
  total: reduxState.shavers.total,
  isLoading: reduxState.shavers.isLoading,
});
export default connect(mapStateToProps, { getShavers, updateFilter })(Shavers);
