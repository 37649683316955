import React, { useState,useEffect } from "react";
import { Icon, Input, Pagination,Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ItemsPerPage from "../../ItemsPerPage";
import ReducedTable from "./ReducedTable";
import ExpandedTable from "./ExpandedTable";
// import ExportButton from "./ExportButton";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import { getProductListV2 } from "../../../actions/product";
import { Alert } from "rsuite";
import { useUpdateContext } from "../../../context/UpdateContext";

function ProductsList(props) {
  const [tableType, setTableType] = useState("reduced");
  const [products,setProducts]          = useState({list:[],total:0})
  const [searchTerm,setSearchTerm]          = useState('')
  const [currentPage,setCurrentpage]          = useState(1)
  const [itemsPerPage,setItemsPerPage]          = useState(10)
  const [isLoading,setIsloading] = useState(false)

  const {businessclient} = useUpdateContext()

useEffect(()=>{
  setIsloading(true)
  getProductListV2({searchTerm,businessclient,itemsPerPage,currentPage})
    .then(data=>{
      setProducts(data)
      setIsloading(false)
    })
    .catch(error=>{setIsloading(false)
                  Alert.error(error)})
},[searchTerm,businessclient,itemsPerPage,currentPage])


  return (
    <>
      <div
        className="flex--between"
        style={{ marginBottom: "2em", textAlign: "right" }}
      >
        <div className="flex--left">
          <Input
            style={{ marginRight: "1em" }}
            loading={isLoading}
            placeholder="Search..."
            icon="search"
            value={searchTerm}
            onChange={(e, d) => setSearchTerm(d.value) }
          />

        <BusinessclientFilter
          multiple={false}
          name="businessclient"
        />
        </div>

        <div>
          <Link to="/products/quotations/import">
            <Button color = 'blue' basic>IMPORT QUOTATIONS(xls)</Button>
          </Link>
          <Link to="/products/create/new">
            <Button color = 'blue' basic>Add Product</Button>
          </Link>
          {/* <ExportButton products={products} checkedItems={checkedItems} /> */}
          <Icon
            name="list"
            color={tableType === "reduced" ? "teal" : "grey"}
            onClick={() => setTableType("reduced")}
          />
          <Icon
            name="list layout"
            color={tableType === "expanded" ? "teal" : "grey"}
            onClick={() => setTableType("expanded")}
          />
          <ItemsPerPage
            total={products.total}
            style={{ height: "1em", marginLeft: "1em" }}
            value={itemsPerPage}
            options={[10, 20, 30, 50, "All"]}
            handleChange={(e, d) => {
              if(d.value==products.total) setCurrentpage(1)
              setItemsPerPage(d.value);
            }}
          />
        </div>
      </div>

      {tableType === "reduced" && (
        <ReducedTable
          products={products.list}
          isLoading={isLoading}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          // // isHeaderChecked={isHeaderChecked}
          // checkedItems={checkedItems}
          // handleSingleCheck={handleSingleCheck}
          // handleMultipleCheck={handleMultipleCheck}
        />
      )}
      {tableType === "expanded" && (
        <ExpandedTable
          products={products.list}
          isLoading={isLoading}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          // // isHeaderChecked={isHeaderChecked}
          // checkedItems={checkedItems}
          // handleSingleCheck={handleSingleCheck}
          // handleMultipleCheck={handleMultipleCheck}
        />
      )}

      <div className="pagination-centered">
        {products.list&&products.list.length > 0 && products.total > itemsPerPage && (
          <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(products.total / itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e,data)=>setCurrentpage(data.activePage)}
          />
        )}
      </div>
    </>
  );
}

export default (ProductsList);
