import React from "react";
import { Segment } from "semantic-ui-react";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import CountryFilter from "../../Filters/CountryFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

function Filters(props) {
  return (
    <div>
      <div className="flex_alignLeft">
        <BusinessclientFilter style={itemStyle}></BusinessclientFilter>
        <CountryFilter style={itemStyle}></CountryFilter>
        <WarehouseFilter style={itemStyle}></WarehouseFilter>
      </div>
    </div>
  );
}

export default Filters;
