import React, { useState } from "react";
import { Icon, Segment, Form, Header, Button, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";

import { createInboundShipment } from "../../../actions/inboundshipment";
import { createCo } from "../../../actions/company";

import Companyform from "../../CompanyForm";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import SellerFilter from "../../Filters/SellerFilter";
import CanViewPage from "../../../role-based-access/CanViewPage";
// import "../inbound.scss";

function CreateInboundShipment(props) {
  const { createCo, createInboundShipment, history } = props;
  const [shipment, setFields] = useState({
    warehouse: "",
    client: "",
    seller: "",
    transporter: "",
    nrOfPacks: "",
    weightKg: "",
  });

  const [isCompanyModalOpen, setCompanyModal] = useState(false);

  const handleInputChange = ({ name, value }) => {
    // console.log('input:',name,value)
    setFields({ ...shipment, [name]: value });
  };
  const toggleCompanyModal = () => {
    setCompanyModal(!isCompanyModalOpen);
  };
  const handleCreateShipment = () => {
    console.log(shipment)
    createInboundShipment({ shipment, history });
  };

  return (
    <CanViewPage path="/shipments/inbound/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/shipments/inbound/">
          <Icon name="arrow left" /> Received Goods List
        </Link>
        <Segment padded>
          <div className="flex--between">
            <Header as="h3">New Received Goods</Header>
          </div>

          <div className="new-inbound-shipment__grid">
            <div className="new-inbound-shipment__col">
              <Form.Field className="new-inbound-shipment__field">
                <label>Warehouse</label>
                <WarehouseFilter
                  useFilter={false}
                  open={!shipment.warehouse}
                  multiple={false}
                  value={shipment.warehouse}
                  handleChange={(value) =>
                    handleInputChange({ name: "warehouse", value })
                  }
                />
              </Form.Field>
              <Form.Field className="new-inbound-shipment__field">
                <label>Client</label>
                <BusinessclientFilter
                  useFilter={false}
                  value={shipment.client}
                  handleChange={(value) =>
                    handleInputChange({ name: "client", value })
                  }
                  name="client"
                />
              </Form.Field>
              <Form.Field className="new-inbound-shipment__field">
                <label className="flex--left vertical--bottom">
                  Supplier
                  {shipment.client && (
                    <Button
                      style={{ marginLeft: "1.4em", marginTop: "-5px" }}
                      //   circular
                      //   basic
                      compact
                      icon="plus"
                      size="mini"
                      className="parties__addbtn"
                      onClick={toggleCompanyModal}
                    />
                  )}
                </label>
                <SellerFilter
                  value={shipment.seller}
                  client={shipment.client}
                  handleChange={(value) =>
                    handleInputChange({ name: "seller", value })
                  }
                />
              </Form.Field>
            </div>
            <div className="new-inbound-shipment__col">
              <Form.Field
                className="new-inbound-shipment__field"
                control={Input}
                label="Transport Company"
                placeholder="company name..."
                name="transporter"
                value={shipment.transporter}
                onChange={(e, { value }) =>
                  handleInputChange({ name: "transporter", value })
                }
              />
              <Form.Field
                className="new-inbound-shipment__field"
                control={Input}
                label="Nr. of packs"
                name="nrOfPacks"
                value={shipment.nrOfPacks}
                onChange={(e, { value }) =>
                  handleInputChange({ name: "nrOfPacks", value })
                }
              />
              <Form.Field
                className="new-inbound-shipment__field"
                control={Input}
                label="Weight (kg)"
                placeholder="kg..."
                name="weightKg"
                value={shipment.weigthKg}
                onChange={(e, { value, name }) =>
                  handleInputChange({ name, value })
                }
              />
            </div>
          </div>

          <Modal
            open={isCompanyModalOpen}
            showCloseIcon={true}
            closeOnEsc={true}
            onClose={toggleCompanyModal}
            center
            classNames={{
              modal: "",
            }}
          >
            <Companyform
              company={shipment.client} //businessclient
              createCo={createCo}
              closeForm={toggleCompanyModal}
            />
          </Modal>
          <Button fluid color="blue" onClick={handleCreateShipment}>
            Create Shipment
          </Button>
        </Segment>
      </main>
    </CanViewPage>
  );
}

// const mapStateToProps = reduxState => ({});

export default connect(null, { createCo, createInboundShipment })(
  CreateInboundShipment
);
