import { apiCall } from "../services/api";

export const barcode_decode = ({barcode}) => {
  const url = `${process.env.REACT_APP_API}/checkout/barcode/decode`;
  return new Promise((resolve, reject) => {
    apiCall("post", url, { barcode })
      .then((resp) => resolve(resp))
      .catch((error) => reject(error.message));
  });
};
export const createCheckout = () => {
  const url = `${process.env.REACT_APP_API}/checkout`;
  return new Promise((resolve, reject) => {
    apiCall("post", url, {})
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};
export const getCheckout = ({ id }) => {
  const url = `${process.env.REACT_APP_API}/checkout/${id}`;
  return new Promise((resolve, reject) => {
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};
export const getCheckouts = () => {
  const url = `${process.env.REACT_APP_API}/checkout`;
  return new Promise((resolve, reject) => {
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};
export const addItem = ({ id,item }) => {
  const url = `${process.env.REACT_APP_API}/checkout/${id}/additem`;
  return new Promise((resolve, reject) => {
    apiCall("post", url, { item })
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};
export const removeItem = ({ id,itemId }) => {
  const url = `${process.env.REACT_APP_API}/checkout/${id}/removeitem/${itemId}`;
  return new Promise((resolve, reject) => {
    apiCall("post", url, { })
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};
export const updateCheckout = ({ status, id, payment }) => {
  const url = `${process.env.REACT_APP_API}/checkout/${id}`;
  return new Promise((resolve, reject) => {
    apiCall("put", url, { status, payment })
      .then((resp) => resolve(resp))
      .catch((error) => reject(error));
  });
};


export const printJob =(query)=>{

  const url = `${process.env.REACT_APP_PRINTER_SRV}`;

return new Promise((resolve,reject)=>{
  apiCall("post", url, query)
  .then(resp=>resolve(resp))
  .catch(err=>reject(err))
})
  

}