import React,{useState,useEffect} from 'react'
import {Segment,Input,Button,Accordion,Icon,Table} from 'semantic-ui-react'
import {addBalance,removeBalance,getBalanceMoves} from '../../actions/accountbalance'
import { Alert } from 'rsuite'
import Moment from 'react-moment'

const Clientbalance =({item,currentUser})=>{
  
const [amount,setAmount] = useState(0)
const [loading,setLoading] =useState(false)
const [data,setData] = useState(null)
const [moves,setMoves] = useState([])
const [active,setActive] = useState(false)

const handleAddBalance = (id)=>{
    setLoading(true)
  if(amount!==0)  addBalance({id,amount,trigger:"User",triggerId:currentUser._id}).then(data=>{
      setLoading(false)
      setData(data)
      setAmount(0)
      setActive(false)
    }
  ).catch(error=>{
      setLoading(false)
      setActive(false)
      Alert.error(error.message)
    })
}
    
const handleRemoveBalance = (id)=>{
    setLoading(true)
  if(amount!==0)  removeBalance({id,amount,trigger:"User",triggerId:currentUser._id}).then(data=>{
      setLoading(false)
      setData(data)
      setAmount(0)
      setActive(false)

    }
  ).catch(error=>{
    setLoading(false)
    setActive(false)
    Alert.error(error.message)
  })
}

const movesTable =<div>
 <Table basic unstackable fixed>
   
      <Table.Header>

        <Table.Cell>date</Table.Cell>
          <Table.Cell>amount</Table.Cell>
          <Table.Cell>trigger</Table.Cell>
          <Table.Cell>Total</Table.Cell>
      </Table.Header>

  
  {moves.map(item=>(
    <Table.Row>
      <Table.Cell> <Moment format="DD/MM/YYYY">{item.createdAt}</Moment></Table.Cell>
      <Table.Cell>{item.amount} {item.currency}</Table.Cell>
      <Table.Cell>{item.trigger} </Table.Cell>
      <Table.Cell>{item.total}</Table.Cell>
    </Table.Row>
  ))}
     </Table> 

</div>

useEffect(()=>{
        if(item) setData(item)
    },[item])

useEffect(()=>{
     if(data)  setData(data)
    },[data])


  useEffect(()=>{
     if(active&&data._id)  getBalanceMoves(data._id).then(data=>{
                          setMoves(data)
                          
                      })
    },[active,data ])

if(!data) return <Segment loading={true}></Segment>

    return (<div style={{margin:'10px'}}> 
  

        <Segment loading={loading} >
                            <div>
                                <h4>{data.businessclient.name}</h4>
                                <div className='flex--left'>
                                <div>Balance:</div>
                                <div style={{marginLeft:'3px',marginRight:'3px'}}>{data.total}</div>
                                <div>{data.currency}</div>
                                </div>
                                
                            </div>
                            <Input 
                                  fluid
                                  value ={amount}
                                  onChange={(e,{value})=>setAmount(value)}
                              >
                            
                              </Input>
                            <div id='actions' className='flex--between' style={{marginTop:'10px'}}>
                              
                               <Button color ='red'onClick={()=>handleRemoveBalance(data._id)}>(-) Deduct</Button>
                              <Button color='green' onClick={()=>handleAddBalance(data._id)}>(+) Add</Button>
                            </div>


            <Accordion fluid styled active={true} style={{marginTop:'20px'}}>
                <Accordion.Title

                  onClick={()=>setActive(!active)}
                >
                  <Icon name='dropdown' />
                    Balance moves
                </Accordion.Title>
                <Accordion.Content active={active}>
                  {movesTable}
                </Accordion.Content>
                </Accordion>
    </Segment>
    
    </div>)

}


export default Clientbalance