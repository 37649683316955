import React from "react";
import { Button } from "semantic-ui-react";
// import { Link } from "react-router-dom";

function Prints({
  processingInvoice,
  isInvoicePrinting,
  isBarcodePrinting,
  isAWBPrinting,
  handleAWBPrint,
  handleBarcodePrint,
  handleBarcodeView,
  // handleInvoiceView,
  // handleSmallInvoicePrint,
  handleInvoicePrint,
  printInvoice,
  handlePrintUrls,
  awb,
  awbDatalink
}) {
  return (
    <table className="print-table">
      <tbody>
  
        <tr className="print-table__row">
          <td className="print-table__label">
            Invoice - autoPrint -  {`${printInvoice}`}
          </td>
          <td className="print-table__files">
            {/* for view in browser */}
            {processingInvoice && <div className="blink_me">Processing...</div>}
            {/* for printing */}
            {isInvoicePrinting && <div className="blink_me">Printing...</div>}
          </td>
          <td className="print-table__button">
            {/* <Button
              basic
              disabled={true}
              circular
              size="tiny"
              icon="download"
              onClick={handleInvoiceView}
            ></Button> */}
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleInvoicePrint}
            ></Button>
              {/* <Button
              basic
              circular
              size="small"
              icon="print"
              onClick={handleSmallInvoicePrint}
            ></Button> */}
          </td>
        </tr>

        <tr className="print-table__row">
          <td className="print-table__label">AWB</td>
          <td className="print-table__files">
            {awb && <div>{awb}</div>}
            {isAWBPrinting && <div className="blink_me">Printing AWB...</div>}
          </td>
          <a href={awbDatalink} target='_blank'>view</a>

          <td className="print-table__button">
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleAWBPrint}
            ></Button>
          </td>
        </tr>

        <tr className="print-table__row">
          <td className="print-table__label">Barcode</td>
          <td className="print-table__files">
            {isBarcodePrinting && (
              <div className="blink_me">Printing barcode...</div>
            )}
          </td>
          <td className="print-table__button">
            <Button
              basic
              circular
              size="tiny"
              icon="download"
              onClick={handleBarcodeView}
            ></Button>
            <Button
              basic
              circular
              size="tiny"
              icon="print"
              onClick={handleBarcodePrint}
            ></Button>
          </td>
        </tr>
        <tr>
          <td><Button basic  circular onClick={handlePrintUrls}>U</Button></td>
        </tr>
      </tbody>
    </table>
  );
}

export default Prints;
