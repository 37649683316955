import React, {  } from "react";
import { Table, Icon, List } from "semantic-ui-react";
import { Link } from "react-router-dom";

// import EditableText from "../../EditableText";

const ItemsHeader = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Products</Table.HeaderCell>
      <Table.HeaderCell>Quantity</Table.HeaderCell>
      <Table.HeaderCell>Difference</Table.HeaderCell>
      <Table.HeaderCell>Purchase Order</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

const ShipmentItems = props => {
  const { pos = [] } = props;

  let itemsBody = <div>No POs</div>;
  if (pos && pos.length > 0) {
    itemsBody = pos.map(
      (po, index) =>
        po.items &&
        po.items.length > 0 &&
        po.items.map((item, pIndex) => {
          let showWarning =false
  
          if(item.stockBlocked==false) showWarning = true
          
          return (
          <Table.Row key={pIndex} className="itemlist_row" >
            <Table.Cell>{pIndex + 1}</Table.Cell>

            <Table.Cell >
            {showWarning&&<Icon name='attention' color ='yellow'/>}
            
            {item.product.name}</Table.Cell>

            <Table.Cell>
              <List>
              {po.status==='Delivered'&& (
                <div>
                  <List.Item>Recevied: {item.quantity_received}</List.Item>
                  </div>
              )}
                <List.Item className="flex--between">
                  <span style={{ marginRight: "0.2em" }}>Shipped:</span>
                  {item.quantity_shipped}
                </List.Item>

               

              </List>
            </Table.Cell>
          
          <Table.Cell>
                {item.quantity_shipped===item.quantity_received &&<Icon name='checkmark' color='olive' />}
                {item.quantity_shipped!==item.quantity_received &&<div>{item.quantity_received-item.quantity_shipped}</div>}

          </Table.Cell>
          
            <Table.Cell>
              <Link to={`/pos/${po._id}`}>{po._id.substr(-9)}</Link>
            </Table.Cell>
          </Table.Row>
        )})
    );
  }
  return (
    <div>
      <Table basic="very" color="white" selectable unstackable>
        <ItemsHeader />
        <Table.Body>{itemsBody}</Table.Body>
      </Table>
    </div>
  );
};

export default ShipmentItems;
