import React from "react";
import { Header } from "semantic-ui-react";
import Moment from "react-moment";
import { connect } from "react-redux";

import { getAllPickingList } from "./../../../actions/picking";

import PickedTable from "./PickedTable";

class PickedTablePrint extends React.Component {
  render() {
    const { item } = this.props;

    if (item) {
      return (
        <div style={{ marginTop: "1em" }}>
          <div style={{ textAlign: "center" }}>
            <Header as="h4">PICKING LIST</Header>
            <span className="smallinfo">
              <Moment format="DD-MM-YY HH:mm">{item.createdAt}</Moment>
            </span>
            <br />
            {item.createdAt === item.updatedAt && (
              <span className="smallinfo">
                Uodated:{" "}
                <Moment style={{ color: "#565656" }} format="DD-MM-YY HH:mm">
                  {item.updatedAt}
                </Moment>
              </span>
            )}
          </div>

          <PickedTable item={item} toPrint={true} />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = reduxState => ({
  item: reduxState.picking.listOfPicked[0]
});

export default connect(mapStateToProps, { getAllPickingList })(
  PickedTablePrint
);
