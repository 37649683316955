import React, { useState } from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import CanViewPage from "../../../role-based-access/CanViewPage";
import CourierTable from "./CourierTable";
import CourierForm from "./CourierForm";
import Filters from "./Filters";
import "../courier.scss";

const emptyCourierForm = {
  name: "",
  country: "",
  isDefault: false
};

function CourierList() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModeOn, setEditMode] = useState(false);
  const [courierForm, setCourierForm] = useState(emptyCourierForm);
  const [couriers,setCouriers]  = useState([])

  const openModal = (edit = false, courier = emptyCourierForm) => {
    setModalOpen(true);
    setEditMode(edit);
    setCourierForm(courier);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  
  return (
    <CanViewPage path="/couriers">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between vertical--middle mb--2">
          <Header as="h1">Couriers</Header>
          <Button color="blue" onClick={() => openModal(false)}>
            Add New
          </Button>
        </div>

        {/* <Header as="h4">Filters:</Header> */}
        <Segment>
          <Filters setCouriers={setCouriers}/>
        </Segment>

        <Segment padded>
          <CourierTable 
          couriers={couriers}
          openModal={openModal} />
        </Segment>

        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={true}
          onClose={closeModal}
          center
          classNames={{
            modal: ""
          }}
        >
          <CourierForm
            courier={courierForm}
            handleClose={closeModal}
            isEditModeOn={isEditModeOn}
          />
        </Modal>
      </main>
    </CanViewPage>
  );
}

export default CourierList;
