import React,{useState,useEffect} from 'react'
import Clientbalance from './clientbalance'
import {getBalances,createBalance} from '../../actions/accountbalance'
import {Button,Divider,Header} from 'semantic-ui-react'
import Modal from "react-responsive-modal";
import BusinessClientFilter from '../../components/Filters/BusinessclientFilter'
import CurrencyFilter from "../../components/Filters/CurrencyFilter";
import { Alert } from 'rsuite';
import CanViewPage  from "../../role-based-access/CanViewPage";

const Accountbalances = ({currentUser})=>{

    const [list,setList] = useState([])
    const [openNew,setOpenNew] = useState(false)
    const [businessclient,setBusiness] = useState()
    const [currency,setCurrency] = useState()

    const handleCreate=()=>{
        createBalance({businessclient,currency}).then(data=> setOpenNew(false)).catch(error=>Alert.error(error.message))
        
    }

    const modal =  <Modal
    open={openNew}
    showCloseIcon={true}
    closeOnEsc={true}
    onClose={()=>setOpenNew(false)}
    center
    classNames={{
      modal: "",
    }}
  >
      <div style={{minWidth:"300px"}}>
      <Header as="h4">Create Currency balance</Header>
      
        <Divider></Divider>
      
      <div className='flex--between'>
          <BusinessClientFilter
              name="businessclient"
              value={businessclient}
              handleChange={(e,{value})=>setBusiness(value)}
          ></BusinessClientFilter>
          <CurrencyFilter
              name="currency"
              value={currency}
              handleChange={(e,{value})=>setCurrency(value)}
          ></CurrencyFilter>

      </div>
      <Divider></Divider>
            <Button onClick={handleCreate}>Create</Button>
      </div>
      
          </Modal>

    const table = <div>
        {list.map(item=>(
            <Clientbalance currentUser={currentUser}item={item}></Clientbalance>
        ))}
    </div>
     
    useEffect(()=>{
        getBalances({businessclient,currency}).then(data=>setList(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openNew])

return  <CanViewPage path="/refunds" ><div style={{margin:'10px'}}>
             {modal}
        <div id='top' className='flex--between' >
            <h2>Balances</h2>
            <Button onClick={()=>setOpenNew(true)}>Create New</Button>
        </div>
        

        {/* <section id='filters'>
            filters
        </section> */}

        <section>
            
            <h4>{table}</h4>

        </section>
    </div>
    </CanViewPage>
}


export default Accountbalances