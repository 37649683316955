import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
// import { connect } from "react-redux";

// import { roles } from "./data";
import { getRoles } from "../../actions/user";

function RolesFilter({
  value,
  handleChange,
  name = "roles",
  placeholder = "role...",
  multiple = false,
  fluid = false,
  selection = true,
  error = false,
}) {
  const [options, setOptions] = useState([]);
  

  useEffect(() => {
    
    getRoles()
    .then(resp=>{
      const users = resp.map((r) => ({ key: r, value: r, text: r }))
      setOptions(users)
    })
  .catch(error=>alert(error))}, []);


  return (
    <Dropdown
      placeholder={placeholder}
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection={selection}
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
    />
  );
}


export default (RolesFilter);
