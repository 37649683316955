import React, { useState, useEffect } from "react";
import { Dropdown, Header } from "semantic-ui-react";
import { connect } from "react-redux";

import { getProductList } from "../../actions/product";

function ProductFilter({
  businessclient = null,
  value,
  name = "sku",
  placeholder = "product...",
  handleChange,
  multiple = false,
  fluid = false,
  selection = true,
  error = false,
  disabled = false,
  getProductList,
  products,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (businessclient) getProductList({ businessclient });
    else getProductList({});
  }, [businessclient]);
  useEffect(
    () =>
      setOptions(
        products.map(({ _id, codes = {}, name, businessclient = {} }) => ({
          key: _id,
          value: codes.sku,
          text: codes.sku,
          content: (
            <Header
              as="h3"
              content={codes && codes.sku}
              // {`${name} - ${codes && codes.sku}`}
              subheader={name}
            />
          ),
        }))
      ),
    [products]
  );

  return (
    <Dropdown
      placeholder={placeholder}
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      disabled={disabled}
      clearable
      selection={selection}
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
    />
  );
}

const mapStateToProps = (reduxState) => ({
  products: reduxState.inventory.products,
});

export default connect(mapStateToProps, { getProductList })(ProductFilter);
