
export const statuses =[
    "New",
    'Order Ready for picking',
    'Product Picking',
    'Packed',
    'Address Error',
    'Draft',
    'No goods',
    'Error',
]