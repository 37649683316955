import React, {  } from "react";
import { Table, Loader, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import CreateUpdateTime from "../../CreateUpdateTime";

function ReducedTable(props) {
  const {
    products=[],
    isLoading,
    itemsPerPage,
    currentPage,
    // isHeaderChecked,
    // checkedItems,
    // handleSingleCheck,
    // handleMultipleCheck
  } = props;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="center" className="index-col-padding ">
        #
      </Table.HeaderCell>
      {/* <Table.HeaderCell textAlign="center" className="index-col-padding ">
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </Table.HeaderCell> */}
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>SKU</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Options</Table.HeaderCell>
      <Table.HeaderCell>Properties</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = products.map(
    (
      {
        _id,
        name,
        codes = {
          sku: "",
          barcode: ""
        },
        businessclient = { name: "" },
        color,
        size,
        proprities = {
          dimensions: {
            height: "",
            width: "",
            lenght: ""
          },
          weight: ""
        },
        createdAt,
        updatedAt
      },
      index
    ) => (
      <Table.Row key={_id} className="prod-list__item product-row">
        <Table.Cell
          collapsing
          textAlign="center"
          verticalAlign="middle"
          className="index-col-padding"
        >
          {itemsPerPage * (currentPage - 1) + index + 1}
        </Table.Cell>

        {/* <Table.Cell collapsing verticalAlign="middle">
          <Checkbox
            checked={checkedItems.includes(_id)}
            onChange={() => handleSingleCheck(_id)}
          />
        </Table.Cell> */}

        <Table.Cell verticalAlign="middle">
          <Link className="x-large--text bold--text" to={`/products/${_id}`}>
            {name}
          </Link>
          <br />
          <span className="grey--text small--text">
            {businessclient && businessclient.name}
          </span>
        </Table.Cell>
        <Table.Cell>{codes.sku}</Table.Cell>
        <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell>
        <Table.Cell>
          <div className="product-row__options">
            {size && (
              <p className="product-row__row">
                <span className="product-row__label">Size:</span> {size}
              </p>
            )}
            {color && (
              <p className="product-row__row">
                <span className="product-row__label">Color:</span> {color}
              </p>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {proprities && (
            <div className="product-row__options">
              {proprities.weight && (
                <p className="product-row__row">
                  <span className="product-row__label">Weight:</span>{" "}
                  {proprities.weight}g
                </p>
              )}

              {proprities.dimentions && (
                <p className="product-row__row">
                  <span className="product-row__label">Dimensions:</span>{" "}
                  {Object.values(proprities.dimentions)
                    .filter(d => d)
                    .map(d => d + "cm")
                    .join(" x ")}
                </p>
              )}
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <div className="table-wrapper--horizontal-scroll">
      {!isLoading && products.length === 0 && (
        <Header textAlign="center" color="grey">
          No products found
        </Header>
      )}
      {isLoading && products.length === 0 && (
        <Loader active inline="centered" />
      )}
      {products.length > 0 && (
        <Table
          loading={isLoading}
          unstackable
          inveted
          basic="very"
          padded
          selectable
          className="prod-list"
        >
          <Table.Header color="teal" className="prod-list__header">
            {tableHeader}
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      )}
    </div>
  );
}

export default ReducedTable;
