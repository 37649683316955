import { apiCall } from "../services/api";

export const getRefunds =({searchTerm='',currentPage=1,limit=10,dateFrom,dateTo,sortColumn,sortType,status,businessclient,country})=>{

    const url = `${process.env.REACT_APP_API}/refunds?searchTerm=${searchTerm}&currentPage=${currentPage}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortColumn=${sortColumn}&sortType=${sortType}&status=${status}&businessclient=${businessclient}&country=${country}`
    return new Promise ((resolve,reject)=>{    
        apiCall('get',url)
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}

export const createRefund=(query)=>{
    const url = `${process.env.REACT_APP_API}/refunds`
    return new Promise ((resolve,reject)=>{
        apiCall('post',url,query)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}
export const updateRefund=(id,query)=>{
    const url = `${process.env.REACT_APP_API}/refunds/${id}`
    return new Promise ((resolve,reject)=>{
        apiCall('put',url,query)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}
// export const approveRefunds=(refunds)=>{
//     const url = `${process.env.REACT_APP_API}/refunds/action/approve`
//     return new Promise ((resolve,reject)=>{
//         apiCall('put',url,{refunds})
//         .then(resp =>resolve(resp))
//         .catch(error=>reject(error))
//     })
// }
export const updateRefundStatusSelected=(query)=>{
    const url = `${process.env.REACT_APP_API}/refunds`
    return new Promise ((resolve,reject)=>{
        apiCall('put',url,query)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}



export const getRefund=(refundId)=>{
    const url = `${process.env.REACT_APP_API}/refunds/${refundId}`
    return new Promise ((resolve,reject)=>{
        apiCall('get',url)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}
export const getApprovedRefund=()=>{
    const url = `${process.env.REACT_APP_API}/refunds/approved`
    return new Promise ((resolve,reject)=>{
        apiCall('get',url)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}

export const deleteRefund=(refundId)=>{
    const url = `${process.env.REACT_APP_API}/refunds/${refundId}`
    return new Promise ((resolve,reject)=>{
        apiCall('delete',url)
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}
export const sendToBankFio=(refundIDs,total)=>{
    const url = `${process.env.REACT_APP_API}/refunds/sendtobank/fio`
    return new Promise ((resolve,reject)=>{
        apiCall('post',url,{refundIDs,total})
        .then(resp =>resolve(resp))
        .catch(error=>reject(error))
    })
}

