import React, { useEffect } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
// import { orderAction } from "../../../actions/packing";
// import Barcode from "react-barcode";
// import ReactToPrint from "react-to-print";

const ActionsPrimary= (props)=>{
  
    const {
      handlePackedClick,
      hadndleGetNextOrder,
      hitPack
    } = props;


    
    const downHandler=(key)=> {
      
      // console.log("KEY:",key," Hit pack:",hitPack)
      if(key.keyCode===32 &&hitPack) {
        
        hadndleGetNextOrder()
      }
      if(key.keyCode===32 &&!hitPack) {
        
        handlePackedClick()
      
      }
    }
    // If released key is our target key then set to false
    // const upHandler = ({ key }) => {
    //   console.log('upckey:',key)

    // };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      // window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        // window.removeEventListener("keyup", upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    
    
    return (
      <div className="actions__primary">
              
        <div className="primary-btn__wrapper">
          <Button
            disabled={hitPack}
            className={
              !hitPack 
              // && (isBarcodePrinted )
              // && isAWBPrinted  
              ? 
              "focused-btn" : ""
            }
            size="large"
            // ref={this.activeButton}
            // disabled={!isDraft && (hitPack || !isAWBPrinted)}
            color="blue"
            onClick={handlePackedClick}
          >
            <Icon name="checkmark" />
            DONE-Packed!
          </Button>

          <Label basic pointing className="actions__label">
            Hit "Space"
          </Label>
        </div>

        <div className="primary-btn__wrapper">
          <Button
            className={hitPack ? "focused-btn" : ""}
            size="large"
            // disabled={[isDraft,hitPack].includes(status)}
            color="green"
            onClick={hadndleGetNextOrder}
          >
            NEXT
            <Icon name="arrow right" />
          </Button>

          <Label basic pointing className="actions__label">
            Hit "Space"
          </Label>
        </div>
       
      </div>
    );
  
}

export default ActionsPrimary;

