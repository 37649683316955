import React, { useEffect,useState } from "react";
import { getNogoodsReport } from "../../../actions/reports";
import Nogoodstable from "./table";
import { Segment, Header } from "semantic-ui-react";

import Filters from "./Filters";
import { useUpdateContext } from "../../../context/UpdateContext";
import { Alert } from "rsuite";

function Nogoodsreport(props) {
  const { businessclient, warehouse, country } = useUpdateContext();

  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    getNogoodsReport({ country, businessclient, shipFrom: warehouse }).then(
      (resp) =>{
        setLoading(false)
         setList(resp)}
    ).catch(error=>Alert.error(error,5000))
  }, [businessclient, country, warehouse]);

  const tables = list.map((item) => <Nogoodstable data={item}></Nogoodstable>);

  return (
    <main  style={{maxWidth:'1100px',margin:'15px'}}>
      <Header>No Goods Report</Header>
      <Filters></Filters>
      {isLoading&&<div>Loading...</div>}
      {!isLoading&&<Segment>{tables}</Segment>}
    </main>
  );
}


export default (
  Nogoodsreport
);
