import React from "react";
import { Segment, Form } from "semantic-ui-react";
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ProductFilter from "./ProductFilter.jsx";
import ItemsPerPage from "../../../ItemsPerPage.jsx";
// import Filters from "../../../Courier/CourierList/Filters.jsx";

function TopBar(props) {
  const {
    filters,
    total,
    handleUpdateFilter,
    handleSearchProduct,
    handleItemsPerPageChange
  } = props;
  const { dateFrom, dateTo, searchedProducts, itemsPerPage } = filters;

  return (
    <Segment>
      <div
        className="a-wrapper flex--between vertical--bottom"
        style={{ marginTop: ".5em", marginBottom: ".5em" }}
      >
        <div className="top-filters__container">
          <div className="top-filters__date-picker">
            <span>Date:</span>
            <Form>
              <div className="date-pick__item">
                <DatePicker
                  selected={dateFrom}
                  isClearable={true}
                  dateFormat="MMMM d, yyyy"
                  onChange={date => handleUpdateFilter({ dateFrom: date })}
                  placeholderText="from..."
                ></DatePicker>
              </div>

              <DatePicker
                selected={dateTo}
                isClearable={true}
                dateFormat="MMMM d, yyyy"
                onChange={date => handleUpdateFilter({ dateTo: date })}
                placeholderText="to..."
              ></DatePicker>
            </Form>
          </div>

          <ProductFilter
            
            searchedProducts={searchedProducts}
            handleSearchProduct={handleSearchProduct}
          />
        </div>

        <ItemsPerPage
          total={total}
          value={itemsPerPage}
          handleChange={handleItemsPerPageChange}
        />

        {/* <div className="d-flex--column"> */}
        {/* <Link to="/orders">
            <Button color="teal">Recieved Orders</Button>
          </Link> */}

        {/* </div> */}
      </div>
    </Segment>
  );
}

export default TopBar;
