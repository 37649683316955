import React from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";

function ViewItem({
  adminViewName,
  adminValues,
  stateValues,
  handleViewToggle,
  handleViewSelection,
}) {
  return (
    <li className="filter-item route-item">
      <span
        className={`views-item__name small--text ${
          stateValues && "bold--text"
        }`}
      >
        {adminViewName}
      </span>
      <div className="views-item__toggle">
        <Checkbox
          toggle
          checked={!!stateValues}
          onChange={(e, { checked }) =>
            handleViewToggle({
              view: adminViewName,
              checked,
            })
          }
        />
      </div>
      {stateValues && (
        <div className="views-item__options">
          <Dropdown
            multiple
            fluid
            clearable
            search
            selection
            placeholder="select action ..."
            name={adminViewName}
            value={stateValues}
            options={adminValues.map((action) => ({
              key: action,
              value: action,
              text: action,
            }))}
            onChange={(e, { value }) =>
              handleViewSelection({
                view: adminViewName,
                value,
              })
            }
          />
        </div>
      )}
    </li>
  );
}

export default ViewItem;
