//STOCKMOVEMNT ACTIONS
export const NEW_ORDER = "NEW_ORDER"; //client order trigger
export const CANCEL_ORDER = "CANCEL_ORDER";
export const SHIPPED_OUT = "SHIPPED_OUT"; //cargo movement
export const SHIPPED_IN = "SHIPPED_IN"; //cargo movement
export const HANDLING = "HANDLING"; //inhouser movement
export const DAMAGED = "DAMAGED";
export const DAMAGED_OUT = "DAMAGED_OUT";
export const PACKED = "PACKED";
export const RETURNS = "RETURNS";
export const MISSING = "MISSING(-)";
export const SURPLUS = "SURPLUS(+)";


//INVENTORY
export const GET_INVENTORY = "GET_INVENTORY";
export const GET_WAREHOUSES = "GET_WAREHOUSES";
export const CREATE_MOVEMENT = "CREATE_MOVEMENT";
export const WAREHOUSE_FILTER = "WAREHOUSE_FILTER";

//PRODUCT

export const ADD_PRODUCT                    = "ADD_PRODUCT";
export const GET_STOCKMOVEMENT              = "GET_STOCKMOVEMENT";
export const CREATE_PRODUCT                 = "CREATE_PRODUCT";
export const UPDATE_PRODUCT                 = "UPDATE_PRODUCT";
export const GET_PRODUCTS                   = "GET_PRODUCTS";
export const GET_CLIENT_PRODUCTS            = "GET_CLIENT_PRODUCTS";
export const GET_FILTERED_PRODUCTS          = "GET_FILTERED_PRODUCTS";
export const GET_PRODUCT                    = "GET_PRODUCT";
export const GET_STOCKMOVEMENTS             = "GET_STOCKMOVEMENTS";
export const CREATE_PRODUCT_GROUP           = "CREATE_PRODUCT_GROUP";
export const GET_PRODUCT_GROUP              = "GET_PRODUCT_GROUP";
export const GET_PRODUCT_GROUPS             = "GET_PRODUCT_GROUPS";
export const UPDATE_PRODUCT_GROUP           = "UPDATE_PRODUCT_GROUP";
export const DELETE_PRODUCT_GROUP           = "DELETE_PRODUCT_GROUP";
export const ADD_PRODUCTS_PRODUCT_GROUP     = "ADD_PRODUCTS_PRODUCT_GROUP";
export const REMOVE_PRODUCTS_PRODUCT_GROUP  = "REMOVE_PRODUCTS_PRODUCT_GROUP";
export const CREATE_PRODUCT_PRICING         = "CREATE_PRODUCT_PRICING";
export const UPDATE_PRODUCT_PRICING         = "UPDATE_PRODUCT_PRICING";
export const DELETE_PRODUCT_PRICING         = "DELETE_PRODUCT_PRICING";
export const ADD_RELATED_PRODUCTS           = 'ADD_RELATED_PRODUCTS'
export const REMOVE_RELATED_PRODUCTS        = 'REMOVE_RELATED_PRODUCTS'
export const CREATE_PROMO                   = "CREATE_PROMO"
export const GET_PROMO                      = "GET_PROMO"
export const GET_PROMOS                     = "GET_PROMOS"
export const UPDATE_PROMO                   = "UPDATE_PROMO"
export const ADD_PROMO_PRODUCTS             = "ADD_PROMO_PRODUCTS"
export const REMOVE_PROMO_PRODUCTS          = "REMOVE_PROMO_PRODUCTS"
export const ADD_PROMO_DISPLAYTOPRODUCTS    = "ADD_PROMO_DISPLAYTOPRODUCTS"
export const REMOVE_PROMO_DISPLAYTOPRODUCTS = "REMOVE_PROMO_DISPLAYTOPRODUCTS"
export const REMOVE_PROMO                   = "REMOVE_PROMO"
export const GET_DOCUMENTS                  = "GET_DOCUMENTS"
export const GET_DOCUMENT                   = "GET_DOCUMENT"
export const CREATE_DOCUMENT                = "CREATE_DOCUMENT"
export const UPDATE_DOCUMENT                = "UPDATE_DOCUMENT"
export const DELETE_DOCUMENT                = "DELETE_DOCUMENT"



//OUTBOUNT SHIPMENT
export const UPDATE_OS_FILTERS = "UPDATE_OS_FILTERS";
export const ADD_SHIPMENT = "ADD_SHIPMENT";
export const GET_SHIPMENTS = "GET_SHIPMENTS";
export const GET_SHIPMENT = "GET_SHIPMENT";
export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const ADD_ITEMS_SHIPMENT = "ADD_ITEMS_SHIPMENT";
export const UPDATE_ITEMS_SHIPMENT = "UPDATE_ITEMS_SHIPMENT";
export const CLEAR_SHIPMENT_STATE = "CLEAR_SHIPMENT_STATE";
export const GET_PICKUPS = "GET_PICKUPS";
export const CREATE_PICKUP = "CREATE_PICKUP";

//INBOUND SHIPMENTS
export const ADD_INBOUND_SHIPMENT = "ADD_INBOUND_SHIPMENT";
export const ADD_INBOUND_SHIPMENT_PHOTO = "ADD_INBOUND_SHIPMENT_PHOTO";
export const DELETE_INBOUND_SHIPMENT = "DELETE_INBOUND_SHIPMENT";
export const GET_INBOUND_SHIPMENT = "GET_INBOUND_SHIPMENT";
export const ADD_INBOUND_SHIPMENT_PHOTOS = "ADD_INBOUND_SHIPMENT_PHOTOS";
export const INBOUND_SHIPMENT_GOODSRECEIVED = "INBOUND_SHIPMENT_GOODSRECEIVED";
export const GET_INBOUND_SHIPMENTS = "GET_INBOUND_SHIPMENTS";
export const INBOUND_FILTER = "INBOUND_FILTER";

//GOODSRECEIVED
export const ADD_GR_DOCUMENTS = "ADD_GR_DOCUMENTS";
export const UPLOADING_GR_DOCUMENTS = "UPLOADING_GR_DOCUMENTS";

//POS
export const GET_POS = "GET_POS";
export const GET_PO = "GET_PO";
export const UPDATE_PO = "UPDATE_PO";
export const CREATE_PO = "CREATE_PO";
export const UPDATE_GR = "UPDATE_GR";
export const DELETE_PO = "DELETE_PO";
export const ADD_DOCUMENTS_POS = "ADD_DOCUMENTS_POS";
export const UPLOADING_PO_DOCUMENTS = "UPLOADING_PO_DOCUMENTS";
export const PO_CREATE_INVOICE = "PO_CREATE_INVOICE";

//Company
export const GET_COMPANIES = "GET_COMPANIES";
export const CREATE_CO = "CREATE_CO";

//Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

//refund
export const GET_REFUND = "GET_REFUND";
export const CREATE_REFUND = "CREATE_REFUND";
export const GET_REFUNDS = "GET_REFUNDS";
export const UPDATE_REFUND = "UPDATE_REFUND";
export const DELETE_REFUND = "DELETE_REFUND";
export const SEARCH_REFUNDS = "SEARCH_REFUNDS";
export const SENDPAYMENT_REFUND = "SENDPAYMENT_REFUND";

//Stats
export const GET_OFFER_STAT = "GET_OFFER_STAT";
export const GET_ACTIVE_OFFERS = "GET_ACTIVE_OFFERS";

//BUSINESSCLIENTS
export const GET_BUSINESSCLIENTS = "GET_BUSINESSCLIENTS";
export const GET_BUSINESSCLIENT = "GET_BUSINESSCLIENT";
export const GET_BUSINESSCLIENT_SHIPTOS = "GET_BUSINESSCLIENT_SHIPTOS";
export const ADD_BUSINESSCLIENT_SHIPTOS = "ADD_BUSINESSCLIENT_SHIPTOS";
export const CREATE_BUSINESSCLIENT = "CREATE_BUSINESSCLIENT";
export const UPDATE_BUSINESSCLIENT = "UPDATE_BUSINESSCLIENT";
export const GET_BUSINESSCLIENT_SETTINGS = "GET_BUSINESSCLIENT_SETTINGS";
export const CREATE_BUSINESSCLIENT_SETTINGS = "CREATE_BUSINESSCLIENT_SETTINGS";
export const UPDATE_BUSINESSCLIENT_SETTINGS = "UPDATE_BUSINESSCLIENT_SETTINGS";
export const CREATE_FULFILMENT_QUOTATION = "CREATE_FULFILMENT_QUOTATION";
export const CREATE_COURIER_QUOTATION = "CREATE_COURIER_QUOTATION";
export const GET_FULFILMENT_QUOTATIONS = "GET_FULFILMENT_QUOTATIONS";
export const GET_COURIER_QUOTATIONS = "GET_COURIER_QUOTATIONS";
export const GET_FULFILMENT_QUOTATION = "GET_FULFILMENT_QUOTATION";
export const GET_COURIER_QUOTATION = "GET_COURIER_QUOTATION";
export const DELETE_FULFILMENT_QUOTATIONS = "DELETE_FULFILMENT_QUOTATIONS";
export const DELETE_COURIER_QUOTATIONS = "DELETE_COURIER_QUOTATIONS";
export const SET_ACTIVE_FULFILMENT_QUOTATION =
  "SET_ACTIVE_FULFILMENT_QUOTATION";
export const SET_ACTIVE_COURIER_QUOTATION = "SET_ACTIVE_COURIER_QUOTATION";

// ORDERS
export const UPDATE_ORDERS_FILTERS = "UPDATE_ORDERS_FILTERS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const ADD_ORDER_UPDATES = "ADD_ORDER_UPDATES";
export const CANCEL_ORDER_UPDATES = "CANCEL_ORDER_UPDATES";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_ADD_PRODUCT = "UPDATE_ORDER_ADD_PRODUCT";
export const UPDATE_ORDER_DELETE_PRODUCT = "UPDATE_ORDER_DELETE_PRODUCT";
export const UPDATE_ORDERS_TOTALS = "UPDATE_ORDERS_TOTALS";

//Picking
export const GET_PICKINGLIST = "GET_PICKINGLIST";
export const SELECT_PICKING_ORDERS = "SELECT_PICKING_ORDERS";
export const CHANGE_COURIER = "CHANGE_COURIER";
export const SUCCESS_UPDATE_ORDERS_STATUS_PICKING =
  "SUCCESS_UPDATE_ORDERS_STATUS_PICKING";
export const ADD_NEW_PICKING_LIST = "ADD_NEW_PICKING_LIST";
export const GET_ALL_PICKING_LISTS = "GET_ALL_PICKING_LISTS";
export const UPDATE_PICKING_LIST = "UPDATE_PICKING_LIST";
export const UPADATE_FILTER_PICKING_LIST = "UPADATE_FILTER_PICKING_LIST";

// Couriers
export const CREATE_COURIER = "CREATE_COURIER";
export const GET_COURIER = "GET_COURIER";
export const UPDATE_COURIER_FILTER = "UPDATE_COURIER_FILTER";
export const GET_COURIERS = "GET_COURIERS";
export const GET_COURIERS_BY_COUNTRY = "GET_COURIERS_BY_COUNTRY";
export const GET_COURIERS_BY_WAREHOUSE = "GET_COURIERS_BY_WAREHOUSE";
export const UPDATE_COURIER = "UPDATE_COURIER";
export const CREATE_REPORT_TEMPLATE = "CREATE_REPORT_TEMPLATE";
export const UPDATE_REPORT_TEMPLATE = "UPDATE_REPORT_TEMPLATE";
export const DELETE_REPORT_TEMPLATE = "DELETE_REPORT_TEMPLATE";
export const GET_REPORT_TEMPLATES = "GET_REPORT_TEMPLATES";
export const CREATE_COURIER_USER = "CREATE_COURIER_USER";
export const UPDATE_COURIER_USER = "UPDATE_COURIER_USER";
export const GET_COURIER_USERS = "GET_COURIER_USERS";
export const DELETE_COURIER_USER = "DELETE_COURIER_USER";

//Packing
export const ADD_PACKAGES = "ADD_PACKAGES";
export const GET_PACKER_ORDERS = "GET_PACKER_ORDERS";
export const GET_PACKER_DRAFT_ORDERS = "GET_PACKER_DRAFT_ORDERS";
export const GET_PACKER_ASSIGNED_ORDERS = "GET_PACKER_ASSIGNED_ORDERS";
export const SELECT_PACKAGE = "SELECT_PACKAGE";
export const UPDATE_SELECTED_PACKAGE = "UPDATE_PACKAGE";
export const PACKED_PACKAGE = "PACKED_PACKAGE";
export const DRAFT_PACKAGE = "DRAFT_PACKAGE";
export const REJECT_PACKAGE = "REJECT_PACKAGE";
export const CLOSE_PACKAGE_ORDER = "CLOSE_PACKAGE_ORDER";
export const NEXT_PACKAGE_ORDER = "NEXT_PACKAGE_ORDER";
export const PRINT_AWB_SUCCESS = "PRINT_AWB_SUCCESS";
export const PRINT_AWB_START = "PRINT_AWB_START";
export const PRINT_AWB_STOP = "PRINT_AWB_STOP";
export const ADD_XLS_PRODUCTS = "ADD_XLS_PRODUCTS";

// Loading
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

//VAT
export const CREATE_VAT = "CREATE_VAT";
export const GET_ALL_VAT = "GET_ALL_VAT";

//FULFIPRODUCT

export const CREATE_FULFILPRODUCT = 'CREATE_FULFILPRODUCT'
export const GET_FULFILPRODUCT = 'GET_FULFILPRODUCT'
export const GET_FULFILPRODUCTLIST = 'GET_FULFILPRODUCTLIST'
export const DELETE_FULFILPRODUCT = 'DELETE_FULFILPRODUCT'
export const UPDATE_FULFILPRODUCT = 'UPDATE_FULFILPRODUCT'
export const ADDPRODUCT_FULFILPRODUCT = 'ADDPRODUCT_FULFILPRODUCT'
export const REMOVEPRODUCT_FULFILPRODUCT = 'REMOVEPRODUCT_FULFILPRODUCT'


//PDF TEMPLATES
export const PDF_TEMPLATE_PPLCZ_ETICKET='PDF_TEMPLATE_PPLCZ_ETICKET'


//SERVICES
export const CREATE_SERVICE_TYPE='CREATE_SERVICE_TYPE'
export const GET_SERVICE_TYPES='GET_SERVICE_TYPES'
export const UPDATE_SERVICE_TYPE='UPDATE_SERVICE_TYPE'
export const DELETE_SERVICE_TYPE='DELETE_SERVICE_TYPE'
export const GET_SERVICE_QUOTATIONS='GET_SERVICE_QUOTATIONS'
export const CREATE_SERVICE_QUOTATION='CREATE_SERVICE_QUOTATION'
export const GET_SERVICE_QUOTATION='GET_SERVICE_QUOTATION'
export const UPDATE_SERVICE_QUOTATION='UPDATE_SERVICE_QUOTATION'
export const DELETE_SERVICE_QUOTATION='DELETE_SERVICE_QUOTATION'

export const GET_BILLABLE_SERVICES='GET_BILLABLE_SERVICES'

