import React, { useState } from "react";
import { Input, Form } from "semantic-ui-react";
// import ExportOrdersButton from "./ExportOrdersButton";
// import { mapData } from "./useExcelOrderData";

// import {isMobile} from 'react-device-detect';

function TableActions(props) {
  const {
    handleSearchIds,
    handleSearchTerm,
    clientOrderIds,
    setClientOrderIds,
    skus,
    setSkus
  } = props;

  const [searchTerm, setSearch] = useState();

  return (
    <div>
      <div
        className="flex--left"
        style={{ marginTop: "20px", marginBottom: "1em" }}
      >
        <Form>
          <Input
            value={searchTerm}
            clear
            style={{ margin: "3px", minWidth: "360px" }}
            // loading={isLoading && filters.searchTerm.length > 0}
            placeholder="phone,tracking..."
            icon="search"
            name="searchTerm"
            type="form"
            onSubmit={() => alert("submited")}
            onChange={(e, { value }) => {
   
              setSearch(value)
              if(value==='') handleSearchTerm(value)
            }}
            action={{
              icon: "search", // "times"
              onClick: (e) => {
                e.preventDefault();
                handleSearchTerm(searchTerm);
              },
            }}
            iconPosition="left"
          />
        </Form>
        <Form>
          <Input
            value={clientOrderIds}
            clear
            style={{ margin: "3px", minWidth: "360px" }}
            // loading={isLoading && filters.searchTerm.length > 0}
            placeholder="clientOrderIds..."
            icon="search"
            name="clientOrderIds"
            type="form"
            onSubmit={() => alert("submited")}
            onChange={(e, { value }) => {
              
              setClientOrderIds(value);
              if(!value||value==='')                handleSearchIds(value);

            }}
            action={{
              icon: "search", // "times"
              onClick: (e) => {
                e.preventDefault();

                handleSearchIds(clientOrderIds);
              },
            }}
            iconPosition="left"
          />
        </Form>
        <Form>
          <Input
            value={skus}
            clear
            style={{ margin: "3px", minWidth: "360px" }}
            // loading={isLoading && filters.searchTerm.length > 0}
            placeholder="product skus..."
            icon="search"
            name="skus"
            type="form"
            onSubmit={() => alert("submited")}
            onChange={(e, { value }) => {
              
              setSkus(value);
              // if(!value||value==='')                handleSearchIds(value);

            }}
            action={{
              icon: "search", // "times"
              onClick: (e) => {
                e.preventDefault();

                handleSearchIds(clientOrderIds);
              },
            }}
            iconPosition="left"
          />
        </Form>
      </div>
    </div>
  );
}

export default TableActions;
