import React, { useEffect, useState } from "react";
import {
  Header,
  Button,
  Input,
  Dropdown,
  Divider,
  Checkbox,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";

import { getAllVat, createVat } from "../../../../actions/vat";

import CountryFilter from "../../../Filters/CountryFilter";
import CurrencyFilter from "../../../Filters/CurrencyFilter";

const config = {
  vatNameOptions: ["Standard rate", "Low rate", "other"],
  vatFormDefaultValues: {
    name: "Standard rate",
    country: "",
    value: "0"
  }
};

function PricingForm(props) {
  const {
    initFormValues,
    handleCloseModal,
    isEditModeOn,

    getAllVat,
    createVat,
    vatList
  } = props;

  const [pricing, setPricing] = useState(initFormValues);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [isVatChecked, setVatChecked] = useState(false);
  const [isVatFormOpen, setVatFormOpen] = useState(false);

  // set VAT checkox state when modal is open
  useEffect(() => setVatChecked(!!initFormValues.VAT), [initFormValues.VAT]);

  // set form initial values when modal is open
  useEffect(() => setPricing(initFormValues), [initFormValues]);

  // check if all fields are compleated (except VAT) on field change
  useEffect(() => {
    const atLeastOneFieldNotCompleted = Object.keys(pricing).find(key => {
      if (["VAT"].includes(key)) return false;
      else return !pricing[key];
    });
    setSaveDisabled(atLeastOneFieldNotCompleted);
  }, [pricing]);

  // If country selected and VAT checked Vat GetAll req
  useEffect(() => {
    if (pricing.country && isVatChecked) getAllVat(pricing.country);
  }, [pricing.country, , isVatChecked]);

  // if VAT checkbox is unchecked remove VAT value
  // useEffect(() => {
  //   if (!isVatChecked && pricing.VAT) setPricing(p => ({ ...p, VAT: "" }));
  // }, [isVatChecked]);

  const handleFieldChange = (e, { name, value }) => {
    setPricing(p => ({ ...p, [name]: value }));
  };

  const handleVatCheck = () => setVatChecked(prevState => !prevState);

  const handlePlusClick = e => {
    e.preventDefault();
    setVatFormOpen(true);
  };
  const handleCancelVatForm = () => setVatFormOpen(false);

  const handleSaveVat = (e, vat) => {
    e.preventDefault();
    createVat(vat);
    setVatFormOpen(false);
  };

  const handleCancel = () => handleCloseModal();

  const handleSubmit = () => {
  
  };

  return (
  
    <section className="product-pricing-form">
      <Header as="h3">{isEditModeOn ? "Edit" : "New"} Pricing</Header>
      <Divider />

      <form autoComplete="off">
        <div className="product-pricing-form__grid">
          <label className="product-pricing-form__label">Country:</label>
          <CountryFilter
            value={pricing.country}
            handleChange={handleFieldChange}
          />

          <label className="product-pricing-form__label">Currency:</label>
          <CurrencyFilter
            value={pricing.currency}
            handleChange={handleFieldChange}
          />

          <label className="product-pricing-form__label">Brutto Price:</label>
          <Input
            name="brutto_price"
            value={pricing.brutto_price}
            onChange={handleFieldChange}
          />

          <Checkbox
            label="with VAT"
            disabled={!pricing.country}
            checked={isVatChecked}
            onChange={handleVatCheck}
          />
        </div>

        {isVatChecked && (
          <div className="product-pricing-form__grid">
            <div className="flex--between vertical--middle">
              <label className="product-pricing-form__label">VAT:</label>
              <Button
                compact
                size="mini"
                icon="plus"
                onClick={handlePlusClick}
                disabled={isVatFormOpen}
              />
            </div>
            <Dropdown
              selection
              search
              clearable
              placeholder="VAT"
              name="VAT"
              value={pricing.VAT}
              onChange={handleFieldChange}
              options={vatList.map(({ _id, name, value }) => ({
                key: _id,
                value: _id,
                text: `${name} - ${value}%`
              }))}
            />
          </div>
        )}

        {isVatFormOpen && (
          <VatForm
            handleCancelVatForm={handleCancelVatForm}
            handleSaveVat={handleSaveVat}
          />
        )}
      </form>
      <div className="flex--right mt--2">
        <Button onClick={handleCancel}>Cancel</Button>

        <Button color="blue" onClick={handleSubmit} disabled={saveDisabled}>
          Save
        </Button>
      </div>
    </section>
  );
}

const mapStateToProps = reduxState => ({
  vatList: reduxState.vat.list
});
export default connect(mapStateToProps, {

  getAllVat,
  createVat
})(PricingForm);

// VAT FORM

function VatForm(props) {
  const { handleCancelVatForm, handleSaveVat } = props;

  const [vat, setVat] = useState(config.vatFormDefaultValues);
  const handleFieldChange = (e, { value, name }) =>
    setVat(vat => ({ ...vat, [name]: value }));

  const [saveDisabled, setSaveDisabled] = useState(true);
  useEffect(() => {
    const atLeastOneFieldNotCompleted = Object.keys(vat).find(key => !vat[key]);
    setSaveDisabled(atLeastOneFieldNotCompleted);
  }, [vat]);

  return (
    <Segment secondary>
      <div className="product-pricing-form__grid_vat product-pricing-form__grid ">
        <label className="product-pricing-form__label">Name:</label>
        <Dropdown
          selection
          search
          placeholder={config.vatNameOptions[0]}
          name="name"
          value={vat.name}
          onChange={handleFieldChange}
          options={config.vatNameOptions.map(item => ({
            key: item,
            value: item,
            text: item
          }))}
        />
        <label className="product-pricing-form__label">Country:</label>
        <CountryFilter
          name="country"
          value={vat.country}
          handleChange={handleFieldChange}
        />
        <label className="product-pricing-form__label">Value:</label>
        <Input
          type="number"
          min="0"
          max="100"
          placeholder="%"
          name="value"
          value={vat.value}
          onChange={handleFieldChange}
        />
        <label />
        <div>
          <Button compact basic size="tiny" onClick={handleCancelVatForm}>
            Cancel
          </Button>
          <Button
            compact
            size="tiny"
            disabled={saveDisabled}
            onClick={e => handleSaveVat(e, vat)}
          >
            Save
          </Button>
        </div>
      </div>
    </Segment>
  );
}
