/* eslint-disable default-case */
import React, { useReducer, useState, useEffect } from "react";
import { Segment, Button, Header, Divider } from "semantic-ui-react";
import Img from "react-image";
import ImgsViewer from "react-images-viewer";

import Previews from "../../Uploader/photos";
import ImageSlider from "./ImageSlider";
import { removeFiles, getFiles } from "../../../actions/files";
import { Alert } from "rsuite";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initialState = {
  executeChildMethod: false,
  editMode: false,
  // photos: [],
  shipmentOpen: false,
  newShipment: true,
  isLightBoxOpen: false,
  currentImg: 0,
  imgURLs: [],
  hasUpdates: false,
  updates: {
    removedFiles: [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "UPLOAD_EXECUTED":
      return { ...state, executeChildMethod: false, editMode: false };

    case "VIEW_PHOTO":
      const imageURLs =
        action.payload.files && action.payload.files.length > 0
          ? action.payload.files.map(({ publicurl }) => ({
              src: publicurl,
            }))
          : [];
      return {
        ...state,
        imgURLs: imageURLs,
        currentImg: action.payload.index,
        isLightBoxOpen: true,
        updates: {
          ...state.updates,
          removedFiles: [],
        },
      };

    case "GO_TO_NEXT":
      if (state.currentImg < state.imgURLs.length)
        return { ...state, currentImg: state.currentImg + 1 };
      else return state;

    case "GO_TO_PREVIOUS":
      if (state.currentImg > 0)
        return { ...state, currentImg: state.currentImg - 1 };
      else return state;

    case "CLOSE_LIGHTBOX":
      return { ...state, isLightBoxOpen: false };

    case "DELETE_PHOTO":
      return {
        ...state,
        updates: {
          ...state.updates,
          removedFiles: [...state.updates.removedFiles, action.payload.id],
        },
      };

    case "UPDATE_PHOTOS_LIST":
      return {
        ...state,
        updates: { ...state.updates, removedFiles: [], addedFiles: [] },
      };
  }
}

function Images(props) {
  const { openShipment, setReloadShipment } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsloading] = useState(false);
  const [files, setFiles] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleDeleteSelected = () => {
    // dispatch({type:'UPDATE_PHOTOS_LIST'})
    setIsloading(true);

    removeFiles(state.updates.removedFiles)
      .then((resp) => {
        Alert.success("Files removed successfully");
        setReloadShipment(true);
        dispatch({ type: "UPDATE_PHOTOS_LIST" });
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(JSON.stringify(err.message), 5000);
      });
  };

  const filesUploaded = () => {
    

    setRefresh(!refresh);
  };

  useEffect(() => {
        setIsloading(true);

        if (openShipment._id)
          setTimeout(() => {
            getFiles({
              triggerId: openShipment._id,
              trigger: "Inboundparcel",
              filetype: "image",
            }).then((resp) => {
              setFiles(resp);
              setIsloading(false);
            });
          }, 1000);
  }, [openShipment, refresh]);

  const images =
    files && files.length > 0
      ? files.map((photo, index) => {
          let opacity = state.updates.removedFiles.includes(photo._id)
            ? { opacity: 0.5 }
            : { opacity: 1 };
          return (
            <div>
              <div style={{ margin: "10px" }}>
                <Img
                  style={opacity}
                  onClick={() =>
                    dispatch({ type: "VIEW_PHOTO", payload: { index, files } })
                  }
                  src={photo.publicurl}
                  alt="text"
                />

                {
                  !state.updates.removedFiles.includes(photo._id) && (
                    <Button
                      fluid
                      size="tiny"
                      // icon="trash"
                      // className="drop_button"
                      onClick={() =>
                        dispatch({
                          type: "DELETE_PHOTO",
                          payload: { index, id: photo._id },
                        })
                      }
                    >
                      delete
                    </Button>
                  )
                  // icon="trash"
                }
                {state.updates.removedFiles.includes(photo._id) && (
                  <div>will be deleted</div>
                )}
              </div>
            </div>
          );
        })
      : [];

  return (
    <Segment loading={isLoading}>
      <div className="inbound_photosx">
        {images.length > 0 && (
          <div>
            <ImageSlider images={images} />
                {state.updates.removedFiles.length > 0 && (
              <Button
                color="red"
                fluid
                style={{ marginTop: "50px" }}
                onClick={() => handleDeleteSelected()}
              >
                {" "}
                Delete selected {`[${state.updates.removedFiles.length}]`}
              </Button>
            )}
          </div>
        )}
        <Divider></Divider>

        {state.imgURLs.length > 0 && (
          <ImgsViewer
            imgs={state.imgURLs}
            currImg={state.currentImg}
            isOpen={state.isLightBoxOpen}
            onClickPrev={() => dispatch({ type: "GO_TO_PREVIOUS" })}
            onClickNext={() => dispatch({ type: "GO_TO_NEXT" })}
            onClose={() => dispatch({ type: "CLOSE_LIGHTBOX" })}
            showThumbnails={true}
            backdropCloseable={true}
            preloadNextImg={false}
            // customCtrls={['test']}
            // closeBtnTitle={'CLOSE'}
          />
        )}

        <Header as="h3">Add photos</Header>
        <Previews
          isLoading={isLoading}
          setLoading={setIsloading}
          getUploadedFiles={filesUploaded}
          businessclient={
            openShipment.businessclient ? openShipment.businessclient._id : null
          }
          triggerId={openShipment._id}
          trigger={"Inboundparcel"}
          name={"photos"}
          files={state.updates.addFiles}
        />
      </div>
    </Segment>
  );
}

export default Images;
