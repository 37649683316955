import React,{useState,useEffect} from 'react'
import {Form,Table,Button} from 'semantic-ui-react'
import {apiCall} from "../../../services/api";
import {Link} from 'react-router-dom'

const Findorders=({businessclient,getOrder,resetList})=>{

    const [searchTerm,setSearchTerms]   = useState()
    const [searchResult,setResult]      = useState([])
    const [tableRows,setRows]           =useState()
    const [linkedOrder,setLinkedOrder] = useState()
    const [isloading,setIsloading]=     useState(false)

const findOrders =()=>{
setIsloading(true)
        const query  = {
                        businessclient,
                        searchTerm
                    }
                    
        const url = `${process.env.REACT_APP_API}/parcels/inbound/get/relatedorders`;

        apiCall('post',url,query)
            .then(resp=>{
                setResult(resp)
                setIsloading(false)
            })
            .catch(err=>{
                setIsloading(false)
                // console.log(err)
            })
    }

useEffect(()=>{

            getOrder(linkedOrder) //parent callback function

},[linkedOrder,getOrder])

useEffect(()=>{
    if(searchResult.length>0)    {
        
        const rows = searchResult.map((row,index)=>(
            <Table.Row>
                <Table.Cell>
                    {index+1}
                </Table.Cell>
                <Table.Cell><Link to={`/orders/${row._id}`} target='_blank'>{row.clientOrderNr}</Link></Table.Cell>
                <Table.Cell>
                            <div>
                                {row.shipping.shipTo.client.firstName} {row.shipping.shipTo.client.lastName}
                            </div>
                            <div className="small--text">
                                <div>phone:{row.shipping.shipTo.phone}</div>
                                <div>street:{row.shipping.shipTo.street} {row.shipping.shipTo.streetNr}</div>
                                <div>{row.shipping.shipTo.postcode},{row.shipping.shipTo.city} </div>
                            </div>
                    </Table.Cell>
                <Table.Cell>{row.items.map(item=><div>{item.name}</div>)}</Table.Cell>
                <Table.Cell>
                      <Button color='blue' fluid
                            onClick={()=>setLinkedOrder(row._id)}
                        >Link this order</Button></Table.Cell>
            </Table.Row>
        ))

        setRows(rows)
    }



},[ searchResult])


useEffect(()=>{
    if(resetList){
        setLinkedOrder()
        setResult([])
    }
},[resetList])

return (<div>
            <h4>Find related order</h4>
            <Form loading={isloading} onSubmit={()=>findOrders()}>
                <Form.Input
                     placeholder='name,order nr,phone'
                     name='searchTerm'
                     value={searchTerm}
                     onChange={(e,{value})=>setSearchTerms(value)}
                    >

                </Form.Input>
            
                <Form.Button fluid content='Search' />
            </Form>

          <segment  id='serchresult'>
                <Table style={{marginTop:'10px'}}>
                    <Table.Body>
                        {searchResult.length>0? tableRows:"no orders"}
                    </Table.Body>
                </Table>
            </segment>
 </div>)
}

export default Findorders