

export const updateFilters = (newFilter) => {
  return {
    type: "UPDATE_MAIN_FILTERS",
    payload: newFilter,
  };
};
export const clearFilters = () => {
  // localStorage.selectedClient=''
  // localStorage.selectedWarehouse=''
  localStorage.removeItem('selectedWarehouse')
  localStorage.removeItem('selectedClient')
  return {
    type: "CLEAR_MAIN_FILTERS",
  };
};


export const setDropList = ({ name, list }) => {
  return {
    type: "SET_DPOP_LIST",
    payload: {
      name,
      list,
    },
  };
};