import React, { useState, useEffect } from "react";
import { Button, Input, Divider } from "semantic-ui-react";
// import { connect } from "react-redux";
// import { getClientProductList } from "../../../actions/product.js";
import "./productlist.scss";
// import ProductFilter from "../../../Filters/ProductFilter";
import ProductFilterList from "../../../Filters/ProductFilterList";
import { getQuotations } from "../../../../actions/product";

const initState = {
  product: null,
  quantity: 1,
  price: 0,
  codes: "",
  name: "",
  weight: "",
};

const AddProduct = (props) => {
  const [state, setState] = useState(initState);
  // const [quotation, setQuotation] = useState({ amount: null });
  const [isLoading, setIsloading] = useState(false);

  const handleSubmit = () => {
    const prodToAdd = {
      product: state.product,
      quantity: Number(state.quantity),
      price: Number(state.price) * Number(state.quantity),
      sku: state.codes.sku,
      name: state.name,
      weight: state.weight,
    };
    // console.log(`in submit`,prodToAdd)
    props.handleAddProduct(prodToAdd);
    setState(initState);
  };

  const { businessclient, warehouse, country } = props;
  const { quantity, price } = state;

  useEffect(() => {
    if (state.product) {
      setIsloading(true);
      getQuotations(state.product).then((resp) => {
        setState({ ...state, price: 0 });

        if (resp.length > 0) {
          //find one for this country

          for (let i = 0; i < resp.length; i++) {
            if (country === resp[i].country) {
              setState({ ...state, price: resp[i].amount });
            }
          }
        }

        setIsloading(false);
      });
    }
  }, [state.product]);

  return (
    <div>
      <div>
        <ProductFilterList
          style={{ margin: "4px" }}
          useFilter={false}
          client={businessclient}
          warehouse={warehouse}
          fluid={true}
          disabled={!businessclient}
          handleChange={(value) => {
            setState({ ...state, product: value });
          }}
        />
        <Divider></Divider>

        {state.product && (
          <div className="flex--between">
            <div>
              <h4>Quotation price </h4>
            </div>

            <Input
              loading={isLoading}
              type="number"
              min="0"
              placeholder="getting price ..."
              name="price"
              value={price}
              onChange={(e, d) => setState({ ...state, price: d.value })}
            />
            <Input
              // fluid
              type="number"
              min="1"
              placeholder="quantity"
              name="quantity"
              value={quantity}
              onChange={(e, d) => setState({ ...state, quantity: d.value })}
            />

            <Button
              className="add-product-button"
              color="grey"
              basic
              content="ADD"
              onClick={handleSubmit}
              style={{
                height: "30px",
                float: "right",
                margin: "5px 0px 5px 0px",
              }}
              // disabled={!state.product || !state.quantity}
            />
          </div>
        )}
        <Divider></Divider>
        {/* {state.product && (
          <div
            className="flex--between"
            // style={{margin:'10px'}}
          >
            <Input
              // fluid
              type="number"
              min="1"
              label="Quantity:"
              placeholder="quantity"
              name="quantity"
              value={quantity}
              onChange={(e, d) => setState({ ...state, quantity: d.value })}
            />

            <Input
              // fluid
              type="number"
              min="0"
              label="Price:"
              placeholder="price"
              name="price"
              value={price}
              onChange={(e, d) => setState({ ...state, price: d.value })}
            />
            <Button
              className="add-product-button"
              color="grey"
              basic
              content="ADD"
              onClick={handleSubmit}
              style={{
                height: "30px",
                float: "right",
                margin: "5px 0px 5px 0px",
              }}
              // disabled={!state.product || !state.quantity}
            />
          </div>
        )}
        <Divider></Divider> */}
      </div>
    </div>
  );
};

export default AddProduct;
