import { apiCall } from "../services/api";
import { addError } from "./error";
import {Alert} from 'rsuite'

export const addNotification = (notification) => ({
  type: "ADD_NOTIFICATION",
  notification,
});

export const createNotification = ({businessclient,query}) =>  {
  const url = `${process.env.REACT_APP_API}/notifications/businessclients/${businessclient}`;
  return apiCall("post", url,query)
    .then((resp) =>
     resp
    )
    .catch((err) => err);
};
export const getNotifications = (businessclient) =>  {
  const url = `${process.env.REACT_APP_API}/notifications/businessclients/${businessclient}`;
  return apiCall("get", url)
    .then((resp) =>
     resp
    )
    .catch((err) => err);
};


export const updateNotification = ({notification,query}) =>{
  const url = `${process.env.REACT_APP_API}/notifications/${notification}`;
  return apiCall("put", url,query)
    .then((resp) =>
     resp
    )
    .catch((err) => err);


};
export const deleteNotification = (id) => {
  const url = `${process.env.REACT_APP_API}/notifications/${id}`;
  return apiCall("delete", url)
    .then((resp) =>
     resp
    )
    .catch((err) => err);
};

export const getUserNotifications = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/notifications`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_USER_NOTIFICATIONS",
        payload: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const delteteOneUserNotification = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/notifications/${id}`;
  apiCall("delete", url)
    .then((resp) =>
      dispatch({
        type: "DELETE_ONE_USER_NOTIFICATION",
        payload: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const delteteAllUserNotifications = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/notifications`;
  apiCall("delete", url)
    .then((resp) =>
      dispatch({
        type: "DELETE_USER_NOTIFICATIONS",
        payload: resp,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

// INFO TAGS
export const getInfoTags = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/infotags`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_INFOTAGS", payload: resp }))
    .catch((err) => dispatch(addError(err.message)));
};

export const openPopup = (popupProps, popupType) => ({
  type: "OPEN_POPUP",
  payload: { popupProps, popupType },
});
export const closePopup = () => ({ type: "CLOSE_POPUP" });


//get Enums

export const getnotificationTriggers = ()=>{
  const url = `${process.env.REACT_APP_API}/enums/notifications/triggers`
  return apiCall('get',url)
  .then(resp=>resp)
  .catch(err=>Alert.error(err.message))
}


export const getnotificationTypes = ()=>{
  const url = `${process.env.REACT_APP_API}/enums/notifications/types`
  return apiCall('get',url)
  .then(resp=>resp)
  .catch(err=>Alert.error(err.message))
}
