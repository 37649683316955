import React from "react";
import { Table, Loader, Header } from "semantic-ui-react";
// import { Link } from "react-router-dom";
// import Moment from "react-moment";

function ServiceTypesTable({
  services,
  isLoading,
  currentPage,
  itemsPerPage,
  style = {},
  deleteServiceType
}) {
  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !services.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
          No services found
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Category</Table.HeaderCell>
      <Table.HeaderCell>Billing Type</Table.HeaderCell>
      <Table.HeaderCell>Recuring period</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
      
    </Table.Row>
  );

  const tableBody = services.map(
    (
      {
        _id,
      name,
      description,
      category,
      billing_type,
      recurring_period
      },
      i
    ) => (
      <Table.Row key={_id}>
      
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{name}
          <div className="smallinfo">{_id}</div >
        </Table.Cell>
        <Table.Cell>{description}</Table.Cell>
        <Table.Cell>{category}</Table.Cell>
        <Table.Cell>{billing_type}</Table.Cell>
        <Table.Cell>{recurring_period}</Table.Cell>
        <Table.Cell><button onClick={()=>deleteServiceType(_id)}>delete</button></Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very" style={style} loading={true}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default ServiceTypesTable;
