import React, { useState, useEffect } from "react";
import { Header, Button, Icon } from "semantic-ui-react";

import ViewItem from "../ViewItem";
import RouteItem from "../RouteItem";
import FilterItem from "../FilterItem";

function RoleSettings({ role, settings, adminSettings, action }) {
  const [settingsState, setSettings] = useState(settings);
  useEffect(() => setSettings(settings), [settings]);
  // const { filters, routes } = settingsState;

  const [showViews, setShowViews] = useState(false);
  const [showRoutes, setShowRoutes] = useState(false);

  const handleFilterToggle = ({ filter, checked }) => {
    setSettings((prev) => ({
      ...prev,
      filters: { ...prev.filters, [filter]: checked ? [] : null },
    }));
  };
  const handleFilterSelection = ({ filter, value }) => {
    setSettings((prev) => ({
      ...prev,
      filters: { ...prev.filters, [filter]: value },
    }));
  };
  const handleViewToggle = ({ view, checked }) => {
    setSettings((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        views: {
          ...prev.filters.views,
          [view]: checked ? [] : null,
        },
      },
    }));
  };
  const handleViewSelection = ({ view, value }) => {
    setSettings((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        views: {
          ...prev.filters.views,
          [view]: value,
        },
      },
    }));
  };
  const handleRouteCheck = ({ route, action, checked }) => {
    setSettings((prev) => {
      let newActionsArr = prev.routes[route] ? prev.routes[route] : [];
      newActionsArr = checked
        ? [...newActionsArr, action]
        : newActionsArr.filter((a) => a !== action);
      newActionsArr = newActionsArr.length ? newActionsArr : null;

      return {
        ...prev,
        routes: { ...prev.routes, [route]: newActionsArr },
      };
    });
    
  };

  return (
    <div className="role-settings">
      {/* FILTERS */}
      <Header as="h5">Filters:</Header>
      <ul className="role-settings__list">
        {Object.keys(adminSettings.filters)
          .filter((key) => key !== "views")
          .map((adminFilterName) => (
            <FilterItem
              key={adminFilterName}
              adminFilterName={adminFilterName}
              //   adminValues={adminSettings.filters[adminFilterName]}
              stateFilterValues={settingsState.filters[adminFilterName]}
              handleFilterToggle={handleFilterToggle}
              handleFilterSelection={handleFilterSelection}
            />
          ))}
      </ul>

      {/* VIEWS */}
      <Header
        as="h5"
        className="role-settings__clickable-header"
        onClick={() => setShowViews((prev) => !prev)}
      >
        Allowed Pages:
        <Icon
          style={{ marginLeft: "1em" }}
          name={`angle ${showViews ? "up" : "down"}`}
          size="large"
          color="grey"
        />
      </Header>
      {showViews && (
        <ul className="role-settings__list role-settings__list_routes">
          {Object.keys(adminSettings.filters.views).map((adminViewName) => (
            <ViewItem
              key={adminViewName}
              adminViewName={adminViewName}
              adminValues={adminSettings.filters.views[adminViewName]}
              stateValues={settingsState.filters.views[adminViewName]}
              handleViewToggle={handleViewToggle}
              handleViewSelection={handleViewSelection}
            />
          ))}
        </ul>
      )}

      {/* ROUTES */}
      <Header
        as="h5"
        className="role-settings__clickable-header"
        onClick={() => setShowRoutes((prev) => !prev)}
      >
        API Routes Access:
        <Icon
          style={{ marginLeft: "1em" }}
          name={`angle ${showRoutes ? "up" : "down"}`}
          size="large"
          color="grey"
        />
      </Header>
      {showRoutes && (
        <ul className="role-settings__list role-settings__list_routes">
          {Object.keys(adminSettings.routes).map((adminRouteName) => (
            <RouteItem
              key={adminRouteName}
              adminRouteName={adminRouteName}
              adminRouteValues={adminSettings.routes[adminRouteName]}
              stateRouteValues={settingsState.routes[adminRouteName]}
              handleRouteCheck={handleRouteCheck}
            />
          ))}
        </ul>
      )}

      <Button
        primary
        disabled={!role}
        style={{ marginTop: "1em" }}
        onClick={() => action({ role, roleSettings: settingsState })}
        content="Save"
      />
    </div>
  );
}

export default RoleSettings;
