import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Commentlist from "./Comments";
import Documents from "./Documents";

function FormWithComDoc({
  children,
  businessclient,
  loading,
  trigger,
  triggerId,
}) {
  return (
    <div className="pocomponent">
      <main>
        <Segment.Group>
          {loading && (
            <Dimmer active inverted style={{ minHeight: "70vh" }}>
              <Loader />
            </Dimmer>
          )}
          {!loading && children}
        </Segment.Group>
      </main>

      {triggerId && (
        <aside>
          {/* COMMENTS */}
          <Commentlist trigger={trigger} triggerId={triggerId} />
          <Documents
            accept={".pdf,.xlsx,.xls,.dot,.jpeg,.jpg"}
            businessclient={businessclient}
            trigger={trigger}
            triggerId={triggerId}
          />
        </aside>
      )}
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  currentUser: reduxState.user.currentUser,
});

export default connect(mapStateToProps, {})(FormWithComDoc);
