import React from "react";
import { Header, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <main className="not-found">
      <section className="not-found--wrapper">
        <Header as="h1" className="not-found--h1">
          404
        </Header>
        <Header as="h2" className="not-found--h2">
          Page Not Found
        </Header>
        <p>Sorry, we couldn't find that.</p>

        <div style={{ marginTop: "0.7em" }}>
          <Link to="/">
            <Button color="blue">
              <Icon name="arrow left" /> Go Back
            </Button>
          </Link>
        </div>
      </section>
    </main>
  );
}

export default NotFoundPage;
