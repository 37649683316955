import {
  apiCall
} from "../services/api";

export const getServiceQuotations = (filters) => {
  return new Promise((resolve, reject) => {
    let query = ''
    for (let item in filters) {
      query = `${query}&${item}=${filters[item]}`
    }
    let url = `${process.env.REACT_APP_API}/services/quotations?${query}`;

    apiCall('get', url)
      .then(resp => resolve(resp))
      .catch(err => {
        reject(err.message)
      });
  })


}
export const createServiceQuotation = (data) => {

  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/services/quotations`;
    apiCall('post', url, data)
      .then(resp => resolve(resp))
      .catch(err => {reject(err.message)
      });
  })


}
export const importServiceQuotation = (data) => {

  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/services/quotations/import`;
    apiCall('post', url, data)
      .then(resp => resolve(resp))
      .catch(err => reject(err));

  })

}
export const copyServiceQuotations = (data) => {

  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/services/quotations/copy`;

    apiCall('post', url, data)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));

  })



}
export const getServiceQuotation = (id) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/quotations/${id}`;

    apiCall('get', url)
      .then(resp => resolve(resp))
      .catch(err => {
       reject(err.message)
      });
  })


}
export const updateServiceQuotation = ({
  id,
  query
}) =>  {

  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/quotations/${id}`;

    apiCall('put', url, query)
      .then(resp => resolve(resp)
        //  dispatch({type:UPDATE_SERVICE_QUOTATION,payload:resp})
      )
      .catch(err => {
        reject(err.message)
      });
  })


}
export const deleteServiceQuotation = (id) =>  {

  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/quotations/${id}`;

    apiCall('delete', url)
      .then(resp => resolve(resp))
      .catch(err => {
        reject(err.message)
      });
  })


}
export const getBillableServices = ({
  id,
  filter
}) => {

  new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/quotations/${id}`;
    apiCall('post', url, filter)
      .then(resp => resolve(resp))
      .catch(err => {
        reject(err.message)
      });
  })

}