import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import {createLogger} from 'redux-logger';
import { BrowserRouter as Router } from "react-router-dom";
import rootReducer from "../reducers";
// import {authenticateUser} from '../actions';
import App from "./App";
import { setAuthorizationToken, setCurrentUser } from "../actions/auth";
import jwtDecode from "jwt-decode";
import { composeWithDevTools } from "redux-devtools-extension";
import HttpsRedirect from 'react-https-redirect';

import history from "../history";

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = composeWithDevTools({
  trace: true,
  function: function (fn) {
    return fn.toString();
  },
});
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

//if page refresh, check if jwtToken exist
if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  // console.log(`decoded token ${JSON.stringify(jwtDecode(localStorage.jwtToken))}`)
  store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
  //prevent from manually tampering with the key of jwtToken in localStorage
  try {
  } catch (error) {
    store.dispatch(setCurrentUser({}));
  }
}

const CRM = () => (
  <Provider store={store}>
    <HttpsRedirect>
      <Router history={history}>
        <App />
      </Router>
    </HttpsRedirect>
  </Provider>
);

export default CRM;
