import React  from 'react'

import {Table} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

function Promolist ({promos=['tes']}){

const headers  = (
<Table.Header>
  <Table.Row>
    <Table.Cell>Name</Table.Cell>
    <Table.Cell>Country</Table.Cell>
    <Table.Cell>Products</Table.Cell>
    <Table.Cell>DisplayTo</Table.Cell>
    
    <Table.Cell>Period</Table.Cell>
    {/* <Table.Cell>Actions</Table.Cell> */}
    </Table.Row>
</Table.Header>)

const tableBody = promos.map(promo=>{
    
    const products = promo.promoProducts.map(item=>{
       
           return ( <li>
                {item.product.name} - price:{item.price} , q:{item.quantity}
            </li>)
        
    })
    const displayTo = promo.displayToProducts.map((item) => {
                        return (
                            <li>
                            {item.name}
                            </li>
                        );
                        });
    return (
      <Table.Row key={promo._id} className="prod-list__item product-row">
        <Table.Cell>
              <Link to={`/promos/${promo._id}`}>{promo.name}</Link>
              <p className ='smallinfo'>{promo.businessclient.name}</p>
                  </Table.Cell>
        <Table.Cell>{promo.country}</Table.Cell>
        <Table.Cell>{products}</Table.Cell>
        <Table.Cell>{displayTo.length>0?displayTo:'ALL'}</Table.Cell>
        <Table.Cell>start:na, end:na</Table.Cell>
        {/* <Table.Cell>2</Table.Cell> */}
      </Table.Row>
    );
})

    return (
      <div>
      
        <Table
          // unstackable
          inveted
          basic="very"
          // padded
          selectable
          // className="prod-list"
        >
           {headers}
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      </div>
    );
}


export default Promolist