import React from "react";
import { Dropdown } from "semantic-ui-react";

import { currency } from "./data";

function CurrencyFilter({
  value,
  handleChange,
  name = "currency",
  placeholder = "currency...",
  error = false,
  multiple,
  disabled=false,
  fluid=false,
  style={}
}) {
  const options = currency.map((currency) => ({
    key: currency,
    value: currency,
    text: currency,
  }));

  return (
    <Dropdown
      style={style}
      disabled={disabled}
      autoComplete="nope"
      placeholder={placeholder}
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={(e,{value})=>handleChange(value)}
      options={options}
    />
  );
}

export default CurrencyFilter;
