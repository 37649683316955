import React, { useEffect } from "react";
import { Button, Segment, Grid, Loader, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  getFulfilProduct,
  updateFulfilProduct,
  addProductsProductGroup,
  removeProductsProductGroup
} from "../../../actions/fulfilproducts";

import CanViewPage from "../../../role-based-access/CanViewPage";
import AddProducts from "./AddProducts";
import ProductGroupInfo from "./ProductGroupInfo";
import "../productGroup.scss";

function EditNewFulfilmentproduct(props) {
  const {
    productGroup = { products: [] },
    isLoading,
    getFulfilProduct,
    updateFulfilProduct,
    addProductsProductGroup,
    removeProductsProductGroup,
    match,
    history,
    businessclient
  } = props;

  useEffect(() => getFulfilProduct(match.params.id), []);

  const addProducts = products =>
    addProductsProductGroup({ _id: match.params.id, products });


  const removeProduct = productId =>
    removeProductsProductGroup({ _id: match.params.id, productId });
  const productsFromPG =
    productGroup.products && productGroup.products.length > 0
      ? productGroup.products.map(({ _id }) => _id)
      : [];

  // console.log({ productsFromPG });

  if (!productGroup) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else {
    return (
      <CanViewPage path="/settings/fulfilproducts/:id">
        <main className="a-wrapper product-group-container">
          <Link to="/settings/fulfilproducts">
            <Icon name="arrow left" /> Fullfilemnt settings
          </Link>
          <Segment>
            <div className="product-group-columns">
              <div className="product-group-columns__info">
                <ProductGroupInfo
                  productGroup={productGroup}
                  removeProduct={removeProduct}
                  updateFulfilProduct={updateFulfilProduct}
                  history={history}
                  businessclient={businessclient}
                />
              </div>
              <div className="product-group-columns__add-prod">
                <AddProducts
                  addProducts={addProducts}
                  productsFromPG={productsFromPG}
                  businessclient={businessclient}
                />
              </div>
            </div>
          </Segment>
        </main>
      </CanViewPage>
    );
  }
}

const mapStateToProps = reduxState => ({
  productGroup: reduxState.fulfilproducts.openfulfilproduct,
  isLoading: reduxState.loading.loading,
  businessclient: reduxState.fulfilproducts.openfulfilproduct.businessclient
});
export default connect(mapStateToProps, {
  getFulfilProduct,
  updateFulfilProduct,
  addProductsProductGroup,
  removeProductsProductGroup
})(EditNewFulfilmentproduct);
