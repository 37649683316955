import {
  GET_PICKINGLIST,
  SUCCESS_UPDATE_ORDERS_STATUS_PICKING,
  ADD_NEW_PICKING_LIST,
  GET_ALL_PICKING_LISTS,
  UPDATE_PICKING_LIST,
  UPADATE_FILTER_PICKING_LIST
} from "./actionTypes"

import { apiCall } from "../services/api";
import { addError, removeError } from "./error";
import { updateFilter } from "./orders";
const startLoading = () => ({
  type: "LOADING_START"
});
const stopLoading = () => ({
  type: "LOADING_STOP"
});

export const getInitialPickingList = ({dateTo_unix,businessclient}) => dispatch => {
  dispatch(removeError());
  dispatch(startLoading());
  const url = `${process.env.REACT_APP_API}/picking?dateTo_unix=${dateTo_unix}&businessclient=${businessclient}`;

  apiCall("get", url)
    .then(resp => dispatch(getPickingListAction(resp)))
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

const getPickingListAction = data => ({
  type: GET_PICKINGLIST,
  data
});

export const changeCourier = changes => dispatch => {
  dispatch(removeError());
  dispatch(startLoading());

  const url = `${process.env.REACT_APP_API}/picking/orders/changecourier`;
  apiCall("post", url, changes)
    .then(resp => {
      if (resp.status === "success") {
        const url = `${process.env.REACT_APP_API}/picking`;

        apiCall("get", url)
          .then(resp => dispatch(getPickingListAction(resp)))
          .catch(err => {
            dispatch(addError(err.message));
            dispatch(stopLoading());
          });
      }
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const getOrdersForPicking = list => dispatch => {
  dispatch(startLoading());
  dispatch(removeError());

  const url = `${process.env.REACT_APP_API}/picking`;

  apiCall("post", url, list)
    // .then(resp => dispatch(getOrdersForPickingAction(resp)))
    .then(resp => dispatch(stopLoading()))

    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const updateOrdersStatusPicking = orders => dispatch => {
   dispatch(startLoading())
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/picking/orders`;
  apiCall("post", url, orders)
    .then(resp => {
      if (resp.status === "success") {
        dispatch(
          updateFilter({
            status: "Product Picking"
          })
        );
        dispatch({
          type: SUCCESS_UPDATE_ORDERS_STATUS_PICKING
        });
      }
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const addNewPickingList = body => dispatch => {
  const url = `${process.env.REACT_APP_API}/picking/pickinglist`;
  apiCall("post", url, body)
    .then(resp => {
      dispatch({
        type: ADD_NEW_PICKING_LIST,
        payload: resp
      });
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const getAllPickingList = filtersUrl => (dispatch, getState) => {
  dispatch(startLoading());
  dispatch(removeError());

  const filters = getState().picking.filters;
  const { currentPage, itemsPerPage, product, dateFrom, dateTo } = filters;
  const filtersUrl =
    `?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}` +
    (dateFrom ? `&dateFrom=${Date.parse(dateFrom)}` : "") +
    (dateTo ? `&dateTo=${Date.parse(dateTo)}` : "") +
    (product.length ? product.map(p => `&product=${p}`).join("") : "");

  const url = `${process.env.REACT_APP_API}/picking/pickinglist/${filtersUrl}`;
  apiCall("get", url)
    .then(resp => {
      dispatch({
        type: GET_ALL_PICKING_LISTS,
        payload: resp
      });
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const updatePickingList = (id, updates) => dispatch => {
  dispatch(startLoading());
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/picking/pickinglist/${id}`;
  apiCall("post", url, updates)
    .then(resp => {
      dispatch({
        type: UPDATE_PICKING_LIST,
        payload: resp
      });
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const updateFilterPickingList = filter => {
  return {
    type: UPADATE_FILTER_PICKING_LIST,
    payload: filter
  };
};
