export const dataSet = [
  {
    sku: '2212',
    currency:'EUR',
    amount:54,
   
  },
  {
    sku: '212112',
    currency:'EUR',
    amount:51,
    
  },
  {
    sku: '322212',
    currency:'EUR',
    amount:19,
  }
];
export const colNames = [

  { label: "SKU", value: "sku" },
  { label: "Currency", value: "currency" },
  { label: "Amount", value: "amount" },
  

];
