import React from "react";
import {
  Header,
  Segment,
  Table,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

function SettingsTable({
  shaversSettings,
  isLoading,
  currentPage,
  itemsPerPage,
  openEditModal,
  deleteShaverSettings,
}) {
  if (isLoading)
    return (
      <div>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </div>
    );

  if (!shaversSettings.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No settings found
      </Header>
    );
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Sku</Table.HeaderCell>
      <Table.HeaderCell>Web</Table.HeaderCell>
      <Table.HeaderCell>Min Rate</Table.HeaderCell>
      <Table.HeaderCell>Max Rate</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = shaversSettings.map(
    ({ _id, country, sku, min_rate, max_rate, web }, i) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{sku}</Table.Cell>
        <Table.Cell>{web}</Table.Cell>
        <Table.Cell>{(min_rate * 100).toFixed(2)} %</Table.Cell>
        <Table.Cell>{(max_rate * 100).toFixed(2)} %</Table.Cell>
        <Table.Cell>
          <Button
            basic
            compact
            color="blue"
            size="tiny"
            icon="pencil"
            onClick={() =>
              openEditModal(true, {
                _id,
                country,
                sku,
                web,
                min_rate,
                max_rate,
              })
            }
          />

          <Button
            basic
            compact
            color="red"
            size="tiny"
            icon="trash"
            onClick={() => deleteShaverSettings({ id: _id })}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default SettingsTable;
