import { apiCall } from "../services/api";


export const addError = (error) => ({
  type: "ADD_ERROR",
  error,
});
export const removeError = () => ({
  type: "REMOVE_ERROR",
});

export const addSuccessMsg = (payload) => ({
  type: "ADD_SUCCESS_MSG",
  payload,
});
export const removeSuccessMsg = () => ({
  type: "REMOVE_SUCCESS_MSG",
});


// export const getErrorLogs = ()=>(dispatch)=>{
//   const url = `${process.env.REACT_APP_API}/health/errors`;

//   apiCall('get',url)
//   .then(resp=>dispatch({type:"GET_ERRORS",list:resp}))
//   .catch((err) => dispatch(addError(err.message)));

// }


export const getErrorLogs = () => (dispatch) => {
  
  apiCall("get",  `${process.env.REACT_APP_API}/health/errors`)
    .then((data) =>
      dispatch({ type: "GET_ERRORS", list: data })
    )
    .catch((err) => dispatch(addError(err.message)));
};