import React from "react";
import {
  Table,
  Button,
  Label,
  Loader,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export const statusColor = {
  New: "red",
  pending: "orange",
  Shipping: "blue",
  Delivered: "green",
  "No goods": "yellow"
};

const ShipHeaders = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {/* < Checkbox></Checkbox> */}
      <Table.HeaderCell>ID</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Client</Table.HeaderCell>
      <Table.HeaderCell>Items Shipped</Table.HeaderCell>
      <Table.HeaderCell>From</Table.HeaderCell>
      <Table.HeaderCell>To</Table.HeaderCell>
      <Table.HeaderCell>Tracking</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export const ShipmentTable = props => {
  const { shipments, deleteShipment, index, isLoading } = props;

  const shipRows = shipments.map((shipment, i) => {
    let items = [];
    if (shipment.pos.length > 0) {
      items = shipment.pos.map((po) =>
        po.items.map((item,index) => {
          if(po.status==='Delivered'&&item.quantity_received>0 ) return (<div>{item.product.name} - {item.quantity_received}</div>)
          if(po.status!=='Delivered'&&item&&item.product&&item.quantity_shipped>0) return (<div>{item.product.name} - {item.quantity_shipped}</div>)
                //   return (
                //   <div>
                //     {po.status==='Delivered'&&product.quantity_received>0 &&`${product.name} - ${product.quantity_received}`}
                //     {po.status!=='Delivered'&&`${product.name} - ${product.quantity_shipped}`}
                //     <br />
                //     {/* {`Ordered - ${product.quantity.ordered}`}                      */}
                //   </div>
                // )
              }
        )
      );
    }
    
    return (
      <Table.Row key={shipment._id}>
        <Table.Cell>{index + 1 + i}</Table.Cell>
        <Table.Cell>
          <Link to={`/shipments/outbound/${shipment._id}`}>
            {shipment._id.substr(-9)}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Label color={statusColor[shipment.status]}>{shipment.status}</Label>
        </Table.Cell>
        <Table.Cell> 
        {shipment.businessclient.name}
          </Table.Cell>
        {/* <Table.Cell > <Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment></Table.Cell> */}
        <Table.Cell>{items}</Table.Cell>
        <Table.Cell>
          {shipment.shipFrom&&
            <div className="flex--column">
              <div>{shipment.shipFrom.name}</div>
              <div>{shipment.shipFrom.address.country}</div>
              <div  className="smallinfo">{shipment.shipFrom.address.street},{shipment.shipFrom.address.streetNr}</div>
            </div>}
    
          <div className="smallinfo">
            <Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment>
          </div>
        </Table.Cell>

        <Table.Cell>
         
        {shipment.shipTo&&
            <div className="flex--column">
              <div>{shipment.shipTo.name}</div>
              <div>{shipment.shipTo.address.country}</div>
              <div className="smallinfo">{shipment.shipTo.address.city}</div>
              <div  className="smallinfo">{shipment.shipTo.address.street},{shipment.shipTo.address.streetNr}</div>
            </div>}
        {shipment.shipToAddress&&
            <div className="flex--column">
              <div>{shipment.shipToAddress.address.country}</div>
              <div className="smallinfo">{shipment.shipToAddress.address.city}</div>
              <div  className="smallinfo">{shipment.shipToAddress.address.street},{shipment.shipToAddress.address.streetNr}</div>
            </div>}
    
          <div className="smallinfo">
            <Moment format="DD-MM-YY HH:mm">{shipment.updatedAt}</Moment>
          </div>
        </Table.Cell>
        <Table.Cell>{shipment.tracking.nr}</Table.Cell>
        <Table.Cell>
          {shipment.status !== "Delivered" && (
            <Button
              icon="trash alternate outline"
              // color = 'grey'
              // id = 'itemlistrow_action'
              basic
              onClick={() => deleteShipment(shipment._id)}
            ></Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <div className="table-wrapper--horizontal-scroll mb--1">
      <Table basic="very" striped selectable unstackable>
        <ShipHeaders />
        <Table.Body>{!isLoading && shipments.length > 0 && shipRows}</Table.Body>
      </Table>
      {isLoading && (
        <div style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Loader active inline="centered" />
        </div>
      )}

      {!isLoading && shipments.length === 0 && (
        <Header
          style={{ paddingTop: "2em", paddingBottom: "1em" }}
          as="h4"
          textAlign="center"
          color="grey"
        >
          No Shipments Found
        </Header>
      )}
    </div>
  );
};
