import React from "react";
import { Form, Header, Button, Divider, Checkbox } from "semantic-ui-react";

class CompanyForm extends React.Component {
  render() {
    const { companyName, phone, taxId, handleChange } = this.props;
    return (
      <div className="form-columns__client">
        <Form.Input
         autoComplete="nope"
          width={16}
          name="companyName"
          label="Company Name"
          placeholder="Name"
          value={companyName}
          onChange={handleChange}
        />
        <Form.Input
         autoComplete="nope"
          width={16}
          name="taxId"
          label="TAX ID"
          placeholder="TAX ID"
          value={taxId}
          onChange={handleChange}
        />
        <Form.Input
         autoComplete="nope"
          width={16}
          name="phone"
          label="Phone"
          placeholder="Phone"
          value={phone}
          onChange={handleChange}
        />
      </div>
    );
  }
}

export default CompanyForm;
