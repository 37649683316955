import React from "react";
import { Tab, Header } from "semantic-ui-react";

import CanViewPage from "../../../role-based-access/CanViewPage";
import NewProductForm from "./NewProductForm";
import ProductFromExcel from "./ProductFromExcel";
import "./newproducts.scss";

function NewProducts({ errors }) {
  const panes = [
    {
      menuItem: "Single Product Form",
      render: () => (
        <Tab.Pane attached={false}>
          <NewProductForm errors={errors} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Upload from Excel file",
      render: () => (
        <Tab.Pane attached={false}>
          <ProductFromExcel />
        </Tab.Pane>
      )
    }
  ];

  return (
    <CanViewPage path="/products/create/new">
      <>
        <Header errors={errors} headerText=" Add new products" />

        <main className="a-wrapper add-products__wrapper">
          <Tab
            menu={{
              color: "teal",
              inverted: true,
              attached: false,
              tabular: false
            }}
            panes={panes}
          />
        </main>
      </>
    </CanViewPage>
  );
}

export default NewProducts;
