
import { apiCall } from "../services/api";

const createFilterUrl = (filters) =>
  filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";


export const getServices = (filters) => 
 {
  return new Promise((resolve,reject)=>{
      const filtersUrl = createFilterUrl(filters);
      const url = `${process.env.REACT_APP_API}/services?${filtersUrl}`;
      apiCall("get", url)
        .then((resp) => resolve(resp))
        .catch((error) => {
         reject(error.message)
        });
  })

};

export const getServicesForExport = (filters) => {
  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/services/export`;
        apiCall("post", url, filters)
          .then((resp) =>
           resolve(resp)
          )
          .catch((error) => {
           reject(error.message)
          });
  })

};

export const getServicesForBilling = (filters) =>  {
  return new Promise((resolve, reject) => {
        const filtersUrl = createFilterUrl(filters);
        const url = `${process.env.REACT_APP_API}/services/billpreview?${filtersUrl}`;
        apiCall("get", url)
          .then((resp) =>
          resolve(resp)
          )
          .catch((error) => {
           reject(error.message)
          });
  })

};

///////////////////////////////////////////////////////////////////////////////
// BILLS


export const getBillServices = (billId) => {
 return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/bills/${billId}/services`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
 })

};

export const createBill = (filters, history) =>  {
  
return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/bills`;
      apiCall("post", url, filters)
        .then((resp) => {
          // dispatch({ type: "CREATE_BILL", payload: resp });
          if (resp.length > 0) {
            history.push(`/billing/${resp[0]._id}`);
          }
        })
        .catch((error) => {
         reject(error.message)
        });
})

};

export const createBillManually = (body, history) =>  {

  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/bills/manual`;
        apiCall("post", url, body)
          .then((resp) => resolve(resp))
          .catch((error) => {
            reject((error.message))
          });
  })
  // dispatch(startLoading);
  
};


export const deleteBill = ({ id, history }) => {

  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/bills/${id}`;
       apiCall("delete", url)
        .then((resp) => {
          // if(resp.status ==='success') dispatch(deleteBillAction(id))
          history.push(`/billing`);
        })
        .catch((error) => {
       reject(error.message)
        });
  })

};


////////////////////////////////////////////////////////////////////
// Credit Note
export const updateCreditNoteFilters = (newFilter) => ({
  type: "UPDATE_CREDIT_NOTE_FILTER",
  payload: newFilter,
});


export const getInvoiceCreditNotesSummary = (filters) => {
 return new Promise((resolve,reject)=>{
      const filtersUrl = createFilterUrl(filters);
      const url = `${process.env.REACT_APP_API}/creditnotes/summary?${filtersUrl}`;
      apiCall("get", url)
        .then((resp) =>
         resolve(resp)
        )
        .catch((error) => {
        reject(error.message)
        });
 })

};

export const getBillCreditNotes = (filters) => {
 return new Promise((resolve,reject)=>{
      const filtersUrl = createFilterUrl(filters);
      const url = `${process.env.REACT_APP_API}/creditnotes?${filtersUrl}&trigger=Bill`;
      apiCall("get", url)
        .then((resp) => resolve(resp))
        .catch((error) => {
          reject(error.message)
        });
 })

};
