import React from "react";
import { Button, Header, Icon, Dimmer, Loader } from "semantic-ui-react";
import ReactToPrint from "react-to-print";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";

import ViewListTable from "./ViewListTable";

class ModalSelection extends React.Component {
  render() {
    return (
      <Modal
        open={this.props.modalIsOpen}
        showCloseIcon={!this.props.isLoading}
        closeOnEsc={true}
        onClose={() => {
          this.props.handleCloseModal();
        }}
        center
        classNames={{
          modal: ""
        }}
      >
        {Object.keys(this.props.selection).length === 0 && (
          <div
            style={{
              minWidth: "320px",
              minHeight: "210px",
              textAlign: "center",
              paddingTop: "60px"
            }}
          >
            {this.props.isLoading && (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}
            {!this.props.isLoading && (
              // this is temporrary
              <Header as="h4">
                Success.
                <Link to={'/orders'}>view</Link>
              </Header>
            )}
          </div>
        )}

        {!this.props.isLoading && Object.keys(this.props.selection).length > 0 && (
          <div className="modal-wrapper">
            <Header
              textAlign="center"
              style={{ marginBottom: "1.8em" }}
              as="h4"
            >
              Picking List{" "}
              {this.props.selectedFromThisCourierName
                ? " - " + this.props.selectedFromThisCourierName
                : ""}
            </Header>

            <ViewListTable
              ref={el => (this.componentRef = el)}
              selection={this.props.selection}
              handleBackClick={this.props.handleBackClick}
              isPicking={this.props.isPicking}
            />

            <div className="d-flex--jc-between" style={{ marginTop: "4em" }}>
              <Button basic onClick={this.props.handleBackClick}>
                Back
              </Button>

              <div className="flex_alignRight">
                <Button
                  color="yellow"
                  disabled={this.props.isPicking}
                  onClick={this.props.handleStartPicking}
                >
                  Generate List
                </Button>

                <ReactToPrint
                  trigger={() => (
                    <Button disabled={!this.props.isPicking}>
                      <Icon name="print" />
                      Print out!
                    </Button>
                  )}
                  content={() => this.componentRef}
                />
              </div>
            </div>

            <div
              className="flex_alignRight"
              style={{ marginTop: "1.5em", marginRight: "1em" }}
            >
              <Link to="/picker/pickedlist">Picked Orders List</Link>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalSelection;
