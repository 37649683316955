import React, { useState, useEffect, createRef } from "react";
import BarcodeScanner from "./barcodescanner";
import { Button, Divider, Icon } from "semantic-ui-react";
import "./styles.css";
import Barcodemanualy from "./barcodemanual";
import ProductList from "./ProductList";
import Cashpay from "./cashpay";
import {
  addItem,
  removeItem,
  updateCheckout,
  createCheckout,
  
} from "./../../actions/cashier";
import { Alert } from "rsuite";
import ConfirmPrint from "./confirmation";


const Cashier = (props) => {
  let inputRef = createRef();
  let inputCashRef = createRef();

  const [checkout, setCheckout] = useState();
  const [showCashPay, setShowCashpay] = useState(false);
  const [enterManualy, setEnterBarcodeManualy] = useState(false);
  const [scannedProduct, setScannedProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [payment, setPayment] = useState();

  const handleRemoveItem = (itemId) => {
    setLoading(true);
    removeItem({ id: checkout._id, itemId })
      .then((resp) => {
        setLoading(false);
        setCheckout(resp);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error(err.message);
      });
  };

  const handlePaid = ({ payment = "card" }) => {
    setLoading(true);
    updateCheckout({ id: checkout._id, status: "paid", payment })
      .then((resp) => {
        setLoading(false);
        setShowCashpay(false);
        setCheckout();
      })
      .catch((err) => {
        Alert.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (scannedProduct) {
      setLoading(true);

      if (!checkout) {
        createCheckout()
          .then((checkout) => {
            addItem({ id: checkout._id, item: scannedProduct })
              .then((resp) => {
                setLoading(false);
                setCheckout(resp);
              })
              .catch((err) => {
                setLoading(false);
                Alert.error(err.message);
              });
          })
          .catch((err) => Alert.error(err.message));
      } else {
        addItem({ id: checkout._id, item: scannedProduct })
          .then((resp) => {
            setLoading(false);

            setCheckout(resp);
          })
          .catch((err) => {
            setLoading(false);
            Alert.error(err.message);
          });
      }
    }
  }, [scannedProduct]);

  useEffect(() => {
    if (!showCashPay) inputRef.current.focus();
    else inputCashRef.current.focus();
  }, [inputCashRef, inputRef, showCashPay]);

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div className="App-header">
        <div className="flex--column" style={{ width: "700px", fontSize: 16 }}>
          <div style={{ fontSize: 25, display: "flex", alignItems: "center" }}>
            <BarcodeScanner
              loading={loading}
              setLoading={setLoading}
              inputRef={inputRef}
              setScannedProduct={setScannedProduct}
            />
            <Button
              onClick={() => setEnterBarcodeManualy(true)}
              style={{
                marginLeft: "30px",
                marginTop: "30px",
                height: "60px",
                width: "65px",
                alignSelf: "center",
                // fontSize: 30,
              }}
            >
              <Icon name="keyboard" size="big" />
            </Button>
          </div>

          <Divider></Divider>

          <Barcodemanualy
            showModal={enterManualy}
            setEnterBarcodeManualy={setEnterBarcodeManualy}
          ></Barcodemanualy>

          {checkout && checkout.items && (
            <ProductList
              loading={loading}
              total={checkout ? checkout.total : 0.0}
              products={checkout.items}
              onDelete={handleRemoveItem}
            />
          )}
        </div>
      </div>
      <div style={{ fontSize: "110px", textAlign: "center", width: "350px" }}>
        <p>{checkout ? checkout.total.toFixed(2) : 0.0}</p>

        <Divider></Divider>
        <div
          className="flex--between"
          style={{ margin: "10px", height: "200px" }}
        >
          <Button
            disabled={!checkout || checkout.total === 0}
            size="big"
            color="orange"
            style={{ height: "100px" }}
            onClick={() => {
              setPayment("cash");

              setShowCashpay(true);
            }}
          >
            PAY CASH
          </Button>
          <Button
            disabled={!checkout || checkout.total === 0}
            size="big"
            color="green"
            style={{ height: "100px" }}
            onClick={() => {
              setOpenConfirmation(true);
              setPayment("card");
            }}
          >
            PAY CARD
          </Button>
        </div>
      </div>
      <Cashpay
        showCashPay={showCashPay}
        setShowCashpay={setShowCashpay}
        topay={checkout && checkout.total ? checkout.total : ""}
        inputCashRef={inputCashRef}
        handlePaid={handlePaid}
        setOpenConfirmation={setOpenConfirmation}
      ></Cashpay>

      <ConfirmPrint
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        handlePaid={handlePaid}
        payment={payment}
        checkout={checkout}
      ></ConfirmPrint>
    </div>
  );
};

export default Cashier;
