import React, { useState, useEffect } from "react";
import {
  Table,
  Loader,
  // Checkbox,
  // Label,
  Button,
  Pagination,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ItemsList from "./ItemsList";
import Modal from "react-responsive-modal";
// import ExportButton from "./ExportButton";
// import ItemsPerPage from "../../components/ItemsPerPage";
import { getInvoices } from "../../actions/invoices";
import Exportids from "./Exportids";
import ExportButton from "../ExportButton";
import { useUpdateContext } from "../../context/UpdateContext";

// const status = { true: "Paid", false: "Not paid - In due" };
// const label = { true: "green", false: "yellow" };

function Invoicestable({ dateFrom, dateTo }) {
  const [items, setItems] = useState([]);
  const [viewItems, setViewItems] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [itemsPerPage, setItemsPerpage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState([]);
  const [isExport_Input_open, setExportInputOpen] = useState(false);

  const { businessclient, country } = useUpdateContext();

  useEffect(() => {
    setLoading(true);
    getInvoices({
      dateFrom,
      dateTo,
      country,
      businessclient,
      // itemsPerPage,
      currentPage,
    }).then((resp) => {
      setInvoices(resp.foundInvoices);
      setTotal(resp.total);
      setLoading(false);
    });
  }, [businessclient, country, dateFrom, dateTo, currentPage]);

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  // if (!isLoading && !invoices.length)
  //   return (
  //     <div style={{ marginTop: "2em", marginBottom: "2em" }}>
  //       <Header as="h4" textAlign="center">
  //         No bills found
  //       </Header>
  //     </div>
  //   );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {/* <Table.HeaderCell> <Checkbox

            onChange={(e,data)=>{
              // console.log(data.checked)
              if(!data.checked) setSelectedItems([])
              else CheckAll()
            }
            }
          /></Table.HeaderCell> */}

      <Table.HeaderCell>Number</Table.HeaderCell>

      <Table.HeaderCell>Businessclient</Table.HeaderCell>
      <Table.HeaderCell>Customer</Table.HeaderCell>
      <Table.HeaderCell>Issued Date</Table.HeaderCell>
      <Table.HeaderCell>Total</Table.HeaderCell>
      {/* <Table.HeaderCell>Currency</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Country</Table.HeaderCell> */}
      <Table.HeaderCell>Actions</Table.HeaderCell>
      {/* <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell> */}
    </Table.Row>
  );

  const tableBody = invoices.map(
    (
      {
        _id,
        businessclient,
        customer, // items,
        dates,
        sequenceNumber,
        currency,
        VAT_total,
        NETTO_total,
        BRUTTO_total, // isPaid,
        creditNote,
        actionSourceId,
      },
      index
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>
          {" "}
          {typeof itemsPerPage === "number" &&
            itemsPerPage * (currentPage - 1) + index + 1}
          {typeof itemsPerPage != "number" && index + 1}
        </Table.Cell>
        {/* <Table.Cell> 
          <Checkbox
            // label="Is paid"
            checked={selectedItems.includes(_id)}
            _id={_id}
            onChange={(e,data)=>{
              // console.log(data.checked)
              if(!data.checked) unselectItem(_id)
              else setSelectedItems([...selectedItems,_id])
            }
            }
          /></Table.Cell> */}

        <Table.Cell>
          Invoice:
          <Link to={`/invoices/${_id}`}>
            {sequenceNumber ? sequenceNumber : "na"}
          </Link>{" "}
          <div>
            {creditNote ? `credit note: ${creditNote.sequenceNumber}` : ""}
          </div>
          Order:
          <Link to={`/orders/${actionSourceId._id}`}>
            {actionSourceId.clientOrderNr ? actionSourceId.clientOrderNr : "na"}
          </Link>{" "}
        </Table.Cell>
        {/* <Table.Cell>
          <Link to={`/orders/${actionSourceId._id}`}>
            {actionSourceId.clientOrderNr ? actionSourceId.clientOrderNr : "na"}
          </Link>{" "}
        </Table.Cell> */}
        <Table.Cell>{businessclient && businessclient.name}</Table.Cell>
        <Table.Cell>{customer && customer.firstName}</Table.Cell>

        <Table.Cell style={{ maxWidth: "200px"}}>
          <Moment utc format="DD-MM-YY HH:mm">
            {dates && dates.issuedDate}
          </Moment>
        </Table.Cell>
        <Table.Cell>
          <>
            <p className="small--text">VAT total: {VAT_total.toFixed(2)}</p>
            <p className="small--text">NETTO total: {NETTO_total.toFixed(2)}</p>
            <p className="small--text">
              BRUTTO total: {BRUTTO_total.toFixed(2)} {currency}
            </p>
          </>
        </Table.Cell>
        {/* <Table.Cell>{currency}</Table.Cell> */}

        <Table.Cell>
          <Link to={`/invoices/${_id}/pdf`}>
            <Button
              // primary
              compact
              basic
              color="red"
              size="tiny"

              // onClick={() => {
              //         setItems(items)
              //         setViewItems(true)}}
              // content="View"
            >
              Pdf
            </Button>
          </Link>
          <Button
            primary
            color="green"
            compact
            basic
            size="tiny"
            // onClick={() => {
            //         setItems(items)
            //         setViewItems(true)}}
            // content="View"
          >
            <Link to={`/invoices/${_id}`}>Edit</Link>
          </Button>
        </Table.Cell>
        {/* <Table.Cell>
          <Label color={label[isPaid]}>{status[isPaid]}</Label>
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            label="Is paid"
            checked={isPaid}
            _id={_id}
            // onClick={handleCheck}
          />
        </Table.Cell> */}
      </Table.Row>
    )
  );

  return (
    <>
      <div className="flex--between">
        <div className="flex--between" style={{ width: "600px" }}>
          <ExportButton
            total={""}
            filters={{ businessclient, dateFrom, dateTo, country }}
            fileName={`invoices_${dateFrom.getDate()}.${
              dateFrom.getMonth() + 1
            }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
              dateTo.getMonth() + 1
            }.${dateTo.getFullYear()}`}
            url="/invoices/export"
            text={`Invoices: ${dateFrom.getDate()}.${
              dateFrom.getMonth() + 1
            }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
              dateTo.getMonth() + 1
            }.${dateTo.getFullYear()}`}
          />
          <ExportButton
            total={""}
            filters={{ businessclient, dateFrom, dateTo, country }}
            fileName={`credit_notes_${dateFrom.getDate()}.${
              dateFrom.getMonth() + 1
            }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
              dateTo.getMonth() + 1
            }.${dateTo.getFullYear()}`}
            url="/invoices/export/creditnotes"
            text={`Credit notes: ${dateFrom.getDate()}.${
              dateFrom.getMonth() + 1
            }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
              dateTo.getMonth() + 1
            }.${dateTo.getFullYear()}`}
          />
        </div>

        <Exportids
          openModal={isExport_Input_open}
          setExportInputOpen={setExportInputOpen}
        />

        {/* <ItemsPerPage
          total={invoices.total}
          style={{ height: "1em" }}
          value={itemsPerPage}
          handleChange={(e, { value }) => setItemsPerpage(value)}
        /> */}
      </div>

      <Table loading={isLoading} celled selectable>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
      {!isLoading && total >= itemsPerPage && (
        <segment>
          {" "}
          <Pagination
            style={{ marginTop: "1em" }}
            activePage={currentPage}
            totalPages={Math.ceil(total / itemsPerPage)}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, { activePage }) => setPage(activePage)}
          />
        </segment>
      )}
      <Modal
        open={viewItems}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={() => setViewItems(false)}
        center
        classNames={{ modal: "" }}
      >
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            paddingTop: "1.6em",
            paddingRight: "0.7em",
          }}
        >
          <ItemsList items={items} />
        </div>
      </Modal>
    </>
  );
}

export default Invoicestable;
