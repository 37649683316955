import React from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import "./warehouseSettings.scss";
import { useToggleModalNewEdit } from "../../hooks/useToggleModalNewEdit";
import CanViewPage from "./../../role-based-access/CanViewPage";
import Errorslist from "./Errorslist";


const defaultInitFormValues = {
  name: "",
  address: {},
  // ["address.country"]: "",
  // ["address.city"]: "",
  // ["address.street"]: "",
  // ["address.streetNr"]: "",
  // ["address.post"]: "",
  isPartner:false,
  countries_supply: [],
};

function WarehouseSettings() {
  const {
    isModalOpen,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitFormValues);
  return (
    <CanViewPage path="/warehouses">
      <main className="a-wrapper page-wrapper">
        <div className="flex--between">
          <Header>ERRORS</Header>
          <Button onClick={() => openModal(false)}>+ Add</Button>
        </div>
        <Segment padded>
          <Errorslist openModal={openModal} />
        </Segment>

        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={false}
          onClose={closeModal}
          center
          classNames={{ modal: "" }}
        >
        
        </Modal>
      </main>
    </CanViewPage>
  );
}

export default WarehouseSettings;
