import React, { useState, useEffect } from "react";
import {
  Icon,
  Button,
  Label,
  Header,
  Segment,
  Form,
  Dropdown,
  Placeholder,
  Divider,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Alert } from "rsuite";
import {
  createTransfer,
  getNewCODs,
  createTransferV2,
} from "../../../../../actions/transfer";
import { addError } from "../../../../../actions/error";
import { apiCall } from "../../../../../services/api";
import CanViewPage from "../../../../../role-based-access/CanViewPage";
import CurrencyFilter from "../../../../Filters/CurrencyFilter";
import CountryFilter from "../../../../Filters/CountryFilter";
import CourierFilter from "../../../../Filters/CourierFilter";
import BusinessclientFilter from "../../../../Filters/BusinessclientFilter";
import OrdersSummary from "./OrdersSummary";

import "../transfer.scss";
var moment = require("moment");

const initialState = {
  businessclient: "",
  country: "",
  courier: [],
  currency: "",
  dateTo: "",
  bankaccount: { accountNr: "", bankCode: "", IBAN: "", SWIFT: "" },
  amount: 0,
};

function CreateTransfer({ createTransfer, history }) {
  // Form handling
  const [
    { businessclient, country, currency, bankaccount, dateTo, courier },
    setState,
  ] = useState(initialState);

  const [orders, setOrders] = useState([]);
  const [totals, setTotals] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [newCods, setNewCods] = useState([]);
  const [reportCreated, setReportCreated] = useState("");

  const [createManualReport, setManual] = useState(false);

  const handleCreateReport = ({ data, businessclient }) => {
    setReportCreated(`${businessclient._id}${data.courier[0]._id}`);
    createTransferV2({
      businessclient: businessclient._id,
      country: data.country,
      amount: data.total,
      orders: data.orders,
    })
      .then((resp) => {
        setReportCreated("");
        Alert.success("Done.");
      })
      .then((resp) => {
        setLoading(true);
        setNewCods([]);
        getNewCODs({ couriers: [], dateTo: moment(new Date()) }).then(
          (resp) => {
            setNewCods(resp);
            setLoading(false);
          }
        );
      });
  };

  const handleChange = ({ name, value }) =>
    setState((s) => ({ ...s, [name]: value }));
  const handleDateToChange = (dateTo) => setState((s) => ({ ...s, dateTo }));

  const handleBankAccountChange = (e, { name, value }) =>
    setState((s) => ({
      ...s,
      bankaccount: { ...s.bankaccount, [name]: value },
    }));
  const handleClear = () => {
    setState(initialState);
    setManual(false);
  };

  useEffect(() => {
    if (businessclient && dateTo) {
      if (!country || !courier) {
        setOrders([]);
        setAmount(0);
      }

      apiCall(
        "get",
        `${process.env.REACT_APP_API}/transffers/newtranffertotal?businessclient=${businessclient}&dateTo=${dateTo}&country=${country}&courier=${courier}`
      )
        .then((resp) => {
          setTotals(resp);

          if (country && courier) {
            setOrders(resp[0].cods_received[0].orders);
            setAmount(resp[0].cods_received[0].total);
          }
        })
        .catch((error) => {
          addError(error.message);
        });
    }
  }, [businessclient, dateTo, country, courier]);

  useEffect(() => {
    getNewCODs({ courier, dateTo: moment(new Date()) })
      .then((resp) => {
        setNewCods(resp);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [courier]);

  // Orders Ids

  //get bclient bankaccount
  const [bankAccounts, setBankAccounts] = useState([]);
  const [accountsOptions, setAccountsOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [amount, setAmount] = useState(0);

  const handleAccountSelect = (e, { value }) => {
    setSelectedAccount(value);
    const foundAccount = bankAccounts.find(
      ({ accountNr }) => accountNr === value
    );
    setState((s) => ({
      ...s,
      bankaccount: { ...s.bankaccount, ...foundAccount },
    }));
  };

  // useEffect(() => {
  //   if (businessclient) {
  //     const foundClient = businessclients.find(bc => bc._id === businessclient);
  //     setBankAccounts((foundClient && foundClient.bankaccounts) || []);
  //   } else
  //     if (bankAccounts.length === 0) setBankAccounts([]);

  // }, [businessclient]);

  useEffect(() => {
    const accountOptions = bankAccounts.map((acc) => ({
      key: acc.accountNr,
      value: acc.accountNr,
      text: acc.addressCountry + " - " + acc.currency + " - " + acc.bankname,
    }));
    setAccountsOptions(accountOptions);
  }, [bankAccounts]);

  useEffect(() => {
    if (bankAccounts && country) {
      const foundAccount = bankAccounts.find(
        ({ addressCountry }) => addressCountry === country
      ) || { accountNr: "", bankCode: "", IBAN: "", SWIFT: "" };

      setState((s) => ({
        ...s,
        currency: foundAccount.currency || "",
        bankaccount: { ...s.bankaccount, ...foundAccount },
      }));
    }
  }, [bankAccounts, country]);

  const manualCreateView = (
    <main className="page-wrapper a-wrapper">
      <Segment padded>
        <Header>Add New Transfer</Header>

        <div className="flex--between">
          <div className="create-transfer__form">
            <div className="create-transfer__section">
              <div>
                <Label
                  circular
                  color="blue"
                  className="create-transfer__number"
                >
                  1
                </Label>
                <label className="create-transfer__label">
                  Select businessclient and date
                </label>
              </div>
              <div className="create-transfer-bankaccount">
                <BusinessclientFilter
                  useFilter={false}
                  style={{ marginRight: "1em" }}
                  placeholder="client..."
                  name="businessclient"
                  value={businessclient}
                  handleChange={(value) =>
                    handleChange({ value, name: "businessclient" })
                  }
                />
                <Form>
                  <DatePicker
                    selected={dateTo}
                    isClearable={true}
                    dateFormat="MMMM d, yyyy"
                    onChange={handleDateToChange}
                    placeholderText="date to..."
                  />
                </Form>
              </div>
            </div>

            <div className="create-transfer__section">
              <div>
                <Label
                  circular
                  color="blue"
                  className="create-transfer__number"
                >
                  2
                </Label>
                <label className="create-transfer__label">Select:</label>
              </div>
              <div>
                <CountryFilter
                  useFilter={false}
                  value={country}
                  handleChange={(value) =>
                    handleChange({ value, name: "country" })
                  }
                  className="mr--1"
                />

                <CourierFilter
                  useFilter={false}
                  value={courier}
                  name="couriers"
                  handleChange={(value) =>
                    handleChange({ value, name: "courier" })
                  }
                  className="mr--1"
                ></CourierFilter>
                {/* <Dropdown
              autoComplete="nope"
              placeholder="country..."
              name="country"
              value={country}
              disabled={!countriesOptions.length}
              fluid
              clearable
              selection
              search
              selectOnBlur={false}
              closeOnChange={true}
              onChange={handleChange}
              options={countriesOptions}
            /> */}
                <CurrencyFilter
                  value={currency}
                  handleChange={(value) =>
                    handleChange({ value, name: "currency" })
                  }
                />
              </div>
            </div>

            <div className="create-transfer__section">
              <div>
                <Label
                  circular
                  color="blue"
                  className="create-transfer__number"
                >
                  3
                </Label>
                <label className="create-transfer__label">Bank account:</label>
              </div>
              {bankAccounts.length > 0 && (
                <div className="create-transfer__account-options">
                  <Dropdown
                    autoComplete="nope"
                    placeholder="account..."
                    name="selectedAccount"
                    value={selectedAccount}
                    fluid
                    clearable
                    selection
                    search
                    selectOnBlur={false}
                    closeOnChange={true}
                    onChange={handleAccountSelect}
                    options={accountsOptions}
                  />
                </div>
              )}

              <div className="create-transfer-bankaccount">
                {[
                  { key: "accountNr", label: "Account Nr." },
                  { key: "bankCode", label: "Bank code" },
                  { key: "IBAN", label: "IBAN" },
                  { key: "SWIFT", label: "SWIFT" },
                ].map(({ key, label }) => (
                  <Form.Input
                    className="bankaccount-field"
                    autoComplete="nope"
                    key={key}
                    label={label}
                    type="text"
                    name={key}
                    value={bankaccount[key]}
                    onChange={handleBankAccountChange}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="create-transfer__summary">
            <OrdersSummary
              totals={totals}
              businessclient={businessclient}
              dateTo={dateTo}
            />
          </div>
        </div>
        <div className="bold--text">
          AMOUNT:{amount} {currency}
        </div>
        <div className="mt--2">
          <Button onClick={handleClear}>Cancel</Button>
          <Button
            disabled={!businessclient || !country || !dateTo}
            primary
            onClick={() =>
              createTransfer(
                {
                  businessclient,
                  country,
                  currency,
                  dateTo,
                  bankaccount,
                  orders,
                  amount,
                },
                history
              )
            }
          >
            Create
          </Button>
        </div>
      </Segment>
    </main>
  );

  const placeHolder = (
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  );

  return (
    <CanViewPage path="/transfers/create">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between">
          <Link to="/reports/finance">
            <Icon name="arrow left" /> Transfers List
          </Link>
          <Button basic onClick={() => setManual(true)}>
            Create manualy
          </Button>
        </div>

        <h1>Unreported Received Cash On Delivery </h1>
        <CourierFilter
          value={courier}
          useFilter={false}
          handleChange={(value) =>
            handleChange({ value, name: "courier" })
          }          className="mr--1"
        ></CourierFilter>
        {createManualReport && manualCreateView}

        {isLoading && placeHolder}
        {isLoading && placeHolder}

        <div
          // className="flex--left"
          style={{ margin: "20px", maxWidth: "1150px" }}
        >
          {newCods.map((item) => {
            const name = item.businessclient.name;

            return (
              <div
                style={{ minWidth: "354px", margin: "10px" }}
                className="flex--column"
              >
                <div className="flex--column">
                  <h4>{name}</h4>
                  <div className="flex--left" >
                    
                    {item.cods_received.map((data) => (
                      <Segment style={{margin:"10px"}}>
                        <table >
                          <tr>
                            <th>Country:</th>
                            <th>{data.country}</th>
                          </tr>
                          <tr>
                            <th>Courier:</th>
                            <th>
                              {data.courier[0] && data.courier[0].name
                                ? data.courier[0].name
                                : "NA"}
                            </th>
                          </tr>
                          <tr>
                            <th>Amount:</th>
                            <th>{data.total}</th>
                          </tr>
                          <tr>
                            <th>Orders:</th>
                            <th>{data.count}</th>
                          </tr>
                        </table>
                        <div className="flex--between">
                          <div></div>
                          <Button
                            onClick={() => {
                              handleCreateReport({
                                businessclient: item,
                                data,
                              });
                            }}
                          >
                            Create report
                          </Button>
                        </div>
                      </Segment>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  // businessclients: reduxState.businessclients.list
  // isLoading: reduxState.services.isLoading
});

export default connect(mapStateToProps, { createTransfer })(CreateTransfer);
