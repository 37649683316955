import React from "react";
import { Header,  Table, Dimmer, Loader } from "semantic-ui-react";

import Moment from "react-moment";

function InventoryCountTable({
  inventoryCounts,
  isLoading,
  currentPage,
  itemsPerPage,
}) {
  if (isLoading)
    return (
      <div>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </div>
    );

  if (!inventoryCounts.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No Counts Found
      </Header>
    );
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Warehouse</Table.HeaderCell>
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>User</Table.HeaderCell>
      <Table.HeaderCell>Quantity</Table.HeaderCell>
      <Table.HeaderCell>Date</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = inventoryCounts.map(
    (
      {
        warehouse,
        product,
        user,
        businessclient,
        quantity_available,
        quantity_blocked,
        quantity_damaged,
        createdAt,
      },
      i
    ) => (
      <Table.Row>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{warehouse && warehouse.name}</Table.Cell>
        <Table.Cell>
          {product && product.name}{" "}
          <p className="small--text grey--text">
            {businessclient && businessclient.name}
          </p>
        </Table.Cell>
        <Table.Cell>{user && user.username}</Table.Cell>
        <Table.Cell>
          <div className="inventory-counts-quantity">
            <span className="inventory-counts-label">Counted:</span>
            {quantity_available || "NA"}
            {/* <span className="inventory-counts-label">Blocked:</span>
            {quantity_blocked || "NA"}
            <span className="inventory-counts-label">Damaged:</span>
            {quantity_damaged || "NA"} */}
          </div>
        </Table.Cell>
        <Table.Cell>
          <Moment format="DD-MM-YY HH:mm" style={{ whiteSpace: "pre" }}>
            {createdAt}
          </Moment>
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default InventoryCountTable;
