import {
    apiCall
} from "../services/api";
import {Alert} from 'rsuite'


const createFilterUrl = (filters) =>
  filters ?
  Object.entries(filters)
  .filter(([key, value]) => value)
  .map(([key, value]) => key + "=" + value)
  .join("&") :
  "";

export const createBill = (filters, history) => {

  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/bills`;
        apiCall("post", url, filters)
          .then((resp) => {
            // dispatch({ type: "CREATE_BILL", payload: resp });
            if (resp.length > 0) {
              history.push(`/billing/${resp[0]._id}`);
            }
            resolve()
          })
          .catch((error) => {
          reject(error.message)
          });
  })

};
export const getBill = (id) =>  {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/bills/${id}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
      Alert.error(error.message)
    });
}
export const getBills = (filters) =>  {
  return new Promise((resolve,reject)=>{
    const filtersUrl = createFilterUrl(filters);
    const url = `${process.env.REACT_APP_API}/bills?${filtersUrl}`;
     apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => reject(error.message));
  })
  
};

export const updateBill = (_id, query) =>{


  const url = `${process.env.REACT_APP_API}/bills/${_id}`;
return  apiCall("put", url, query)
    .then((resp) => resp)

    .catch((error) => {
      Alert.error(error.message)
    });
};


export const addItem =({id,query})=>{
 
  const url = `${process.env.REACT_APP_API}/bills/${id}/items`;
return apiCall("post", url, query)
    .then((resp) =>resp)
    .catch((error) => Alert.error(error.message));
}

export const removeItem =({id,itemId})=>{
  const url = `${process.env.REACT_APP_API}/bills/${id}/items/${itemId}`;
 return  apiCall("delete", url)
      .then((resp) => resp)
      .catch((error) =>Alert.error(error.message));
}

export const getServicesForBilling = (filters) =>  {
  
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/billable/billpreview/fulfilment`;
    apiCall("post", url, filters)
      .then((resp) =>
        resolve(resp)
      )
      .catch((error) => {
        
        reject(error.message)
      });
  })
  // const filtersUrl = createFilterUrl(filters);
  
};

export const getBillableGoodsPreview =(filters)=>{

  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/billable/billpreview/goods`
            apiCall('post',url,filters)
              .then(resp=>resolve(resp))
              .catch(err => reject(err));

  })
}
export const runBillableGoodsService =(filters)=>{

  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/services/billable/billpreview/goods/run`
            apiCall('post',url,filters)
              .then(resp=>resolve(resp))
              .catch(err => reject(err));

  })
}
export const updateFilters = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});

// const createFilterUrl = (filters) =>
//   filters
//     ? Object.entries(filters)
//         .filter(([key, value]) => value)
//         .map(([key, value]) => key + "=" + value)
//         .join("&")
//     : "";


export const getBusinessParty = ({id}) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
    return apiCall("get", url).then((resp) =>resp).catch(err=>err)
   
  };