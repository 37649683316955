import React, { useState, useEffect } from "react";
import { Segment, Icon } from "semantic-ui-react";

import { Link } from "react-router-dom";

import {
  getShipment,
  updateShipment,

} from "../../../actions/shipments";
import { updatePoShippedQuantity } from "../../../actions/pos";
// import { shipmentStatusOptions } from "../../../setup";
import FormWithComDoc from "../../FormWithComDoc";
import ShipmentItems from "./ShipmentItems";
import ShipmentForm from "./ShipmentForm";
import CanViewPage from "../../../role-based-access/CanViewPage";

function EditViewOS(props) {
  const {
    match  } = props;
  const [openShipment,setOpenShipment] = useState()

  useEffect(() => {
    const shipment_id = match.params.id;
    getShipment(shipment_id).then(data=>setOpenShipment(data))
    
  }, []);

  if(!openShipment) return (<div>Loading...</div>)
  return (
    <CanViewPage path="/shipments/outbound/:id">
      <div className="a-wrapper pocomponent-wrapper">
        <Link to="/shipments/outbound">
          <Icon name="arrow left" /> Shipments list
        </Link>
        <FormWithComDoc
          // uploading={uploading}
          triggerId={openShipment._id}
          trigger="Outboundshipment"
          // loading={loading}
          businessclient={openShipment.businessclient}
        >
          <Segment padded>
            <ShipmentForm
              openShipment={openShipment}
              history={props.history}
              updateShipment={updateShipment}
              cb = {(shipment)=>setOpenShipment(shipment)}
            />
          </Segment>

          <Segment stacked padded secondary>
            <div
              className="newpo__products"
              style={{ marginTop: "1em", marginBottom: "0.5em" }}
            >
              {/* <Header as="h3">Items</Header> */}
              <ShipmentItems
                ship_id={openShipment._id}
                pos={openShipment.pos}
                updatePoShippedQuantity={updatePoShippedQuantity}
              />
            </div>
          </Segment>
        </FormWithComDoc>
      </div>
    </CanViewPage>
  );
}


export default(EditViewOS);
