import React, { useState } from "react";
import {
  Segment,
  
  Icon,
  Header,
  Input,
  Grid,
  Button,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {Alert} from 'rsuite'
import { createBusinessClient } from "../../../actions/businessclient";
import CanViewPage from "../../../role-based-access/CanViewPage";
import AddContact from "./AddContact";
import AddBankAccount from "./AddBankAccount";
import AddClientIds from "./AddClientIds";
import Address from "./Adress";
import ViewContact from "./ViewContact";
import ViewBankAccount from "./ViewBankAccount";
import ViewClientIds from "./ViewClientIds";
import "../businessclient.scss";

const initFormValues = {
  name: "",
  address: {
    country: "",
    region: "",
    province: "",
    city: "",
    street: "",
    streetNr: "",
    postcode: "",
  },
  contacts: [],
  bankaccounts: [],
  sequences: [],
  companyids: [],
};

function CreateBC({  history }) {
  const [formValues, setFormValues] = useState(initFormValues);
  const { name, address, contacts, bankaccounts, companyids } = formValues;
  // console.log(formValues);

  const handleChange = (e, { name, value }) =>
    setFormValues((prev) => ({ ...prev, [name]: value }));

  const handleAddressChange = (e, { name, value }) => {
    // console.log({ name, value });
    setFormValues((prev) => ({
      ...prev,
      address: { ...prev.address, [name]: value },
    }));
  };

  const addContact = (newContact) =>
    setFormValues((prev) => ({
      ...prev,
      contacts: [...prev.contacts, newContact],
    }));

  const addBankAccount = (newBankAccount) =>
    setFormValues((prev) => ({
      ...prev,
      bankaccounts: [...prev.bankaccounts, newBankAccount],
    }));

  const addClientIds = (newCompanyIds) =>
    setFormValues((prev) => ({
      ...prev,
      companyids: [...prev.companyids, newCompanyIds],
    }));

  const handleSaveBClient = () =>
    createBusinessClient({ history, query: formValues }).then(resp=>Alert.success('Created'))

  const handleClear = () => setFormValues(initFormValues);

  // if (isLoading)
  //   return (
  //     <Dimmer active inverted>
  //       <Loader inverted size="medium">
  //         Loading
  //       </Loader>
  //     </Dimmer>
  //   );

  return (
    <CanViewPage path="/businessclients/create/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/businessclients">
          <Icon name="arrow left" />
          Business clients list
        </Link>
        <Segment padded>
          <Header as="h1">New business client</Header>
          <div className="businessclient">
            <div className="businessclient__sidebar">
              <Header as="h4">Name</Header>
            </div>
            <div className="businessclient__form">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <label>Business client</label>
                    <Input
                      fluid
                      type="text"
                      name="name"
                      value={name}
                      placeholder="name..."
                      onChange={handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <div className="businessclient__sidebar">
              <Header as="h4">Address</Header>
            </div>
            <Address
              handleAddressChange={handleAddressChange}
              address={address}
            />

            <div className="businessclient__sidebar">
              <Header as="h4">Contacts</Header>
            </div>
            <div>
              {contacts.map((contact, i) => (
                <ViewContact {...contact} key={i} i={i} />
              ))}
              <AddContact addContact={addContact} />
            </div>

            <div className="businessclient__sidebar">
              <Header as="h4">Bank accounts</Header>
            </div>
            <div>
              {bankaccounts.map((account, i) => (
                <ViewBankAccount {...account} key={i} i={i} />
              ))}
              <AddBankAccount addBankAccount={addBankAccount} />
            </div>

            <div className="businessclient__sidebar">
              <Header as="h4">Invoicing</Header>
            </div>
            <div>
              {companyids.map((coIds, i) => (
                <ViewClientIds {...coIds} key={i} i={i} />
              ))}
              <AddClientIds addClientIds={addClientIds} />
            </div>

            <div></div>
            <div>
              <Button size="large" color="blue" onClick={handleSaveBClient}>
                Create
              </Button>
              <Button size="large" onClick={handleClear}>
                Cancel
              </Button>
            </div>
          </div>
        </Segment>
      </main>
    </CanViewPage>
  );
}



export default (CreateBC);
