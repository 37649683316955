import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ExportExcel({
  dataSet = [],
  colNames = [],
  button,
  excelSheetName,
  filename='filname',
}) {
  return (
    <ExcelFile
      filename={filename}
      // hideElement={true}
      element={button}
    >
      <ExcelSheet data={dataSet} name={excelSheetName}>
        {colNames.map(({ label, value }, i) => (
          <ExcelColumn key={i} label={label} value={value} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExportExcel;
