import React, { useEffect, useState } from "react";
import { Segment, Header, Button, Icon,Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { getBusinessClients } from "../../../actions/businessclient";
import CanViewPage from "../../../role-based-access/CanViewPage";
// import Pagination from "../../Filters/Pagination";
import BCTable from "./BCTable";
import Filters from "./Filters";

function BCList(props) {
  const [businessclients,setList] = useState({list:[],total:0})
  const [filters, setFilters] = useState({
                              currentPage: 1,
                              itemsPerPage: 10,
                              
                            });

  const handleFilterChange = (e, { name, value }) => {
    
    if (name === "currentPage")
      setFilters(prev => ({ ...prev, [name]: value,currentPage:1 }));
     else setFilters(prev => ({ ...prev, [name]: value, currentPage: 1 }))

  }; 


  useEffect(() => {
     getBusinessClients(filters)
      .then(data=>setList(data))
      // .catch(error=>alert(error.message))
    }, [filters]);


  return (
    <CanViewPage path="/businessclients">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between vertical--middle">
          <Header as="h1">Business Clients List</Header>
          <Link to="businessclients/create/new">
            <Button labelPosition="left" icon color="blue">
              <Icon name="user" />
              Add Client
            </Button>
          </Link>
        </div>

        <Segment>
          <Filters
            filters={filters}
            handleFilterChange={handleFilterChange}
            // isLoading={isLoading}
          />
        </Segment>

        <Segment padded>
          <BCTable
            businessclients={businessclients.list}
            currentPage={filters.currentPage}
            itemsPerPage={filters.itemsPerPage}
          />
           <Pagination
            activePage={filters.currentPage}
            totalPages={Math.ceil(businessclients.total / filters.itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e,data)=>setFilters({...filters,currentPage:data.activePage})}
          />
         
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default BCList
