import React from "react";
import Moment from "react-moment";

function CreateUpdateTime({ createdAt, updatedAt, newLine = false }) {
  return (
    <>
      <span className="smallinfo">
        {/* Updated: {newLine && <br />} */}
      <Moment format="DD-MM-YY HH:mm" 
        // style={{ whiteSpace: "pre" }} 
        style={{ color: "#565656", whiteSpace: "pre" }}
      utc>
        {updatedAt}
      </Moment>
      <br />
        {/* <span className="smallinfo"> */}
        {newLine && <br />}
        <Moment format="DD-MM-YY HH:mm" utc>
          {createdAt}
        </Moment>
      </span>
    </>
  );
}

export default CreateUpdateTime;
