import React from "react";
import { Button, Icon } from "semantic-ui-react";
import ExportExcel from "../../ExportExcel";

function ExportButton_warehouse_column({ list,days }) {

  // console.log("DATA EXPORT:", list);

  const lines = Object.keys(list);

  let tempDataSet=[];
  let productTable = {};

  for (let i = 0; i < lines.length; i++) {
    const key = lines[i];
    let item = list[key];

    productTable = {
      ...productTable,
      [item.product]: {
        ...productTable[item.product],
        [item.warehouse]: { leads: item.leads, stock: item.stock },
      },
    };
  }

  // console.log("productTable", productTable);
  const productLines = Object.keys(productTable) 

let warehouses=[]     //get all warehouses , then will remove duplicates - to build columns


  for (let i=0;i<productLines.length;i++){
    const product = productLines[i]

    let lineData ={
      name:product
    }

    for(let warehouse in productTable[product]){
        warehouses.push(warehouse)
         
        lineData = {
           ...lineData,
           [warehouse]: `${productTable[product][warehouse].leads}/${productTable[product][warehouse].stock}`,
         };
    }

    
    tempDataSet.push(lineData)

  }
  const uniqWarehouses = [...new Set(warehouses)]
    let columns = uniqWarehouses.map(item=>({label:item,value:item}))
    
    let colNames = [{ label: "Product", value: "name" },...columns];

    // console.log("line data:", lineData);
    
  // console.log('table columns:',colNames);    
  // console.log('table lines:',tempDataSet);
      

  // tempDataSet.push({
  //   name: item.product,
  //   country: JSON.stringify(item.warehouse),
  //   leads: item.leads,
  //   stock: item.stock,
  // });

  // setData(tempDataSet)

  return (
    <ExportExcel
      filename={`lead_stock_${days}`}
      excelSheetName="xdays"
      dataSet={tempDataSet}
      colNames={colNames}
      button={
        <Button basic color="grey">
          <Icon name="excel file" />
          Export_per_product
        </Button>
      }
    />
  );
}

//
export default ExportButton_warehouse_column;
