import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
} from "semantic-ui-react";
import {
  createNotification,
  deleteNotification,
  getNotifications,
  updateNotification,
  getnotificationTriggers,
  getnotificationTypes,
} from "../../../../actions/notifications";

import Modal from "react-responsive-modal";

import OrderStatusFilter from "../../../Filters/OrderStatusFilter";

const Notificationsettings = ({ businessclient }) => {
  const [notifications, setNotifications] = useState([]);
  const [modalOpen, openModal] = useState(false);
  const [email, setEmail] = useState();
  const [triggers, setTriggers] = useState([]);
  const [selectedTrigger, setTrigger] = useState();
  const [notification_types, setNotificationTypes] = useState([]);
  const [selectedType, setType] = useState();
  const [status, setStatus] = useState();

  const handleUpdate = ({ name, checked, notification }) => {
    updateNotification({ notification, query: { [name]: checked } }).then(
      (resp) => {
        getNotifications(businessclient).then((resp) => {
          setNotifications(resp);
        });
      }
    );
  };
  const handleDelete = (id) => {
    deleteNotification(id).then((resp) => {
      getNotifications(businessclient).then((resp) => {
        setNotifications(resp);
      });
    });
  };

  const handleCreateNew = () => {
    const query = {
      triggeredBy: selectedTrigger,
      notification_type: selectedType,
      email,
      status,
    };
    createNotification({ businessclient, query }).then((resp) => {
      openModal(false);

      getNotifications(businessclient).then((resp) => {
        setNotifications(resp);
      });
    });
  };

  useEffect(() => {
    getNotifications(businessclient).then((resp) => {
      setNotifications(resp);
    });
  }, [businessclient]);

  useEffect(() => {
    getnotificationTriggers().then((resp) => setTriggers(resp));
    getnotificationTypes().then((resp) => setNotificationTypes(resp));
  }, []);

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>To</Table.HeaderCell>
      <Table.HeaderCell>Trigger</Table.HeaderCell>
      <Table.HeaderCell>Condition</Table.HeaderCell>
      <Table.HeaderCell>Active</Table.HeaderCell>

      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
    </Table.Row>
  );

  const body = notifications.map((notification, i) => (
    <Table.Row key={notification._id}>
      <Table.Cell>{i + 1}</Table.Cell>
      <Table.Cell>{notification.notification_type}</Table.Cell>
      <Table.Cell>{notification.email}</Table.Cell>

      <Table.Cell>{notification.triggeredBy}</Table.Cell>
      <Table.Cell>{notification.status}</Table.Cell>
      
      <Table.Cell>
        <Checkbox
          toggle
          disabled={false}
          name="isActive"
          checked={notification.isActive}
          onChange={(e, { name, checked }) =>
            handleUpdate({ name, checked, notification: notification._id })
          }
        />
      </Table.Cell>

      <Table.Cell>
        <Button
          compact
          basic
          color="red"
          icon="trash"
          size="mini"
          onClick={() => handleDelete(notification._id)}
        />
      </Table.Cell>
    </Table.Row>
  ));
  return (
    <div style={{ padding: "1em" }}>
      <div style={{ padding: "1em" }}>
        <Button
          compact
          floated="right"
          style={{ marginBottom: "1em" }}
          content="Add new"
          onClick={() => openModal(true)}
        />
      </div>
      <Table basic="very">
        {tableHeader}
        {body}
      </Table>

      <Modal
        style={{ width: "500px" }}
        open={modalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={() => openModal(false)}
        center
        classNames={{ modal: "" }}
      >
        <div>
          <h1>Add Notification</h1>
          <Divider></Divider>
          <div>
          <table>
              <tr>
                <td>
                  <label>Notification trigger</label>
                </td>
                <td>
                  <Dropdown
                    style={{ width: "250px",margin:'20px' }}
                    fluid
                    value={selectedTrigger}
                    options={triggers.map((item) => ({
                      text: item,
                      value: item,
                    }))}
                    onChange={(e, { value }) => {
                      setTrigger(value);
                    }}
                  ></Dropdown>
                </td>
              </tr>
            </table>

            {selectedTrigger === "Order" && (
              <div>
                <OrderStatusFilter

                  placeholder="order status..."
                  style={{ marginTop: "10px" }}
                  fluid ={true}
                  value={status}
                  handleChange={(e, { value }) => {
                    setStatus(value);
                  }}
                ></OrderStatusFilter>
              </div>
            )}

          
<Divider></Divider>
            <table>
              <tr>
                <td style={{ margin: "20px" }}>
                  <label>Notification type</label>
                </td>
                <td>
                  <Dropdown
                    style={{ width: "250px", margin: "20px" }}
                    fluid
                    value={selectedType}
                    options={notification_types.map((item) => ({
                      text: item,
                      value: item,
                    }))}
                    onChange={(e, { value }) => {
                      setType(value);
                    }}
                  ></Dropdown>
                </td>
              </tr>
              {selectedType === "email" &&  <tr>
                <td><label>Email</label></td>
                <td style={{ margin: "20px" }}>
                <Input
                  style={{ marginTop: "10px" }}
                  fluid
                  placeholder="name@email.com"
                  value={email}
                  onChange={(e, { value }) => setEmail(value)}
                ></Input>
                </td>
         
              </tr>}
            </table>


            
            <Divider></Divider>
          </div>
          <Divider></Divider>
          <Button floated="right" onClick={handleCreateNew}>
            Add
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Notificationsettings;
