import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Loader, } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import {
  getBillingSettings,
  deleteBilling,
  updateBillingSettings,

} from "../../../../actions/businessclient";
import { useToggleModalNewEdit } from "../../../../hooks/useToggleModalNewEdit";
import BillingModal from "./BillingModal";

const defaultInitialFormValues = {
  country: "",
  cycles: "Once in two weeks",
  daysToDue: 14,
  vat_apply: true,
  vat: 0,
};

function BillingTable({
  bclientId,
  businessclient
}) {
  const { isModalOpen, isEditModeOn, openModal, closeModal } =
    useToggleModalNewEdit(defaultInitialFormValues);

  const [isLoading, setIsloading] = useState(false);
  const [billingSettings, setBillingsettings] = useState([]);

  const handleGetAll = ()=>{
   setIsloading(true);
    getBillingSettings({ id: bclientId }).then((resp) => {
      setIsloading(false);
      setBillingsettings(resp);
    });
  }

  const handleUpdate = ({bill_set_id, name, checked }) => {
    setIsloading(true)
    updateBillingSettings({ query: { [name]: checked },
                            id:bclientId,
                            bill_set_id, })
                            
                            .then(resp=>{
                              handleGetAll()
                            })
  };

  useEffect(() => {
    setIsloading(true);
    getBillingSettings({ id: bclientId }).then((resp) => {
      setIsloading(false);
      setBillingsettings(resp);
    });
  }, [bclientId]);

  //   const [isViewOpen, setViewOpen] = useState([]);

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Issuer</Table.HeaderCell>
      <Table.HeaderCell>Services for</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Due Days</Table.HeaderCell>
      <Table.HeaderCell>VAT</Table.HeaderCell>
      <Table.HeaderCell>Services Category</Table.HeaderCell>
      <Table.HeaderCell>Auto Billing</Table.HeaderCell>
      <Table.HeaderCell>Billing Cycle</Table.HeaderCell>
      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = billingSettings.map(
    (
      {
        _id,
        country,
        daysToDue,
        autoBillRun,
        category,
        clients = [],
        issuer,
        vat,
        cycle
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>
        <Table.Cell>{issuer ? issuer.name : "na"}</Table.Cell>
        <Table.Cell>
          {clients.map((client) => (
            <div>{client.name}</div>
          ))}
        </Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{daysToDue}</Table.Cell>
        <Table.Cell>{vat}</Table.Cell>
        <Table.Cell>
          {category.map((item) => (
            <div>{item}</div>
          ))}
        </Table.Cell>
        <Table.Cell>
          {/* <Checkbox
            checked={autoBillRun}
          ></Checkbox> */}
          <Checkbox
            toggle
            disabled={false}
            name="autoBillRun"
            checked={autoBillRun}
            onChange={(e, { name, checked }) =>
              handleUpdate({ name, checked, bill_set_id: _id })
            }
          />
        </Table.Cell>
        <Table.Cell>{cycle}</Table.Cell>
        <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() =>{
              setIsloading(true)
              deleteBilling({
                id: bclientId,
                bill_set_id: _id,
              }).then((resp) => handleGetAll())
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      {!billingSettings.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>No billing options saved yet.</p>
          <Button
            style={{ marginTop: "0.5em" }}
            compact
            content="Add billing"
            onClick={() => openModal(false)}
          />
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content="Add new"
            onClick={() => openModal(false)}
          />
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        // open={true}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        <BillingModal
          businessclient={businessclient}
          handleClose={closeModal}
          isEditModeOn={isEditModeOn}
          handleGetAll={handleGetAll}
        />
      </Modal>
    </>
  );
}



export default (BillingTable);
