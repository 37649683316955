import React from "react";
import { Table, } from "semantic-ui-react";
import { Link } from "react-router-dom";

// const listOfPicked = [
//   {
//     editable: true,
//     _id: "5dd68258819d016166eff064",
//     products: [
//       {
//         orders: ["5dce85033f45e3ab3273a72e", "5dce84fe3f45e3ab3273a72b"],
//         _id: "5dd68258819d016166eff066",
//         product: "5dce6773d56f15b85e692364",
//         inhouse_position: null,
//         quantity: 2
//       },
//       {
//         orders: [
//           "5dce84e13f45e3ab3273a728",
//           "5dce84cb3f45e3ab3273a725",
//           "5dce84c63f45e3ab3273a722"
//         ],
//         _id: "5dd68258819d016166eff065",
//         product: "Nikotinoff",
//         inhouse_position: null,
//         quantity: 6
//       }
//     ],
//     user: "5dce666134cb25b80c936328",
//     warehouse: "5dc2ca9a317dc54f6c7c96fa",
//     createdAt: "2019-11-21T12:26:00.575Z",
//     updatedAt: "2019-11-21T12:26:00.575Z",
//     __v: 0
//   }
// ];

class PickedTable extends React.Component {
  render() {
    const { item, toPrint } = this.props;
    const headers = (
      <Table.Row>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">SKU</Table.HeaderCell>
        <Table.HeaderCell>Position</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">#Products</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">#Pick Orders</Table.HeaderCell>
      </Table.Row>
    );

    const rows = item.products.map(
      ({ inhouse_position, quantity, orders, product }) => {
        return (
          <Table.Row>
            <Table.Cell>
              {product &&
                (toPrint ? (
                  product.name
                ) : (
                  <Link to={`/products/${product._id}`}>{product.name}</Link>
                ))}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {product && product.codes && product.codes.sku}
            </Table.Cell>
            <Table.Cell>{inhouse_position}</Table.Cell>
            <Table.Cell textAlign="center">{quantity}</Table.Cell>
            <Table.Cell textAlign="center">{orders.length}</Table.Cell>
          </Table.Row>
        );
      }
    );

    return (
      <Table fixed unstackable striped>
        <Table.Header>{headers}</Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  }
}

export default PickedTable;
