import { connect } from "react-redux";

function CanView({ user, path, action, children, fallback = null }) {
  // console.log(path, action, user.views && user.views[path].includes(action));
  if (user.views && user.views[path] && user.views[path].includes(action))
    return children;
  else return fallback;
}
const mapStateaToProps = reduxState => ({
  user: reduxState.user.currentUser
});

export default connect(mapStateaToProps, {})(CanView);
