import React from 'react'
import {Button} from 'semantic-ui-react'

const DropDownField = ({
    text='First name',
    name ='shipping.shipTo.client.firstName',
    updateField,
    setShowUpdateField,
    handleUpdateField,
    value,
    id='fistName',
    updateFieldBlock="address",
    object=<div></div>
})=>{

return (
          <tr id={id} style={{ height: "33px" }}>
              <td style={{ width: "100px", fontWeight: "bold" }}>{text}</td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === name && (
                  <div>
                  {object}
                  </div>
                )}
                {updateField.name !== name &&
                  value}
              </td>
              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === name && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField(updateFieldBlock)}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== name && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: name,
                        value: value,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
    
)
}


export default DropDownField