import { combineReducers } from "redux";
import user from "./currentUser";
import notifications from "./notifications";
import picking from "./picking";
import packing from "./packing";
import tickets from "./tickets";
import reports from "./reports";
// import errorList from "./error";
import filtersmain from "./filtersmain";
import droplists from "./droplists";

const appReducer = combineReducers({
  // errorList,
  user,
  notifications,
  picking,
  packing,
  tickets,
  reports,
  filtersmain,
  droplists,
});
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
