import React, { useState,useEffect } from "react";
import { Table, Checkbox, Loader, Button,Input } from "semantic-ui-react";
import { getBusinessClients } from "../../../actions/businessclient";

function SelectBclients({
  isLoading,
  deleteAction,
  add,
}) {

  const [searchTerm,setsearchTerm] = useState('')
  const [businessclients,setBusinessclients] = useState([])

  useEffect(() => {getBusinessClients({searchTerm}).then(data=>setBusinessclients(data.list))}, []);
  useEffect(()=>{
      if(searchTerm.length>1) getBusinessClients({searchTerm}).then(data=>setBusinessclients(data.list))
                  },[searchTerm])
  return (
    <div className="add-remove-items-section">
      <ViewTable
        businessclients={[]}
        deleteAction={deleteAction}
      />
      <AddTable
        setsearchTerm={setsearchTerm}
        add={add}
        isLoading={isLoading}
        businessclients={businessclients}
      />
    </div>
  );
}

export default SelectBclients;


const AddTable = ({ businessclients, add, isLoading,setsearchTerm }) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [isHeaderChecked, setHeaderChecked] = useState(false);

  const handleSingleCheck = (_id) => {
    let newArr = [];
    if (checkedItems.includes(_id)) {
      newArr = checkedItems.filter((item) => item !== _id);
    } else {
      newArr = [...checkedItems, _id];
    }
    setCheckedItems(newArr);
  };
  const handleMultipleCheck = () => {
    if (isHeaderChecked) {
      setCheckedItems([]);
      setHeaderChecked(false);
    } else {
      setCheckedItems(businessclients.map(({ _id }) => _id));
      setHeaderChecked(true);
    }
  };

  const tableHead = (
    <Table.Row>
      <Table.HeaderCell verticalAlign="bottom" collapsing>
        <Checkbox
          style={{ margin: 0 }}
          onChange={handleMultipleCheck}
          checked={isHeaderChecked}
        />
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="left " collapsing>
        Select client
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="right" width="5">
        <Button
          compact
          color="blue"
          size="tiny"
          icon="plus"
          disabled={!checkedItems.length}
          onClick={() => {
            const selectedBusinessclients = businessclients
              .filter(({ _id }) => checkedItems.includes(_id))
              .map(({ name, _id }) => ({ name, _id }));
            add(checkedItems, selectedBusinessclients);
            setCheckedItems([]);
            setHeaderChecked(false);
          }}
        >
          Add {checkedItems.length ? `(${checkedItems.length})` : "checked"}
        </Button>
      </Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = businessclients.map(({ _id, name }) => (
    <Table.Row key={_id}>
      <Table.Cell verticalAlign="middle">
        <Checkbox
          style={{ margin: 0 }}
          onChange={() => handleSingleCheck(_id)}
          checked={checkedItems.includes(_id)}
        />
      </Table.Cell>
      <Table.Cell verticalAlign="middle">{name}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          size="tiny"
          color="blue"
          basic
          icon="pencil"
          onClick={() => {
            add([_id], [{ name, _id }]);
            if (checkedItems.includes(_id)) {
              let newArr = [];
              newArr = checkedItems.filter((item) => item !== _id);
              setCheckedItems(newArr);
            }
          }}
        />
      </Table.Cell>
    </Table.Row>
  ));

  if (isLoading) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } 
  // else if (!businessclients.length) {
  //   return <p className="add-items-table">No other clients</p>;
  // }
  return (
    <div className="add-items-table">
      <Input
        placeholder='searchTerm...'
        fluid
        onChange={(e,{value})=>setsearchTerm(value)}
      ></Input>
      <Table unstackable selectable>
        <Table.Header style={{ display: "block", width: "100%" }}>
          {tableHead}
        </Table.Header>
        <Table.Body className="add-items-table__body">{tableBody}</Table.Body>
      </Table>
    </div>
  );
};

const ViewTable = ({ businessclients, deleteAction }) => {
  const tableBody = businessclients.map(({ _id, name }, i) => (
    <Table.Row key={_id}>
      <Table.Cell verticalAlign="middle">{i + 1}</Table.Cell>
      <Table.Cell verticalAlign="middle">{name}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          size="tiny"
          color="red"
          basic
          icon="trash"
          onClick={() => deleteAction(_id)}
        />
      </Table.Cell>
    </Table.Row>
  ));

  if (!businessclients.length) {
    return <p className="view-items-table">No clients added yet</p>;
  }
  return (
    <div className="view-items-table view-items-table__body ">
      <Table unstackable selectable>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
};
