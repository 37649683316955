
import { apiCall } from "../services/api";

export const createCourier = (courier) =>  {
  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/couriers/`;
        apiCall("post", url, courier)
          .then((data) =>resolve(data))
          .catch((err) =>reject(err.message))
  })

};

export const getCourier = (id) =>  {
  return new Promise((resolve,reject)=>{
       const url = `${process.env.REACT_APP_API}/couriers/${id}`;
       apiCall("get", url)
         .then((data) => resolve(data))
         .catch((err) => reject(err.message))
  })
 
};

export const getCouriers = (filters = {}) => {
  const { country, searchTerm } = filters;
return new Promise((resolve,reject)=>{
      apiCall(
         "get",
         `${process.env.REACT_APP_API}/couriers/?country=${country}&searchTerm=${searchTerm}`
       )
       .then((data) => resolve(data))
       .catch((err) => reject(err.message));
})
 
};

export const getCouriersByWarehouse = () =>  {
  
  return new Promise((resolve,reject)=>{
       apiCall("get", `${process.env.REACT_APP_API}/couriers/by/warehouse`)
         .then((data) =>resolve(data))
         .catch((err) => reject(err.message))
  })
 
};

export const editCourier = ({ id, updates }) =>  {
  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/couriers/${id}`;
        apiCall("put", url, updates)
          .then((data) => resolve(data))
          .catch((err) => reject(err.message))
  })

};


// REPORTS TEMPLATE

export const createReportTemplate = ({ id, query }) =>  {

  return new Promise((resolve,reject)=>{
        const url = `${process.env.REACT_APP_API}/couriers/${id}/codreports`;
        apiCall("post", url, query)
          .then((data) =>resolve(data))
          .catch((err) => reject(err.message))
  })

};

export const getReportTemplates = (id) =>  {
  return new Promise((resolve,reject)=>{
       const url = `${process.env.REACT_APP_API}/couriers/${id}/codreports`;
       apiCall("get", url)
         .then((data) => resolve(data))
         .catch((err) => reject(err.message))
  })
 
};

export const editReportTemplate = ({ id, query }) =>  {

  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/couriers/${id}/codreports/${query.reportId}`;
      apiCall("put", url, query)
        .then((data) => resolve(data))
        .catch((err) => reject(err.message))
  })
  
};

export const deleteReportTemplate = ({ id, reportId }) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/couriers/${id}/codreports/${reportId}`;
    apiCall("delete", url)
      .then((data) => resolve(data))
      .catch((err) => reject(err.message))
  })
  
};

// Users

export const createCourierUser = ({ id, query }) => {
  const url = `${process.env.REACT_APP_API}/couriers/${id}/users`;
  return apiCall("post", url, query)
    .then((data) => data)
    .catch((err) => err);
};

export const getCourierUsers = (id) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/couriers/${id}/users`;
    apiCall("get", url)
      .then((data) => resolve(data))
      .catch((err) => reject(err.message))
  })
  
};

export const editCourierUser = ({ id, userId, query }) => {
  return new Promise((resolve, reject) => {
     const url = `${process.env.REACT_APP_API}/couriers/${id}/users/${userId}`;
     apiCall("put", url, query)
       .then((data) => resolve(data))
       .catch((err) => reject(err.message))
  })
 
};
export const deleteCourierUser = ({ id, userId }) => {
  return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_API}/couriers/${id}/users/${userId}`;
      apiCall("delete", url)
        .then((data) =>resolve(data))
        .catch((err) => reject(err.message))
  })

};

export const addCourierUserClient = ({ id, userId, query }) =>  {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/couriers/${id}/users/${userId}/clients`;
    apiCall("post", url, query)
      .then((data) => resolve(data))
      .catch((err) => reject(err.message))
  })

};

export const removeCourierUserClient = ({ id, userId, clientId }) => {
  return new Promise((resolve, reject) => {
     const url = `${process.env.REACT_APP_API}/couriers/${id}/users/${userId}/clients/${clientId}`;
     apiCall("delete", url)
       .then((data) => resolve(data))
       .catch((err) => reject(err.message))
  })
 
};
