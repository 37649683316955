import React from "react";

import { countries } from "../../../countries";
import FormField from "./FormField";

function EditAddress({
  handleAddressChange,
  country,
  region,
  province,
  city,
  street,
  streetNr,
  postcode,
  handleUpdate,
}) {
  const countriesOptions = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  // console.log(address);

  return (
    <div className="businessclient__form">
      <div className="view-grid_no-bg view-grid ">
        <FormField
          label="Country"
          name="address.country"
          placeholder="country ..."
          value={country}
          handleChange={handleUpdate}
          options={countriesOptions}
        />

        <FormField
          label="Region"
          name="address.region"
          placeholder="region ..."
          value={region}
          handleChange={handleUpdate}
        />

        <FormField
          label="Province"
          name="address.province"
          placeholder="province ..."
          value={province}
          handleChange={handleUpdate}
        />

        <FormField
          label="City"
          name="address.city"
          placeholder="city ..."
          value={city}
          handleChange={handleUpdate}
        />

        <FormField
          label="Street"
          name="address.street"
          placeholder="street ..."
          value={street}
          handleChange={handleUpdate}
        />

        <FormField
          label="Street Nr."
          name="address.streetNr"
          placeholder="street nr ..."
          value={streetNr}
          handleChange={handleUpdate}
        />

        <FormField
          label="Postcode"
          name="address.postcode"
          placeholder="postcode ..."
          value={postcode}
          handleChange={handleUpdate}
        />
      </div>
    </div>
  );
}

export default EditAddress;
