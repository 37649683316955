import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { Header, Form, TextArea, Divider, Button } from "semantic-ui-react";
import { createInventoryCard } from "../../../actions/inboundshipment";
import { Alert } from "rsuite";
import ProductFilterList from "../../Filters/ProductFilterList";
import DatePicker from "react-datepicker";

const AddGRitem = ({ openModal, closeModal, businessclient, id, cb }) => {
  const [item, setItem] = useState();
  const [quantity, setQuantity] = useState();
  const [lot, setLot] = useState();
  const [expiration, setExpiration] = useState();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const addCountedItem = () => {
    setLoading(true);
    Alert.info("Validating No Goods orders...", 3000);
    createInventoryCard({
      id,
      item: {
        product: item,
        quantity_available: quantity,
        expiration,
        description,
        lot
      },
    })
      .then((data) => {
        setLoading(false);
        Alert.success("Item added");
        closeModal();
        cb(data);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error(err.message);
      });
  };

  const newItemMode = (
    <div id="product_label">
      <Header as="h4">Add New Item</Header>

      <Divider />
      <Form widths="equal" autoComplete="off" loading={loading}>
        <Form.Group>
          <ProductFilterList
            useFilter={false}
            client={businessclient}
            handleChange={(value) => setItem(value)}
            fluid={true}
            disabled={!businessclient}
          ></ProductFilterList>
        </Form.Group>
        <Form.Group>
          <Form.Input
            fluid
            autocomplete="off"
            label="Quantity Available"
            placeholder="quantity"
            // value={quantity_available}
            name="quantity_available"
            onChange={(e, { value }) => setQuantity(value)}
          />

          <Form.Input
            fluid
            autocomplete="off"
            label="Lot"
            placeholder="lot number"
            name="lot"
            onChange={(e, { value }) => setLot(value)}
            // value={quantity_damaged}
          />

        </Form.Group>
        <p>Expiration date</p>
        <DatePicker
          autoComplete="off"
          selected={expiration}
          isClearable={true}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => setExpiration(date)}
          placeholderText="select date..."
          name="expiration"
          selectsEnd
          // minDate={filters.startDate}
        ></DatePicker>

        <Form.Field>
          <TextArea
            rows="4"
            cols="50"
            label="Description"
            name="description"
            value={description}
            onChange={(e, { value }) => setDescription(value)}
          />
        </Form.Field>

        <div className="flex--right">
          {/* <Button onClick={() => this.handleClose()}>Cancel</Button> */}
          <Button fluid color="blue" onClick={addCountedItem}>
            Add Item
          </Button>
        </div>
      </Form>
    </div>
  );

  const modal = (
    <Modal
      open={openModal}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => {
        closeModal(false);
      }}
      center
      classNames={{
        modal: "",
      }}
    >
      {newItemMode}
    </Modal>
  );

  return <div>{modal}</div>;
};

export default AddGRitem;
