import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as Chartjs, defaults } from "chart.js/auto";
import { Segment } from "semantic-ui-react";
import { getProductLine_chartsjs } from "../../actions/dashboard.js";
import { Alert } from "rsuite";

const labels=["2016-01-15","2016-01-16","2016-01-17"]
const example = [
  {
    label: "test1",
    borderColor: ["rgba(10, 110, 10, 200)"],
    tension: 0.2,
    data: [
      { x: "2016-01-15", y: 20 },
      { x: "2016-01-20", y: 10 },
      { x: "2016-01-27", y: 50 },
      { x: "2016-01-28", y: 80 },
      { x: "2016-01-29", y: 80 },
      { x: "2016-02-04", y: 80 },
    ],
  },

  {
    label: "test2",

    borderColor: ["rgba(01, 155, 100, 0.2)"],
    tension: 0.2,
    data: [
      { x: "2016-01-15", y: 10 },
      { x: "2016-01-16", y: 15 },
      { x: "2016-01-17", y: 42 },
      { x: "2016-01-28", y: 10 },
      { x: "2016-01-29", y: 33 },
      { x: "2016-02-01", y: 33 },
      { x: "2016-02-02", y: 33 },
    ],
  },
  // {
  //   label: "test3",

  //   borderColor: ["rgba(100, 0, 220, 0.2)"],
  //   tension: 0.2,
  //   data: [
  //     { x: "2016-12-25", y: 1 },
  //     { x: "2016-12-26", y: 105 },
  //     { x: "2016-12-27", y: 49 },
  //     { x: "2016-12-28", y: 100 },
  //     { x: "2016-12-29", y: 83 },
  //   ],
  // },
];
function LineProductSales({ filters, update }) {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [labels,setLabels] =useState([])

  const { product } = filters;

  useEffect(() => {
    setLoading(true);
    if (
      (!product || (product && product.length === 0)) &&
      localStorage.getItem("dashProducts")
    )
      getProductLine_chartsjs({
        ...filters,
        product: localStorage.getItem("dashProducts").split(","),
      })
        .then((resp) => {
          setDatasets(resp.datasets);
          setLabels(resp.labels);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });

    if (product && product.length > 0)
      getProductLine_chartsjs(filters)
        .then((resp) => {
          setDatasets(resp.datasets);
          setLabels(resp.labels);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });
  }, [update]);

  return (
    <>
      {" "}
      {/* <h2 style={{ textAlign: "center" }}>Product sales</h2> */}
      <Segment
        style={{ width:"90%",maxWidth:"1000px", margin: "40px 0px 0px 20px" }}
        loading={loading}
      >        <Line
          data={{
            labels: labels,
            datasets: datasets,
            // datasets: example,
          }}
          options={{
            plugins: {
              title: {
                display: true,
                text: `Product sales per day`,
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </Segment>
    </>
  );
}
export default LineProductSales;
