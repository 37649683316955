import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { apiCall } from "../../../../services/api";
import { Divider, Dropdown, Button, Header } from "semantic-ui-react";

const NextlevelValidator = ({
  showValidator,
  setShowValidator,
  country,
  city,
  handleUpdate
}) => {
  const [list, setList] = useState([]);
  const [post_code,setValue] = useState()

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/orders/nextlevel/postcode`;
    apiCall("post", url, { country, city })
      .then((resp) => setList(resp))
      .catch((err) => alert(err.message));
  }, []);
  return (
    <div>
      <Modal
        open={showValidator}
        showCloseIcon={true}
        closeOnEsc={false}
        onClose={() => setShowValidator(false)}
        center
        classNames={{
          modal: "",
        }}
      >
        <div
          style={{
            width: "300px",
          }}
        >
          <Header as="h4">Post Code Validator</Header>
          <Divider></Divider>
          <table style={{ textAlign: "left" }}>
            <tr>
              <th>Country:</th>
              <th>{country}</th>
            </tr>
            <tr>
              <th>City:</th>
              <th>{city}</th>
            </tr>
          </table>
          <Divider></Divider>
          <Header as="h5">Options</Header>
          <Dropdown
            search
            selection
            fluid
            // selectOnBlur={false}
            // closeOnChange={true}
            // closeOnBlur={true}
            // openOnFocus={true}
            // placeholder={placeholder}
            value={post_code}
            options={list.map((item) => ({
              text: `${item.name}-${item.post_code}`,
              value: item.post_code,
            }))}
            onChange={(e,{value})=>setValue(value)}
            // onBlur={handleBlur}
          />
        </div>
        <Button onClick={() => handleUpdate({post_code})}>Update</Button>
      </Modal>
    </div>
  );
};

export default NextlevelValidator;
