import React from "react";
import {
  Table,
  Header,
  Loader,
  Button,
  Icon,
} from "semantic-ui-react";
// import Moment from "react-moment";
import { Link } from "react-router-dom";

function BCTable(props) {
  const {
    businessclients = [],
    isLoading,
    // updateUser,
    currentPage,
    itemsPerPage,
  } = props;

  if (isLoading)
    return (
      <div style={{ marginTop: "3em", marginBottom: "4em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!businessclients.length)
    return (
      <Header
        as="h2"
        color="grey"
        textAlign="center"
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        No businessclients found
      </Header>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
      <Table.HeaderCell>Username</Table.HeaderCell>
      <Table.HeaderCell>Address</Table.HeaderCell>
      <Table.HeaderCell>Contact Details</Table.HeaderCell>
      <Table.HeaderCell>Category</Table.HeaderCell>
      <Table.HeaderCell>Weeks nr. orders</Table.HeaderCell>
      {/* <Table.HeaderCell>Active</Table.HeaderCell> */}
      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = businessclients.map(
    ({ _id, address, contacts, name }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="center">
          <span className="grey--text">
            {(currentPage - 1) * itemsPerPage + i + 1}
          </span>
        </Table.Cell>

        <Table.Cell>
          <Link to={`/businessclients/${_id}`}>
            <span className="bold--text">{name}</span>
          </Link>
        </Table.Cell>

        <Table.Cell>
          {address && (
            <>
              {address.country && address.country}
              <br />
              {address.city && address.city}
            </>
          )}
        </Table.Cell>

        <Table.Cell collapsing>
          {contacts && contacts[0] && (
            <>
              {contacts[0].name && (
                <span className="grey--text small--text">
                  <Icon name="user" size="small" color="grey" />
                  {contacts[0].name}
                </span>
              )}
              <br />
              {contacts[0].email && (
                <span className="grey--text small--text">
                  <Icon name="mail outline" size="small" color="grey" />
                  {contacts[0].email}
                </span>
              )}
              <br />
              {contacts[0].phone && (
                <span className="grey--text small--text">
                  <Icon name="phone" size="small" color="grey" />
                  {contacts[0].phone}
                </span>
              )}
            </>
          )}
        </Table.Cell>

        <Table.Cell>category</Table.Cell>

        <Table.Cell>nr. of orders</Table.Cell>

        {/* <Table.Cell collapsing>
          <Checkbox
            readOnly
            //   toggle
            color="grey"
            checked={status.isActive}
            //   onChange={() =>
            //     updateUser({
            //       id: _id,
            //       query: { ["status.isActive"]: !status.isActive }
            //     })
            //   }
          />
        </Table.Cell> */}

        <Table.Cell textAlign="right">
          <Link to={`/businessclients/${_id}`}>
            <Button basic compact size="tiny" icon="pencil" />
          </Link>
          {/* <Button basic compact size="tiny" icon="trash" /> */}
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <div className="table-wrapper--horizontal-scroll ">
      <Table basic="very" selectable unstackable>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
}

export default BCTable;
