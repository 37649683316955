import React from "react";
import {
  Header,
  Table,
  Dimmer,
  Loader,
  Button,
  Label
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import CanView from "../../../../../role-based-access/CanView";
import { Checkbox } from "rsuite";

const color ={
  sent:'green',
  draft:'orange'
}

function TransfersTable({
  transfers=[],
  isLoading,
  currentPage,
  itemsPerPage,
  updateTransfer,
  deleteTransfer,
}) {

  if (isLoading)
    return (
      <div style={{ paddingTop: "4em", paddingBottom: "4em" }}>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </div>
    );

  if (!transfers.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No transfers found
      </Header>
    );
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Businessclient</Table.HeaderCell>
      <Table.HeaderCell>Transfer Nr.</Table.HeaderCell>
      <Table.HeaderCell>Details</Table.HeaderCell>
      {/* <Table.HeaderCell>Notes</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Amount</Table.HeaderCell> */}
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Dates</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = transfers.map(
    (
      {
        _id,
        businessclient,
        tranfferNr,
        toBankAccount={IBAN:''},
        amount,
        currency,
        country,
        status,
        createdAt,
        updatedAt,
        notes
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{businessclient && businessclient.name}</Table.Cell>
        <Table.Cell>
          <Link to={`/transfers/${_id}`}>{tranfferNr}</Link>
        </Table.Cell>
        <Table.Cell>
        <div>
                <span className="grey--text small--text transfers-bank-labels">
                  Country
                </span>{" "}
                {country}
              </div>

            <>
              <div>
                <span className="grey--text small--text transfers-bank-labels">
                  Amount
                </span>{" "}
                {currency} {amount.toFixed(2)} 
              </div>
              {/* <div>
                <span className="grey--text small--text transfers-bank-labels">
                  Bank code
                </span>{" "}
                {toBankAccount.bankCode}
              </div> */}
              <div>
                <span className="grey--text small--text transfers-bank-labels">
                  IBAN
                </span>{" "}
                {toBankAccount.IBAN}
              </div>
              <div>
                <span className="grey--text small--text transfers-bank-labels">
                  Notes
                </span>{" "}
                {notes}
              </div>
            </>
        </Table.Cell>
        {/* <Table.Cell>{currency}</Table.Cell> */}
        {/* <Table.Cell>{amount.toFixed(2)}</Table.Cell> */}
        <Table.Cell>
          <Label color={color[status]}> {status}</Label>
         
          </Table.Cell>
        <Table.Cell>
          <Moment format="DD/MM/YYYY">{createdAt}</Moment>
          <br></br>
          <Moment format="DD/MM/YYYY">{updatedAt}</Moment>
        </Table.Cell>

        <Table.Cell width='2'>
        <CanView path="/reports/transffers" action="delete">
          <div className='flex--between'>
        
         <Checkbox
             checked={status==='sent'}
             onChange={(e,checked)=>{
                updateTransfer(_id,{status:checked?'sent':'draft'})
         }}

          >Sent</Checkbox>
           {status!=='sent' && <Button
              icon="trash"
              color="red"
              basic
              compact
              size="tiny"
              onClick={() => deleteTransfer(_id)}
            />}
         {status==='sent' && <Link to={`/transfers/${_id}`}>view</Link>}
          </div>
         
          </CanView>
          
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table  basic="very" selectable>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default TransfersTable;
