import React, { useState, useEffect } from "react";
import { Button, TextArea, Form, Header,Segment } from "semantic-ui-react";
import {getComments,addComment} from '../../actions/comments'
// import {comments} from './commentdata.js'
import Comment from "./Comment";
import "./commentlist.scss";

const Comments =({trigger,triggerId})=>{
  const [text,setText]=useState('')
  const [comlist,setComlist] =useState([])

  const handleAddComment=()=> {
    addComment({triggerId,trigger,text})
       .then(list=>setComlist(list))

        setText('')
  }

  useEffect(()=>{
   if(triggerId!=='undefined' && trigger) getComments({trigger,triggerId}).then(list=>setComlist(list))
  },[trigger,triggerId])

  const list = comlist.map(comment=>(
        <Comment
          username={comment.user.username}
          text ={comment.text}
          date={comment.createdAt}
          ></Comment>
  ))
    return (
      <Segment>
        <Header as="h4">Comments</Header>
        <div className="po_comment_body">
          {comlist.length > 0 && (
            <div className="comment__list">
              <div>{list}</div>
            </div>
          )}

          <div className="comment__form">
            <Form className="comment_text-area">
              <TextArea
                rows={1}
                value={text}
                name="textArea"
                placeholder="comment..."
                onChange={(e,{value})=>setText(value)}
              ></TextArea>
            </Form>
            <Button
              color="teal"
              disabled={text === ""}
              onClick={()=>handleAddComment()}
            >
              Add
            </Button>
          </div>
        </div>
      </Segment>
    );
  }


export default Comments;
