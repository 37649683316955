import React, { useState, useEffect } from "react";
import {
  Header,
  Label,
  Segment,
  Button,
  Dropdown,
  Form,
  Divider,
  Checkbox,
} from "semantic-ui-react";

import {
  getBusinessParty,
  getServicesForBilling,
  createBill,
  getBillableGoodsPreview,
  runBillableGoodsService,
} from "../../../actions/bills";

import { getBillingSettings } from "../../../actions/businessclient";

import Unbilledservices from "./unbilledservices";

import CanViewPage from "../../../role-based-access/CanViewPage";
import Servicesummary from "./runbillsummary";

import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";

import { Alert, DatePicker, DateRangePicker } from "rsuite";

const initialState = {
  issuer: "",
  selectedIssuerId: "",
  client: "",
  selectedClientId: "",
  selectedClientServices: [],
  country: "",
  currency: "",
  items: [],
  dateFrom: new Date(),
  dateTo: new Date(),
};

function CreateBillRun({
  history,
  // foundServices,
  isLoading,
}) {
  const [filters, setFilters] = useState({
    categories: ["warehouse", "shipping"],
    warehouse: null,
    issuer: null,
    selectedIssuerId: null,
    client: null,
    selectedClientId: null,
    selectedClientServices: [],
    supplier: null,
    dateFrom: null,
    dateTo: null,
    country: null,
    cycles: null,
    vat: null,
  });
  const [servicesForBilling, setServices] = useState({ foundServices: [] });
  const [clientBilling, setBillingsettings] = useState([]);

  const [cogs, setCogs] = useState({
    no_quote_ids: [],
    withActiveQuotation: [
      {
        productIds: [],
      },
    ],
  });

  const {
    vat,
    client,
    issuer,
    country,
    cycles,
    dateTo,
    selectedClientId,
    selectedClientServices,
    selectedIssuerId,
    warehouse,
    categories,
    supplier,
  } = filters;

  const setCompanyIds = ({ party, companyIds }) => {
    if (party === "client") setclientId(companyIds);
    if (party === "issuer") setissuerId(companyIds);
  };

  const handleChange = ({ name, value }) => {
    setFilters((s) => ({ ...s, [name]: value }));
  };

  const handleChangeCategory = (e, { name, checked }) => {
    if (checked)
      setFilters((s) => ({ ...s, categories: [...s.categories, name] }));
    if (!checked) {
      const newList = filters.categories.filter((item) => item !== name);
      // console.log(newList)
      setFilters((s) => ({ ...s, categories: newList }));
    }
  };

  const handleSetDate = ({ dateFrom, dateTo }) => {
    setFilters((s) => ({ ...s, dateFrom, dateTo }));
  };

  const handleCountryChange = (e, { value: { country, cycles, vat } }) =>
    setFilters((s) => ({ ...s, country, cycles, vat }));

  const handleGetGoodsPreview = () => {
    const body = {
      businessclients: selectedClientServices,
      supplier: filters.issuer._id,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
    };
    getBillableGoodsPreview(body)
      .then((resp) => {
        setCogs(resp);
      })
      .catch((error) => Alert.error(JSON.stringify(error)));
  };

  const handleRunGoodsQuotation = () => {
    const body = {
      businessclients: selectedClientServices,
      supplier: filters.issuer._id,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      productIds:
        cogs.withActiveQuotation.length > 0
          ? cogs.withActiveQuotation[0].productIds
          : [],
    };
    Alert.info("Services is running....", 0);

    setTimeout(() => {
      runBillableGoodsService(body)
        .then((resp) => {
          Alert.closeAll();
          Alert.success("Goods Services generated!");
        })
        .catch((err) => {
          Alert.closeAll();
          Alert.error(JSON.stringify(err));
        });
    }, 1000);
  };
  const [clientId, setclientId] = useState(null);
  const [issuerId, setissuerId] = useState(null);

  useEffect(() => {
    if (cycles) {
      let date = new Date();
      switch (cycles) {
        case "Once a week":
        case "Once in two weeks":
          const currentDayOfWeek = date.getDay();
          const currentDate = date.getDate();
          date.setDate(currentDate - currentDayOfWeek);
          break;
        case "Once a month":
          date.setDate(0);
          break;
        default:
          date = null;
      }
      setFilters((s) => ({ ...s, dateTo: date }));
    } else setFilters((s) => ({ ...s, dateTo: null }));
  }, [cycles]);

  const preview = () => {
    getServicesForBilling({
      vat,
      client,
      issuer,
      country,
      cycles,
      dateTo,
      selectedClientId,
      selectedClientServices,
      selectedIssuerId,
      warehouse,
      categories,
      supplier,
    }).then((resp) => setServices(resp));
  };

  useEffect(() => {
    if (selectedClientId)
      getBusinessParty({ id: selectedClientId })
        .then((resp) => {
          if (resp.companyids && country) {
            const companyIds = resp.companyids.find(
              (coids) => coids.country === country
            );
            setCompanyIds({ party: "client", companyIds });
          }

          setFilters((s) => ({ ...s, client: resp }));
        })
        .catch((error) => Alert.error(error.message));
  }, [selectedClientId, country]);

  useEffect(() => {
    if (selectedIssuerId)
      getBusinessParty({ id: selectedIssuerId })
        .then((resp) => {
          if (resp.companyids && country) {
            const companyIds = resp.companyids.find(
              (coids) => coids.country === country
            );
            setCompanyIds({ party: "issuer", companyIds });
          }

          setFilters((s) => ({ ...s, issuer: resp }));
        })
        .catch((error) => Alert.error(error.message));
  }, [selectedIssuerId, country]);

  const handleCreateBill = () => {
    let list = {};

    // console.log(foundServices,foundServices.length)
    const { foundServices } = servicesForBilling;

    for (let i = 0; i < foundServices.length; i++) {
      //create hash table  ['currency']:[...serviceids]
      const currency = foundServices[i]._id;
      const ids = foundServices[i].allids;
      const grandTotal = foundServices[i].grandTotal;

      if (!list[currency])
        list = { ...list, [currency]: { ids: [], grandTotal: 0 } };

      list[currency].ids = [...list[currency].ids, ...ids];
      list[currency].grandTotal = list[currency].grandTotal + grandTotal;
    }

    // console.log(list)

    createBill(
      {
        issuer,
        issuerId,
        vat,
        businessclientId: clientId, //tax ids
        businessclient: client._id,
        country,
        dateTo,
        dateFrom: servicesForBilling.dateFrom,
        list,
      },
      history
    );
  };

  useEffect(() => {
    if (client) {
      getBillingSettings({ id: client._id }).then((resp) => {
        setBillingsettings(resp);
      });
    }
  }, [client]);

  const handleClear = () => {
    setFilters(initialState);
  };

  return (
    <CanViewPage path="/billing/manually">
      <Unbilledservices></Unbilledservices>

      <main style={{ maxWidth: "900px", margin: "20px" }}>
        <Segment padded>
          <Header>Create Bill for Provided Services</Header>
          {/* Issuer invoice data */}
          <section id="caterries">
            <Checkbox
              style={{ marginRight: "10px" }}
              name="warehouse"
              label="Warehouse"
              checked={filters.categories.includes("warehouse")}
              onChange={handleChangeCategory}
            ></Checkbox>
            <Checkbox
              style={{ marginRight: "10px" }}
              name="shipping"
              label="Shipping"
              checked={filters.categories.includes("shipping")}
              onChange={handleChangeCategory}
            ></Checkbox>
            <Checkbox
              style={{ marginRight: "10px" }}
              name="finance"
              label="Finance"
              checked={filters.categories.includes("finance")}
              onChange={handleChangeCategory}
            ></Checkbox>
            <Checkbox
              style={{ marginRight: "10px" }}
              name="goods"
              label="Goods"
              checked={filters.categories.includes("goods")}
              onChange={handleChangeCategory}
            ></Checkbox>
            <Checkbox
              style={{ marginRight: "10px" }}
              name="callcenter"
              label="Call Center"
              checked={filters.categories.includes("callcenter")}
              onChange={handleChangeCategory}
            ></Checkbox>
          </section>
          <Divider></Divider>
          {filters.categories.includes("warehouse") && (
            <div className="create-bill__section">
              <Label circular color="blue" className="create-bill__number">
                1
              </Label>
              <label className="create-bill__label">Select Warehouse:</label>
              <WarehouseFilter
                useFilter={false}
                open={!filters.warehouse}
                value={filters.warehouse}
                handleChange={(value) =>
                  handleChange({ name: "businessclient", value })
                }
                multiple={false}
              ></WarehouseFilter>
              (optional)
            </div>
          )}
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              2
            </Label>
            <label className="create-bill__label">Select Issuer:</label>{" "}
            <BusinessclientFilter
              placeholder="issuer..."
              multiple={false}
              useFilter={false}
              name="selectedIssuerId"
              value={selectedIssuerId}
              handleChange={(value) =>
                handleChange({ name: "selectedIssuerId", value })
              }
            />
            {issuer && issuer._id && (
              <div className="client-info">
                <div className="client-info__seaction">
                  <Header>{issuer.name}</Header>

                  <Header as="h5">Address</Header>
                  {issuer.address && selectedIssuerId && (
                    <p>
                      {issuer.address.country && (
                        <p>Country: {issuer.address.country}</p>
                      )}
                      {issuer.address.region && (
                        <p>Region: {issuer.address.region}</p>
                      )}
                      {issuer.address.province && (
                        <p>Province: {issuer.address.province}</p>
                      )}
                      {issuer.address.city && (
                        <p>City: {issuer.address.city}</p>
                      )}
                      {issuer.address.street && (
                        <p>Street: {issuer.address.street}</p>
                      )}
                      {issuer.address.streetNr && (
                        <p>Street Nr: {issuer.address.streetNr}</p>
                      )}
                      {issuer.address.postcode && (
                        <p>Postcode: {issuer.address.postcode}</p>
                      )}
                    </p>
                  )}
                </div>
                <div className="client-info__seaction">
                  <Header as="h5">Company ID</Header>
                  {issuerId ? (
                    <>
                      <p>Country: {issuerId.country}</p>
                      <p>Company ID: {issuerId.coid}</p>
                      <p>TAX ID: {issuerId.taxid}</p>
                    </>
                  ) : (
                    <p>Nothing found for the selected country</p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* CLIENT INOVICE DATA */}
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              3
            </Label>
            <label className="create-bill__label">Select Client:</label>

            <BusinessclientFilter
              placeholder="client..."
              multiple={false}
              // disabled={!country}
              name="selectedClientId"
              value={selectedClientId}
              useFilter={false}
              handleChange={(value) =>
                handleChange({ name: "selectedClientId", value })
              }
            />
            {client && client._id && selectedClientId && (
              <div className="client-info">
                <div className="client-info__seaction">
                  <Header>{client.name}</Header>
                  <Header as="h5">Address</Header>
                  {client.address && (
                    <p>
                      {client.address.country && (
                        <p>Country: {client.address.country}</p>
                      )}
                      {client.address.region && (
                        <p>Region: {client.address.region}</p>
                      )}
                      {client.address.province && (
                        <p>Province: {client.address.province}</p>
                      )}
                      {client.address.city && (
                        <p>City: {client.address.city}</p>
                      )}
                      {client.address.street && (
                        <p>Street: {client.address.street}</p>
                      )}
                      {client.address.streetNr && (
                        <p>Street Nr: {client.address.streetNr}</p>
                      )}
                      {client.address.postcode && (
                        <p>Postcode: {client.address.postcode}</p>
                      )}
                    </p>
                  )}
                </div>
                <div className="client-info__seaction">
                  <Header as="h5">Company IDs</Header>
                  {clientId ? (
                    <>
                      <p>Country: {clientId.country}</p>
                      <p>Company ID: {clientId.coid}</p>
                      <p>TAX ID: {clientId.taxid}</p>
                    </>
                  ) : (
                    <p>Nothing found for the selected country</p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              4
            </Label>
            <label className="create-bill__label">Select services</label>

            <BusinessclientFilter
              useFilter={false}
              placeholder="client services..."
              multiple={true}
              style={{ minWidth: "200px" }}
              name="selectedClientServices"
              value={selectedClientServices}
              handleChange={(value) =>
                handleChange({ name: "selectedClientServices", value })
              }
            />
          </div>
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              5
            </Label>
            <label className="create-bill__label">Billing settings</label>
            <Dropdown
              disabled={!client}
              placeholder="billing settings..."
              name="country"
              value={{ country, cycles }}
              // error
              clearable
              selection
              search
              selectOnBlur={false}
              closeOnChange={true}
              onChange={handleCountryChange}
              options={clientBilling.map(
                ({ country, cycle, vat, daysToDue }, i) => ({
                  key: i,
                  value: { country, cycles, vat },
                  text: `${country} (VAT:${vat}%)`,
                  content: (
                    <Header
                      as="h3"
                      content={`${country} VAT ${vat}`}
                      subheader={`${cycles} - ${daysToDue} days to due`}
                    />
                  ),
                })
              )}
            />
            {/* <CountryFilter
              value={country}
              handleChange={handleChange}
              className="mr--1"
            /> */}
            {/* <CurrencyFilter value={currency} handleChange={handleChange} /> */}
          </div>

          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              6
            </Label>
            {filters.categories.includes("goods") && (
              <>
                <label className="create-bill__label">Range:</label>
                <DateRangePicker
                  style={{ width: "250px" }}
                  placeholder="Select Date Range"
                  format="DD-MM-YYYY"
                  // ranges={[dateFrom,dateTo]}
                  onChange={([dateFrom, dateTo]) =>
                    handleSetDate({ dateFrom, dateTo })
                  }
                />
                <br></br>

                <button
                  disabled={!filters.client}
                  onClick={handleGetGoodsPreview}
                >
                  Check goods quotations
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  disabled={!filters.client}
                  onClick={handleRunGoodsQuotation}
                >
                  Run quotations{" "}
                  {cogs.withActiveQuotation.length > 0
                    ? cogs.withActiveQuotation[0].productIds.length
                    : 0}
                </button>

                {cogs.no_quote_ids.length > 0 && (
                  <div>
                    <p>No Quotations</p>
                    {cogs.no_quote_ids.map((item) => (
                      <li>
                        {item}-
                        <a
                          href={`/products/${item}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          view
                        </a>
                      </li>
                    ))}
                  </div>
                )}
              </>
            )}
            {!filters.categories.includes("goods") && (
              <>
                <label className="create-bill__label">Up to date:</label>
                <DatePicker
                  style={{ width: "200px" }}
                  oneTap
                  placeholder="date to.."
                  value={dateTo}
                  format="DD-MM-YYYY"
                  // ranges={[dateFrom,dateTo]}
                  onChange={(dateTo) =>
                    handleSetDate({ dateTo, currentPage: 1 })
                  }
                />
              </>
            )}
          </div>

          <label className="create-bill__label">
            Select supplier(if applicable)
          </label>

          <BusinessclientFilter
            placeholder="service supplier..."
            useFilter={false}
            name="supplier"
            value={supplier}
            handleChange={(value) => handleChange({ name: "supplier", value })}
          />
          <br></br>
          <Button onClick={() => preview()}>Preview</Button>

          <Divider></Divider>

          {servicesForBilling && (
            <Form loading={isLoading}>
              <Servicesummary services={servicesForBilling} />
            </Form>
          )}

          <Divider></Divider>

          <div className="flex--right">
            <Button onClick={handleClear}>Cancel</Button>
            <Button
              disabled={!selectedClientId || !country || !servicesForBilling}
              primary
              onClick={handleCreateBill}
            >
              Create
            </Button>
          </div>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default CreateBillRun;
