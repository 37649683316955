import React, { Component } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import {isMobile} from 'react-device-detect';

export class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isMobile:null,
      email: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.onAuth(this.state);
    this.setState({
      username: "",
      password: "",
      email: ""
    });
  }
  componentDidMount() {
    
    this.setState({isMobile})
  }
  render() {
    const { username, password } = this.state;
    const {  isAuthenticated } = this.props;

  
    const LoginForm = (
      <div className="page-wrapper">
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h1" color="teal" textAlign="center">
              {/* <Image src='/logo.png' />  */}
              Log-in to your account
            </Header>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="username"
                  id="username"
                  name="username"
                  onChange={this.handleChange}
                  value={username}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  value={password}
                />

                <Button
                  color="teal"
                  fluid
                  size="large"
                  onClick={this.handleSubmit}
                >
                  Login
                </Button>
              </Segment>
            </Form>
            {/* <Message>
                          New to us? <a href='#'>Sign Up</a>
                        </Message> */}
          </Grid.Column>
        </Grid>
      </div>
    );

    return (
      <div>
        {isAuthenticated && this.props.history.push("/")}
        {!isAuthenticated && LoginForm}
      </div>
    );
  }
}
