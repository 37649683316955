import React, { useEffect, useState } from "react";
import {
  Header,
  Button,
  Table,
  Divider,
} from "semantic-ui-react";
import Filters from "./Filters";
import { Alert } from "rsuite";
import ExportButton from "./ExportButton";
import ExportButton_warehouse_column from "./ExportButton_per_warehouse";
import { stock_lead_report } from "../../../actions/reports";
import EditableText from "../../EditableText";

import { useUpdateContext } from "../../../context/UpdateContext";
import "./services.scss";

// const testData = {
//   products: ["620f93bab542d371afcf7836", "645207b2bd3f384c040b847b"],
//   days: 45,
// };

function Leadstock(props) {
  const { handleAddToPO, businessclient, warehouse, country, product } =
    useUpdateContext();

  const [isLoading, setLoading] = useState(false);
  const [days, setDays] = useState(15);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    stock_lead_report({ days,businessclient,product,warehouse,country }).then((resp) => {
      setList(resp);
      setLoading(false);
    });
  }, []);

  let report = [];
  const lines = Object.keys(list);

  for (let i = 0; i < lines.length; i++) {
    const key = lines[i];
    let item = list[key];

    const str = item.leads ? item.leads.toString() : "";
    const len = str !== "" ? str.length : 0;
    const first = str.substring(0, 1);
    const value = (first * 1 + 1) * Math.pow(10, len - 1);

    report.push(
      <Table.Row>
        <Table.Cell>{item.product}</Table.Cell>
        <Table.Cell>{JSON.stringify(item.warehouse)}</Table.Cell>
        <Table.Cell>{item.leads}</Table.Cell>
        <Table.Cell>{item.stock}</Table.Cell>
        <Table.Cell textAlign="left">
          <div>
            <EditableText
              style={{ width: "100px" }}
              fluid={false}
              name="quantity_shipped"
              value={value}
              handleChange={(e, { value, name }) => {}}
              showButton={true}
              handleAdd={(value) =>
                handleAddToPO({
                  quantity: value,
                  product: {
                    name: item.product,
                    sku: item.sku,
                    _id: item.productId,
                  },
                  warehouse: {
                    _id: item.warehouseId,
                    name: item.warehouseName,
                  },
                  businessclient: {
                    _id: item.businessclientId,
                    name: item.businessclient,
                  },
                })
              }
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    // <CanViewPage path="/reports/turnover">
    <main style={{ maxWidth: "1100px", margin: "15px" }}>
      <Header style={{ marginBottom: 0 }} as="h3">
        LEAD STOCK REPORT
      </Header>
      <Divider />
      <Filters days={days} setDays={setDays} />
      <Button
        onClick={() => {
          setLoading(true);
          stock_lead_report({
            businessclient,
            products: product,
            days,
            warehouse,
            country,
          })
            .then((resp) => {
              setList(resp);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              Alert.error(err);
            });
        }}
      >
        {" "}
        Generate Report
      </Button>
      <ExportButton list={list} days={days} />

      <ExportButton_warehouse_column list={list} days={days} />

      {isLoading && <div>Getting moves for last {days} days...</div>}
      {!isLoading && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Warehouse</Table.HeaderCell>
              <Table.HeaderCell>Leads</Table.HeaderCell>
              <Table.HeaderCell>Stock</Table.HeaderCell>
              <Table.HeaderCell>Add to PO Card</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{report}</Table.Body>
        </Table>
      )}
    </main>
    // {/* </CanViewPage> */}
  );
}

export default Leadstock;
