import React, { useEffect, useState } from "react";
import { Button, Table, Checkbox } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import {
  getCourierUsers,
  deleteCourierUser,
  editCourierUser,
} from "../../../actions/couriers";
import UserEditModal from "./UserEditModal";

function UsersTable({ courierId, refresh }) {
  
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [initCourierUserFormValues, setInitFormValues] = useState({
    name: "",
    businessclients: [],
    _id: "",
    account: "",
  });

  const [courierUsers, setUsers] = useState([]);

  const openEditModal = (user) => {
    setEditModalOpen(true);
    setInitFormValues(user);
  };
  const closeEditModal = () => setEditModalOpen(false);

  useEffect(() => {
    if (courierId) getCourierUsers(courierId).then((resp) => setUsers(resp));
  }, [courierId, reload]);

  if (!courierUsers.length)
    return <p className="center--text">No users saved yet</p>;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Username</Table.HeaderCell>
      <Table.HeaderCell>Account</Table.HeaderCell>
      <Table.HeaderCell>Clients</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Active</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = courierUsers.map(
    ({ _id, username, isActive, businessclients, account }, i) => (
      <Table.Row key={_id}>
        <Table.Cell>{i + 1}</Table.Cell>
        <Table.Cell>{username.slice(0, 40)}</Table.Cell>
        <Table.Cell>{account}</Table.Cell>
        <Table.Cell>
          <ol>
            {businessclients.map((bc) => bc && <li key={bc._id}>{bc.name}</li>)}
          </ol>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            toggle
            checked={isActive}
            onChange={() =>
              editCourierUser({
                id: courierId,
                userId: _id,
                query: { isActive: !isActive },
              }).then(resp=>setReload(!reload))
            }
          />
          {/* {isActive && <Icon name="check" color="grey" />} */}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            basic
            compact
            size="tiny"
            icon="pencil"
            onClick={() =>
              openEditModal({ username, businessclients, _id, account })
            }
          />
          <Button
            basic
            compact
            size="tiny"
            icon="trash"
            onClick={() =>
              deleteCourierUser({ id: courierId, userId: _id }).then((resp) =>
                refresh()
              )
            }
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      <Table unstackable basic="very" >
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>

      <Modal
        open={isEditModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeEditModal}
        center
        classNames={{ modal: "" }}
      >
        <UserEditModal
          initCourierUserFormValues={initCourierUserFormValues}
          handleCloseModal={closeEditModal}
          courierId={courierId}
        />
      </Modal>
    </>
  );
}


export default (UsersTable);
