import React, { useState, useEffect } from "react";
import {
  Header,
  Divider,
  Input,
  Button,
  Segment,
  // Icon,
  Loader,
} from "semantic-ui-react";
// import { setCurrentUser } from "../../actions/auth";

import { getUserProfile, updateOwnUser } from "./../../actions/user";
import "./userProfile.scss";

// const requiredFields = [];

const initialState = {
  isChangingPassword: false,
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
  passwordsMatch: false,
  username: "",
  isUsernameTouched: false,
  values: {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  },
  isTouched: [],
  validation: {
    emailIsEmailType: false,
  },
  isSubmitted: false,
  successfulSubmit: false,
};

function UserProfile(props) {

  const [state, setState] = useState(initialState);
  const [isLoading,setLoading] = useState(false)
  const [user,setCurrentUser] = useState()
  const {
    values = {},
    isTouched,
    isSubmitted,
    validation = {},
    successfulSubmit,
    isChangingPassword,
    isUsernameTouched,
    oldPassword,
    newPassword,
    repeatPassword,
    passwordsMatch,
    username,
  } = state;

  // console.log({ state });

  useEffect(() => {
    setLoading(true)
    getUserProfile().then(resp=>{
      setLoading(false)
      setCurrentUser(resp)})}, []);


  const handleUsernameChange = (e, { value }) =>
    setState((s) => ({ ...s, username: value, isUsernameTouched: true }));

  const handlePasswordChange = (e, { name, value }) =>
    setState((s) => {
      const passwordsMatch =
        name === newPassword
          ? value === s.repeatPassword
          : value === s.newPassword;
      return { ...s, [name]: value, passwordsMatch };
    });

  const handleValueChange = (e, { name, value }) =>
    setState((s) => {
      const isTouched = s.isTouched.includes(name)
        ? s.isTouched
        : [...s.isTouched, name];
      return { ...s, values: { ...s.values, [name]: value }, isTouched };
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setState((s) => {
      let isEmail = false;
      if (values.email) {
        isEmail = /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          s.values.email
        );
      }
      const successfulSubmit = (values.email && isEmail) || !values.email;
      return {
        ...s,
        validation: { ...s.validation, emailIsEmailType: isEmail },
        isSubmitted: true,
        successfulSubmit,
      };
    });
  };

  useEffect(() => {
    if (successfulSubmit) {
      let query = {};
      for (let key in values) {
        if (isTouched.includes(key) && values[key] !== user[key])
          query[key] = values[key];
      }
      if (successfulSubmit) updateOwnUser(query);
    }
  }, [isTouched, successfulSubmit, updateOwnUser, user, values]);

  return (
    // <CanViewPage path="/users/:id">
    <main className="page-wrapper a-wrapper">
      <Segment padded>
        {isLoading ? (
          <div style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <section>
            <Header as="h3">Update profile</Header>
            {/* <Divider /> */}
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="profile-form">
                <div className="profile-form__filed">
                  <label>Username:</label>
                  <div>
                    <Input
                      className="mr--1"
                      type="text"
                      name="username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                    {isUsernameTouched && username.length > 0 && (
                      <Button onClick={() => updateOwnUser({ username })}>
                        Update
                      </Button>
                    )}
                  </div>
                </div>
                <div className="profile-form__filed">
                  <label>Password:</label>
                  <div>
                    {isChangingPassword ? (
                      <div className="change-password">
                        <div className="mb--1">
                          <label style={{ display: "block" }}>
                            Old password:
                          </label>
                          <Input
                            placeholder="password..."
                            type="password"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={handlePasswordChange}
                          />
                        </div>

                        <div className="mb--1">
                          <label style={{ display: "block" }}>
                            New password:
                          </label>
                          <Input
                            className="mr--1"
                            placeholder="new password..."
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            onChange={handlePasswordChange}
                          />
                        </div>

                        <div className="mb--1">
                          <label>Repeat password:</label>
                          <div>
                            <Input
                              className="mr--1"
                              placeholder="new password..."
                              type="password"
                              name="repeatPassword"
                              value={repeatPassword}
                              onChange={handlePasswordChange}
                            />

                            {oldPassword &&
                              newPassword &&
                              repeatPassword &&
                              passwordsMatch && (
                                <Button
                                  onClick={() =>
                                    updateOwnUser({ oldPassword, newPassword })
                                  }
                                >
                                  Update
                                </Button>
                              )}
                            {!passwordsMatch && repeatPassword && (
                              <p className="user-form__error-msg">
                                Passwords do not match
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Button
                        compact
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            isChangingPassword: true,
                          }))
                        }
                      >
                        Change password
                      </Button>
                    )}
                  </div>
                </div>

                <Divider />
                <div className="profile-form__filed">
                  <label>Roles:</label>
                  {/* <p>{user.roles && user.roles.join(", ")}</p> */}
                </div>
                <div className="profile-form__filed">
                  <label>Country:</label>
                  {/* <p>{user.country && user.country.join(", ")}</p> */}
                </div>

                <div className="profile-form__filed">
                  <label>First name:</label>
                  <Input
                    placeholder="first name..."
                    type="text"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleValueChange}
                  />
                </div>

                <div className="profile-form__filed">
                  <label>Last name:</label>
                  <Input
                    placeholder="last name..."
                    type="text"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleValueChange}
                  />
                </div>

                <div className="profile-form__filed">
                  <label>E-mail:</label>
                  <div>
                    <Input
                      placeholder="email..."
                      type="email"
                      name="email"
                      fluid
                      value={values.email}
                      onChange={handleValueChange}
                    />
                    {!validation.emailIsEmailType &&
                      values.email &&
                      isSubmitted && (
                        <p className="user-form__error-msg">! Not an email</p>
                      )}
                  </div>
                </div>

                <div className="profile-form__filed">
                  <label>Phone:</label>

                  <Input
                    placeholder="phone..."
                    type="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleValueChange}
                  />
                </div>
              </div>

              <div>
                {/* <Button onClick={handleClear}>Clear Form</Button> */}
                <Button
                  disabled={!isTouched.length}
                  onClick={handleSubmit}
                  color="blue"
                >
                  Save
                </Button>
              </div>
            </form>
          </section>
        )}
      </Segment>
    </main>
  );
}

export default (UserProfile);
