import React from "react";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from "semantic-ui-react";
import html2pdf from "html2pdf.js";
import { printJob } from "./../../actions/cashier";
import { Alert } from "rsuite";

function exampleReducer(state, action) {
  switch (action.type) {
    case "close":
      return { open: false };
    case "open":
      return { open: true, size: action.size };
    default:
      throw new Error("Unsupported action...");
  }
}

const ConfirmPrint = ({
  openConfirmation,
  setOpenConfirmation,
  handlePaid,
  payment,
  checkout
}) => {
  const PrintPaycheck = () => {
    const opt = {
      filename: `paycheck.pdf`,
      image: { type: "jpeg", quality: 1 },
      // html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm",
        format: [90, 200],
        orientation: "p",
      },
    };
    const invoice = document.querySelector("#paycheck");

    html2pdf()
      .set(opt)
      .from(invoice)
      .outputPdf("dataurlstring")
      .then((data) => {
        // console.log("printing invoice...", data);
        printJob({
          format: "paragon",
          uriData: data,
        })
          .then((resp) => Alert.success("printed"))
          .catch((error) => Alert.error(error.message));
      });
  };

  return (
    <>
      <Modal
        size="tiny"
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <ModalHeader>PRINT RECEIPT</ModalHeader>
        <ModalContent>
          <p>
            {" "}
            <div id="paycheck" >
              I AM PAYCHECK for {checkout?checkout.total:''}
            </div>
          </p>
        </ModalContent>
        <ModalActions>
          <Button negative onClick={() => setOpenConfirmation(false)}>
            Cancel
          </Button>
          <Button
            positive
            onClick={() => {
              PrintPaycheck();
              handlePaid({ payment });
              setOpenConfirmation(false);
            }}
          >
            Print
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default ConfirmPrint;
