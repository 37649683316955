import React, { useState, useEffect } from "react";
import {
  Header,
  Button,
  Divider     ,
  Table,
  Segment,
  Input
} from "semantic-ui-react";
import Moment from "react-moment";
import DeleteGroupModal from "./DeleteGroupModal";
import {Link} from 'react-router-dom'
import AddProducts from "./AddProducts";
import ProductFilter from '../../../Filters/ProductFilter'


function ProductGroupInfo(props) {
  const {
    addPromoProducts,
    removeDispProduct,
    productGroup,
    updatePromo,
    removeProduct,
    history,
    addDispProducts,
    businessclient,
    productsFromPG,
    
  } = props;


  const [isModalOpen, setModal] = useState(false);
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState();

  const [isEditingTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState("");

  const handlePromoProduct = (e,{value}) => setProduct(value);
  
  const handleToggleModale = () => setModal(!isModalOpen);
  const handleEditTitle = () => setEditTitle(!isEditingTitle);
  const handleTitleChange = (e, { value }) => setTitle(value);
  const handleSave = () => {
    handleEditTitle();
    updatePromo({ id: productGroup._id, query: {name:title }});
   
  };

  useEffect(() => {
    if (isEditingTitle) setTitle(productGroup.name);
  }, [isEditingTitle, productGroup.name]);

  const displayToProducts =productGroup.displayToProducts.map(({codes,name,_id},index)=>( <Table.Row key={_id + index}>
        <Table.Cell verticalAlign="middle">{index + 1}</Table.Cell>
        <Table.Cell verticalAlign="middle">
          
            <Link to={`/products/${_id}`} target="_blank">
                {name} - <span className="grey--text">{codes.sku}</span>
             </Link>
            
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            size="tiny"
            color="red"
            basic
            icon="trash"
            onClick={() => removeDispProduct([_id])}
          />
        </Table.Cell>
      </Table.Row>))


  const promoProducts =productGroup.promoProducts.map(({ _id,price,quantity,product }, index) => (
      <Table.Row key={_id + index}>
        <Table.Cell verticalAlign="middle">{index + 1}</Table.Cell>
        <Table.Cell verticalAlign="middle">
         
      
            <Link to={`/products/${product._id}`} target="_blank">
                {product.name} - <span className="grey--text">{product.codes.sku}</span>
             </Link>
         
        </Table.Cell>
        <Table.Cell>{quantity}</Table.Cell>
        <Table.Cell>{price}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            size="tiny"
            color="red"
            basic
            icon="trash"
            onClick={() => removeProduct([_id])}
          />
        </Table.Cell>
      </Table.Row>
    ));


    return (
      <section className="pg-add-products">
        <div className="flex--between">
          <Header as="h2">
            {productGroup.name}{" "}
            <span className="grey--text">
              - {productGroup.promoProducts.length} products
            </span> - <span>{productGroup.country}</span>
          </Header>

          <div>
            <Button
              compact
              size="tiny"
              color="yellow"
              disabled={isEditingTitle}
              onClick={handleEditTitle}
            >
              Change name
            </Button>
            <Button
              compact
              size="tiny"
              color="red"
              onClick={handleToggleModale}
            >
              Delete group
            </Button>
          </div>
        </div>
        <DeleteGroupModal
          id={productGroup._id}
          isModalOpen={isModalOpen}
          handleCloseModal={handleToggleModale}
          history={history}
        />

        {isEditingTitle && (
          <Segment raised>
            <label>New group name:</label>
            <br />
            <Input
              type="text"
              placeholder="New name..."
              action
              value={title}
              onChange={handleTitleChange}
            >
              <input />
              <Button color="yellow" onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleEditTitle}>Cancel</Button>
            </Input>
          </Segment>
        )}

        <div class="small--text">
          <span
            class="grey--text"
            style={{ minWidth: "5.8em", display: "inline-block" }}
          >
            Client:
            {productGroup.businessclient
              ? productGroup.businessclient.name
              : "NA"}
          </span>
        </div>
        <div class="small--text">
          <span
            class="grey--text"
            style={{ minWidth: "5.8em", display: "inline-block" }}
          >
            Created at:
          </span>
          <Moment format="DD-MM-YY HH:mm">{productGroup.createdAt}</Moment>
        </div>
        <div class="small--text">
          <span
            class="grey--text"
            style={{ minWidth: "5.8em", display: "inline-block" }}
          >
            Updated at:
          </span>
          <Moment format="DD-MM-YY HH:mm">{productGroup.updatedAt}</Moment>
        </div>


        <Divider></Divider>
        <Header>Promo Bundle:</Header>
        <div className="product-group-columns">
          <div className="product-group-columns__info">
            <Table unstackable selectable style={{ marginTop: "1.3em" }}>
              <tr>
                <th></th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th></th>
              </tr>
              <Table.Body>{promoProducts}</Table.Body>
            </Table>
          </div>
          <div className="product-group-columns__add-prod">
            <div>
              <h3>Add Product</h3>
              <div>
                <ProductFilter
                  value={product}
                  handleChange={handlePromoProduct}
                  businessclient={businessclient._id}
                                disabled={!businessclient}

                />
                <div><Input placeholder='quantity' type='number' value ={ quantity} onChange={(e,{value})=>setQuantity(value)}></Input></div>
                <div><Input placeholder='price' type='number' value ={ price} onChange={(e,{value})=>setPrice(value)}></Input></div>
               
              </div>
              <button onClick={() => addPromoProducts({product,quantity,price})}>Add</button>
            </div>
          </div>
        </div>

        <Divider></Divider>
        <Header>Display To Products:</Header>
        <div className="product-group-columns">
          <div className="product-group-columns__info">
           {displayToProducts.length>0 && <Table>
              <Table.Body>{displayToProducts}</Table.Body>
            </Table>}
            {displayToProducts.length===0&&<div>This promo will dipslay to ALL products.</div>}
          </div>
          <div className="product-group-columns__add-prod">
            <AddProducts
              addDispProducts={addDispProducts}
              productsFromPG={productsFromPG}
              businessclient={businessclient}
            />
          </div>
        </div>
      </section>
    );
  }


export default ProductGroupInfo;
