import React, { Component } from "react";
// import {Movementlist} from '../Stockmovement/movementlist'
import { connect } from "react-redux";
import { getStockMovements } from "../../actions/inventory";
import EditViewProduct from "../Product/EditViewProduct/";
import { Segment, Button, Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";

class Inventorycard extends Component {
  componentDidMount() {
    const { warehouse_id, limit, skip, sku } = this.props.match.params;

    this.props.getStockMovements({
      warehouse_id,
      limit,
      skip,
      sku
    });
  }
  render() {
    const { stockMovements } = this.props;

    return (
      <div className="inventory">
        <div>
          <a href="/warehouse">{`<<Warehouse - list`}</a>
        </div>

        <div>
          <a href="/warehouse">{`<<Warehouse - list`}</a>
        </div>
        {stockMovements.length > 0 && (
          <h3>
            {stockMovements[0].name} - warehouse:{" "}
            {stockMovements[0].warehouse_id.name}
          </h3>
        )}
        <EditViewProduct />
        <h3>Product Movements</h3>
        <Segment>
          <div className="flex_spaceBetween">
            <Button content="Standard" basic>
              Export
            </Button>
            <Form>
              <DatePicker
                //   selected = {
                //       filters.createdAt.$gte}
                isClearable={true}
                dateFormat="MMMM d, yyyy"
                //   onChange={handleDateFrom}
                placeholderText="Date from..."
              ></DatePicker>

              <DatePicker
                //   selected = {
                //           filters.createdAt.$lte
                //   }
                isClearable={true}
                dateFormat="MMMM d, yyyy"
                //   onChange={handleDateTo}
                placeholderText="Date to..."
              ></DatePicker>
            </Form>
          </div>
          {/* <Movementlist
                    movements = {stockMovements}
                /> */}
        </Segment>
      </div>
    );
  }

  //             <DatePicker
  //               //   selected = {
  //               //           filters.createdAt.$lte
  //               //   }
  //               isClearable={true}
  //               dateFormat="MMMM d, yyyy"
  //               //   onChange={handleDateTo}
  //               placeholderText="Date to..."
  //             ></DatePicker>
  //           </Form>
  //         </div>
  //         <Movementlist movements={stockMovements} />
  //       </Segment>
  //     </div>
  //   );
  // }
}
function mapStateToProps(reduxState) {
  return {
    stockMovements: reduxState.inventory.stockMovements
  };
}

export default connect(mapStateToProps, { getStockMovements })(Inventorycard);
