import React from "react";
import { Segment,Header, Button } from "semantic-ui-react";

function PriceList({ prices = [] }) {
  return (
    <section>
      <div className="flex--between vertical--center mt--2">
        <Header as="h2">Price List</Header>
        {/* <Button onClick={() => openModal(false)}>Add New</Button> */}
      </div>
      <Segment padded>
      Price List
      <ol>
        {prices.map((price, index) => (
          <li key={index}>{price}</li>
        ))}
      </ol>
    </Segment>
    </section>
    
  );
}

export default PriceList;
