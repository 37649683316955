import React, { useReducer, useEffect } from "react";
import {
  Header,
  Divider,
  Input,
  Button,
  Segment,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { createUser } from "../../../actions/user";

import CanViewPage from "../../../role-based-access/CanViewPage";
import RolesFilter from "../../Filters/RolesFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import { Alert } from "rsuite";

const requiredFields = ["username", "password", "passwordRepeat", "roles"];

const initialState = {
  values: {
    username: "",
    password: "",
    passwordRepeat: "",
    location: "",
    roles: [],
    isBusinessclient: false,
    businessclient: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  },
  isTouched: [],
  validation: {
    passwordsMatch: false,
    emailIsEmailType: false,
  },
  isSubmitted: false,
  successfulSubmit: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_FIELD":
      const { name, value } = action.payload;
      let newIsTouched = state.isTouched;
      if (requiredFields.includes(name) && !newIsTouched.includes(name)) {
        newIsTouched.push(name);
      }
      return {
        ...state,
        values: { ...state.values, [name]: value },
        isTouched: newIsTouched,
      };

    case "CHANGE_PASSWORD":
      const passwordMatch =
        action.payload.name === "password"
          ? state.values.passwordRepeat === action.payload.value
          : state.values.password === action.payload.value;
      const isTouched = state.isTouched;
      if (!state.isTouched.includes(action.payload.name)) {
        isTouched.push(action.payload.name);
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
        isTouched: isTouched,
        validation: { ...state.validation, passwordsMatch: passwordMatch },
      };

    case "CHANGE_EMAIL":
      const isEmail = /^\w+([\.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        state.values.email
      );
      return {
        ...state,
        values: { ...state.values, email: action.payload.value },
        validation: { ...state.validation, emailIsEmailType: isEmail },
      };

    case "CHECK BUSINESSCLIENT":
      return {
        ...state,
        values: { ...state.values, isBusinessclient: action.payload.checked },
      };
    case "SUBMIT_FORM":
      let {
        values,
        validation: { passwordsMatch, emailIsEmailType },
      } = state;
      const allRequiredFieldsCompleted = !requiredFields.find(
        (key) => !values[key]
      );
      const emailValidation = emailIsEmailType || !values.email;
      if (passwordsMatch && emailValidation && allRequiredFieldsCompleted)
      {
        const userObj = Object.entries(values)
        .filter(([k, v]) => v)
        .reduce((newObj, [k, v]) => {
          newObj[k] = v;
          return newObj;
        }, {});
      createUser(userObj).then(resp=>Alert.success('User created successfuly.'));
      return {
        ...state,
        isSubmitted: true,
        successfulSubmit: true,
        isTouched: [],
      };
      }
        
        
      return { ...state, isSubmitted: true, successfulSubmit: false };

    case "CLEAR_FORM":
      return { ...state, ...initialState, isTouched: [] };

    default:
      return state;
  }
};

function CreateUser(props) {
  const { createUser } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    values,
    isTouched,
    validation,
    isSubmitted,
    successfulSubmit,
  } = state;

  // console.log(state);

  const handleClear = (e) => {
    e.preventDefault();
    dispatch({ type: "CLEAR_FORM" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "SUBMIT_FORM" });
  };

  const handleFieldChange = (e, { name, value }) =>
    dispatch({ type: "CHANGE_FIELD", payload: { name, value } });

  const handleBClientCheck = (e, { name, checked }) =>
    dispatch({ type: "CHECK BUSINESSCLIENT", payload: { name, checked } });
  const handlePasswordChange = (e, { name, value }) =>
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: { name, value },
    });

  const showError = ({ field, isArray = false }) =>
    isArray
      ? (isTouched.includes(field) || isSubmitted) && !values[field]
      : (isTouched.includes(field) || isSubmitted) && !values[field].length;

  // useEffect(() => {
  //   if (successfulSubmit) {
  //     //deep clean values
  //     const userObj = Object.entries(values)
  //       .filter(([k, v]) => v)
  //       .reduce((newObj, [k, v]) => {
  //         newObj[k] = v;
  //         return newObj;
  //       }, {});
  //     createUser(userObj).then(resp=>Alert.success('User created successfuly.'));
  //   }
  // }, [successfulSubmit, values]);

  return (
    <CanViewPage path="/users/create/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/users">
          <Icon name="arrow left" />
          Users List
        </Link>
        <Segment padded>
          <section>
            <Header as="h3">Add New User</Header>
            {/* <Divider /> */}
            <form autocomplete="off" onSubmit={handleSubmit}>
              <div className="user-form__form-section">
                <div className="user-form__filed">
                  <label>Username:</label>
                  <Input
                    // autocomplete="off"
                    type="text"
                    name="username"
                    value={values.username}
                    onChange={handleFieldChange}
                    error={showError({ field: "username" })}
                  />
                  {showError({ field: "username" }) && (
                    <span className="user-form__error-msg">
                      ! This field is required
                    </span>
                  )}
                </div>

                <div className="user-form__filed">
                  <label>Password:</label>
                  <Input
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handlePasswordChange}
                    error={showError({ field: "password" })}
                  />
                  {showError({ field: "password" }) && (
                    <span className="user-form__error-msg">
                      ! This field is required
                    </span>
                  )}
                </div>

                <div className="user-form__filed">
                  <label>Repeat Password:</label>
                  <Input
                    type="password"
                    name="passwordRepeat"
                    value={values.passwordRepeat}
                    onChange={handlePasswordChange}
                    error={showError({ field: "passwordRepeat" })}
                  />
                  {showError({ field: "passwordRepeat" }) && (
                    <span className="user-form__error-msg">
                      ! This field is required
                    </span>
                  )}
                  {isSubmitted && !validation.passwordsMatch && (
                    <span className="user-form__error-msg">
                      ! Passwords do not match
                    </span>
                  )}
                </div>

                <div className="user-form__filed">
                  <label>Location:</label>
                  <WarehouseFilter
                    value={values.location}
                    name="location"
                    handleChange={handleFieldChange}
                    error={showError({ field: "location" })}
                  />
                  {showError({ field: "location" }) && (
                    <span className="user-form__error-msg">
                      ! This field is required
                    </span>
                  )}
                </div>

                <div className="user-form__filed">
                  <label>Roles:</label>
                  <RolesFilter
                    multiple
                    value={values.roles}
                    handleChange={handleFieldChange}
                    error={showError({ field: "roles", isArray: true })}
                  />
                  {showError({ field: "roles", isArray: true }) && (
                    <span className="user-form__error-msg">
                      ! This field is required
                    </span>
                  )}
                </div>

                <div className="user-form__filed">
                  <Checkbox
                    name="isBusinessclient"
                    checked={values.isBusinessclient}
                    onChange={handleBClientCheck}
                    label="is business client"
                  />
                  {values.isBusinessclient && (
                    <BusinessclientFilter
                      useFilter={false}
                      name="businessclient"
                      value={values.businessclient}
                      handleChange={(value)=>handleFieldChange(null,{value,name:"businessclient"})}
                    />
                  )}
                </div>
              </div>

              <Divider />
              <div className="user-form__form-section user-form__form-section_details">
                <div className="user-form__filed">
                  <label>First name:</label>
                  <Input
                    type="text"
                    name="firstname"
                    placeholder="first name ..."
                    value={values.firstname}
                    onChange={handleFieldChange}
                  />
                </div>
                <div className="user-form__filed">
                  <label>Last name:</label>
                  <Input
                    type="text"
                    name="lastname"
                    placeholder="last name ..."
                    value={values.lastname}
                    onChange={handleFieldChange}
                  />
                </div>

                <div className="user-form__filed">
                  <label>E-mail:</label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="example@mail.com ..."
                    value={values.email}
                    onChange={(e, { value }) =>
                      dispatch({
                        type: "CHANGE_EMAIL",
                        payload: { value },
                      })
                    }
                  />
                  {!validation.emailIsEmailType &&
                    values.email &&
                    isSubmitted && (
                      <span className="user-form__error-msg">
                        ! Not an email
                      </span>
                    )}
                </div>
                <div className="user-form__filed">
                  <label>Phone:</label>
                  <Input
                    type="phone"
                    name="phone"
                    placeholder=""
                    value={values.phone}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>

              <div>
                <Button onClick={handleClear}>Clear Form</Button>
                <Button onClick={handleSubmit} color="blue">
                  Save
                </Button>
              </div>
            </form>
          </section>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default connect(null, {
  createUser,
})(CreateUser);
