import { useState, useEffect } from "react";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const mapData = (orders, checkedItems) => {
  if (checkedItems && checkedItems.length)
    orders = orders.filter(({ _id }) => checkedItems.includes(_id));
  return orders.map(
    ({
      _id,
      internalNumericId,
      clientOrderNr,
      status,
      businessclient = { name: "" },
      createdAt,
      updatedAt,
      shipping = {},
      payment = {},
      processing = {},
      errMsgs,
      items,
    }) => ({
      "Client Order Nr": clientOrderNr,
      internalNumericId,
      ID: _id,
      Status: `${status} ${errMsgs}`,
      "Created at": new Date(createdAt).toLocaleString(),
      "Updated at": new Date(updatedAt).toLocaleString(),
      Businessclient: businessclient&&businessclient.name?businessclient.name:'na',

      Items:
        items.length &&
        items.map((i) => `${i.name} - ${i.sku} - ${i.quantity}`).join("; \n"),

      Client:
        (shipping.shipTo.client.firstName || "") +
        " " +
        (shipping.shipTo.client.lastName || ""),
      "Client is company": shipping.shipTo.client.isCompany || "",
      Country: shipping.shipTo.country || "",
      City: shipping.shipTo.city || "",
      Province: shipping.shipTo.province || "",
      District: shipping.shipTo.district || "",
      Postcode: shipping.shipTo.postcode || "",
      Street: shipping.shipTo.street || "",
      "Street Nr": shipping.shipTo.streetNr || "",
      Phone: shipping.shipTo.phone || "",

      "Payment status": payment.status || "",
      "Payment currency": payment.currency || "",
      "Cod apply": payment.COD.apply || "",
      "Cod card": payment.COD.card || "",
      "Cod amount": payment.COD.amount || "",

      "Shipping status": shipping.status || "",
      Courier: shipping.courier ? shipping.courier.name : "",
      "Tracking ID": shipping.trackingId || "",
      "Shipping price": shipping.shippingPrice || "",
      "Delivery date": new Date(shipping.deliveryDate).toLocaleString(),

      "Packed user": processing.packedUser || "",
      "Last processed": processing.lastProcessed || "",

      "Error Message": errMsgs.join("; \n"),
    })
  );
};

export const useExcelOrderData = (orders, checkedItems, fileName) => {
  const [dataSet, setData] = useState([]);

  useEffect(() => {
    setData(mapData(orders, checkedItems));
  }, [orders, checkedItems]);

  function createExcelFile() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dataSet);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return { createExcelFile };
};
