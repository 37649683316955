import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
  getInitialPickingList,
  getOrdersForPicking,
  updateOrdersStatusPicking,
  changeCourier,
  addNewPickingList,
  getAllPickingList,
} from "../../../../actions/picking";
// import { getCouriersByWarehouse } from "../../../../actions/couriers";

import CanViewPage from "../../../../role-based-access/CanViewPage";
import TopBar from "./TopBar";
import PickerListByCourier from "./PickerListByCourier";
import PickerListByProduct from "./PickerListByProduct";
import ModalSelection from "./ModalSelection";
import { Alert } from "rsuite";
import "../../picking.scss";
import "../picker.scss";

const initState = {
  groupBy: "courier", //  "product" | "courier"
  searchedProducts: [],
  modalIsOpen: false,
  selectedFromThisCourierId: null,
  isPicking: false,
  dateTo: new Date(),
  selection: {},
  businessclient:'undefined',
  //  {
  //  product-id:  {
  //      courier-id: pick-orders
  //    }
  //  }
};

class PickerOrders extends Component {
  constructor(props) {
    super(props);

    this.state = initState;

    this.chooseGroupBy = this.chooseGroupBy.bind(this);
    this.groupListByProdut = this.groupListByProdut.bind(this);
    this.addSearchProduct = this.addSearchProduct.bind(this);
    this.filterByProduct = this.filterByProduct.bind(this);
    this.handleInputOrders = this.handleInputOrders.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleViewList = this.handleViewList.bind(this);
    this.handleStartPicking = this.handleStartPicking.bind(this);
    // this.handlePrint = this.handlePrint.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.hanldeInputOrdersClear = this.hanldeInputOrdersClear.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleDateTo = this.handleDateTo.bind(this);
    this.handleBusinessclient = this.handleBusinessclient.bind(this);
  }

  
  handleBusinessclient(businessclient){
    this.setState({...this.state,businessclient})
    this.props.getInitialPickingList({ dateTo_unix: new Date() / 1000,businessclient });

  }
  handleDateTo(dateTo) {
    console.log(dateTo)
    this.setState({ ...this.state, dateTo });
  }

  chooseGroupBy(category) {
    this.setState({ groupBy: category });
  }

  groupListByProdut(list) {
    let products = [];

    for (const c of list) {
      for (const p of c.products) {
        // if the product is already in the new list
        if (products.map((each) => each.product._id).includes(p._id)) {
          // find the already added product in the new list and
          // push a new courier in that product's couriers list
          let product = products.find((prod) => prod.product._id === p._id);
          const index = products.indexOf(product);
          product.couriers.push({
            courier_name: c.courier.name,
            courier_country: c.courier.country,
            courier_id: c.courier._id,
            prod_orders: p.orders,
            prod_color: p.color,
            prod_size: p.size,
            prod_quantity: p.quantity,
          });
          products[index] = product;
          // if product is not yet in new list
        } else {
          products.push({
            product: {
              name: p.name,
              _id: p._id,
            },
            couriers: [
              {
                courier_name: c.courier.name,
                courier_country: c.courier.country,
                courier_id: c.courier._id,
                prod_orders: p.orders,
                prod_color: p.color,
                prod_size: p.size,
                prod_quantity: p.quantity,
              },
            ],
          });
        }
      }
    }
    return products;
  }

  addSearchProduct(e, { name, value }) {
    this.setState((prevState) => ({
      searchedProducts: [...value],
    }));
  }

  filterByProduct() {
    const { searchedProducts, groupBy } = this.state;
    const { list } = this.props;
    let filteredList = [];
    if (groupBy === "courier") {
      filteredList = this.props.list
        .map((courier) => {
          const products = courier.products.filter((prod) =>
            searchedProducts.includes(prod._id)
          );
          return {
            courier: courier.courier,
            products,
          };
        })
        .filter((courier) => courier.products.length);
    } else {
      filteredList = this.groupListByProdut(this.props.list).filter((prod) =>
        searchedProducts.includes(prod.product._id)
      );
    }

    return filteredList;
  }

  handleInputOrders(e, { id, name, value }) {
    this.setState((prevState) => {
      return {
        selection: {
          ...prevState.selection,
          [id]: {
            ...prevState.selection[id],
            [name]: Number(value),
          },
        },
      };
    });
  }
  hanldeInputOrdersClear(courierId) {
    console.log("clearing...");
    const { selection } = this.state;
    let zeroValue = {};
    // const keys=Object.keys(selection)
    for (const item in selection) {
      //item = productId
      console.log(`checking item:`, item);
      for (const child in selection[item]) {
        //child = courierId
        console.log(`child:${child}`);
        console.log("value", selection[item][child]);
        if (child === courierId)
          zeroValue[item] = { ...zeroValue[item], [child]: 0 };
        else
          zeroValue[item] = {
            ...zeroValue[item],
            [child]: selection[item][child],
          };
      }
    }
    this.setState({ selection: zeroValue });
  }

  handleClearAll() {
    const { selection } = this.state;
    let zeroValue = {};
    for (const productId in selection) {
      for (const courierId in selection[productId]) {
        zeroValue[productId] = { ...zeroValue[productId], [courierId]: 0 };
      }
    }
    this.setState({ selection: zeroValue });
  }

  handleOpenModal() {
    this.setState({ modalIsOpen: true });
  }

  handleCloseModal() {
    this.setState({ modalIsOpen: false });
  }

  handleViewList(courier) {
    this.setState({
      isPicking: false,
      modalIsOpen: false,
      selectedFromThisCourierName: courier.name,
    });

    Alert.info("Moving orders for picking", 5000);

    const goods = {};
    for (const [productId, couriers] of Object.entries(this.state.selection)) {
      // let ordersNr = 0;
      // for (const orders of Object.values(couriers)) {
      //   ordersNr += orders;
      // }
      goods[productId] = couriers[courier._id]; // number of orders for that product and courier
    }
    // goods =  { productId1: pickedOrders, productId2: pickedOrders, }
    this.props.getOrdersForPicking({ courier: courier._id, goods });
  }

  handleStartPicking() {
    let orders = [];
    if (this.props.selection.length > 0) {
      for (const selection of this.props.selection) {
        orders = [...orders, ...selection.orders];
      }
    }
    this.props.updateOrdersStatusPicking(orders);
    // tick each selection if server stat ok
    this.props.addNewPickingList(this.props.selection);
  }

  // handlePrint() {
  //   alert("printing");
  //   this.setState({ isPrinted: true });
  // }

  handleBackClick() {
    const {businessclient} = this.state
    this.props.getInitialPickingList({ dateTo_unix: new Date() / 1000,businessclient });
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const {businessclient} = this.state
    this.props.getInitialPickingList({ dateTo_unix: new Date() / 1000,businessclient });
    const defaultSelection = {};
    for (const { courier, products } of this.props.list) {
      for (const product of products) {
        if (defaultSelection[product._id]) {
          defaultSelection[product._id][courier._id] = product.orders;
        } else {
          defaultSelection[product._id] = { [courier._id]: product.orders };
        }
      }
    }
    this.setState({ selection: defaultSelection });
    // this.props.getCouriersByWarehouse();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.list) !== JSON.stringify(this.props.list)) {
      const defaultSelection = {};
      //  {
      //  product-id:  {
      //      courier-id: pick-orders
      //    }
      //  }
      for (const { courier, products } of this.props.list) {
        for (const product of products) {
          if (defaultSelection[product._id]) {
            defaultSelection[product._id][courier._id] = product.orders;
          } else {
            defaultSelection[product._id] = { [courier._id]: product.orders };
          }
        }
      }
      this.setState({ selection: defaultSelection });
    }

    if (this.props.successUpdateStatus && !prevProps.successUpdateStatus) {
      this.setState({ isPicking: true });
    }

        // this.props.getInitialPickingList({ dateTo_unix: new Date() / 1000 });

  }

  render() {
    const { groupBy, searchedProducts, selectedFromThisCourierName } =
      this.state;
    const { list, isLoading } = this.props;

    const courierOptions = this.props.couriers.map((c) => ({
      key: c._id,
      value: c._id,
      text: c.name,
    }));

    if (isLoading) return <div>Loading...</div>;
    return (
      <CanViewPage path="/picker/orders">
        <>
          <div
            className="a-wrapper"
            style={{ marginTop: "1.3em", marginBottom: "0em" }}
          >
            <div className="d-flex--jc-between">
              <Header as="h3">Product Picking</Header>

              <Link to="/picker/pickedlist">List of Picked</Link>
            </div>
          </div>
          {/* <Divider></Divider> */}
          <TopBar
            chooseGroupBy={this.chooseGroupBy}
            groupBy={groupBy}
            searchedProducts={searchedProducts}
            list={list}
            addSearchProduct={this.addSearchProduct}
            handleClearAll={this.handleClearAll}
            dateTo={this.state.dateTo}
            setDateTo={this.handleDateTo}
            handleBusinessclient={this.handleBusinessclient}
            businessclient={this.state.businessclient}
          />
          <div
            className="a-wrapper"
            style={{ marginTop: "4em", marginBottom: "7em" }}
          >
            {this.props.list.length > 0 && (
              <Form loading={this.props.isLoading}>
                <div>
                  {groupBy === "courier" && (
                    <PickerListByCourier
                      list={
                        //filter list based on seached products, display by courier
                        searchedProducts.length
                          ? this.filterByProduct(list)
                          : list
                      }
                      handleInputOrders={this.handleInputOrders}
                      hanldeInputOrdersClear={this.hanldeInputOrdersClear}
                      handleViewList={this.handleViewList}
                      selection={this.state.selection}
                    />
                  )}
                  {groupBy === "product" && (
                    <PickerListByProduct
                      courierOptions={courierOptions}
                      list={
                        //filter list based on search products, display by product
                        searchedProducts.length
                          ? this.filterByProduct(this.groupListByProdut(list))
                          : this.groupListByProdut(list)
                      }
                      handleInputOrders={this.handleInputOrders}
                      handleViewList={this.handleViewList}
                      selection={this.state.selection}
                      changeCourier={this.props.changeCourier}
                    />
                  )}

                  <ModalSelection
                    isLoading={this.props.isLoading}
                    selection={this.props.selection}
                    isPicking={this.state.isPicking}
                    modalIsOpen={this.state.modalIsOpen}
                    selectedFromThisCourierName={selectedFromThisCourierName}
                    handleBackClick={this.handleBackClick}
                    handleCloseModal={this.handleCloseModal}
                    handleStartPicking={this.handleStartPicking}
                  />
                </div>
              </Form>
            )}
          </div>
        </>
      </CanViewPage>
    );
  }
}

function mapSateToProps(reduxState) {
  return {
    list: reduxState.picking.list,
    couriers: reduxState.picking.couriers,
    selection: reduxState.picking.selection,
    listOfPicked: reduxState.picking.listOfPicked,
    isLoading: reduxState.picking.isLoading,
    successMsg: reduxState.picking.successMsg,
    successUpdateStatus: reduxState.picking.successUpdateStatus,
  };
}
export default connect(mapSateToProps, {
  getInitialPickingList,
  // getCouriersByWarehouse,
  changeCourier,
  getOrdersForPicking,
  updateOrdersStatusPicking,
  addNewPickingList,
  getAllPickingList,
})(PickerOrders);
