import React , {useEffect,useState} from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {getPromos} from '../../../actions/promo'
import Promolist from './promolist'
import {Button,Input} from 'semantic-ui-react'
import CountryFilter from '../../Filters/CountryFilter'
import ProductFilter from '../../Filters/ProductFilter'
import BusinessclientFilter from '../../Filters/BusinessclientFilter'

 function Promos(props){

    const { promos, getPromos } = props;
    const [country,setCountry] = useState()
    const [product,setProduct] = useState()
    const [businessclient,setBusinessclient] = useState()

    useEffect(() => getPromos({country,product}),[country,product]);


    return (
        <div>

            <div className='flex--between'>
              <div className='flex--left'>
              <CountryFilter
                  value = {country}
                  handleChange ={(e,{value})=>setCountry(value)}
                />

              <BusinessclientFilter
                value={businessclient}
                handleChange={(e,{value})=>setBusinessclient(value)}
              />
            
              {!businessclient&&<Input disabled type='text' placeholder='product: select client'></Input>}
               {businessclient && <ProductFilter 

                  businessclient={businessclient}
                  value={product}
                  handleChange={(e,{value})=>setProduct(value)}
                  disabled={!businessclient}
                />}

              
              </div>
              <Link to="/promos/create/new">
                <Button color="blue" basic>Add NEW</Button>
              </Link>
              
            </div>

            <Promolist
                promos={promos}
            ></Promolist>
        </div>
    )
}

const mapStateToProps = (reduxState) => ({
  promos: reduxState.promos.list,
  isLoading: reduxState.loading.loading,
});

export default connect(mapStateToProps, {
  getPromos,
})(Promos);
