import React,{useState,useEffect} from 'react'
import Refundstable from './table'
import {getApprovedRefund,sendToBankFio} from '../../actions/refunds'
import {Alert,Panel,Divider} from 'rsuite'
import CanViewPage  from "../../role-based-access/CanViewPage";

import Approvedcard from './approvedcard'

const Refunds = function(props){

    const [approvedRefunds,setApprovedRefunds] = useState([])
    const [loading,setLoading] = useState(false)
    const [updateList,setUpdatelist]=useState(false)
    const [updateListFromChild,setUpdatelistChild]=useState(false)

    const sendPayment =(refunds,total)=>{
        setLoading(true)
        sendToBankFio(refunds,total)
        .then(resp=>
            {
                Alert.success('Refunds sent to bank for confirmation',5000)
                setUpdatelist(true)
                
                setLoading(false)

            })
        .catch(error=>{
            setLoading(false)
            Alert.error(error.message,5000)})
      }
    

    useEffect(()=>{
        // if(!updateList) return 
        setLoading(true)
  
          getApprovedRefund()
          .then(resp=>{
                      setApprovedRefunds(resp)
                      setLoading(false)

              })
          .catch(error=>{
              setLoading(false)
              Alert.error(error.message)})
      
      },[updateList,updateListFromChild])
    
    return (
        <CanViewPage path="/refunds" >

        <div className='flex--column' style={{marginTop:"30px",marginLeft:"20px"}}>
            <h3>Refunds</h3>

            <div id='left_view' style={{marginRight:"15px"}}>

                <div className='flex--column'>
                    {approvedRefunds.length>0&&<h5>Approved refunds</h5>
}
                    <div className='flex--left' >
                        {loading&&    <Panel loading={true} 
                                              shaded  
                                              style={{height:'150px',margin:'10px'}} 
                                              bordered 
                                              header='Loading...'/>}

                        {!loading&&approvedRefunds&&approvedRefunds.map(item=>
                           <Approvedcard item={item}
                                         sendPayment={sendPayment}  

                            ></Approvedcard>)
                            }
                           
                            </div>
                        
                </div>
                <Divider></Divider>
                <Refundstable
                 updateListParent={updateList}
                 updateListChild ={()=>{
                    // alert('update approved this list') 
                    setUpdatelistChild(!updateListFromChild)}}
                sendPayment={sendPayment}  
                 />
            </div>
            <div id='right_view'>
            
            </div>
           
         
        </div>
        </CanViewPage>
    )
}


export default Refunds
