import React from "react";
import { Header } from "semantic-ui-react";

function OrdersSummary({ totals, businessclient, dateTo }) {
  if (!businessclient)
    return (
      <>
        <Header as="h3">Orders Summary</Header>
        <p>Select businessclient</p>
      </>
    );
  if (!dateTo)
    return (
      <>
        <Header as="h3">Orders Summary</Header>
        <p>Select date</p>
      </>
    );
  return (
    <>
      <Header as="h3">Orders Summary</Header>

      {totals.length > 0 ? (
        <div className="create-transfer__summary-grid">
          <div>
          {totals.map(({ _id,total,cods_received }) => (
            <>
              <h3 style={{textDecoration:'underline'}} className="bold--text"> {_id}- {total}</h3>
              {cods_received.map(({courier,total,count})=>(
                      <div>
                        <h5>{courier.name}</h5>
                        <p>Nr. of orders: {count}</p>
                        <p>COD: {total}</p>
                        <div>----------------</div>
                      </div>))}
              
            </>
          ))}
          </div>
        </div>
      ) : (
        <p>No orders found</p>
      )}
    </>
  );
}

export default OrdersSummary;
