import React, { useEffect, useState } from "react";
import { Header, Button, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import BillsTable from "./BillsTable";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import { updateBill, getBills } from "../../../actions/bills";
import { Alert } from "rsuite";

import CanViewPage from "../../../role-based-access/CanViewPage";
import CanView from "../../../role-based-access/CanView";

import Filters from "./Filters";
import { useUpdateContext } from "../../../context/UpdateContext";

function Billing() {
  const { businessclient, supplier } = useUpdateContext();

  const [filters, setFilters] = useState({
    status: "Not paid - In due",
    total: 0,
    itemsPerPage: 10,
    currentPage: 1,
  });
  const [bills, setBills] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [ticked, setTicked] = useState(false);
  const [total, setTotal] = useState(0);

  const handleFilterChange = ({ name, value }) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleCheck = ({ checked, _id }) => {
    setLoading(true);
    updateBill(_id, { isPaid: checked }).then((resp) => {
      setTicked(!ticked);
      Alert.info("Updated");
    });
  };

  const handleDateUpdate = ({ dateFrom, dateTo }) => {
    setFilters({ ...filters, dateFrom, dateTo });
  };
  const { itemsPerPage, currentPage } = filters;

  useEffect(() => {
    setLoading(true);
    getBills({ businessclient, issuer: supplier, ...filters }).then((resp) => {
      setLoading(false);
      setBills(resp.list);
      setTotal(resp.total);
    });
  }, [businessclient, supplier, ticked, filters]);

  return (
    <CanViewPage path="/billing">
      <main className="page-wrapper">
        <div className="flex--between">
          <Header as="h3" style={{ marginBottom: 0 }}>
            Client Billing
          </Header>
          <CanView path="/billing" action="create">
            <div>
              {/* <Link className="mr--1" to="/creditnote/create">
              <Button>Create credit note</Button>
            </Link> */}
              <Link className="mr--1" to="/billing/manually">
                <Button>Create Bill</Button>
              </Link>
              <Link className="mr--1" to="/billing/billrunservices">
                <Button color="blue">Bill Run Services</Button>
              </Link>
            </div>
          </CanView>
        </div>
        <Divider></Divider>
        <Filters
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleDateUpdate={handleDateUpdate}
        />
        <>
          <div className="flex--right">
            <ItemsPerPage
              total={total}
              value={itemsPerPage}
              setItemsPerPage={(value) =>
                handleFilterChange({ name: "itemsPerPage", value })
              }
              options={[10, 20, 30, "All"]}
            />
          </div>

          <BillsTable
            bills={bills}
            isLoading={isLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            // handleTicked={handleTicked}
            handleCheck={handleCheck}
          />

          {!isLoading && total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={(currentPage) =>
                handleFilterChange({
                  value: currentPage,
                  name: "currentPage",
                })
              }
            />
          )}
        </>
      </main>
    </CanViewPage>
  );
}

export default Billing;
