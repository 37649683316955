import React from "react";
import { Table, Loader, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import EditableText from "../../EditableText";

// import { useUpdateContext } from "../../../context/UpdateContext";

function Turnovertable({ stocks, isLoading, currentPage, itemsPerPage }) {
  // const { handleAddToPO } = useUpdateContext();

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  // if (!isLoading && !stocks.length)
  //   return (
  //     <div style={{ marginTop: "2em", marginBottom: "2em" }}>
  //       <Header as="h4" textAlign="center">
  //       No product found
  //       </Header>
  //     </div>
  //   );

  const tableHeader = (
    <Table.Row>
      {/* <Table.HeaderCell>
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </Table.HeaderCell> */}
      <Table.HeaderCell width={1}>#</Table.HeaderCell>
      <Table.HeaderCell width={2}>SKU</Table.HeaderCell>
      <Table.HeaderCell width={4}>NAME</Table.HeaderCell>
      <Table.HeaderCell width={2}>Warehouse</Table.HeaderCell>
      <Table.HeaderCell width={1}>Balance</Table.HeaderCell>

      <Table.HeaderCell width={1}>TurnOver</Table.HeaderCell>
      <Table.HeaderCell width={1}>Days of Inventory</Table.HeaderCell>
      {/* <Table.HeaderCell>Add To PO Card</Table.HeaderCell> */}
    </Table.Row>
  );

  let tableBody = [];
  let i = 0;

  for (const key in stocks) {
    let item = stocks[key];
    
    tableBody.push(
      <Table.Row key={i}>
        {/* <Table.Cell>
      <Checkbox
        checked={checkedItems.includes(_id)}
        onChange={() => handleSingleCheck(_id)}
      />
    </Table.Cell> */}
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>
          <Link to={`/products/${item.product}`}>{item.product_sku} </Link>{" "}
        </Table.Cell>
        <Table.Cell>{item.product_name}</Table.Cell>
        <Table.Cell>{item.warehouse_name}</Table.Cell>
        <Table.Cell>{item.stock}</Table.Cell>
        <Table.Cell>{item.turnover}</Table.Cell>
        <Table.Cell>{item.days_of_inventory}</Table.Cell>

        {/* <Table.Cell textAlign="left">
        <div>
          <EditableText
            maxWidth={"80px"}
            fluid={false}
            name="quantity_shipped"
            value={product.quantity}
            handleChange={(e, { value, name }) => {}}
            showButton={true}
            handleAdd={(value) =>
              handleAddToPO({
                quantity: value,
                product: {
                  name: product.name[0],
                  sku: product.sku[0],
                  _id: product.id,
                },
                warehouse: {
                  _id: data.warehouse[0]._id,
                  name: data.warehouse[0].name,
                },
                businessclient: {
                  _id: data.client[0]._id,
                  name: data.client[0].name,
                },
              })
            }
          />
        </div>
      </Table.Cell>{" "} */}
      </Table.Row>
    );
    i++;
  }

  return (
    <Table unstackable fixed basic="very">
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default Turnovertable;
