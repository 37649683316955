import React from "react";
import { Table } from "semantic-ui-react";

// import { ReactComponent as NoImg } from "../../../no-img.svg";

function ProductsList({ items }) {
  // const renderItems =
  //   items &&
  //   items.map(({ sku, quantity, name }, index) => (
  //     <ul className="products-list__item" key={sku}>
  //       <div className="products-list__row">
  //         <span className="product-list__label">Product:</span>
  //         <span className="product-list__text">{name}</span>
  //       </div>
  //       <div className="products-list__row">
  //         <span className="product-list__label">SKU:</span>
  //         <span>{sku}</span>
  //       </div>
  //       <div className="products-list__row">
  //         <span className="product-list__label">Quantity:</span>
  //         <span className="product-list__text">{quantity}</span>
  //       </div>
  //     </ul>
  //   ));
  const renderItems2 =
    items &&
    items.map(({ sku, woo_sku,quantity, name, photos = [] }, index) => (
      <Table.Row key={index}>
        <Table.Cell
          className="product-table__cell"
          style={{ paddingRight: "1em" }}
          collapsing
        >
          <div className="product-table__image">
            {photos.lenght ? <img alt={name} src={photos[0]} /> : <div />}
          </div>
        </Table.Cell>
        <Table.Cell className="product-table__cell">
          <div>{name} - {sku}</div>
          {woo_sku&&<div>woo_sku:{woo_sku}</div>}
        </Table.Cell>
        <Table.Cell textAlign="center" className="product-table__cell">
          {quantity}
        </Table.Cell>
      </Table.Row>
    ));

  return (
    <>
      <Table unstackable basic="very" className="product-table">
        {/* <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="products-table__header">
              Product Name
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="products-table__header"
            >
              SKU
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="products-table__header"
            >
              Quantity
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header> */}
        <Table.Body>{renderItems2}</Table.Body>
      </Table>
      {/* <ul className="products-list">{renderItems}</ul> */}
    </>
  );
}
export default ProductsList;
