import React,{useEffect,useState} from 'react'
import {Panel,Button,Divider} from 'rsuite'
import {getBalances} from '../../actions/accountbalance'
import {Label} from 'semantic-ui-react'
import CanView from "../../role-based-access/CanView";

const Approvedcard =({item,sendPayment})=>{

    const [balance,setBalance]=useState({total:''})

    useEffect(()=>{
        
        getBalances({businessclient:item._id.businessclient,
                    currency:item._id.currency}).then(data=>{if(data.length>0)setBalance(data[0])})
                            
                            },[])
    return ( <Panel  shaded bordered  
        //  bodyFill 
         style={{ margin: 10 }}
            >   
            
        <h3>{item._id.currency} {item.total} </h3>
        <p>{item.businessclient[0].name}</p>

        {/* <CanView action='send' path='/refunds'> */}
            <p>Balance:   <Label color={balance.total>0?'green':'red'} horizontal>{balance.currency} {balance.total>0?balance.total.toFixed(2):0} </Label></p>
        {/* </CanView> */}
        
        <Divider></Divider>
        <div>Refunds - {item.refunds.length} </div>
        <div>avg - {item._id.currency} {(item.total/item.refunds.length).toFixed(2)} </div>
        <Divider></Divider>
      
        <CanView action='send' path='/refunds'>
                    <Button
                            appearance="ghost"
                            onClick={()=>sendPayment(item.refunds,item.total)}
                                >Send Payments</Button>                           
        </CanView>
                 </Panel>)
}

export default Approvedcard