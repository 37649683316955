import React, { useState, useEffect } from "react";
import { Header, Input, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getRolesSettings, addRoleSettings } from "../../../actions/user";
import CanViewPage from "../../../role-based-access/CanViewPage";
import RoleSettings from "./RoleSetting";

const defaultSettings = {
  filters: { views: {} },
  routes: {},
};

function CreateRole({ getRolesSettings, settings, addRoleSettings, history }) {
  useEffect(() => {
    getRolesSettings();
  }, []);

  const [role, setRole] = useState("");
  const handleRoleChange = (e, { value }) => setRole(value);

  return (
    <CanViewPage path="/roles/create/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/roles">
          <Icon name="arrow left" />
          Roles settings
        </Link>
        <Header as="h1" style={{ marginTop: "0.5em" }}>
          Add New Role
        </Header>

        <section className="rules__section">
          <div className="mb--2">
            <Header as="h4">Role:</Header>
            <Input
              type="text"
              name="role"
              value={role}
              placeholder="role name..."
              onChange={handleRoleChange}
            />
          </div>

          <Header as="h4">Role Settings:</Header>
          {settings.super_admin && (
            <RoleSettings
              role={role}
              settings={defaultSettings}
              adminSettings={settings.super_admin}
              action={(query) => addRoleSettings(history, query)}
            />
          )}
        </section>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  settings: reduxState.users.settings,
});
export default connect(mapStateToProps, { getRolesSettings, addRoleSettings })(
  CreateRole
);
