import React, { useState, useEffect } from "react";
import {
  Segment,
  Dimmer,
  Loader,
  Icon,
  Header,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {Alert} from 'rsuite'
import {
  updateBusinessClient,
  getBusinessClient,
  updateBusinessClientContact,
  updateBusinessClientBankAccount,
  createSequence,
  deleteSequence,
  addClientIds,
  updateClientIds,
  deleteClientIds,
} from "../../../actions/businessclient";
import CanViewPage from "../../../role-based-access/CanViewPage";
import CanView from "../../../role-based-access/CanView";
import EditAddress from "./EditAddress";
import EditContact from "./EditContact";
import EditBankAccount from "./EditBankAccount";
import EditClientIds from "./EditClientIds";
import AddBankAccount from "../CreateBC/AddBankAccount";
import AddContact from "../CreateBC/AddContact";
import AddClientIds from "../CreateBC/AddClientIds";
import FormField from "./FormField";
import ProfileSettings from "./ProfileSettings/ProfileSettings";
import Documents from "../../../components/Documents";

import "../businessclient.scss";

// const initFormValues = {
//   name: "",
//   address: {
//     country: "",
//     region: "",
//     province: "",
//     city: "",
//     street: "",
//     streetNr: "",
//     postcode: "",
//   },
//   contacts: [],
//   bankaccounts: [],
// };

function EditBC({

  match,
}) {
  const [businessclient,setBC] = useState({name:'',address:{},contact:''})
  const [isLoading,setLoading] = useState(false)
  const [reload,setReload] = useState(false)

  useEffect(() => {
    setLoading(true)
    getBusinessClient(match.params.id).then(resp=>{
      setLoading(false)
      setBC(resp)}).catch(err=>{
        setLoading(false)
        Alert.error(err)})}, [match.params.id,reload])
  // const [formValues, setFormValues] = useState(initFormValues);
  const {
    name,
    address,
    contacts = [],
    bankaccounts = [],
    // sequences = [],
    companyids = [],
  } = businessclient;

  const handleUpdate = (name, value) =>
    updateBusinessClient({ id: match.params.id, query: { [name]: value } }).then(data=>setBC(data)).catch(err=>Alert.error(err))


  const [addContactIsOpen, setAddContactOpen] = useState(false);
  const [addBankIsOpen, setAddBankOpen] = useState(false);
  // const [addSequenceIsOpen, setAddSequenceOpen] = useState(false);
  const [addClientIdsIsOpen, setAddClientIdsOpen] = useState(false);

  const handleDeleteId=({ clientId: bclientId, id })=>{
    setLoading(true)

    deleteClientIds({ clientId: bclientId, id }).then(dat=>setReload(!reload))
  }
  const handleUpdateWithType = ({ query, type }) =>
    updateBusinessClient({
      id: match.params.id,
      query,
      type,
    });


  if (isLoading || !businessclient._id)
    return (
      <Dimmer active inverted>
        <Loader inverted size="medium">
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <CanViewPage path="/businessclients/create/new">
      <main className="page-wrapper a-wrapper">
        <Link to="/businessclients">
          <Icon name="arrow left" />
          Business clients list
        </Link>

        <Header as="h1" style={{ marginTop: "1em" }}>
          Edit business client
        </Header>
        <Segment padded>
          <Header as="h2">Business client information</Header>

          <div className="businessclient">
            <div className="businessclient__sidebar">
              <Header as="h4">Name</Header>
            </div>
            {/* <div className="businessclient__form"> */}
              {/* <div className="view-grid_no-bg view-grid "> */}
                <FormField
                  label="Business client"
                  name="name"
                  value={name}
                  placeholder="name..."
                  handleChange={handleUpdate}
                />
              {/* </div> */}
            {/* </div> */}
            {/* Address  */}
            <div className="businessclient__sidebar">
              <Header as="h4">Address</Header>
            </div>
            <EditAddress handleUpdate={handleUpdate} {...address} />
            {/* Contacts */}
            <div className="businessclient__sidebar">
              <Header as="h4">Contacts</Header>
            </div>
            <div className="businessclient__form">
              {contacts.map((contact, i) => (
                <EditContact
                  {...contact}
                  key={contact._id}
                  i={i}
                  contactId={contact._id}
                  bclientId={match.params.id}
                  // contacts={contacts}
                  updateBusinessClientContact={updateBusinessClientContact}
                  // removeContact={removeContact}
                  // updateContacts={updateContacts}
                  handleUpdateWithType={handleUpdateWithType}
                />
              ))}
              {addContactIsOpen ? (
                <AddContact
                  addContact={(query) =>
                    handleUpdateWithType({ query, type: "new contact" })
                  }
                />
              ) : (
                <CanView path="/businessclients/:id" action="edit">
                  <Button
                    circular
                    compact
                    basic
                    // floated="right"
                    style={{ marginLeft: "4em" }}
                    size="mini"
                    icon="plus"
                    content="Add"
                    onClick={() => setAddContactOpen(true)}
                  />
                </CanView>
              )}
            </div>
            {/* Bank Account */}
            <div className="businessclient__sidebar">
              <Header as="h4">Bank accounts</Header>
            </div>
            <div className="businessclient__form">
              {bankaccounts.map((account, i) => (
                <EditBankAccount
                  {...account}
                  bankAccountId={account._id}
                  bclientId={match.params.id}
                  key={i}
                  i={i}
                  handleUpdateWithType={handleUpdateWithType}
                  updateBusinessClientBankAccount={
                    updateBusinessClientBankAccount
                  }
                  // updateBankAccount={updateBankAccount}
                  // removeBankAccount={removeBankAccount}
                />
              ))}
              {addBankIsOpen ? (
                <AddBankAccount
                  addBankAccount={(query) =>
                    handleUpdateWithType({ query, type: "new bank account" })
                  }
                />
              ) : (
                <CanView path="/businessclients/:id" action="edit">
                  <Button
                    circular
                    compact
                    basic
                    // floated="right"
                    style={{ marginLeft: "4em" }}
                    size="mini"
                    icon="plus"
                    content="Add"
                    onClick={() => setAddBankOpen(true)}
                  />
                </CanView>
              )}
            </div>

            {/* Client Ids */}
            <div className="businessclient__sidebar">
              <Header as="h4">Client Ids</Header>
              <p>Company reg.Ids, Vat</p>
            </div>
            <div className="businessclient__form">
              {companyids.map((coIds, i) => (
                <EditClientIds
                  {...coIds}
                  id={coIds._id}
                  bclientId={match.params.id}
                  key={i}
                  i={i}
                  updateBusinessClientBankAccount={
                    updateBusinessClientBankAccount
                  }
                  updateClientIds={updateClientIds}
                  handleDeleteId={handleDeleteId}
                />
              ))}


              {addClientIdsIsOpen ? (
                <AddClientIds
                  addClientIds={(query) =>{
                    setLoading(true)
                    addClientIds({ ...query, businessclient: match.params.id }).then(resp=>
                      setReload(!reload))
                  }
                    
                  }
                />
              ) : (
                <CanView path="/businessclients/:id" action="edit">
                  <Button
                    circular
                    compact
                    basic
                    // floated="right"
                    style={{ marginLeft: "4em" }}
                    size="mini"
                    icon="plus"
                    content="Add"
                    onClick={() => setAddClientIdsOpen(true)}
                  />
                </CanView>
              )}
            </div>
            <div className="businessclient__sidebar">
              <Header as="h4">Documents</Header>
            </div>
            <div className="businessclient__form">
              
              <Documents
                businessclient={match.params.id}
                trigger="Businessclient"
                triggerId={match.params.id}
              ></Documents>
              
            </div>
          </div>
        </Segment>

        <ProfileSettings
          bclientId={match.params.id}
          businessclient={businessclient}
          billing_clients={businessclient.billing_clients}
        />
      </main>
    </CanViewPage>
  );
}



export default connect(null, {
  createSequence,
  deleteSequence,
})(EditBC);
