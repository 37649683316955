import {  CREATE_CO } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

export const getCompanies = ({client}) =>  {
  
  const url = `${process.env.REACT_APP_API}/company/businessclient/${client}`;

  return apiCall("get", url)
    .then(resp => resp)
    .catch(err => err);
};

export const createCo = data => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/company`;
  apiCall("post", url, data)
    .then(resp => dispatch(createCoAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

const createCoAction = company => ({
  type: CREATE_CO,
  company
});
