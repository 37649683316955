import React from "react";
import { DateRangePicker } from "rsuite";

import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import CountryFilter from "../../Filters/CountryFilter";
import ServiceTypes from "../../Filters/ServiceTypes";

const itemStyle = {
  margin: "2px",
  minWidth: "360px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
function Filters({  setServiceType,setDateTo,setDateFrom,country,setCountry }) {
  return (
    
    <div className="flex--left">
      <BusinessclientFilter
      
        
      />
     
        <DateRangePicker
          placeholder="Select Date Range"
          block
          style={itemStyle}
          
          onChange={([dateFrom, dateTo]) =>
            {setDateFrom(dateFrom)
            setDateTo(dateTo)}
          }
        />
     
      <CountryFilter handleChange={(value)=>setCountry(value)} value={country}/>
      <ServiceTypes handleChange={(value) =>setServiceType(value)}></ServiceTypes>
     
    </div>
  );
}
export default Filters;
