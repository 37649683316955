import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

// import { roles } from "./data";
import { getDepartments } from "../../actions/ticket";

function RolesFilter({
  value,
  name = "departments",
  handleChange,
  multiple = false,
  fluid = false,
  error = false,
  selection = true,
  departments,
  getDepartments,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => getDepartments(), []);
  useEffect(
    () => setOptions(departments.map((d) => ({ key: d, value: d, text: d }))),
    [departments]
  );

  return (
    <Dropdown
      placeholder="department ..."
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection={selection}
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
    />
  );
}

const mapStateToProps = (reduxState) => ({
  departments: reduxState.tickets.departments,
});

export default connect(mapStateToProps, { getDepartments })(RolesFilter);
