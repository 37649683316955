import React, { useState, useEffect } from "react";
import { Button, Checkbox } from "semantic-ui-react";
import {
  deleteProducQuotation,
  getQuotations,
} from "../../../../actions/product";
import ModalOnDelete from "../../../ModalOnDelete";
import { Alert } from "rsuite";

function PricingTable(props) {
  const {
    productId,
    openEditModal,
    isHeaderChecked,
    checkedItems,
    handleSingleCheck,
    handleMultipleCheck,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [qid, setPricingId] = useState(null);

  const [quotations, setQuotations] = useState([]);

  const closeDeleteModal = () => {
    setPricingId(null);
    setModalOpen(false);
  };
  const openDeleteModal = (id) => {
    setPricingId(id);
    setModalOpen(true);
  };

  const handleDeleteQuotation = () => {
    // alert(qid);
    deleteProducQuotation({ qid }).then((resp) => Alert.success("Done."));
  };

  useEffect(
    () => getQuotations(productId).then((resp) => setQuotations(resp)),
    [productId]
  );

  if (!quotations.length) return <p>No pricing saved yet</p>;

  const tableHeader = (
    <tr style={{textAlign:'left'}}>
      {/* <th>
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </th> */}
      <th>#</th>
      <th>Country</th>
      <th>Warehouse</th>
      <th>Currency</th>
      <th>Price</th>
      <th>Active</th>
      <th>Action</th>
    </tr>
  );
  const tableBody = quotations.map(
    ({ _id, country, currency, amount, isActive ,warehouse}, index) => (
      <tr key={_id}>
        {/* <td>
          <Checkbox
            checked={checkedItems.includes(_id)}
            onChange={() => handleSingleCheck(_id)}
          />
        </td> */}
        <td>{index + 1}</td>
        <td>{country}</td>
        <td>{warehouse?warehouse.name:'na'}</td>
        <td>{currency}</td>
        <td>{amount}</td>
        <td>{isActive ? "active" : "not active"}</td>
        <td>
          <Button
            disabled={true}
            basic
            compact
            size="tiny"
            icon="pencil"
            onClick={() =>
              openEditModal(true, {
                _id,
                country,
                currency,
                amount,
              })
            }
          />
          <Button
            basic
            compact
            size="tiny"
            icon="trash"
            onClick={() => openDeleteModal(_id)}
          />
        </td>
      </tr>
    )
  );

  return (
    <>
      <table className="product-pricing__table ">
        <thead>{tableHeader}</thead>
        <tbody>{tableBody}</tbody>
      </table>

      <ModalOnDelete
        isModalOpen={isModalOpen}
        handleCloseModal={closeDeleteModal}
        deleteAction={() => handleDeleteQuotation()}
        modalText="Are you sure you want to delete this pricing?"
      />
    </>
  );
}

export default PricingTable;
