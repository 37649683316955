import React, { useState, useEffect } from "react";
import { Grid, Header, Button, List, Label, Input } from "semantic-ui-react";
import Moment from "react-moment";
import EditableText from "../../EditableText";
import { statusColor } from "../ListOS/ShipmentTable";
import { apiCall } from "../../../services/api";
import { Alert } from "rsuite";

function ShipmentForm(props) {
  const { openShipment, updateShipment, cb, history } = props;

  const [hasChange, setHasChange] = useState(false);
  const [trackingHasChange, setTrackingHasChange] = useState(false);
  const [trackingNr, setTrackingNr] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const { tracking } = openShipment;

    if (tracking) setTrackingNr(tracking.nr);
    // if (status) setStatus(status);
  }, [openShipment]);

  const handleCreateInbound = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API}/shipments/inbound/create/fromoutbound/${openShipment._id}`;

    apiCall("post", url, {})
      .then((resp) => {
        setLoading(false);

        history.push(`/shipments/inbound/${resp._id}`);
      })
      .catch((error) => {
        setLoading(true);
        Alert.error(error.message);
      });
  };

  const handleStatusChange = ({ status }) => {
    // setStatus(status);
    const { _id } = openShipment;
    updateShipment({
      _id,
      // shipFrom,
      // shipTo,
      ["tracking.nr"]: trackingNr,
      status,
    }).then((shipment) => cb(shipment));
  };

  // const handleToChange = ({ value }) => {
  //   setTo(value);
  //   if (!hasChange) setHasChange(true);
  // };
  const handleTrackingChange = ({ value }) => {
    setTrackingNr(value);
    if (!hasChange) setHasChange(true);
  };
  const handleTrackingInputChange = (e, { value }) => {
    setTrackingNr(value);
    if (!trackingHasChange) setTrackingHasChange(true);
  };

  const handleUpdateShipment = () => {
    const { _id } = openShipment;
    setHasChange(false);
    updateShipment({ _id, ["tracking.nr"]: trackingNr }).then((shipment) =>
      cb(shipment)
    );
  };

  return (
    <>
      <div className="flex-row space" style={{ marginBottom: "1.6em" }}>
        <h4 style={{ color: "grey" }}>
          <Label
            color={statusColor[openShipment.status]}
            ribbon
            size="tiny"
            style={{ transform: "translate(-7px, -2px)" }}
          >
            {openShipment.status}
          </Label>
          {`Outbound Shipment ${
            openShipment &&
            openShipment._id &&
            " - " + openShipment._id.substr(-6)
          } `}
        </h4>

        <div>
          {hasChange && (
            <Button
              color="grey"
              disabled={!hasChange}
              onClick={handleUpdateShipment}
            >
              Update Shipment
            </Button>
          )}
        </div>
        {["pending"].includes(openShipment.status) && (
          <div>
            <Button
              color="blue"
              // disabled={!hasChange}
              onClick={() => handleStatusChange({ status: "Shipping" })}
            >
              Set as Shipped
            </Button>
          </div>
        )}
        {["Shipping"].includes(openShipment.status) && (
          <div>
            <Button
              color="green"
              // disabled={!hasChange}
              onClick={() => handleStatusChange({ status: "Delivered" })}
            >
              Set Delivered
            </Button>
          </div>
        )}
        {["Delivered"].includes(openShipment.status) && (
          <Button
            color="red"
            loading={isLoading}
            onClick={() => handleCreateInbound()}
          >
            Create Inbound
          </Button>
        )}
      </div>

      <Grid columns={3} divided stackable style={{ marginBottom: "0.6em" }}>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3">Ship From Warehouse</Header>
            <div className="po-form__grid-cell-content">
              {openShipment.shipFrom && openShipment.shipFrom.address && (
                <div style={{ marginTop: "10px" }}>
                  <p>
                    {openShipment.shipFrom.address.country},{" "}
                    {openShipment.shipFrom.address.city}
                  </p>
                  <p>
                    {openShipment.shipFrom.address.street},
                    {openShipment.shipFrom.address.streetNr}
                  </p>
                  <p>{openShipment.shipFrom.address.post}</p>
                  <p>{openShipment.shipFrom.address.phone}</p>
                </div>
              )}
              {/* <EditableDropdown
                placeholder="Select Pickup adress..."
                value={shipFrom}
                options={createOptions(warehouses)}
                handleChange={handleFromChange}
              /> */}
            </div>
          </Grid.Column>

          <Grid.Column>
            <Header as="h3">Ship To Warehouse</Header>
            <div className="po-form__grid-cell-content ">
              {openShipment.shipTo && openShipment.shipTo.address && (
                <div style={{ marginTop: "10px" }}>
                  <p>
                    {openShipment.shipTo.address.country},{" "}
                    {openShipment.shipTo.address.city}
                  </p>
                  <p>
                    {openShipment.shipTo.address.street},
                    {openShipment.shipTo.address.streetNr}
                  </p>
                  <p>{openShipment.shipTo.address.post}</p>
                  <p>{openShipment.shipTo.address.phone}</p>
                </div>
              )}

              {/* <EditableDropdown
                placeholder="Select Destination..."
                value={shipTo}
                options={createOptions(warehouses)}
                handleChange={handleToChange}
              /> */}
            </div>
          </Grid.Column>

          <Grid.Column>
            <Header as="h4" style={{ marginLeft: "1.2em" }}>
              Details
            </Header>
            <div className="po-form__grid-cell-content po-form__grid-cell-content_parties">
              <List size="small">
                <List.Item>
                  <span className="po-details__label">Tracking Nr:</span>
                  {openShipment.tracking.nr ? (
                    <EditableText
                      placeholder="tracking nr..."
                      value={openShipment.tracking.nr}
                      name="tracking.nr"
                      handleChange={handleTrackingChange}
                      maxWidth="10em"
                    />
                  ) : (
                    <Input
                      action={{
                        disabled: !trackingHasChange,
                        color: "teal",
                        icon: "save",
                        onClick: () => handleUpdateShipment(),
                      }}
                      style={{ marginTop: "0.3em", marginBottom: "0.6" }}
                      name="tracking"
                      value={trackingNr ? trackingNr : openShipment.tracking.nr}
                      onChange={handleTrackingInputChange}
                      placeholder="tacking nr..."
                    />
                  )}
                </List.Item>

                {openShipment.createdAt && (
                  <List.Item>
                    <span className="po-details__label">Created:</span>
                    <Moment format="DD-MM-YY HH:mm">
                      {openShipment.createdAt}
                    </Moment>
                  </List.Item>
                )}
                {openShipment.tracking.shippedDate && (
                  <List.Item>
                    <span className="po-details__label">Shipped:</span>
                    <Moment format="DD-MM-YY HH:mm">
                      {openShipment.tracking.shippedDate}
                    </Moment>
                  </List.Item>
                )}
                {openShipment.tracking.deliveredDate && (
                  <List.Item>
                    <span className="po-details__label">Delivered:</span>
                    <Moment format="DD-MM-YY HH:mm">
                      {openShipment.tracking.deliveredDate}
                    </Moment>
                  </List.Item>
                )}
              </List>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default ShipmentForm;
