import React from "react";
import { Table, Segment, Header, Button, Icon } from "semantic-ui-react";
import { useDrop } from "react-dnd";

import ItemTypes from "./ItemTypes";
import Item from "./Item";

function Target({
  courier,
  products,
  handleInputOrders,
  hanldeInputOrdersClear,
  handleViewList,
  selection
}) {
  //USE DROP
  const [{ isOver, canDrop, border }, drop] = useDrop({
    accept: ItemTypes.PRODUCT,
    drop: () => ({ newCourier: courier._id, newCourierName: courier.name }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      border: monitor.isOver() ? "1px dashed teal" : "1px dashed transparent"
    })
  });

  const headers = (
    <tr>
      <th>Product</th>
      <th className="text-center">Quantity</th>
      <th className="text-center">#Orders</th>
      <th>#Pick</th>
    </tr>
  );

  return (
    <div
      key={courier._id}
      style={{
        marginTop: "2.5em",
        padding: "5px",
        border,
        borderRadius: "0.4em"
      }}
      ref={drop}
      onDrop={() => {
        return { newCourier: courier.name };
      }}
    >
      <div className="flex_spaceBetween">
        <Header style={{ color: isOver ? "teal" : "black" }} as="h4">
          {courier.name}
        </Header>
        <div>
          <Button basic onClick={() => hanldeInputOrdersClear(courier._id)}>
            Clear
          </Button>
          <Button
            onClick={() =>
              handleViewList({ _id: courier._id, name: courier.name })
            }
          >
            <Icon name="th list" />
            Start Picking
          </Button>
        </div>
      </div>

      <Segment>
        <div style={{ margin: "1em 0.5em -0.3em" }}>
          <Table basic="very" fixed unstackable selectable>
            <thead>{headers}</thead>
            <tbody>
              {products.map((product, i) => (
                <Item
                  key={i}
                  courier={courier}
                  product={product}
                  handleInputOrders={handleInputOrders}
                  selection={selection}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </Segment>
    </div>
  );
}

export default Target;
