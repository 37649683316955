import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../../ExportExcel";

function ExportButton({data,filename}) {
  const [formatedData, setData] = useState([{}]);

  const colNames = [
    { label: "Type", value: "type" },
    { label: "Businessclient", value: "businessclient" },

    { label: "Order Nr", value: "orderNr" },
    { label: "Product", value: "product" },
    { label: "Quantity", value: "quantity" },
    { label: "COD", value: "cod" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Country", value: "country" },
    { label: "Created date ", value: "createdAt" },
    // { label: "Blocked", value: "blocked" },
    // { label: "Total", value: "total" },
    // { label: "Turnover 7 days", value: "turnover7days" }
  ];

  useEffect(() => {
    if(!data||data.length===0) setData({})
    else {
      let list = []

      for(let i=0;i<data.length;i++){
          const parcel = data[i]
          if(parcel.goodsreceived) {const goodsReceived = parcel.goodsreceived.map((item) => ({
                        type:            "Received parcel",
                        businessclient:  parcel.businessclient?parcel.businessclient.name:'',
                        orderNr:         parcel.order ? parcel.order.clientOrderNr : "",
                        product:         item.product&&item.product.name ? item.product.name : "",
                        quantity:        item.quantity?item.quantity:'na',
                        warehouse:       parcel.warehouse?parcel.warehouse.name:'', 
                        country:         parcel.warehouse? parcel.warehouse.address.country:'',
                        createdAt:       parcel.createdAt,
                      }));

          list =[...list,...goodsReceived]     }

          //add order items
        if(parcel.order) {
            const originalOrder = parcel.order.items.map((item) => ({
                        type: "Order",
                        businessclient: parcel.order.businessclient.name,
                        orderNr:        parcel.order.clientOrderNr,
                        product:        item.name,
                        quantity:       item.quantity,
                        cod:            parcel.order.payment.COD.amount,
                        warehouse:      parcel.order.shipping.shipFrom.name,
                        country:        parcel.order.shipping.shipTo.country,
                        createdAt:      parcel.order.createdAt
                      }));   
              
            list = [...list, ...originalOrder]
             }
                    

              // setData(tempDataSet);
      }
          setData(list);
      
    }
  }, [data]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={formatedData}
      colNames={colNames}
      button={
        <Button basic color="grey" disabled={!data || !data.length}>
          <Icon name="excel file" />
          Export {`${data && data.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
