const initialState={
        isAuthenticated:false,
        currentUser: {},
        permissions:{
           
            permittedRoles:[],
            canCreateRoles: []
        },
        notifications:[]
}

 const currentUser = (state=initialState,action)=>{
    switch (action.type) {
        
        case 'SET_CURRENT_USER':
            
            return {...state,
                isAuthenticated: Object.keys(action.currentUser).length > 0, //if user , then is authenticated
                currentUser: action.currentUser,
            }
      case 'UPDATE_PROCESSED_ORDERS':
            const {lastProcessedOrders}=action.data
      return {
            ...state,
            currentUser:{...state.currentUser,
                        lastProcessedOrders
            }
      }
      case 'SET_PERMISSIONS':
      return { ...state,
        
        permissions:action.permissions,

      }
      case 'GET_NOTIFICATIONS':
          return {
              ...state,
              notifications:action.notifications
          }
        default:
            return state;
    }
}

export default currentUser