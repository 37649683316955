export const dataSet = [
  {
    category: "goods",
    sku: '2212',
    currency:'EUR',
    amount:3.2,
    kgfrom:0,
    kgto:1,
  },
  {
    category: "shipping",
    currency:'EUR',
    amount:0.5,
    kgfrom:0,
    kgto:1,
  },
  {
    category: "shipping",
    currency:'EUR',
    amount:1,
    kgfrom:1,
    kgto:2,
  }
];
export const colNames = [

  { label: "Category", value: "category" },
  { label: "SKU", value: "sku" },
  { label: "Currency", value: "currency" },
  { label: "Amount", value: "amount" },
  { label: "Kg from", value: "kgfrom" },
  { label: "Kg to", value: "kgto" },
  
];
