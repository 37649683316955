const initialState = {
  status: "All",
  // status: "Order Ready for picking",
  days: 7,
  product: [],
  businessclient: localStorage.selectedClient ? localStorage.selectedClient:'',
  customer: null,
  supplier: null,
  courier: [],
  users: [],
  assigned: [],
  itemsPerPage: 10,
  currentPage: 1,
  searchTerm: null,
  dateFrom: null,
  dateTo: null,
  country: null,
  warehouse: localStorage.selectedWarehouse?localStorage.selectedWarehouse.split(','):[],
  shipToAddress: null,
  sort: {
    byDate: "down",
    // byProduct: "up"
  },
};

const filtersmain = (state = initialState, action) => {
  switch (action.type) {

    case "UPDATE_MAIN_FILTERS":
      
      if (!state.businessclient && !state.supplier)
        return {
          ...state,
          ...action.payload,
          product: [],
        };
      else
        return {
          ...state,
          ...action.payload,
        };


    case "CLEAR_MAIN_FILTERS":
    
    return {...initialState};

    default:
      return state;
  }
};
export default filtersmain;


