import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import {Alert} from 'rsuite'
// import { closePopup } from "../actions/notifications";
// import { addError } from "../actions/error";
import { apiCall } from "../services/api";

function ExportWindow({
  url,
  method = "post",
  filters,
  fileName,
  // closePopup,
  // addError,
}) {
  const [isProcessing, setIsProcessing] = useState(true);

  function getData(filters) {
    // console.log("!!!getting data...");
    const finalUrl = `${process.env.REACT_APP_API}${url}`;
    // getFileToDownload({apiUrl:completeUrl,filters})
    apiCall(method, finalUrl, filters)
      .then((response) => {
        // console.log("RESPONSE:", response);
        setIsProcessing(false);
        createExcelFile(response);
      })
      .catch((err) => {
        Alert.error(err.message);
        // closePopup();
      });
  }

  function createExcelFile(dataForExport) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
    // setTimeout(closePopup, 7000);
  }

  useEffect(() => {
    setIsProcessing(true);
    getData(filters);
  }, [filters]);

  // useEffect(() => {
  //   if (mappedData.length > 0) {
  //     setIsProcessing(false);
  //     createExcelFile(mappedData);
  //   }
  // }, [mappedData]);

  if (isProcessing)
    return (
      <p className="notification--text-on">
        <Icon name="download" />{" "}
        <span className="blink_me">Getting data...</span>
      </p>
    );
  else
    return (
      <p className="notification--text-on">
        <Icon name="check" /> Finished!
      </p>
    );
}

const mapStateToProps = (reduxState) => ({
  isOpen: reduxState.notifications.isPopupOpen,
  // mapData: reduxState.notifications.popupProps.mapData,
  filters: reduxState.notifications.popupProps.filters,
  url: reduxState.notifications.popupProps.url,
  fileName: reduxState.notifications.popupProps.fileName,
  method: reduxState.notifications.popupProps.method,
});
export default connect(mapStateToProps, {})(ExportWindow);
