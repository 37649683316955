import React,{useState} from 'react'
import { Form, FormGroup, FormControl, ControlLabel, InputPicker,Modal,Button,Divider,Radio,RadioGroup } from 'rsuite';
const pickerData=[
  {label:'day',
  value:'day',
  role:'master'},
  {label:'week',
  value:'week',
  role:'master'},
  {label:'month',
  value:'month',
  role:'master'},
  {label:'year',
  value:'year',
  role:'master'},
  
]

const ServiceType = ({createServiceType})=>{
 const [show,openModal]=useState(false)
 

 const [formValue, setFormValue] = useState({
     name:'',
     description:'',
     billing_type:'',
     category:'',
     recurring_period:''
 })

const handleCreate =()=>{ 
              createServiceType(formValue)
      }

    return (
        <div>
          <Modal show={show} onHide={()=>openModal(false)} size="xs">
            <Modal.Header>
              <Modal.Title>NEW ServiceType</Modal.Title>
            </Modal.Header>
            <Divider>

            </Divider>
            <Modal.Body>
              <Form
                
                formValue={formValue} 
                onChange={formValue => {  
                  setFormValue(formValue)
                 
                }}
              >
                   <FormGroup>
                      <FormControl
                        name="name"
                        placeholder="name"
                       
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        name="description"
                        placeholder="description"
                        componentClass="textarea"
                        rows={2}
                      />
                    </FormGroup>
              
                <FormGroup>
                    <ControlLabel>Service Catergory</ControlLabel>
                 
                     <RadioGroup
                        
                        name="category"
                        value={formValue.radio}
                        onChange={value => {
                          setFormValue({...formValue,category:value});
                        }}>
                      <Radio  name='warehouse' value="warehouse">
                        Warehouse Operations
                        </Radio>
                      <Radio  name='shipping' value="shipping">
                        Shipping Operations
                      </Radio>
                      <Radio  name='finance' value="finance">
                        Finance
                      </Radio>
                      <Radio  name='finance' value="goods">
                        Goods
                      </Radio>
                      <Radio  name='finance' value="callcenter">
                        Call Center
                      </Radio>
                      </RadioGroup>
                   
            
            </FormGroup>

            <FormGroup>
             <ControlLabel>Billting type</ControlLabel>
             <RadioGroup
                    inline
                    name="billing_type"
                    value={formValue.billing_type}
                    onChange={value => {
                      setFormValue({...formValue,billing_type:value});
                    }}>
                       <Radio  name='fixed' value="fixed">
                        Fixed
                        </Radio>
                        <Radio  name='relative' value="relative">
                       Relative
                        </Radio>
                        <Radio  name='recurring' value="recurring">
                        recurring
                        </Radio>
                </RadioGroup>
            </FormGroup>
          
           {formValue.billing_type==='recurring'&& <FormGroup>
                <ControlLabel>Recurring period</ControlLabel>
                <InputPicker
                  name="recurring_period"
                  data={pickerData}
                  onChange={value => {
                    setFormValue({...formValue,recurring_period:value});
                  }}
                />
              </FormGroup>}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>{
                                handleCreate()
                                openModal(false)}
                          } 
                appearance="primary">
                Confirm
              </Button>
              <Button onClick={()=>openModal(false)} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        <Button onClick={()=>openModal(true)}>Add Service Type</Button>
        </div>
      );
}


export default ServiceType