import React from "react";
import {
  Table,
  Header,
  Loader,
  Button,
  Checkbox,
  Label,
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

function UserTable(props) {
  const {
    users = [],
    isLoading,
    updateUser,
    currentPage,
    itemsPerPage,
  } = props;

  if (isLoading)
    return (
      <div style={{ marginTop: "3em", marginBottom: "4em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!users.length)
    return (
      <Header
        as="h2"
        color="grey"
        textAlign="center"
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        No users found
      </Header>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
      <Table.HeaderCell>Username</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Businessclient</Table.HeaderCell>
      <Table.HeaderCell>Roles</Table.HeaderCell>
      {/* <Table.HeaderCell>Start Date</Table.HeaderCell> */}
      <Table.HeaderCell>Last LogedIn</Table.HeaderCell>
      <Table.HeaderCell>Active</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = users.map(
    (
      {
        _id,
        username,
        password,
        firstname,
        lastname,
        // email,
        // phone,
        roles,
        status,
        businessclient,
        
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="center">
          <span className="grey--text">
           { itemsPerPage * (currentPage - 1) + i + 1}
          </span>
        </Table.Cell>

        <Table.Cell collapsing>
          <span className="bold--text">{username}</span>
        </Table.Cell>

        {/* <Table.Cell>
          {country.map(c => (
            <div key={c}>{c}</div>
          ))}
        </Table.Cell> */}

        <Table.Cell>
          {firstname} {lastname}
        </Table.Cell>

        <Table.Cell>
        {businessclient&&businessclient.name}
        </Table.Cell>

        <Table.Cell collapsing>
          {roles &&
            roles.map((r) => (
              <div className="small--text" key={r}>
                {r}
              </div>
            ))}
        </Table.Cell>
        <Table.Cell>
          {status.lastLogedIn && (
            <Moment
              style={{ color: "#565656", whiteSpace: "pre" }}
              format="DD-MM-YY HH:mm"
            >
              {status.lastLogedIn}
            </Moment>
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          <Checkbox
            // readOnly
            toggle
            color="grey"
            checked={status.isActive}
            onChange={(e, { checked }) => updateUser({ _id, status: checked })}
          />
        </Table.Cell>
        <Table.Cell textAlign="right">
          {businessclient && (
            <Label color="orange">

              <a
                href={`http://client.wyzbox.com/internal/loginas/${username}/${password}`}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                logIn as
              </a>
            </Label>
          )}
          <Link to={`/users/${_id}`}>
            <Button basic compact size="tiny" icon="pencil" />
          </Link>
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <div className="table-wrapper--horizontal-scroll ">
      <Table basic="very" selectable unstackable>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
}

export default UserTable;
