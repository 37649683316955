import React, { useEffect, useState } from "react";
import { Header, Button, Segment, Icon, Pagination } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { getUsers, updateUser } from "../../../actions/user";

import CanViewPage from "../../../role-based-access/CanViewPage";
import UserTable from "./UserTable";
import Filters from "./Filters";
import "../users.scss";
import { useUpdateContext } from "../../../context/UpdateContext";

function UserList(props) {
  const {  businessclient } = useUpdateContext();

  const [users, setUsers] = useState([]);
  const [updatedOne, setUpdateOne] = useState({ _id: null, status: false });
  const [isLoading, setIsloading] = useState(false);
  const [searchTerm, setsearchTerm] = useState();
  const [role, setRole] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrenPage] = useState(1);

  useEffect(() => {
    setIsloading(true);
    getUsers(
      { businessclient, role, currentPage, itemsPerPage },
      searchTerm
    ).then((data) => {
      setUsers(data);
      setIsloading(false);
    });
  }, [businessclient, role, currentPage, itemsPerPage, searchTerm]);

  useEffect(() => {
    setIsloading(true);
    if (updatedOne._id)
      updateUser({
        query: { "status.isActive": updatedOne.status },
        id: updatedOne._id,
      }).then((user) => {
        getUsers(
          { businessclient, role, currentPage, itemsPerPage,searchTerm },
          searchTerm
        ).then((data) => {
          setUsers(data);
          setIsloading(false);
        });
      });
  }, [
    businessclient,
    role,
    currentPage,
    itemsPerPage,
    updatedOne._id,
    updatedOne.status,
    searchTerm,
  ]);

  return (
    <CanViewPage path="/users">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between vertical--middle">
          <Header as="h1">Users List</Header>
          <Link to="users/create/new">
            <Button labelPosition="left" icon color="blue">
              <Icon name="user" />
              Add User
            </Button>
          </Link>
        </div>

        <Segment>
          <Filters
            role={role}
            setRole={setRole}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            searchTerm={searchTerm}
            setsearchTerm={setsearchTerm}
          />
        </Segment>

        <Segment padded loading={isLoading}>
          <UserTable
            users={users.foundUsers}
            // isLoading={isLoading}
            updateUser={(value) => setUpdateOne(value)}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
         {users.total>itemsPerPage&& <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(users.total / itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e, { activePage }) => setCurrenPage(activePage)}
          />}
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default UserList;
