import React from "react";
import { Input } from "semantic-ui-react";

import ItemsPerPage from "../../ItemsPerPage";
import CountryFilter from "../../Filters/CountryFilter";

function Filters(props) {
  const {
    filters: { searchTerm, country, itemsPerPage },
    handleFilterChange,
    isLoading
  } = props;
  return (
    <section className="flex--between vertical--top">
      <div className="user-filters">
        <Input
          loading={isLoading && searchTerm}
          placeholder="Search..."
          icon="search"
          name="searchTerm"
          value={searchTerm}
          onChange={handleFilterChange}
        />
        <CountryFilter
          multiple
          value={country}
          handleChange={handleFilterChange}
        />

  
      </div>
      <ItemsPerPage handleChange={handleFilterChange} value={itemsPerPage} />
    </section>
  );
}

export default Filters;
