import React from "react";
import { Input } from "semantic-ui-react";

import ItemsPerPage from "../../ItemsPerPage";
import RolesFilter from "../../Filters/RolesFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

function Filters(props) {
  const {
    role,
    setRole,
    itemsPerPage,
    setItemsPerPage,
    searchTerm,
    setsearchTerm,
    isLoading,
  } = props;
  return (
    <section className="flex--between vertical--top">
      <div className="user-filters">
        <Input
          loading={isLoading && searchTerm}
          placeholder="Search..."
          icon="search"
          name="searchTerm"
          value={searchTerm}
          onChange={(e, { value }) => setsearchTerm(value)}
        />
        {/* <CountryFilter
          multiple
          value={country}
          handleChange={handleFilterChange}
        /> */}
        <RolesFilter
          // multiple
          value={role}
          name="role"
          handleChange={(e, { value }) => setRole(value)}
        />
        <BusinessclientFilter name="businessclient" />
      </div>
      <ItemsPerPage setItemsPerPage={setItemsPerPage} value={itemsPerPage} />
    </section>
  );
}

export default Filters;
