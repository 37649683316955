import React, { useEffect, useState } from "react";
import { Header, Segment } from "semantic-ui-react";

import { getBillableServices } from "../../../actions/billableservices";
import { useTableCheckbox } from "../../../hooks/useTableCheckbox";
import CanViewPage from "../../../role-based-access/CanViewPage";
import ExportButton from "../../ExportButton";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import ServicesTable from "./ServicesTable";
import Filters from "./Filters";
import "./services.scss";
import { useUpdateContext } from "../../../context/UpdateContext";

function Services(props) {
  const {
    businessclient,
    country,
    setCountry,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
  } = useUpdateContext();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [services, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState();

  const {
    isHeaderChecked,
    checkedItems,
    handleSingleCheck,
    handleMultipleCheck,
    // handleClearCheckedItems,
  } = useTableCheckbox(services);

  useEffect(() => {
    setLoading(true);
    getBillableServices({ dateFrom, dateTo }).then((resp) => {
      setLoading(false);
      setList(resp.list);
      setTotal(resp.total);
    });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    setLoading(true);
    getBillableServices({
      dateTo,
      dateFrom,
      businessclient,
      serviceType,
      country,
      itemsPerPage,
      currentPage,
    }).then((resp) => {
      setLoading(false);
      setList(resp.list);
      setTotal(resp.total);
    });
  }, [
    dateTo,
    dateFrom,
    serviceType,
    businessclient,
    country,
    itemsPerPage,
    currentPage,
  ]);

  // useEffect(
  //   () =>
  //     setFileName(
  //       `services${dateFrom ? " - " + dateFrom.toLocaleDateString() : ""}${
  //         dateTo ? " - " + dateTo.toLocaleDateString() : ""
  //       }${status === "false" ? " - not billed" : ""}${
  //         status === "true" ? " - billed" : ""
  //       }`
  //     ),
  //   [dateFrom, dateTo, status]
  // );

  return (
    <CanViewPage path="/services">
      <main className="page-wrapper">
        <Header>Services</Header>

        <Filters
          serviceType={serviceType}
          setServiceType={setServiceType}
          country={country}
          setCountry={setCountry}
          setDateTo={handleDateToChange}
          setDateFrom={handleDateFromChange}
        />

        <Segment>
          <div className="flex--right">
            <ExportButton
              fileName={"fileName"}
              total={total}
              filters={{
                dateFrom,
                dateTo,
                businessclient,
                country,
                itemsPerPage,
                currentPage,
              }}
              // mapData={mapData}
              url="/services/billable/export"
            />
            <ItemsPerPage
              style={{ marginLeft: "10px" }}
              total={total}
              value={itemsPerPage}
              setItemsPerPage={(value) => setItemsPerPage(value)}
            />
          </div>

          <ServicesTable
            services={services}
            isLoading={isLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            isHeaderChecked={isHeaderChecked}
            checkedItems={checkedItems}
            handleSingleCheck={handleSingleCheck}
            handleMultipleCheck={handleMultipleCheck}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={(value) => setCurrentPage(value)}
            />
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default Services;
