import React, { useEffect,useState } from "react";
import { Header, Segment } from "semantic-ui-react";

import Filters from "./Filters";

import { getStockTurnover,updateFilters } from "../../../actions/reports";

import ExportButton from "./ExportButton";

// import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";

import "./services.scss";
import Turnovertable from "./Turnovertable";
import { useUpdateContext } from "../../../context/UpdateContext";

function Stocksturnover({filters}) {
  const {
    businessclient,
    warehouse,
    product
  } = useUpdateContext();

  const [days, setDays] = useState(7)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setLoading] = useState(false);
  const [stocks,setStocks] = useState({})
  const [total,setTotal] = useState(0)

  // console.log("STOCKS:",stocks)

  useEffect(() => {
    setLoading(true)    
    getStockTurnover({businessclient,warehouse,product,days}).then(resp=>{
      setStocks(resp.table)
          setLoading(false);    

  })
}, [ businessclient,warehouse, product,days]);


  return (
    // <CanViewPage path="/reports/turnover">
    <main className="page-wrapper">
      <div className="flex--between">
        <Header style={{ marginBottom: 0 }}>Turnover</Header>
        
        <ExportButton stocks={stocks} />
      </div>
      <Filters
        setDays={setDays}
        days={days}
        // handleFilterChange={handleFilterChange}
        // updateFilters={updateFilters}
        // warehouses={warehouses}
      />
      <Segment>
        <div className="flex--right">
          {/* <ItemsPerPage
              style={{ marginLeft: "10px" }}
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
            /> */}
        </div>
        <Turnovertable
          stocks={stocks}
          isLoading={isLoading}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          value={days}
          style={{ marginLeft: "10px" }}
          // handleChangeDays={handleFilterChange}
        />
        {total > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            total={total}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        )}
      </Segment>
    </main>
    // {/* </CanViewPage> */}
  );
}


export default (Stocksturnover);
