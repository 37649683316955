import {
    apiCall
} from "../services/api";

import {
    GET_PROMO, 
    GET_PROMOS, 
    UPDATE_PROMO, 
    ADD_PROMO_PRODUCTS, 
    REMOVE_PROMO_PRODUCTS,
    ADD_PROMO_DISPLAYTOPRODUCTS,
    REMOVE_PROMO_DISPLAYTOPRODUCTS,
}  from './actionTypes'

import {addError} from './error'

export const createPromo =(history,body)=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos`;

  apiCall('post',url,body)
  .then(resp=>  history.push(`/promos/${resp._id}`))
   .catch(err => {dispatch(addError(err.message))});

}
export const getPromo =(id)=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos/${id}`;

  apiCall('get',url)
  .then(resp=>dispatch({type:GET_PROMO,payload:resp}))
   .catch(err => {dispatch(addError(err.message))});

}
export const getPromos =({country,product})=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos?country=${country}&product=${product}`;

  apiCall('get',url)
  .then(resp=>dispatch({type:GET_PROMOS,payload:resp}))
   .catch(err => {dispatch(addError(err.message))});

}
export const updatePromo =({id,query})=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos/${id}`;

  apiCall('put',url,query)
  .then(resp => dispatch({
      type: UPDATE_PROMO,
      payload: resp
  }))
   .catch(err => {dispatch(addError(err.message))});

}
export const removePromo =(id,history)=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos/${id}`;

  apiCall('delete',url)
  .then(resp =>  history.push(`/products/list/promos`))
   .catch(err => {dispatch(addError(err.message))});

}
export const addPromoProducts =({id,product})=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos/${id}/products`;

  apiCall('post',url,{product})
  .then(resp=>dispatch({type:ADD_PROMO_PRODUCTS,payload:resp}))
   .catch(err => {dispatch(addError(err.message))});

}
export const removePromoProducts = ({id,product}) => dispatch => {

  const url = `${process.env.REACT_APP_API}/promos/${id}/products`;

  apiCall('put',url,{product})
  .then(resp=>dispatch({type:REMOVE_PROMO_PRODUCTS,payload:resp}))
   .catch(err => {dispatch(addError(err.message))});

}
export const addPromoDisplayProducts = ({
    _id,
    products
  }) => dispatch => {

  const url = `${process.env.REACT_APP_API}/promos/${_id}/diplaytoproducts`;
  dispatch({
    type: "LOADING_START"
  });
  apiCall('post', url, {
    products
  })
  .then(resp=>{
      dispatch({
        type: "LOADING_STOP"
      });
    dispatch({type:ADD_PROMO_DISPLAYTOPRODUCTS,payload:resp})})
   .catch(err => {dispatch(addError(err.message))});

}
export const removePromoDisplayProducts =({_id,products})=>dispatch=>{
  const url = `${process.env.REACT_APP_API}/promos/${_id}/diplaytoproducts`;

  apiCall('put',url,{products})
  .then(resp=>dispatch({type:REMOVE_PROMO_DISPLAYTOPRODUCTS,payload:resp}))
   .catch(err => {dispatch(addError(err.message))});

}
