import {apiCall} from "../services/api"

export const uploadDocuments = ({files}) => {
 
    const url = `${process.env.REACT_APP_API}/files/upload`
    return  apiCall('post', url, files) //upload file to fileserver 
                .then(files => (files))
                .catch(err => (err))
                                             }


export const getFiles = ({
    trigger,
    triggerId,
    filetype
  }) => {
  
  const url = `${process.env.REACT_APP_API}/files/?trigger=${trigger}&triggerId=${triggerId}&filetype=${filetype}`;
  return  apiCall("get", url) //upload file to fileserver
            .then((files) => files)
            .catch((err) => (err));
        }

export const removeFiles = (files)=>{ 
    
 const url = `${process.env.REACT_APP_API}/files/delete`
 return  apiCall('post', url,{files})
            .then(resp =>(resp))
            .catch(err => (err))       
    }
   
export const updateFile =({id,country,print})=>{
    
    return new Promise((resolve,reject)=>{

        const url = `${process.env.REACT_APP_API}/files/${id}`

        apiCall('put',url,{country,print})
        .then(resp=>resolve(resp))
        .catch(err=>reject(err))

    }) 
}

    