import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { getCouriers } from "../../../actions/couriers";
// import { updateFilters } from "../../../actions/filtersmain";
import CountryFilter from "../../Filters/CountryFilter";
import { useUpdateContext } from "../../../context/UpdateContext";

function Filters({ setCouriers }) {

  const {country} = useUpdateContext()

  const [isLoading, setIsloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    getCouriers({ searchTerm, country })
      .then((data) => {
        if (data.length > 0) setCouriers(data);
      })
      .catch((error) => error.message);
  }, [searchTerm, country, setCouriers]);

  return (
    <section className="flex--left vertical--center">
      <Input
        style={{ marginRight: "1em" }}
        loading={isLoading}
        placeholder="Search..."
        icon="search"
        name="searchTerm"
        value={searchTerm}
        onChange={(e, { value }) => setSearchTerm(value)}
      />

      <CountryFilter
        multiple={true}
       
      />
    </section>
  );
}

export default Filters;
