import React, { useState, useEffect } from "react";
import { Header, Input, Button } from "semantic-ui-react";
import CountryFilter from "../../Filters/CountryFilter";
import SkuFilter from "../../Filters/SkuFilter";

const requieredFields = ["country"];

function SettingsModal({
  initFormValues,
  isEditModeOn,
  handleClose,
  create,
  update,
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const [touched, setTouched] = useState([]);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (initFormValues._id)
      setFormValues({
        country: initFormValues.country,
        sku: initFormValues.sku,
        min_rate: initFormValues.min_rate * 100,
        max_rate: initFormValues.max_rate * 100,
        web: initFormValues.web,
      });
  }, [initFormValues._id]);

  const handleFieldChange = (e, { name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (touched.includes(name)) return;
    setTouched((prev) => [...prev, name]);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    // setFormValues(initFormValues);
    // setTouched([]);
    // if (validationError) setValidationError(false);
    handleClose();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const hasEmptyRequierdField = requieredFields.find(
      (field) => !formValues[field]
    );
    if (hasEmptyRequierdField) {
      setValidationError(true);
      return;
    }
    const query = {};
    for (let field of touched) {
      if (["min_rate", "max_rate", "target_rate", "end_rate"].includes(field))
        query[field] = formValues[field] / 100;
      else query[field] = formValues[field];
    }
    // console.log(query);
    isEditModeOn
      ? update({ id: initFormValues._id, updates: query })
      : create({ query });
  };
  return (
    <>
      <Header>{isEditModeOn ? "Edit" : "New"} Shavers Setting</Header>
      <form autoComplete="off" className="shavers-form__grid">
        <label className="shavers-form__label">Country:</label>
        <CountryFilter
          value={formValues.country}
          handleChange={handleFieldChange}
          //   error={validationError && !formValues.country}
        />
        <label className="shavers-form__label">SKU:</label>
        <SkuFilter
          value={formValues.sku}
          handleChange={handleFieldChange}
          //   error={validationError && !formValues.sku}
        />
        <label className="shavers-form__label">Min Rate (%):</label>
        <Input
          type="number"
          min={0}
          placeholder={0}
          name="min_rate"
          value={formValues.min_rate}
          onChange={handleFieldChange}
          //   error={validationError && !formValues.min_rate}
        />
        <label className="shavers-form__label">Max Rate (%):</label>
        <Input
          type="number"
          min={0}
          placeholder={0}
          name="max_rate"
          value={formValues.max_rate}
          onChange={handleFieldChange}
          //   error={validationError && !formValues.max_rate}
        />
        <label className="shavers-form__label">Web:</label>
        <Input
          type="text"
          placeholder="web..."
          name="web"
          value={formValues.web}
          onChange={handleFieldChange}
          //   error={validationError && !formValues.web}
        />
      </form>

      <div className="flex--right">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          color="blue"
          onClick={handleSubmit}
          disabled={!touched.length || validationError}
          content="Save"
        />
      </div>

      {validationError && (
        <span className="user-form__error-msg">
          Fill in the required fields!
        </span>
      )}
    </>
  );
}

export default SettingsModal;
