import React from "react";
import { Input } from "semantic-ui-react";
import { useDrag } from "react-dnd";
import { connect } from "react-redux";

import ItemTypes from "./ItemTypes";
import { changeCourier } from "../../../../actions/picking";

function Item({
  product,
  courier,
  handleInputOrders,
  selection,
  changeCourier
}) {
  //USE DRAG
  const [{ opacity, cursor }, drag] = useDrag({
    item: {
      product: product.name,
      type: ItemTypes.PRODUCT
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const isDroppedAtDiffCourier = courier._id !== dropResult.newCourier;
      if (item && dropResult && isDroppedAtDiffCourier) {
        // alert(
        //   `Moved ${item.product} from ${courier.name} to ${dropResult.newCourierName}!`
        // );
        // console.log("change courier", {
        //   product: product._id,
        //   orders: selection[product._id] && selection[product._id][courier._id],
        //   oldCourier: courier._id,
        //   newCourier: dropResult.newCourier
        // });
        changeCourier({
          product: product._id,
          orders: selection[product._id] && selection[product._id][courier._id],
          oldCourier: courier._id,
          newCourier: dropResult.newCourier
        });
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.2 : 1,
      cursor: monitor.isDragging() ? "grabbing" : "grab"
    })
  });

  return (
    <tr
      key={product._id}
      ref={drag}
      style={{ opacity, border: "1px solid red", cursor: "move" }}
    >
      <td>{product.name}</td>
      <td className="text-center">{product.quantity}</td>
      <td className="text-center">{product.orders}</td>
      <td>
        <Input
          type="number"
          min="0"
          max={product.orders}
          fluid
          id={product._id}
          name={courier._id}
          onChange={handleInputOrders}
          value={selection[product._id] && selection[product._id][courier._id]}
        />
      </td>
    </tr>
  );
}

export default connect(() => ({}), { changeCourier })(Item);
