import { useState } from "react";

export function useToggleModalNewEdit(dafaultInitFormValues) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModeOn, setEditMode] = useState(false);
  const [initFormValues, setinitFormValues] = useState(dafaultInitFormValues);

  const openModal = (edit = false, itemToEdit = dafaultInitFormValues) => {
    setModalOpen(true);
    setEditMode(edit);
    setinitFormValues(itemToEdit);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return { isModalOpen, isEditModeOn, initFormValues, openModal, closeModal };
}
