import React, { useState } from "react";
import { Form, Button, Divider, TextArea } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createProduct } from "../../../actions/product";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import { Alert } from "rsuite";

const requiredFields = ["name", "sku"];
const initState = {
  product: {
    name: "",
    sku: "",
    barcode: "",
    businessclient: "",
    color: "",
    size: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    temp: "",
    propertiesNote: "",
    storageNote: "",
  },
  validationErrors: {
    name: false,
    sku: false,
  },
  formHasChanges: false,
  errMessage: "",
};

const NewProductForm = (props) => {
  const [state, setState] = useState(initState);

  const handleChange = (e, { name, value }) => {
    setState({
      ...state,
      formHasChanges: true,
      product: {
        ...state.product,
        [name]: value,
      },
    });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // // console.log("submit");

    // const emptyReqField = {};
    // for (let field of requiredFields) {
    //   if (!state.product[field]) emptyReqField[field] = true;
    // }
    // setState({
    //   validationErrors: { ...state.validationErrors, ...emptyReqField },
    // });

    // const validationErrsPresent =
    //   !!Object.values(state.validationErrors).find((value) => value) ||
    //   !!Object.values(emptyReqField).find((value) => value);
    // // console.log(validationErrsPresent);

    // if (validationErrsPresent) {
    //   setState({
    //     ...state,
    //     errMessage: "Please fill in the required fields before submitting",
    //   });
    //   // console.log("error msg");
    //   return;
    // }

    const {
      name,
      sku,
      barcode,
      color,
      size,
      height,
      length,
      width,
      weight,
      temp,
      propertiesNote,
      storageNote,
      businessclient,
    } = state.product;

    const product = {
      name,
      "codes.sku": sku,
      "codes.barcode": barcode,
      businessclient,
      color,
      size,
      "proprities.weight": weight,
      "proprities.dimentions.height": height,
      "proprities.dimentions.lenght": length,
      "proprities.dimentions.width": width,
      "proprities.note": propertiesNote,
      "storage.temp": temp,
      "storage.note": storageNote,
    };

    createProduct(product)
      .then((resp) => {
        console.log("in response:",resp)
        Alert.success("Product Added.");
      })
      .catch((error) => {
        console.log("ERROR:", error);
        Alert.error(error);
      });
  };

  const handleClearForm = () => {
    setState({ ...initState });
  };

  const {
    name,
    sku,
    barcode,
    color,
    size,
    height,
    length,
    width,
    weight,
    temp,
    propertiesNote,
    storageNote,
    businessclient,
  } = state.product;

  return (
    <>
      <Form
        success
        autoComplete="off"
        className="product-form"
      >
        {/* <div id="product_img">
                <img src="https://react.semantic-ui.com/imgs/wireframe/square-image.png" />
              </div> */}

        <div className="product-form__section product-form__name">
          <h4>Product Identification</h4>
          <Divider />
          <Form.Group>
            <Form.Input
              required
              error={state.validationErrors.name}
              label="Product name"
              placeholder="name"
              value={name}
              name="name"
              onChange={handleChange}
            />
            <Form.Input
              required
              error={state.validationErrors.sku}
              label="SKU"
              placeholder="SKU"
              name="sku"
              value={sku}
              onChange={handleChange}
            />

            <div>
              <p style={{ fontWeight: "bold" }}>Client</p>
              <BusinessclientFilter
                useFilter={false}
                handleChange={(value) =>
                  handleChange(null, { name: "businessclient", value })
                }
                value={businessclient}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Barcode"
              name="barcode"
              placeholder="Barcode"
              value={barcode}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div className="product-form__section product-form__options">
          <h4>Product options</h4>
          <Divider />
          <Form.Group>
            <Form.Input
              label="Color"
              name="color"
              placeholder="color"
              value={color}
              onChange={handleChange}
            />
            <Form.Input
              label="Size"
              placeholder="size"
              name="size"
              value={size}
              onChange={handleChange}
            />
          </Form.Group>
        </div>

        <div className="product-form__section product-form__description">
          <h4>Description</h4>
          <Divider />
          <Form.Group>
            <Form.Input
              label="Height"
              name="height"
              placeholder="cm..."
              value={height}
              onChange={handleChange}
            />
            <Form.Input
              label="Length"
              name="length"
              placeholder="cm..."
              value={length}
              onChange={handleChange}
            />
            <Form.Input
              label="Width"
              placeholder="cm..."
              value={width}
              name="width"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Weight"
              placeholder="grams..."
              value={weight}
              name="weight"
              onChange={handleChange}
            />
          </Form.Group>
          <TextArea
            rows={2}
            placeholder="Other notes..."
            value={propertiesNote}
            name="propertiesNote"
            onChange={handleChange}
          />
        </div>

        <div className="product-form__section product-form__storage">
          <h4>Storage</h4>
          <Divider />
          <Form.Group>
            <Form.Input
              label="Temperature"
              placeholder="°C ..."
              value={temp}
              name="temp"
              onChange={handleChange}
            />
          </Form.Group>
          <TextArea
            rows={2}
            placeholder="Other notes..."
            value={storageNote}
            name="storageNote"
            onChange={handleChange}
          />
        </div>

        <Button
          color="blue"
          size="large"
          // disabled={!state.formHasChanges}
          loading={props.loading}
          onClick={handleSubmit}
        >
          Create
        </Button>
        <Button
          type="clear"
          size="large"
          disabled={!state.formHasChanges}
          onClick={handleClearForm}
        >
          Clear
        </Button>
      </Form>

      {props.id && (
        <div className=" feedback-section feedback-section_success ">
          <p className="feedback-section__success">
            {/* <Icon name="check" color="green" /> */}
            Product successfully saved!
          </p>
          <Link to={`/products/${props.id}`}>See product</Link>
        </div>
      )}
      {state.errMessage && !props.id && (
        <div className=" feedback-section feedback-section_errors  ">
          <p className="feedback-section__errors">
            {/* <Icon name="check" color="green" /> */}
            {state.errMessage}
          </p>
        </div>
      )}
    </>
  );
};

export default NewProductForm;
