import React, { useState } from "react";
import { Input, Button, Header } from "semantic-ui-react";

import CountryFilter from "../Filters/CountryFilter";
import CurrencyFilter from "../Filters/CurrencyFilter";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import SkuFilter from "../Filters/SkuFilter";

const requieredFields = ["country", "currency"];

function RestrictionModal({
  initFormValues,
  isEditModeOn,
  handleClose,
  createRestriction,
  updateRestriction,
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const [touched, setTouched] = useState([]);
  const [validationError, setValidationError] = useState(false);

  // console.log({ formValues, touched, validationError, isEditModeOn });

  const handleChange = (e, { name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (touched.includes(name)) return;
    setTouched((prev) => [...prev, name]);
  };

  const handleClientChange = (e, { name, value }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
      sku_restricted: [],
      sku_allowed: [],
    }));
    if (touched.includes(name)) return;
    setTouched((prev) => [...prev, name]);
  };

  const handleSubmit = () => {
    const hasEmptyRequierdField = requieredFields.find(
      (field) => !formValues[field]
    );
    if (hasEmptyRequierdField) {
      setValidationError(true);
      return;
    }
    const query = {};
    for (let field of touched) {
      query[field] = formValues[field];
    }
    // console.log(query);
    isEditModeOn
      ? updateRestriction({ id: formValues._id, updates: query })
      : createRestriction(query);
    handleClose();
  };

  return (
    <section>
      <Header as="h4">
        {!isEditModeOn ? "Add new" : "Update"} restriction:
      </Header>
      <div className="quotations-modal-grid">
        <div className="quotations-modal-label-input">
          <label>
            Country: <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <CountryFilter
            value={formValues.country}
            handleChange={handleChange}
            error={validationError && !formValues.country}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
            Currency:{" "}
            <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <CurrencyFilter
            value={formValues.currency}
            handleChange={handleChange}
            error={validationError && !formValues.currency}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
            Businessclient:{" "}
            {/* <span className="new-ticket-form__required-symbol">*</span> */}
          </label>
          <BusinessclientFilter
            name="businessclient"
            value={formValues.businessclient}
            handleChange={handleClientChange}
            // error={validationError && !formValues.businessclient}
          />
        </div>

        <div></div>

        <div className="quotations-modal-label-input">
          <label>
            SKU - allowed:{" "}
            {/* <span className="new-ticket-form__required-symbol">*</span> */}
          </label>
          <SkuFilter
            businessclient={formValues.businessclient}
            disabled={!formValues.businessclient}
            multiple
            name="sku_allowed"
            value={formValues.sku_allowed}
            handleChange={handleChange}
            // error={validationError && !formValues.sku_allowed}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
            SKU - restricted:{" "}
            {/* <span className="new-ticket-form__required-symbol">*</span> */}
          </label>
          <SkuFilter
            businessclient={formValues.businessclient}
            disabled={!formValues.businessclient}
            multiple
            name="sku_restricted"
            value={formValues.sku_restricted}
            handleChange={handleChange}
            // error={validationError && !formValues.sku_restricted}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>Limit:</label>
          <Input
            type="number"
            min="0"
            value={formValues.limit12m}
            name="limit12m"
            onChange={handleChange}
          />
        </div>
      </div>
      {validationError && (
        <span className="user-form__error-msg">
          Fill in the required fields!
        </span>
      )}
      <Button
        floated="right"
        primary
        content={!isEditModeOn ? "Create" : "Update"}
        onClick={() => handleSubmit({ formValues })}
      />
      <Button floated="right" content="Cancel" onClick={handleClose} />
    </section>
  );
}

export default RestrictionModal;
