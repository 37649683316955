import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useUpdateContext } from "../../context/UpdateContext";

import { countries } from "../../countries";

function CountryFilter({
  disabled=false,
  style = {
    margin: "2px",
    minWidth: "330px",
    maxWidth: "360px",
    border: "none !important",
    color: "#3F72AF !important"
  },
  value,
  name = "country",
  placeholder = "country...",
  multiple = false,
  fluid = false,
  selection = true,
  error = false,
  clearable = true,
  className = "",
  useFilter = true,
  handleChange,
}) {
  const options = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  const {handleCountryChange,
    country,} = useUpdateContext()
  return (
    <Dropdown
      style={style}
      disabled={disabled}
      className={className}
      autoComplete="nope"
      placeholder={placeholder}
      name={name}
      value={useFilter?country:value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable={clearable}
      selection={selection}
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={(e,{value})=>{
        
        useFilter?handleCountryChange(value):handleChange(value)}}
      options={options}
    />
  );
}

export default CountryFilter;
