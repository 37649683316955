import {
  CHANGE_COURIER,
  SUCCESS_UPDATE_ORDERS_STATUS_PICKING,
  ADD_NEW_PICKING_LIST,
  GET_ALL_PICKING_LISTS,
  UPDATE_PICKING_LIST,
  UPADATE_FILTER_PICKING_LIST,
  GET_COURIERS_BY_WAREHOUSE
} from "../actions/actionTypes";

const initstate = {
  list: [],
  listOfPicked: [],
  pickedTotal: 0,
  selection: [],
  isLoading: false,
  successMsg: null,
  successUpdateStatus: false,
  couriers:[],
  filters: {
    product: [],
    dateFrom: null,
    dateTo: null,
    currentPage: 1,
    itemsPerPage: 10
  }
};

const picking = (state = initstate, action) => {
  switch (action.type) {
    case "GET_PICKINGLIST":
      return {
        ...state,
        list: action.data,
        selection: [],
        isLoading: false
      };

    case "SELECT_PICKING_ORDERS":
      return {
        ...state,
        selection: action.data,
        isLoading: false
      };
    case "LOADING_START":
      return {
        ...state,
        isLoading: true
      };
    case "LOADING_STOP":
      return {
        ...state,
        isLoading: false
      };
    case CHANGE_COURIER:
      return {
        ...state,
        list: action.payload,
        isLoading: false
      };
    case SUCCESS_UPDATE_ORDERS_STATUS_PICKING:
      return {
        ...state,
         isLoading: false,
        successUpdateStatus: true
      };
    case ADD_NEW_PICKING_LIST:
    case UPDATE_PICKING_LIST:
      return {
        ...state,
        listOfPicked: [action.payload, ...state.listOfPicked],
        isLoading: false
      };
    case GET_ALL_PICKING_LISTS:
      return {
        ...state,
        listOfPicked: action.payload.foundList,
        pickedTotal: action.payload.total,
        isLoading: false
      };
    case UPADATE_FILTER_PICKING_LIST:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        isLoading: false
      };
    case GET_COURIERS_BY_WAREHOUSE:
      return {
        ...state,
        couriers:action.payload,
        isLoading: false
      };

    default:
      return state;
  }
};
export default picking;
