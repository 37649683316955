import React from "react";
import { Loader, Header } from "semantic-ui-react";
import { Segment, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

// import ItemCard from "./Order/ListOrders/Mobile/ItemCard";

function CardsList({ items, isLoading, notFoundMsg, currentPage,itemsPerPage }) {
  if (isLoading) {
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );
  }

  if (!items.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        {notFoundMsg}
      </Header>
    );
  }

  const list = items.map((item,index)=>{
    const {
      product_details,
      businessclient,
      warehouse,
      available,
      blocked,
      total,
    } = item;
  
    let product = {};
    if (!product_details) {
      product = {
        sku: "unpaired",
        name: "NA",
        url: "/shipments/inbound/lists/unpaired"
      };
    } else {
      product = {
        sku: product_details.codes.sku,
        name: product_details.name,
        url: `/products/${product_details._id}`
      };
    }
  
    return (
      <Segment>
        <div className="warehouse-card">
          <p className="left--text" style={{ position: "absolute" }}>
          {(index + 1)+(currentPage-1)*itemsPerPage}
          </p>
  
          <div className="warehouse-card--head vertical--center">
            <Link to={product.url} className="large--text">
               {product.name}
            </Link>
            {/* <span className="warehouse-card--name x-large--text dark-blue--text bold--text">
              {product.name}
            </span> */}
          </div>
  
          <Divider />
          <div className="warehouse-card--grid">
            <span >Businessclient:</span>
            <span >{businessclient.name}</span>
  
            <span >Warehouse:</span>
            <span >{warehouse.name}</span>
            <span >Sku:</span>
            <span >{product.sku}</span>
  
            <span>Available:</span>
            <div>{available}</div>
  
            <span>Blocked:</span>
            <div>{blocked}</div>
  
            <span>Total:</span>
            <div>{total}</div>
  
            {/* <span>Turnover 7 days:</span>
            <div>{turnover7days}</div> */}
          </div>
        </div>
      </Segment>
    );
   })
    
  return (
    <section>
      {list}
    </section>
  );
}

export default CardsList;
