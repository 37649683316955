// import {
//     GET_BUSINESSCLIENTS,
//   } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = {
  type: "START_LOADING",
};
const stopLoading = {
  type: "STOP_LOADING",
};

export const updateFilter = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});

export const getRestrictions = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());

  const filtersUrl = filters
    ? "?" +
      Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";

  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/restrictions${filtersUrl}`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_RESTRICTIONS",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const createRestriction = (query) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());

  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/restrictions`;
  apiCall("post", url, query)
    .then((resp) =>
      dispatch({
        type: "NEW_RESTRICTION",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateRestriction = ({ id, updates }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());

  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/restrictions/${id}`;
  apiCall("put", url, updates)
    .then((resp) =>
      dispatch({
        type: "UPDATE_RESTRICTION",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const deleteRestriction = ({ id }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());

  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/restrictions/${id}`;
  apiCall("delete", url)
    .then((resp) => dispatch({
      type: "GET_RESTRICTIONS",
      payload: resp,
    }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
