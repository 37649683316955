import React from "react";
import Main from "./Main.jsx";
import "./App.scss";
// import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import "./flags.css";
import HOC_Main from "../hocs/HOC_Main";
// import history from "../history";
import withClearCache from "../ClearCache";
import { UpdateProvider } from "../context/UpdateContext";
// import { PrimeReactProvider } from "primereact/api";

// import 'rsuite/lib/styles/index.less'; // or 'rsuite/dist/styles/rsuite-default.css'

const MainApp = HOC_Main(Main);
// const MainApp = (Main);
const ClearCacheComponent = withClearCache(MainApp);

class App extends React.Component {
  render() {
    return (
      <UpdateProvider>
        {/* <PrimeReactProvider> */}
          <ClearCacheComponent />
        {/* </PrimeReactProvider> */}
      </UpdateProvider>
    );
  }
}

export default App;
