import React, { useEffect, useState } from "react";
import {
  Header,
  Segment,
  Icon,
  Button,
  // Label,
  Input,
  Divider,
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
// import {addItem,removeItem} from '../../../actions/bills'
import {
  getInvoice,
  updateInvoice,
  removeItem,
  addItem,
} from "../../../actions/invoices";
import CanViewPage from "../../../role-based-access/CanViewPage";
// import ExportButton from "../../ExportButton";
// import EditableText from "../../EditableText";
import ItemsList from "./ItemsList";
import CountryFilter from "../../Filters/CountryFilter";
import { DatePicker } from "rsuite";
import CurrencyFilter from "../../Filters/CurrencyFilter";

import AddItem from "./AddItem";
import Modal from "react-responsive-modal";
// import Documents from "../../Documents";
import "../bills.scss";

// const statusColor = {
//   Paid: "green",
//   "Not paid - In due": "yellow",
//   "Not paid- Over due": "red",
// };

function Viewinvoice({ isLoading, match }) {
  const id = match.params.id;

  const [invoice, setInvoice] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const [updateField, setShowUpdateField] = useState({
    name: null,
    value: null,
  });
  const [updatingSegment, setUpdatingSegment] = useState();

  const handleUpdateField = (segment) => {
    setUpdatingSegment(segment);
    updateInvoice({
      query: { [updateField.name]: updateField.value },
      id: invoice._id,
    })
      .then((invoice) => {
        setInvoice(invoice);
        setUpdatingSegment(null);
        setShowUpdateField({ name: null, value: null });
      })
      .catch((err) => setUpdatingSegment(null));

    // setShowUpdateField({ name: null, value: null });
  };

  const addItemAction = (item) => {
    addItem({ query: item, id: invoice._id }).then((invoice) =>
      setInvoice(invoice)
    );
    setModalOpen(false);
  };

  const removeItemAction = (itemId) => {
    removeItem({ itemId, id: invoice._id }).then((invoice) =>
      setInvoice(invoice)
    );
  };
  useEffect(() => {
    getInvoice(id).then((invoice) => {
      setInvoice(invoice);
      // setInvoiceItems(invoice.items)
    });
  }, [id]);

  if (!invoice) return <div>Loading...</div>;
  return (
    <CanViewPage path="/billing">
      <div style={{ maxWidth: "950px" }}>
        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={false}
          onClose={() => setModalOpen(false)}
          center
          classNames={{ modal: "" }}
        >
          <div>
            <AddItem addItem={addItemAction} withAddVisible={true} />
          </div>
        </Modal>

        <main className="page-wrapper a-wrapper">
          <Link to="/invoices">
            <Icon name="arrow left" />
            Invoices
          </Link>

          <div className="flex--between">
            <table style={{ width: "550px" }}>
              <tr id="sequenceNumber" style={{ height: "33px" }}>
                <td style={{ width: "95px", fontWeight: "bold" }}>Number</td>
                <td style={{ textAlign: "center", width: "110px" }}>
                  {updateField.name === "sequenceNumber" && (
                    <div>
                      <Input
                        loading={updatingSegment === "number"}
                        value={updateField.value}
                        style={{ height: "25px", width: "110px" }}
                        onChange={(e, { value }) => {
                          setShowUpdateField({ ...updateField, value });
                        }}
                      ></Input>
                    </div>
                  )}
                  {updateField.name !== "sequenceNumber" &&
                    invoice.sequenceNumber}
                </td>
                <td style={{ textAlign: "left", width: "250px" }}>
                  {updateField.name === "sequenceNumber" && (
                    <div>
                      <Button
                        size="mini"
                        basic
                        color="red"
                        onClick={() => {
                          setShowUpdateField({ name: null });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="mini"
                        basic
                        color="green"
                        onClick={() => handleUpdateField("number")}
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  {updateField.name !== "sequenceNumber" && (
                    <Button
                      icon="edit"
                      basic
                      size="mini"
                      onClick={() => {
                        setShowUpdateField({
                          name: "sequenceNumber",
                          value: invoice.sequenceNumber,
                        });
                      }}
                    ></Button>
                  )}
                </td>
              </tr>
            </table>

            <div>
              <Link to={`/invoices/${id}/pdf`}>
                View <Icon name="file" />
              </Link>
              <Button
                basic
                size="small"
                icon="trash"
                onClick={() => console.log("canceling inovice")}
              ></Button>
            </div>
          </div>

          <Segment style={{ columnGap: "10px" }} className="flex--between">
            <Description
              label="NETTO total: "
              text={`${invoice.NETTO_total && invoice.NETTO_total.toFixed(2)} ${
                invoice.currency
              }`}
            />
            <Description
              label="VAT total: "
              text={`${invoice.VAT_total && invoice.VAT_total.toFixed(2)} ${
                invoice.currency
              }`}
            />

            <Description
              label="BRUTTO total: "
              text={`${
                invoice.BRUTTO_total && invoice.BRUTTO_total.toFixed(2)
              } ${invoice.currency}`}
            />
          </Segment>

          <div
            className="flex--between"
            style={{ margin: "5px", rowGap: "8px", columnGap: "8px" }}
          >
            <Segment
              name="supplier"
              loading={updatingSegment == "supplier"}
              padded
              style={{ maxWidth: "440px", width: "400px", marginTop: "14px" }}
            >
              <Header>Supplier</Header>
              <Divider></Divider>
              <div>
                <section>
                  <Description
                    label="Issuer: "
                    text={
                      invoice.businessclient && (
                        <Link
                          to={`/businessclients/${invoice.businessclient._id}`}
                        >
                          {invoice.businessclient.name}
                        </Link>
                      )
                    }
                  />
                  {/* <Description label="Currency: " text={invoice.currency} /> */}
                  <tr id="currency " style={{ height: "33px" }}>
                    <td style={{ width: "100px", fontWeight: "bold" }}>
                      Currency
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {updateField.name === "currency" && (
                        <div>
                          <CurrencyFilter
                            value={updateField.value}
                            handleChange={(value) => {
                              setShowUpdateField({ ...updateField, value });
                            }}
                          />
                        </div>
                      )}
                      {updateField.name !== "currency" && invoice.currency}
                    </td>
                    <td style={{ textAlign: "right", width: "150px" }}>
                      {updateField.name === "currency" && (
                        <div>
                          <Button
                            size="mini"
                            basic
                            color="red"
                            onClick={() => {
                              setShowUpdateField({ name: null });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="mini"
                            basic
                            color="green"
                            onClick={() => handleUpdateField("address")}
                          >
                            Save
                          </Button>
                        </div>
                      )}

                      {updateField.name !== "currency" && (
                        <Button
                          icon="edit"
                          basic
                          size="mini"
                          onClick={() => {
                            setShowUpdateField({
                              name: "currency",
                              value: invoice.currency,
                            });
                          }}
                        ></Button>
                      )}
                    </td>
                  </tr>
                  <Description
                    label="Credit note: "
                    text={
                      invoice.creditNote && (
                        <Link to={`/creditnote/${invoice.creditNote._id}`}>
                          {invoice.creditNote.sequenceNumber}
                        </Link>
                      )
                    }
                  />
                </section>

                <table>
                  <tr id="IBAN" style={{ height: "33px" }}>
                    <td style={{ width: "100px", fontWeight: "bold" }}>IBAN</td>
                    <td style={{ textAlign: "left" }}>
                      {updateField.name === "IBAN" && (
                        <div>
                          <Input
                            value={updateField.value}
                            style={{ height: "25px" }}
                            onChange={(e, { value }) => {
                              setShowUpdateField({ ...updateField, value });
                            }}
                          ></Input>
                        </div>
                      )}
                      {updateField.name !== "IBAN" && invoice.IBAN}
                    </td>
                    <td style={{ textAlign: "right", width: "160px" }}>
                      {updateField.name === "IBAN" && (
                        <div>
                          <Button
                            size="mini"
                            basic
                            color="red"
                            onClick={() => {
                              setShowUpdateField({ name: null });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="mini"
                            basic
                            color="green"
                            onClick={() => handleUpdateField("address")}
                          >
                            Save
                          </Button>
                        </div>
                      )}

                      {updateField.name !== "IBAN" && (
                        <Button
                          icon="edit"
                          basic
                          size="mini"
                          onClick={() => {
                            setShowUpdateField({
                              name: "IBAN",
                              value: invoice.IBAN,
                            });
                          }}
                        ></Button>
                      )}
                    </td>
                  </tr>

                  <tr id="issuedDate" style={{ height: "33px" }}>
                    <td style={{ width: "100px", fontWeight: "bold" }}>
                      Issue Date
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {updateField.name === "dates.issuedDate" && (
                        <div>
                          <DatePicker
                            style={{ width: "200px" }}
                            oneTap
                            // placeholder="select"
                            value={updateField.value}
                            format="DD-MM-YYYY"
                            // ranges={[dateFrom,dateTo]}
                            onChange={(value) =>
                              setShowUpdateField({
                                name: "dates.issuedDate",
                                value,
                              })
                            }
                          />
                        </div>
                      )}
                      {updateField.name !== "dates.issuedDate" && (
                        <Moment
                          //  className="small--text"
                          format="DD-MM-YY HH:mm"
                          style={{ display: "block" }}
                        >
                          {invoice.dates.issuedDate}
                        </Moment>
                      )}
                    </td>
                    <td style={{ textAlign: "right", width: "150px" }}>
                      {updateField.name === "dates.issuedDate" && (
                        <div>
                          <Button
                            size="mini"
                            basic
                            color="red"
                            onClick={() => {
                              setShowUpdateField({ name: null });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="mini"
                            basic
                            color="green"
                            onClick={() => handleUpdateField("supplier")}
                          >
                            Save
                          </Button>
                        </div>
                      )}

                      {updateField.name !== "dates.issuedDate" && (
                        <Button
                          icon="edit"
                          basic
                          size="mini"
                          onClick={() => {
                            setShowUpdateField({
                              name: "dates.issuedDate",
                              value: invoice.dates.issuedDate,
                            });
                          }}
                        ></Button>
                      )}
                    </td>
                  </tr>
                  <tr id="taxDate" style={{ height: "33px" }}>
                    <td style={{ width: "100px", fontWeight: "bold" }}>
                      Tax Date
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {updateField.name === "dates.taxDate" && (
                        <div>
                          <DatePicker
                            style={{ width: "200px" }}
                            oneTap
                            // placeholder="select"
                            value={updateField.value}
                            format="DD-MM-YYYY"
                            // ranges={[dateFrom,dateTo]}
                            onChange={(value) =>
                              setShowUpdateField({
                                name: "dates.taxDate",
                                value,
                              })
                            }
                          />
                        </div>
                      )}
                      {updateField.name !== "dates.taxDate" && (
                        <Moment
                          //  className="small--text"
                          format="DD-MM-YY HH:mm"
                          style={{ display: "block" }}
                        >
                          {invoice.dates.taxDate}
                        </Moment>
                      )}
                    </td>
                    <td style={{ textAlign: "right", width: "150px" }}>
                      {updateField.name === "dates.taxDate" && (
                        <div>
                          <Button
                            size="mini"
                            basic
                            color="red"
                            onClick={() => {
                              setShowUpdateField({ name: null });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="mini"
                            basic
                            color="green"
                            onClick={() => handleUpdateField("supplier")}
                          >
                            Save
                          </Button>
                        </div>
                      )}

                      {updateField.name !== "dates.taxDate" && (
                        <Button
                          icon="edit"
                          basic
                          size="mini"
                          onClick={() => {
                            setShowUpdateField({
                              name: "dates.taxDate",
                              value: invoice.dates.taxDate,
                            });
                          }}
                        ></Button>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            </Segment>
            <Segment
              name="address"
              loading={updatingSegment == "address"}
              style={{ maxWidth: "440px", width: "400px", marginTop: "14px" }}
            >
              <div className="flex--between">
                <Header>Client ShipTo Address</Header>
              </div>

              <Divider></Divider>
              <table>
                <tr id="companyName" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Company Name
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "customer.companyName" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "customer.companyName" &&
                      invoice.customer.companyName}
                  </td>
                  <td style={{ textAlign: "right", width: "160px" }}>
                    {updateField.name === "customer.companyName" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "customer.companyName" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "customer.companyName",
                            value: invoice.customer.companyName,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="taxId" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Tax Id</td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "customer.taxId" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "customer.taxId" &&
                      invoice.customer.taxId}
                  </td>
                  <td style={{ textAlign: "right", width: "160px" }}>
                    {updateField.name === "customer.taxId" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "customer.taxId" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "customer.taxId",
                            value: invoice.customer.taxId,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="fistName" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    First name
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "customer.firstName" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "customer.firstName" &&
                      invoice.customer.firstName}
                  </td>
                  <td style={{ textAlign: "right", width: "160px" }}>
                    {updateField.name === "customer.firstName" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "customer.firstName" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "customer.firstName",
                            value: invoice.customer.firstName,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="lastName" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Last name
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "customer.lastName" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "customer.lastName" &&
                      invoice.customer.lastName}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "customer.lastName" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "customer.lastName" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "customer.lastName",
                            value: invoice.customer.lastName,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="phone" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Phone</td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.phone" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.phone" &&
                      invoice.shipTo.phone}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.phone" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.phone" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.phone",
                            value: invoice.shipTo.phone,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="country " style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Country
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.country" && (
                      <div>
                        <CountryFilter
                         useFilter={false}
                          value={updateField.value}
                          handleChange={(value) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        />
                      </div>
                    )}
                    {updateField.name !== "shipTo.country" &&
                      invoice.shipTo.country}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.country" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.country" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.country",
                            value: invoice.shipTo.country,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="province " style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Province
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.province" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.province" &&
                      invoice.shipTo.province}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.province" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.province" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.province",
                            value: invoice.shipTo.province,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="city" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>City</td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.city" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.city" && invoice.shipTo.city}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.city" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.city" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.city",
                            value: invoice.shipTo.city,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="street" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Street</td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.street" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.street" &&
                      invoice.shipTo.street}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.street" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.street" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.street",
                            value: invoice.shipTo.street,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>

                <tr id="streetNr" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Street Nr
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.streetNr" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.streetNr" &&
                      invoice.shipTo.streetNr}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.streetNr" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.streetNr" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.streetNr",
                            value: invoice.shipTo.streetNr,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>
                <tr id="postcode" style={{ height: "33px" }}>
                  <td style={{ width: "100px", fontWeight: "bold" }}>
                    Post code
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {updateField.name === "shipTo.postcode" && (
                      <div>
                        <Input
                          value={updateField.value}
                          style={{ height: "25px" }}
                          onChange={(e, { value }) => {
                            setShowUpdateField({ ...updateField, value });
                          }}
                        ></Input>
                      </div>
                    )}
                    {updateField.name !== "shipTo.postcode" &&
                      invoice.shipTo.postcode}
                  </td>
                  <td style={{ textAlign: "right", width: "150px" }}>
                    {updateField.name === "shipTo.postcode" && (
                      <div>
                        <Button
                          size="mini"
                          basic
                          color="red"
                          onClick={() => {
                            setShowUpdateField({ name: null });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="mini"
                          basic
                          color="green"
                          onClick={() => handleUpdateField("address")}
                        >
                          Save
                        </Button>
                      </div>
                    )}

                    {updateField.name !== "shipTo.postcode" && (
                      <Button
                        icon="edit"
                        basic
                        size="mini"
                        onClick={() => {
                          setShowUpdateField({
                            name: "shipTo.postcode",
                            value: invoice.shipTo.postcode,
                          });
                        }}
                      ></Button>
                    )}
                  </td>
                </tr>
              </table>
            </Segment>
          </div>

          {/* <div className='flex--between' style={{marginTop:"15px"}}>
 <div></div>
     

</div> */}

          <Segment padded loading={isLoading}>
            <div className="flex--between" style={{ marginBottom: "20px" }}>
              <Header as="h4">Items</Header>
              <Button basic size="small" onClick={() => setModalOpen(true)}>
                Add Item
              </Button>
            </div>

            <ItemsList
              items={invoice.items}
              // isPaid={isPaid}
              removeItemAction={removeItemAction}
            />
          </Segment>

          {/* <Documents
          businessclient={invoice.businessclient._id}
          trigger="Invoice"
          triggerId={id}
        ></Documents> */}
        </main>
      </div>
    </CanViewPage>
  );
}

export default Viewinvoice;

const Description = ({ label, text, children }) => (
  <div className="bill-description">
    <span className="bill-description__label">{label}</span>
    <span className="bill-description__text">{text}</span>
    {children && (
      <div className="bill-description bill-subdescription">{children}</div>
    )}
  </div>
);
