import React from "react";
import { Table, Header, Icon, Button, Input } from "semantic-ui-react";
// import Moment from "react-moment";
import Modal from "react-responsive-modal";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";

import { updatePickingList } from "../../../../actions/picking";

import PickedTablePrint from "../PickedTablePrint";

class ModalForEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPicking: false,
      updates: {}
    };

    this.handleUpdateList = this.handleUpdateList.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleUpdateList() {
    // let orders = [];
    // if (this.props.selection.length > 0) {
    //   for (const selection of this.props.selection) {
    //     orders = [...orders, ...selection.orders];
    //   }
    // }
    // this.props.updateOrdersStatusPicking(orders);
    // // tick each selection if server stat ok
    this.props.updatePickingList(this.props._id, this.state.updates);
  }
  handleChangeInput(e, { name, value }) {
    this.setState(prevState => ({
      updates: { ...prevState.updates, [name]: Number(value) }
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.successUpdateStatus && !prevProps.successUpdateStatus) {
      this.setState({ isPicking: true });
    }
  }

  render() {
    const { modalIsOpen, closeModal, pickingList } = this.props;

    return (
      <Modal
        open={modalIsOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{
          modal: ""
        }}
      >
        <div className="modal-wrapper">
          {/* Header */}
          <Header textAlign="center" style={{ marginBottom: "1.8em" }} as="h4">
            Update Picking List
          </Header>

          {/* Table */}
          {/* <PickedTable item={pickingList} /> */}

          <Table celled fixed unstackable className="fixedHTable">
            <thead>
              <tr>
                <th>Product</th>
                <th>SKU</th>
                <th>Position</th>
                <th>#Products</th>
                <th>#Pick (old)</th>
                <th>#Pick (new)</th>
              </tr>
            </thead>
            <tbody>
              {pickingList.products.map(
                ({ product, inhouse_position, quantity, orders, _id }) => (
                  <tr>
                    <td>{product && product.name}</td>
                    <td>{product && product.codes && product.codes.sku}</td>
                    <td>{inhouse_position}</td>
                    <td>{quantity}</td>
                    <td>{orders.length}</td>
                    <td>
                      <Input
                        type="number"
                        min="0"
                        max={orders.length}
                        fluid
                        //   id={product._id}
                        name={_id}
                        onChange={this.handleChangeInput}
                        value={
                          this.state.updates[_id] ||
                          this.state.updates[_id] === 0
                            ? this.state.updates[_id]
                            : orders.length
                        }
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>

          {/*  Buttons */}
          <div className="flex_alignRight" style={{ marginTop: "3em" }}>
            <Button
              color="yellow"
              disabled={this.state.isPicking}
              onClick={this.handleUpdateList}
            >
              Save Updates
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button disabled={!this.state.isPicking}>
                  <Icon name="print" />
                  Print out!
                </Button>
              )}
              content={() => this.componentRef}
            />

            {/* Printed Table */}
            <div style={{ display: "none" }}>
              <PickedTablePrint ref={el => (this.componentRef = el)} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (reduxState, ownProps) => ({
  pickingList: reduxState.picking.listOfPicked.find(
    item => item._id === ownProps._id
  ),
  successUpdateStatus: reduxState.picking.successUpdateStatus
});

export default connect(mapStateToProps, { updatePickingList })(ModalForEdit);
