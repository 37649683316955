import React, { useState } from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import ReportModal from "./ReportModal";
import ReportList from "./ReportList";

const initialReportForm = {
  name: "",
  reportId:'',
  pairedKey: "clientOrderNr",
  columnPosition: "1",
};

function ReportTemplates({ courierId,reportId }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModeOn, setEditMode] = useState(false);
  const [initReportFormValues, setinitReportFormValues] = useState(
    initialReportForm
  );

  const openModal = (edit = false, report = initialReportForm) => {
    setModalOpen(true);
    setEditMode(edit);
    setinitReportFormValues(report);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
 
  return (
    <section>
      <div className="flex--between vertical--center mt--2">
        <Header as="h2">Reports Templates</Header>
        <Button compact onClick={() => openModal(false)}>
          Add New
        </Button>
      </div>
      <Segment padded>
        <ReportList courierId={courierId} openEditModal={openModal} />
      </Segment>

      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{
          modal: "",
        }}
      >
        <ReportModal
          
          initReportFormValues={initReportFormValues}
          handleCloseModal={closeModal}
          isEditModeOn={isEditModeOn}
          courierId={courierId}
          reportId={reportId}
        />
      </Modal>
    </section>
  );
}

export default ReportTemplates;
