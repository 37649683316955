import React from 'react';
import {Table} from 'semantic-ui-react'
const ProductItem = ({ item, index, onDelete }) => {
  return (
    <Table.Row>
      <td>{index}</td>
      <td>{item.product.codes.sku}</td>
      <td>{item.product.name}</td>
      <td>{item.quantity.toFixed(2)}</td>
      <td>{item.unit_price.toFixed(2)}</td>
      <td>{item.total_price.toFixed(2)}</td>
      <td>
        <button onClick={() => onDelete(item._id)}>Delete</button>
      </td>
    </Table.Row>
  );
};

export default ProductItem;
