import React from "react";
import { Dropdown } from "semantic-ui-react";

function ItemsPerPage({
  value=10,
  setItemsPerPage,
  options = [10, 20, 30, 50, 100,200,"All"],
  style = {},
  selection = true,
  disabled=false
}) {
  //   Array of options for Number of items displayed per page
  const optionsItemsPrePage = options.map(nr => ({
    key: nr,
    value: nr,
    text: nr
  }));

  return (
    <Dropdown
      disabled={disabled}
      selectOnBlur={false}
      closeOnChange={true}
      compact
      selection={selection}
      name="itemsPerPage"
      value={value}
      onChange={(e,{value})=>setItemsPerPage(value)}
      options={optionsItemsPrePage}
      style={style}
    />
  );
}

export default ItemsPerPage;
