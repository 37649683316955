export const dataSet = [
  {
    name: "Shoes",
    sku: 2306,
    barcode: 12345678,
    color: "black",
    size: "M",
    'proprities.weight': 200,
    box_units:10,
    box_height:44,
    box_lenght:30,
    box_width:11

  }
];
export const colNames = [
  { label: "Name", value: "name" },
  { label: "SKU", value: "sku" },
  { label: "Barcode", value: "barcode" },
  { label: "Color", value: "color" },
  { label: "Size", value: "size" },
  { label: "Weight", value: "proprities.weight" },
  { label: "box_units", value: "box_units" },
  { label: "box_height", value: "box_height" },
  { label: "box_lenght", value: "box_lenght" },
  { label: "box_width", value: "box_width" }
];
