import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

import { getCompanies } from "../../actions/company";

function SellerFilter({
  value,
  handleChange,
  client,
  multiple,  
}) {
  const [options, setOptions] = useState([]);
  // const [item,setItem] = useState()

  useEffect(() => {
    if (client) getCompanies({client}).then(list=>setOptions(list))
  }, [client]);

  return (
    <Dropdown
      
      placeholder="seller..."
      name="seller"
      value={value}
      multiple={multiple}
      disabled={!client}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={(e,{value})=>handleChange(value)}
      options={options.map(item=>({value:item._id,text:item.name,key:item._id}))}
      // value: _id,
      // text: name,
      // key: _id
    />
  );
}

export default (SellerFilter);
