import React, { useState } from "react";
import {
  Button,
  Segment,
  Input,
  Divider,
  Header,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BusinessclientFilter from '../../../Filters/BusinessclientFilter'

import {getFilteredProductList} from "../../../../actions/product";
import {createPromo} from '../../../../actions/promo'

import CanViewPage from "../../../../role-based-access/CanViewPage";

import CountryFilters from '../../../Filters/CountryFilter'
import CurrencyFilter from '../../../Filters/CurrencyFilter'

import "../../productGroup.scss";

function CreatePromo(props) {

  const { createPromo, history } = props;

  
  
  const [name, setName] = useState("");
  const [businessclient,setClient] = useState("")  
  const [country,setCountry]  = useState()
  const [currency,setCurrency]  = useState()

  const handleNameChange = (e, { value }) => setName(value);

  const handleSubmit = e => e.preventDefault();
  const handleCreate = () => createPromo(history, { name,businessclient,country,currency });

  return (
    <CanViewPage path="/promos/create">
      <main className="a-wrapper product-group-container">
        <Link to="/products/list/relatedproducts">
          <Icon name="arrow left" /> Product group List
        </Link>

        <Segment>
          <div
            className="flex--between vertical--bottom"
            style={{ padding: "1em 1.3em 0em" }}
          >
            <h1>Create new product group</h1>

            <Button color="green" onClick={handleCreate}>
              Create Group
            </Button>
          </div>
          <Divider
            style={{
              marginLeft: "1.3em",
              marginRight: "1.3em",
              marginBottom: "0em",
            }}
          />

              <Header> Product-group name</Header>
           
              <form autocomplete="off" onSubmit={handleSubmit} className ='flex--left' >
               
                <Input
                  name="name"
                  placeholder="product-group name..."
                  focus
                  onChange={handleNameChange}
                  style={{marginRight:'6px'}}
                />
            
            <div style={{marginRight:'6px'}}>
              <BusinessclientFilter
                  
                  value={businessclient}
                  handleChange={(e, { value }) => setClient(value)}
                />
              
            </div>
            <div style={{marginRight:'6px'}}>
              <CountryFilters
                    value ={country}
                    handleChange = {(e,{value})=>setCountry(value)}
              ></CountryFilters>
            </div>
             
            
           
            
             <CurrencyFilter
                value ={currency}
                handleChange={(e,{value})=>setCurrency(value)} 
             ></CurrencyFilter>
          </form>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default connect(null, {
  createPromo,
  getFilteredProductList,
})(CreatePromo);
