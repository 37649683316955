import { useState } from "react";

export function useTableCheckbox(items) {
  // items = table list
  const [isHeaderChecked, setHeaderChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleSingleCheck = (id) => {
    setCheckedItems((prevState) => {
      if (prevState.includes(id))
        return prevState.filter((item) => item !== id);
      else return [...prevState, id];
    });
  };

  const handleClearCheckedItems = () => {
    setHeaderChecked(false);
    setCheckedItems([]);
  };

  const handleMultipleCheck = (condition) => {
    if (!isHeaderChecked) {
      setHeaderChecked(true);
      let itemstoCheck = condition
        ? items.filter(
            (item) =>
              item[Object.keys(condition)[0]] === Object.values(condition)[0]
          )
        : items;
      setCheckedItems(itemstoCheck.map((item) => item._id));
    } else {
      handleClearCheckedItems();
    }
  };

  return {
    isHeaderChecked,
    checkedItems,
    handleSingleCheck,
    handleMultipleCheck,
    handleClearCheckedItems,
  };
}
