import React, { useEffect, useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import { getServiceTypes,createServiceType,deleteServiceType } from "../../actions/servicetypes";
import ItemsPerPage from "../ItemsPerPage";
import Pagination from "../Filters/Pagination";
import ServiceTypesTable from "./ServiceTypesTable";
import Filters from "./Filters";
import ServiceType from './Servicetype'
import "./services.scss";
// import servicetypes from "../../reducers/servicetypes";
import {Alert} from 'rsuite'

function ServiceTypes(props) {

  const [itemsPerPage,setItemsPerPage]=useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [onlyRelative,setOnlyRelative] = useState('')
  const [country,setCountry] = useState('')
  const [onlyShipping,setOnlyShipping]=useState('')  
  const [list,setList] = useState([])
  const [isLoading,setLoading] = useState(false)
  const [total,setTotal] =useState(100)
  const [refresh,setRefresh] = useState(false)
  // const {
  //   isHeaderChecked,
  //   checkedItems,
  //   handleSingleCheck,
  //   handleMultipleCheck,
  //   handleClearCheckedItems,
  // } = useTableCheckbox(servicetypes);


  useEffect(()=>{
    setLoading(true)
    getServiceTypes({
      country,onlyRelative,onlyShipping
    }).then(resp=>{
      setLoading(false)
      setList(resp)})
      .catch(err=>Alert.error(err))
  },[onlyRelative,onlyShipping,country,refresh])  

  const handleItemsPerPage = ({ value }) => {
    setItemsPerPage(value);
    // handleClearCheckedItems();
  };

  const handlePageChange = ({ currentPage }) => {
    setCurrentPage( currentPage );
    // handleClearCheckedItems();
  };

  // const { dateFrom, dateTo, status } = filters;
  // const [fileName, setFileName] = useState("services");

  // useEffect(
  //   () =>
  //     setFileName(
  //       `services${dateFrom ? " - " + dateFrom.toLocaleDateString() : ""}${
  //         dateTo ? " - " + dateTo.toLocaleDateString() : ""
  //       }${status === "false" ? " - not billed" : ""}${
  //         status === "true" ? " - billed" : ""
  //       }`
  //     ),
  //   [dateFrom, dateTo, status]
  // );


  return (
    // <CanViewPage path="/services">
    <main className="page-wrapper">
      <div class="flex--between">
        <Header>Service types</Header>

        <ServiceType createServiceType={createServiceType}></ServiceType>
      </div>

      <Filters
        onlyRelative={onlyRelative}
        setOnlyRelative={setOnlyRelative}
        onlyShipping={onlyShipping}
        setOnlyShipping={setOnlyShipping}
        country={country}
        setCountry={setCountry}
      />

      <Segment>
        <div className="flex--right">
          {/* <ExportButton
              fileName={fileName}
              total={total}
              filters={filters}
              // mapData={mapData}
              url="/services/export"
            /> */}
          <ItemsPerPage
            style={{ marginLeft: "10px" }}
            total={total}
            value={itemsPerPage}
            handleChange={handleItemsPerPage}
          />
        </div>

        <ServiceTypesTable
          deleteServiceType={(id) =>
            deleteServiceType(id).then((resp) => setRefresh(!refresh))
          }
          services={list}
          isLoading={isLoading}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          // isHeaderChecked={isHeaderChecked}
          // checkedItems={checkedItems}
          // handleSingleCheck={handleSingleCheck}
          // handleMultipleCheck={handleMultipleCheck}
        />
        {total > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            total={total}
            itemsPerPage={itemsPerPage}
            updateFilter={handlePageChange}
          />
        )}
      </Segment>
    </main>
    // </CanViewPage>
  );
}

export default (ServiceTypes);
