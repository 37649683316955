import React, { useState } from "react";
import { Input, Button, Header } from "semantic-ui-react";

import CountryFilter from "../../../Filters/CountryFilter";

const requieredFields = ["country", "type"];

function SequencesModal({
  initFormValues,
  isEditModeOn,
  handleClose,
  edit,
  create,
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const [touched, setTouched] = useState([]);
  const [validationError, setValidationError] = useState(false);

  const handleChange = (e, d) => {
    const { name, value } = d;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setTouched((prev) => [...prev, name]);
  };

  const handleAction = () => {
    const hasEmptyRequierdField = requieredFields.find(
      (field) => !formValues[field]
    );
    if (hasEmptyRequierdField) {
      setValidationError(true);
      return;
    }
    const query = {};
    for (let field of touched) {
      query[field] = formValues[field];
    }
    isEditModeOn
      ? edit({ id: formValues._id, update: query })
      : create(formValues);
    handleClose();
  };

  return (
    <section>
      <Header as="h4">
        {!isEditModeOn ? "Add new" : "Update"} courier sequences:
        <p className="small--text grey--text">[identifier][possitions+start]</p>
      </Header>
      <div className="quotations-modal-grid">
        <div className="quotations-modal-label-input">
          <label>Name:</label>
          <Input
            type="text"
            placeholder="ex:Issued invoices..."
            value={formValues.name}
            name="name"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
            Country: <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <CountryFilter
            style={{maxWidth:"200px"}}
            useFilter={false}
            value={formValues.country}
            handleChange={(value) =>
              handleChange(null, { value, name: "country" })
            }
            error={validationError && !formValues.country}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>
            Currency:{" "}
            <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <Input
            type="text"
            placeholder="EUR RON CZK..."
            value={formValues.currency}
            name="currency"
            onChange={handleChange}
            error={validationError && !formValues.type}
          />
        </div>
        <div className="quotations-modal-label-input">
          <label>
            Type: <span className="new-ticket-form__required-symbol">*</span>
          </label>
          <Input
            type="text"
            placeholder="ex:Invoice,Bill..."
            value={formValues.type}
            name="type"
            onChange={handleChange}
            error={validationError && !formValues.type}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>Uniq Identifier:</label>
          <Input
            type="number"
            min="0"
            value={formValues.identifier}
            name="identifier"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>Start at:</label>
          <Input
            type="number"
            min="0"
            value={formValues.value}
            name="value"
            onChange={handleChange}
          />
        </div>

        <div className="quotations-modal-label-input">
          <label>Positions:</label>
          <Input
            type="number"
            min="0"
            value={formValues.positions}
            name="positions"
            onChange={handleChange}
          />
        </div>
      </div>
      {validationError && (
        <span className="user-form__error-msg">
          Fill in the required fields!
        </span>
      )}
      <Button
        floated="right"
        primary
        content={!isEditModeOn ? "Create" : "Update"}
        onClick={() => handleAction({ formValues })}
      />
      <Button floated="right" content="Cancel" onClick={handleClose} />
    </section>
  );
}

export default SequencesModal;
