import React from "react";
// import { TieredMenu } from "primereact/tieredmenu";
import { Menu, Dropdown, } from "semantic-ui-react";

import { useExcelOrderData } from "./useExcelOrderData";

// import "primereact/resources/themes/nova-light/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";

function OrdersListMenu(props) {
  const {
    checkedItems = [],
    orders,
    openChangeStatusModal,
    openChangeStatusModalFromInput,
    openShipFromWarehouseModal,
    openAssignUserModal,
    toggleAssignCourierModal,
    manualAssing,
  } = props;

  const { createExcelFile } = useExcelOrderData(
    orders,
    checkedItems,
    "orders-selected"
  );
  // const items = [
  //   {
  //     label: `Action ${
  //       checkedItems.length ? "( " + checkedItems.length + " )" : ""
  //     }`,
  //     items: [
  //       {
  //         label: "Change status",
  //         icon: "pi pi-fw pi-cog",
  //         items: [
  //           {
  //             label: "Selected",
  //             icon: "pi pi-fw pi-check-square",
  //             command: () => openChangeStatusModal({ isDnDdisplayed: false }),
  //             disabled: !checkedItems.length,
  //           },
  //           {
  //             label: "From Input",
  //             icon: "pi pi-fw pi-copy",
  //             command: () =>
  //               openChangeStatusModalFromInput({ isDnDdisplayed: true }),
  //           },
  //           {
  //             label: "From file",
  //             icon: "pi pi-fw pi-file-excel",
  //             command: () => openChangeStatusModal({ isDnDdisplayed: true }),
  //           },
  //         ],
  //       },

  //       // {
  //       //   label:"Flag as",
  //       //   icon:'pi pi-fw pi-bookmark',
  //       //   items:[
  //       //     {
  //       //       label:"Reported",
  //       //     icon:'pi pi-plus-circle',
  //       //     command:() => openChangeStatusModal({ isDnDdisplayed: true })
  //       //   },
  //       //     {
  //       //     label:"Un-Reported",
  //       //     icon:'pi pi-minus-circle',
  //       //     command:() => openChangeStatusModal({ isDnDdisplayed: true })
  //       //   },
  //       //   ]
  //       // },
  //       {
  //         label: `Export ${
  //           checkedItems.length
  //             ? "( " + checkedItems.length + " )"
  //             : "selection"
  //         }`,
  //         icon: "pi pi-fw pi-file-excel",
  //         command: createExcelFile,
  //         disabled: !checkedItems.length,
  //       },
  //       {
  //         label: "Assign packer",
  //         icon: "pi pi-fw pi-user-edit",
  //         command: () => openAssignUserModal(),
  //         disabled: !checkedItems.length,
  //       },
  //       {
  //         label: "Unssign packer",
  //         icon: "pi pi-fw pi-user-minus",
  //         command: () => manualAssing({ orderIds: checkedItems, toUser: "" }),
  //         disabled: !checkedItems.length,
  //       },
  //       {
  //         label: "Ship From",
  //         icon: "pi pi-fw pi-sign-in",
  //         command: () => {
  //           openShipFromWarehouseModal({ isDnDdisplayed: true });
  //         },
  //         disabled: !checkedItems.length,
  //       },
  //       {
  //         label: "Set Courier",
  //         icon: "pi pi-fw pi-sign-in",
  //         command: () => {
  //           toggleAssignCourierModal();
  //         },
  //         disabled: !checkedItems.length,
  //       },
  //     ],
  //   },
  // ];

  return (
    <div>
      <Menu
      // vertical
      >
        <Dropdown
          item
          text={`Actions  ${
            checkedItems.length > 0 ? checkedItems.length : ""
          }`}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={false}
              icon="edit"
              text="Change Status -  From Input"
              onClick={()=> openChangeStatusModalFromInput()}
            />

            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="edit"
              text={`Change Status`}
              onClick={()=>openChangeStatusModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="file"
              text={`Export selection (${checkedItems.length})`}
              onClick={()=>createExcelFile()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="user"
              text="Assign packer"
              onClick={()=>openAssignUserModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="remove user"
              text="Un-Assign packer"
              onClick={()=>manualAssing({ orderIds: checkedItems, toUser: "" })}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="warehouse"
              text="Change Ship From"
              onClick={()=>openShipFromWarehouseModal()}
            />
            <Dropdown.Item
              disabled={checkedItems.length === 0}
              icon="shipping"
              text="Change Courier"
              onClick={()=>toggleAssignCourierModal()}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </div>
  );
}

export default OrdersListMenu;
