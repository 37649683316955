import React, { useState } from 'react';
import Select from 'react-select';
import * as XLSX from 'xlsx';

const ExcelTemplateBuilder = () => {
  const [columns, setColumns] = useState([]);
  const [columnName, setColumnName] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [sourceValue, setSourceValue] = useState('');

  const sourceOptions = [
    { value: 'static', label: 'Static Value' },
    { value: 'objectKey', label: 'Object Key' }
  ];

  const addColumn = () => {
    setColumns([...columns, { columnName, sourceType, sourceValue }]);
    setColumnName('');
    setSourceType('');
    setSourceValue('');
  };

  const handleSourceChange = (selectedOption) => {
    setSourceType(selectedOption.value);
  };

  const handleExport = () => {
    const data = columns.map(column => ({
      [column.columnName]: column.sourceType === 'static' ? column.sourceValue : `{${column.sourceValue}}`
    }));
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'template.xlsx');
  };

  return (
    <div>
      <h2>Create Excel Template</h2>
      <div>
        <input 
          type="text" 
          placeholder="Column Name" 
          value={columnName}
          onChange={(e) => setColumnName(e.target.value)} 
        />
        <Select 
          options={sourceOptions}
          value={sourceOptions.find(option => option.value === sourceType)}
          onChange={handleSourceChange}
        />
        <input 
          type="text" 
          placeholder="Source Value" 
          value={sourceValue}
          onChange={(e) => setSourceValue(e.target.value)} 
          disabled={!sourceType}
        />
        <button onClick={addColumn}>Add Column</button>
      </div>
      <div>
        <h3>Columns</h3>
        <ul>
          {columns.map((col, index) => (
            <li key={index}>
              {col.columnName} - {col.sourceType === 'static' ? col.sourceValue : `{${col.sourceValue}}`}
            </li>
          ))}
        </ul>
      </div>
      <button onClick={handleExport}>Export to Excel</button>
    </div>
  );
};

export default ExcelTemplateBuilder;
