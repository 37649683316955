import React, { useState, useEffect } from "react";
import { Segment, Header, Button, Pagination,Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  getAllInventory,
  // searchSku,
  // updateFilter,
} from "../../actions/inventory";
import { getBalanceAtDate } from "../../actions/warehouses";

import CanViewPage from "../../role-based-access/CanViewPage";
import CanView from "../../role-based-access/CanView";
import ItemsPerPage from "../ItemsPerPage";
import Inventorycount from "./inventorycount";
import ExportButton from "./ExportButton";
import { Inventorylist } from "./inventorylist";
import Filters from "./filters";
import "./inventory.scss";
import Responsive from "../Responsive.jsx";
import CardsList from "../CardsList.jsx";
import moment from "moment";
import { DatePicker } from "rsuite";
import { useUpdateContext } from "../../context/UpdateContext";



const Stocks = () => {
  const [inventory, setInventory] = useState([]);
  const [productSKU, setProductSku] = useState();
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [atDate, setDateAt] = useState(new Date());
  const [balanceView, setBalanceView] = useState(false);
  const [currentPage, setCurrentpage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortTotal, setSortTotal] = useState(-1);
  const [reload, setReload] = useState(false);

  const { businessclient, warehouse, product } = useUpdateContext();

  const [state, setState] = useState({
    showModalCount: false,
    checkedItems: [],
    headerIsChecked: false,
  });

  const showModal = () => setState({ showModalCount: !state.showModalCount });

  useEffect(() => {
    // console.log('ping',balanceView)
    setIsloading(true);

    if (!balanceView)
      getAllInventory({
        businessclient,
        product,
        warehouse,
        itemsPerPage,
        currentPage,
        sortTotal,
        productSKU,
      })
        .then((resp) => {
          setIsloading(false);
          setInventory(resp.list);
          setTotals(resp.totals);
        })
        .catch((error) => {
          setIsloading(false);
          setInventory([]);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    warehouse,
    businessclient,
    product,
    balanceView,
    itemsPerPage,
    currentPage,
    sortTotal,
    reload,
  ]);

  useEffect(() => {
    const today = new Date();
    const time1 = moment(today).format("YYYY-MM-DD");
    const time2 = moment(atDate).format("YYYY-MM-DD");

    if (time1 !== time2) {
      setBalanceView(true);
      if (inventory) {
        const products = inventory.map((item) => {
          if (item.product_details) return item.product_details._id;
          return null;
        });
        setIsloading(true);
        getBalanceAtDate({
          products,
          warehouse,
          atDate,
        }).then((resp) => {
          setInventory(resp.list);
          setTotals(resp.totals);
          setIsloading(false);
        });
      }
    }
  }, [atDate, warehouse, inventory]);

  return (
    <CanViewPage path="/warehouse">
      <div  style={{maxWidth:'1100px',margin:'20px'}}>
        <div className="flex_spaceBetween">
          <Header as="h3">Stock Balances</Header>
          
          <div className="flex_alignRight">
            <CanView path="/warehouse" action="create new product">
              <Link to="/products/create/new">
                <Button color="blue">+Product</Button>
              </Link>
            </CanView>
            <CanView path="/warehouse" action="inventory count">
              <Button basic onClick={showModal}>
                Inventory Count
              </Button>
            </CanView>
          </div>
        </div>
        <div className="topbar">
          <Inventorycount
            isOpen={state.showModalCount}
            showModal={showModal}
            // errors={errors}
          />
        </div>
        <Divider></Divider>

        <Filters
          setProductSku={setProductSku}
          productSKU={productSKU}
          setReload={setReload}
          reload={reload}
        />

        <Segment padded>
          <div className="flex--between" style={{ marginBottom: "1em" }}>
            <CanView path="/warehouse" action="export">
              <ExportButton
                inventory={inventory}
                filename={`stockbalance_${moment(new Date())}`}
              />
            </CanView>
            <div className="flex--left">
              <div>
                <DatePicker
                  disabled={warehouse.length === 0}
                  style={{ width: "200px", marginRight: "5px" }}
                  oneTap
                  placeholder="date to.."
                  value={atDate}
                  format="DD-MM-YYYY"
                  // ranges={[dateFrom,atDate]}
                  onChange={(atDate) => {
                    if (!atDate) {
                      setDateAt(new Date());
                      setBalanceView(false);
                    } else {
                      setDateAt(atDate);
                      // handleGetBalances()
                    }
                  }}
                />
                {warehouse.length === 0 && (
                  <div className="smallinfo">
                    *select warehouse to activate(beta)
                  </div>
                )}
              </div>

              <ItemsPerPage
                style={{ maxHeight: "30px" }}
                disabled={balanceView}
                total={totals}
                value={itemsPerPage}
                setItemsPerPage={(value) => {
                  // if(d.value==products.total) setCurrentpage(1)
                  setItemsPerPage(value);
                }}
              />
            </div>
          </div>
          <Responsive
            mobileComponent={
              <CardsList
                items={inventory}
                isLoading={isLoading}
                notFoundMsg="Nothing was found"
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              ></CardsList>
            }
            desktopComponent={
              <Inventorylist
                inventory={inventory}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                sortTotal={sortTotal}
                setSortTotal={setSortTotal}
                isLoading={isLoading}
              />
            }
          />

          <Pagination
            style={{ marginTop: "1em" }}
            activePage={currentPage}
            totalPages={Math.ceil(totals / itemsPerPage)}
            boundaryRange={1}
            // siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e, data) => setCurrentpage(data.activePage)}
          />
          {/* )} */}
        </Segment>
      </div>
    </CanViewPage>
  );
};

export default Stocks;
