// import {
//     GET_BUSINESSCLIENTS,
//   } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError, addSuccessMsg } from "./error";

const getFiltersUrl = (filters) =>
  filters
    ? "?" +
      Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";

const startLoading = {
  type: "START_LOADING",
};
const stopLoading = {
  type: "STOP_LOADING",
};

export const updateFilter = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});

export const getShavers = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());

  const filtersUrl = getFiltersUrl(filters);

  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver${filtersUrl}`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_SHAVERS",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

// Settings
export const updateSettingsFilter = (newFilter) => ({
  type: "UPDATE_SETTINGS_FILTER",
  payload: newFilter,
});

export const createShaverSettings = ({ query, history }) => (
  dispatch,
  getState
) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver/settings`;
  apiCall("post", url, query)
    .then((resp) => {
      if (resp._id) {
        const filters = getState().shavers.settingsFilters;
        dispatch(getAllShaversSettings(filters));
        dispatch(addSuccessMsg("The setting was created successfully"));
      }
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getAllShaversSettings = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = getFiltersUrl(filters);
  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver/settings?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_ALL_SHAVERS_SETTINGS",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
export const getShaverSettings = ({ id }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver/settings/${id}`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_SHAVERS_SETTING",
        payload: resp,
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateShaverSettings = ({ id, updates }) => (
  dispatch,
  getState
) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver/settings/${id}`;
  apiCall("put", url, updates)
    .then((resp) => {
      if (resp._id) {
        const filters = getState().shavers.settingsFilters;
        dispatch(getAllShaversSettings(filters));
        dispatch(addSuccessMsg("The setting was updated successfully"));
      }
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
export const deleteShaverSettings = ({ id }) => (dispatch, getState) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_DISTRIBUTOR_SHAVER_API}/shaver/settings/${id}`;
  apiCall("delete", url)
    .then((resp) => {
      if (resp.status === "success") {
        const filters = getState().shavers.settingsFilters;
        dispatch(getAllShaversSettings(filters));
        dispatch(addSuccessMsg("The setting was deleted successfully"));
      }
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
