import React from "react";
import { Header, Table, Dimmer, Loader, Button } from "semantic-ui-react";
// import Moment from "react-moment";
// import { Link } from "react-router-dom";

const skuListLimit = 7;

function RestrictionsTable({
  restrictions,
  isLoading,
  currentPage,
  itemsPerPage,
  openEditModal,
  deleteRestriction,
  history,
}) {
  if (isLoading)
    return (
      <div>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </div>
    );

  if (!restrictions.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No restrictions found
      </Header>
    );
  }

  const renderSku = (skuList) => {
    if (skuList.length < skuListLimit)
      return skuList.map((sku) => <p>{sku}</p>);
    else return skuList.slice(0, skuListLimit).map((sku) => <p>{sku}</p>);
  };

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>BusinessClient</Table.HeaderCell>
      <Table.HeaderCell>SKU Allowed</Table.HeaderCell>
      <Table.HeaderCell>SKU Restricted</Table.HeaderCell>
      <Table.HeaderCell>Currency</Table.HeaderCell>
      <Table.HeaderCell>Limit</Table.HeaderCell>
      <Table.HeaderCell>Total</Table.HeaderCell>
      <Table.HeaderCell>Overlimit</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = restrictions.map(
    (
      {
        _id,
        country,
        sku_allowed,
        sku_restricted,
        businessclient,
        currency,
        total,
        limit12m,
        overlimit,
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{businessclient && businessclient.name}</Table.Cell>
        <Table.Cell>{renderSku(sku_allowed)}</Table.Cell>
        <Table.Cell>{renderSku(sku_restricted)}</Table.Cell>
        <Table.Cell>{currency}</Table.Cell>
        <Table.Cell>
          {limit12m}
          {/* <Moment format="DD-MM-YY HH:mm" style={{ whiteSpace: "pre" }}>
            {toDate}
          </Moment> */}
        </Table.Cell>
        <Table.Cell>{total}</Table.Cell>
        <Table.Cell>{overlimit}</Table.Cell>
        <Table.Cell>
          <Button
            basic
            compact
            color="blue"
            size="tiny"
            icon="pencil"
            onClick={() =>
              openEditModal(true, {
                _id,
                country,
                sku_allowed,
                sku_restricted,
                businessclient: businessclient._id,
                currency,
                total,
                limit12m,
                overlimit,
              })
            }
          />
          <Button
            basic
            compact
            color="red"
            size="tiny"
            icon="trash"
            onClick={() => deleteRestriction({ id: _id, history })}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default RestrictionsTable;
