import React from "react";
import ReactDOM from "react-dom";
// import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { closePopup } from "../actions/notifications";

function PopupWindow({ isOpen, closePopup, children }) {
  const notificationContainer = React.useMemo(() =>
    document.getElementById("notification")
  );
  // console.log({ isOpen, notificationContainer });
  if (isOpen) {
    return ReactDOM.createPortal(
      <div className="notification fade-in notification_info">
        <button className="notification--button" onClick={closePopup}>
          {/* <Icon name="times" /> */}✖
        </button>
        {children}
      </div>,
      notificationContainer
    );
  } else return null;
}

const mapStateToProps = (reduxState) => ({
  isOpen: reduxState.notifications.isPopupOpen,
});
export default connect(mapStateToProps, { closePopup })(PopupWindow);
