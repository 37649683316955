import {
    apiCall
} from "../services/api";

export const getBillableServices = (filter) => {

    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API}/services/billable`;

        apiCall('post', url, filter)
            .then(resp => resolve(resp))
            .catch(err => {
                reject(err.message)
            });
    })


}
export const getUnbilledServices = (filter) => {

    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API}/services/billable/billpreview/notbilled`;

        apiCall('post', url, filter)
            .then(resp => resolve(resp))
            .catch(err => reject(err));
    })


}