import React from "react";
import { Table, Loader, Header ,Checkbox} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

function ServicesQuotationTable({
  serviceQuotations,
  isLoading,
  currentPage,
  itemsPerPage,
  style = {},
  deleteServiceQuotation,
  handleToggle
}) {
  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !serviceQuotations.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
          No services found
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Active</Table.HeaderCell>
      <Table.HeaderCell>Businessclient</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      {/* <Table.HeaderCell>Description</Table.HeaderCell> */}
      <Table.HeaderCell>Details</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Amount</Table.HeaderCell>
      <Table.HeaderCell>Dates</Table.HeaderCell>
      <Table.HeaderCell>...</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = serviceQuotations.map(
    (
      {
        _id,
        isActive,
        businessclient,
        currency,
        serviceType,
        country,
        amount,
        courier,
        rate,
        createdAt, 
        weight_kg,
        product,
        warehouse,
        supplier   ,
        quantity_dependency
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>
            <Checkbox toggle 
                  onChange={()=>{
                    // console.log('sending..',!isActive)
                    handleToggle({id:_id,isActive:!isActive})
                    }}
                  checked={isActive}
            />
          </Table.Cell>
          <Table.Cell>
            {businessclient && (
              <Link to={`/businessclients/${businessclient._id}`}>
                {businessclient.name}
              </Link>
            )}
            {!businessclient &&<div>Standard rate</div>}
          </Table.Cell>
        

        <Table.Cell>{serviceType.name}</Table.Cell>
        {/* <Table.Cell>{weight_kg?
                      `${weight_kg.from} - ${weight_kg.to} kg`
                :''}
                {product?product.name:''}
        </Table.Cell> */}
        <Table.Cell>
          <div>
            <div>{courier?`Courier: ${courier.name}`:''}</div>
            <div>{weight_kg?
                      `Weight: (${weight_kg.from} - ${weight_kg.to} kg)`
                :''}
                {product?product.name:''}</div>
            <div>{warehouse?`Warehouse: ${warehouse.name}`:''}</div>
            <div> {supplier?`Supplier:${supplier.name}`:''}</div>
          </div>
          
          
          </Table.Cell>
        <Table.Cell>{serviceType.billing_type}</Table.Cell>
       <Table.Cell>{country}</Table.Cell>
       <Table.Cell>
                  {rate ? `${rate*100} %`:`${amount} ${currency}`} 
                  <br></br>
                  {quantity_dependency>0?`Quantity in pack>${quantity_dependency}`:''}
          </Table.Cell>
       
        <Table.Cell>
          <>
            <Moment
              className="small--text"
              format="DD-MM-YY HH:mm"
              key={createdAt}
              style={{ display: "block" }}
            >
              {createdAt}
            </Moment>
          </>
        </Table.Cell>
        <Table.Cell>
        <button onClick={()=>deleteServiceQuotation(_id)}>delete</button></Table.Cell>

      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very" style={style} loading={true}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default ServicesQuotationTable;
