import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Alert } from "rsuite";
import { scannedTracking } from "../../actions/driver";
import { Button, Divider, Icon } from "semantic-ui-react";

const found_sound = new Audio("/positive-1.mp3");

function Barcodescanner() {
  // const [action, setAction] = useState();

  const [data, setData] = useState();
  const [scan, setScan] = useState(false);
  const [loading, setLoading] = useState(false);


  const playSound = (audio) => {
    // console.dir(audio.ended);
    // console.dir(audio.currentTime);
    if (!audio.ended) {
      audio.currentTime = 0;
    }
    audio.play();
  };

  const handleScanedId = (action) => {
    setLoading(true);
    playSound(found_sound);
    setScan(false);
    scannedTracking({ trackingId: data.shipping.trackingId, action })
      .then((resp) => {
        Alert.success("Success");
        if (resp.length > 0) setData(null);
        setLoading(false);
      })
      .catch((err) => Alert.error(err.message));
  };

  const handleScan = (trackingId) => {
    scannedTracking({ trackingId, action: "view" })
      .then((resp) => {
        playSound(found_sound);

        if (resp.length === 0) Alert.error("PACKAGE NOT FOUND.");
        if (resp.length > 0) setData(resp[0]);
        setScan(false);
      })
      .catch((err) => {
        Alert.error(err.message);
        setScan(false);
      });
    setData(null);
  };
  
  return (
    <div style={{ maxWidth: "600px", margin: "10px" }}>
      {data && (
        <>
          {/* <Header style={{ marginLeft: "14px" }}>PACKAGE</Header> */}

          {data.payment.COD.amount > 0 && (
            <div
              style={{
                fontSize: "40px",
                color: "red",
                borderStyle: "dashed",
              }}
            >
              COD AMOUNT{" "}
              <span style={{ float: "right" }}>{data.payment.COD.amount}</span>
            </div>
          )}
          <Divider></Divider>
          <table style={{ textAlign: "left", margin: "14px" }}>
            <tr>
              <th>BARCODE:</th>
              <th>{data.shipping.trackingId}</th>
            </tr>
            <tr>
              <th>Name</th>
              <th>{data.shipping.shipTo.client.firstName}</th>
            </tr>
            <tr>
              <th>Phone</th>
              <th>{data.shipping.shipTo.phone}</th>
            </tr>
            <tr>
              <th>Address</th>
              <th>
                {data.shipping.shipTo.city},{data.shipping.shipTo.street},
                {data.shipping.shipTo.streetNr}
              </th>
            </tr>
          </table>
        </>
      )}
      {/* <Divider></Divider> */}

      {data && (
        <>
          <Divider></Divider>
          <div className="flex--column" style={{ gap: "10px" }}>
            <Button
              disabled={loading}
              loading={loading}
              size="huge"
              color="green"
              onClick={() => handleScanedId("delivered")}
            >
              {data.payment.COD.amount > 0
                ? `DELIVERED. COD COLLECTED - ${data.payment.COD.amount}`
                : "DELIVERED"}
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              size="huge"
              color="red"
              onClick={() => handleScanedId("rejected")}
            >
              REJECTED
            </Button>
            {/* <Button
            size="huge"
            color="blue"
            onClick={() => handleScanedId("view")}
          >
            VIEW
          </Button> */}
            <Button
              onClick={() => {
                setData(null);
              }}
            >
              CLEAR
            </Button>
            <Divider></Divider>
          </div>
        </>
      )}

      <div>
        {scan && (
          <BarcodeScannerComponent
            // stopStream={scan}
            // user
            width={500}
            height={500}
            onUpdate={(err, result) => {
              // console.log(result);
              // if (err.name === "NotAllowedError") {
              //   // Handle messaging in our app after the user chooses to not allow the camera permissions
              //   // setData("Camer Not allowed");
              // }
              if (result) {
                handleScan(result.text);
              }
            }}
          />
        )}
        <segment>
          <Button
            fluid
            basic
            color="red"
            onClick={() => {
              setScan(!scan);
              setData(null);
            }}
            style={{
              marginTop: "15px",
              // width: "600px",
              height: "80px",
              fontSize: "30px",
            }}
          >
            <Icon name="barcode" size="large"></Icon>
            {scan ? "CLOSE SCANNER" : "SCAN PACKAGE"}
          </Button>
        </segment>
      </div>
    </div>
  );
}

export default Barcodescanner;
