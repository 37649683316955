import React from "react";
import { Dropdown } from "semantic-ui-react";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import ProductFilterList from "../../Filters/ProductFilterList";
import CountryFilter from "../../Filters/CountryFilter";

const optionsDaysSelect = [5, 15, 30, 45, 60].map((nr) => ({
  key: nr,
  value: nr,
  text: `${nr} days`,
}));

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
function Filters({ days, setDays }) {

  return (
      <div className="flex--between">
        <div className="flex--left">
          <WarehouseFilter style={itemStyle} useFilter={true} />

          <BusinessclientFilter style={itemStyle} useFilter={true} />
          <ProductFilterList style={itemStyle} multiple={true} />
          <CountryFilter style={itemStyle}></CountryFilter>
        </div>
        <Dropdown
          selectOnBlur={false}
          closeOnChange={true}
          compact
          selection={true}
          name="days"
          defaultValue={days}
          onChange={(e, { value }) => setDays(value)}
          options={optionsDaysSelect}
          // style={{ marginLeft: "10px" }}
        />
      </div>
  );
}

export default Filters;
