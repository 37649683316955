import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import ExportExcel from "../../../ExportExcel";



function ExportButton({ data}) {


  const [dataSet, setData] = useState([{businessclient:'Na',createdAt:'na',shipTo:'na',shipFrom:{name:'na'},item:'na',qunatity:'na'}]);

  const colNames = [
    { label: "id",       value: "id" },
    { label: "businessclient",      value: "businessclient" },
    { label: "shipFrom",   value: "shipFrom" },
    { label: "shipTo",  value: "shipTo" },
    { label: "item",  value: "item" },
    { label: "sku",  value: "sku" },
    { label: "quantity",  value: "quantity" },
    
    { label: "createdAt",  value: "createdAt" },

  ];

  useEffect(() => {
  
    let tempDataSet = [];
    
    for (let i=0;i<data.items.length;i++){
      const line = data.items[i]
      
      tempDataSet.push(
                  
        {
                id:data._id,
                businessclient:   data.businessclient.name,
                shipFrom:         data.shipFrom?data.shipFrom.name:null,
                shipTo:           data.shipToAddress&&data.shipToAddress.name,
                item:             line.product.name,
                sku:              line.sku,
                quantity:         line.quantity_received?line.quantity_received:line.quantity_shipped,
                createdAt:        data.createdAt,
                

      })
    }

   if(tempDataSet.length>0) setData(tempDataSet);
  }, [data]);

  return (
    <ExportExcel
      filename={`PO_${dataSet[0].businessclient}_${dataSet[0].createdAt}`}
      excelSheetName="purchase order"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button basic color="grey" >
          <Icon name="excel file" />
          Export
        </Button>
      }
    />
  );
}

export default (ExportButton);
