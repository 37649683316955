import React, { useEffect, useState } from "react";
import { getCheckouts } from "../../actions/cashier";
import { Table,Segment } from "semantic-ui-react";

const Listcheckouts = (props) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getCheckouts().then((resp) => setList(resp));
  }, []);

  const body = list.map((item, index) => (
    <tr>
      <td>{index + 1}</td>
      <td>{item.createdAt}</td>
      <td>{item.total}</td>
      <td>{item.payment}</td>
      <td>{item.status}</td>
    </tr>
  ));

  return (
    <Segment>
      <Table style={{textAlign:'left'}}>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Total</th>
          <th>Payment type</th>
          <th>Status</th>
        </tr>
        {body}
      </Table>
    </Segment>
  );
};

export default Listcheckouts;
