import React, { useState,useEffect } from "react";
import { Segment, Header, Button,Form } from "semantic-ui-react";

import {
  removeInventoryCard,
} from "../../../actions/inboundshipment";
import AddGRitem from './addgr'
// import ItemView from "./ItemView";
import ItemList from "./ItemList";
import {Alert} from 'rsuite'

const GoodsReceived =({goodsreceived,openShipment,setShipment,reload,setReload})=> {


const [state,setState] = useState({
                            grs:[],
                            openItem: {
                              _id: "",
                              stockMovement: {
                                inventory: {
                                  _id: "",
                                  product: { name: "" },
                                  photos: {
                                    files: []
                                  }
                                }
                              }
                            },
                            modalIsOpen: false,
                            modalViewMode: false,
                            hasClient: false,
                            hasSupplier: false
                          })

const [isLoading,setIsloading]=useState(false)
useEffect(()=>{

  setState({...state,grs:goodsreceived})
},[goodsreceived])


  const handleOpenModal=() =>{
    setState({...state,
      modalIsOpen: true
    });
  }

  const handleCloseModal=()=> {
    setState({...state,
      modalIsOpen: false,
      openItem: {}
    });
  }
function  handleEdit(index) {
    setState({
      ...state,
      // modalIsOpen: true,
      modalViewMode: true,
      openItem: { ...goodsreceived[index] }
    });
  }
  
  function handleDelete(itemId) {
    
          setIsloading(true)
              removeInventoryCard({ id:openShipment._id, itemId })
              .then(data=>{
                setIsloading(false)
                Alert.success('Item removed')
                setState({...state,grs:data})
              })
              .catch(err=>{
                setIsloading(false)
                Alert.error(JSON.stringify(err))})
            }



    const {grs}= state
    return (
      <Form 
      // loading={updating}
      >
      <section className="inbound-goodsreceived">
        <AddGRitem
          openModal={state.modalIsOpen}
          closeModal={()=>handleCloseModal()}
          businessclient={openShipment.client&&openShipment.client._id?openShipment.client._id:null}
          id={openShipment._id}
          cb={(grs=>setState({...state,grs}))}

        />
        
        <Segment padded loading={isLoading}>
          <div className="flex--between">
            <Header as="h3">Goods Received</Header>
            <Button onClick={handleOpenModal}>Add Item</Button>
          </div>

          {grs.length > 0 && grs[0]._id && (
            <ItemList
              goodsreceived={grs}
              reload={reload}
              setReload={setReload}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={openShipment._id}
              setShipment={setShipment}

            />
          )}
        </Segment>
      </section>
      </Form>
    );
  }

export default (GoodsReceived);
