import React, { useEffect,useState } from "react";
import { Header, Icon, Button } from "semantic-ui-react";
// import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import {Alert} from 'rsuite'
import { getCourier,editCourier } from "../../../actions/couriers";

import { useToggleModalNewEdit } from "../../../hooks/useToggleModalNewEdit";
import CanViewPage from "../../../role-based-access/CanViewPage";
import ReportTemplates from "./ReportTemplates";
import PriceList from "./PriceList";
import Users from "./Users";
import CourierForm from "../CourierList/CourierForm";
import APIsettings from "../CourierPage/Apisettings";
import Documents from "../../Documents";

const emptyCourierForm = {
  name: "",
  country: "",
  isDefault: false,
};

function CourierPage(props) {
  const { match } = props;
  const [courier, setCourier] = useState();
  const [doRefresh,refresh] = useState(false)

  const updateCourier = (data) => {
    editCourier({
      id: _id,
      updates: { [data.name]: data.value },
    }).then((resp) => setCourier(resp)).catch(err=>Alert.error(err));
    
  };

  const { isModalOpen, isEditModeOn, initFormValues, openModal, closeModal } =
    useToggleModalNewEdit(emptyCourierForm);

  useEffect(
    () => getCourier(match.params.id).then((resp) => setCourier(resp)),
    [doRefresh, match.params.id]
  );

if(!courier) return (<div>Loading..</div>)

const { name, country, isDefault, _id, prices } = courier;

  return (
    <CanViewPage path="/couriers/:id">
      <main className="page-wrapper a-wrapper">
        <Link to="/couriers">
          <Icon name="arrow left" />
          Couriers list
        </Link>

        <div className="mt--1">
          <Header as="h1">{name}</Header>
          <span className="mr--1">{country}</span>
          {isDefault && <Icon name="check" color="grey" size="small" />}{" "}
          <Button
            basic
            compact
            size="tiny"
            icon="pencil alternate"
            onClick={() => openModal(true, { _id, name, country, isDefault })}
          />
        </div>

        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={true}
          onClose={closeModal}
          center
          classNames={{ modal: "" }}
        >
          <CourierForm
            courier={initFormValues}
            handleClose={closeModal}
            isEditModeOn={isEditModeOn}
          />
        </Modal>
        <APIsettings
          courier={courier}
          updateCourier={updateCourier}
        ></APIsettings>
        <ReportTemplates courierId={_id} reportId={""} />
        <PriceList prices={prices} />
        <Documents
          category={"Couriers"}
          businessclient={"5df9f1732d9d10847d35ab96"}
          trigger="Courier"
          triggerId={match.params.id}
        ></Documents>
        <Users courierId={_id} refresh={() => refresh(!doRefresh)} />
      </main>
    </CanViewPage>
  );
}


export default (CourierPage);
