import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
  getTransfersV2,
  updateTransferV2,
  deleteTransferV2,
} from "../../../../../actions/transfer";

import CanViewPage from "../../../../../role-based-access/CanViewPage";
import CanView from "../../../../../role-based-access/CanView";

import TransfersTable from "./Table";
import Filters from "./Filters";
import ItemsPerPage from "../../../../ItemsPerPage";
import Pagination from "../../../../Filters/Pagination";
import { useUpdateContext } from "../../../../../context/UpdateContext";
import ExportButton from "./ExportButton";

function ListTransfers(props) {
  const { businessclient, country } = useUpdateContext();

  const [transfers, setTransfers] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [filters, setFilters] = useState({
    status: "draft",
    currentPage: 1,
    itemsPerPage: 10,
  });
  const [searchId, setSearchId] = useState();

  const handleUpdateTranfer = (id, query) => {
    setIsloading(true);
    updateTransferV2(id, query).then((resp) => {
      getTransfersV2(filters).then((resp) => {
        setTransfers(resp.list);
        setTotal(resp.total);
        setIsloading(false);
      });
    });
  };
  const handleDeleteTransfer = (id) => {
    setIsloading(true);
    deleteTransferV2(id).then((resp) => {
      getTransfersV2(filters).then((resp) => {
        setTransfers(resp.list);
        setTotal(resp.total);
        setIsloading(false);
      });
    });
  };

  useEffect(() => {
    setIsloading(true);
    getTransfersV2({ ...filters, businessclient, country, searchId }).then(
      (resp) => {
        setTransfers(resp.list);
        setTotal(resp.total);
        setIsloading(false);
      }
    );
  }, [filters, searchId, businessclient, country]);

  return (
    <CanViewPage path="/reports/transffers">
      <div className="flex--between">
        <div></div>
        <CanView path="/reports/transffers" action="create">
          <Link to="/reports/transfers/create">
            <Button primary>+Create</Button>
          </Link>
        </CanView>
      </div>

      <main>
        <div className="flex--between">
          {/* <Header>Transfers</Header> */}
          <div></div>
        </div>

        <Filters
          filters={filters}
          setFilters={setFilters}
          search={searchId}
          setSearch={setSearchId}
        />

        <ExportButton
          style={{ marginBottom: "10px" }}
          data={transfers}
          filename={"Cod Tranffers"}
        ></ExportButton>

        <div className="flex--right">
          <ItemsPerPage
            total={total}
            value={filters.itemsPerPage}
            setItemsPerPage={(value) =>
              setFilters({ ...filters, itemsPerPage: value })
            }
          />
        </div>
        <TransfersTable
          currentPage={filters.currentPage}
          itemsPerPage={
            filters.itemsPerPage !== "All" ? filters.itemsPerPage : 0
          }
          transfers={transfers}
          isLoading={isLoading}
          deleteTransfer={handleDeleteTransfer}
          updateTransfer={handleUpdateTranfer}
        />
        <div className="flex--center">
          <Pagination
            currentPage={filters.currentPage}
            itemsPerPage={filters.itemsPerPage}
            total={total}
            updateFilter={(activePage) =>
              setFilters({ ...filters, currentPage: activePage })
            }
          />
        </div>
      </main>
    </CanViewPage>
  );
}

export default ListTransfers;
