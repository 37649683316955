import React from "react";
import ProductFilterList from "../../Filters/ProductFilterList";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import { DatePicker } from "rsuite";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
const Filters = ({
  dateFrom,
  dateTo,
  handleDateFromChange,
  handleDateToChange,
}) => {
  return (
    <div className="filters_wrapper">
      <div className="flex--left">
        {/* <Dropdown
              placeholder="status..."
              value={filters.status}
              multiple
              clearable
              selectOnBlur={false}
              onChange={handleUpdateFilter}
              selection
              options={shipmentStatusOptions}
              closeOnChange={true}
              name="status"
            /> */}

        <BusinessclientFilter
          style={itemStyle}
          multiple={false}
        ></BusinessclientFilter>

        <ProductFilterList style={itemStyle} placeholder="select..." multiple />

        <WarehouseFilter style={itemStyle} />

        <div className="flex--left">
          <div style={{ zIndex: 0 }}>
            <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateFrom}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => handleDateFromChange(date)}
              placeholder="from..."
              style={itemStyle}
            ></DatePicker>
          </div>

          <div style={{ marginRight: "0", zIndex: 0 }}>
            <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateTo}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => handleDateToChange(date)}
              placeholder="to..."
              style={itemStyle}
            ></DatePicker>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
