export const orderStatuses = [
  "All",
  "New",
  "Draft",
  "Validated",
  "Unpaired items",
  "Canceled",
  "Order Ready for picking",
  "Error",
  "No goods",
  "Goods Blocked",
  "pending",
  "Product Picking",
  "Packing",
  "Packed",
  "Shipping",
  "Unsuccessful attempt",
  "At courier",
  "Delivered",
  "Done",
  "Not shipped",
  "COD RECEIVED",
  "COD TRANFFERED TO CLIENT",
  "Damaged with courier"
];

export const roles = [
  "admin",
  "packer_ro",
  "buyer_ro",
  "packer_bg",
  "buyer_bg",
  "buyer_cz",
  "picker_ro",
  "picker_bg",
  "ship_in_ro",
  "ship_out_ro",
  "ship_in_bg",
  "ship_out_bg",
  "logist_mng_Bg",
  "inboundshipments_manager_ro",
  "inboundshipments_manager_bg",
  "outboundshipments_manager_ro",
  "outboundshipments_manager_bg",
  "logistic_manager",
  "bc_admin",
  "bc_guest",
  "web_dev"
];

export const currency = [
  "USD",
  "CAD",
  "EUR",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ARS",
  "AUD",
  "AZN",
  "BAM",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BND",
  "BOB",
  "BRL",
  "BWP",
  "BYN",
  "BZD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EEK",
  "EGP",
  "ERN",
  "ETB",
  "GBP",
  "GEL",
  "GHS",
  "GNF",
  "GTQ",
  "HKD",
  "HNL",
  "HRK",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KHR",
  "KMF",
  "KRW",
  "KWD",
  "KZT",
  "LBP",
  "LKR",
  "LTL",
  "LVL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MOP",
  "MUR",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SDG",
  "SEK",
  "SGD",
  "SOS",
  "SYP",
  "THB",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "XAF",
  "XOF",
  "YER",
  "ZAR",
  "ZMK",
  "ZWL"
];
