const initialState = {
  businessclients: [],
  products: [],
  warehouse: [],
  couriers: [],
  users: [],
  bgcities: [],
  orderStatuses:[]
};

const droplists = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DPOP_LIST":
      return {
        ...state,
        [action.payload.name]: action.payload.list,
      };

    default:
      return state;
  }
};
export default droplists;
