import React from "react";
import { Button, Input, Icon } from "semantic-ui-react";

const EditInputField = ({
  text = "no name setup",
  name='no name setup',
  updateField,
  setShowUpdateField,
  handleUpdateField,
  value,
  id = "noId",
  updateFieldBlock = "address",
  link = null,
  validate = false,
  showValidator,
  setShowValidator
}) => {
  return (
    <tr id={id} style={{ height: "33px" }}>
      <td style={{ width: "100px", fontWeight: "bold" }}>{text}</td>
      <td style={{ textAlign: "left" }}>
        {updateField.name === name && (
          <div>
            <Input
              value={updateField.value}
              style={{ height: "25px" }}
              onChange={(e, { value }) => {
                setShowUpdateField({ ...updateField, value });
              }}
            ></Input>
          </div>
        )}

        {updateField.name !== name && (
          <div className="flex--between">
            <div>{value}</div>
          </div>
        )}
      </td>
      <td style={{ textAlign: "right", width: "150px" }}>
        {updateField.name === name && (
          <div>
            <Button
              size="mini"
              basic
              color="red"
              onClick={() => {
                setShowUpdateField({ name: null });
              }}
            >
              Cancel
            </Button>
            <Button
              size="mini"
              basic
              color="green"
              onClick={() => handleUpdateField(updateFieldBlock)}
            >
              Save
            </Button>
          </div>
        )}

        {updateField.name !== name && (
          <div>
            {" "}
            {link && (
              <a target="_blank" href={link}>
                {" "}
                <Icon name="file pdf" />
              </a>
            )}
           {validate&& <Button
              // icon="edit"
              basic
              size="mini"
              onClick={() => {
                setShowValidator(!showValidator)}}
              color='blue'
            >validate</Button>}
            <Button
              icon="edit"
              basic
              size="mini"
              onClick={() => {
                setShowUpdateField({
                  name: name,
                  value: value,
                });
              }}
            ></Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default EditInputField;
