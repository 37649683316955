import React, { useState, useEffect } from "react";
import { Pagination as Pagination2 } from "semantic-ui-react";

import { getAllOrders } from "../../../actions/packing";
import OrdersTable from "../../Order/ListOrders/OrdersTable";
import ItemsPerPage from "../../ItemsPerPage";

function PackingList(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orders,setOrders] = useState([])
  const [total,setTotal] = useState(100)
  const [isLoading,setIsloading] = useState(false)
  
  useEffect(() => {
    setIsloading(true)
    getAllOrders({ currentPage, itemsPerPage }).then(resp=>{
      setIsloading(false)
      setOrders(resp.list)
      setTotal(resp.total)
    })
  }, [currentPage, itemsPerPage]);

  return (
    <section>
      {orders.length > 0 && total > itemsPerPage && (
        <div style={{ textAlign: "right", marginBottom: "1em" }}>
          <ItemsPerPage
            total={total}
            style={{ height: "1em", marginLeft: "1em" }}
            value={itemsPerPage}
            options={[10, 20, 30, 50, "All"]}
            handleChange={(e, { value }) => {
              setItemsPerPage(value);
            }}
          />
        </div>
      )}
      <OrdersTable
        orders={orders}
        // checkedItems={this.state.checkedItems}
        // headerIsChecked={this.state.headerIsChecked}
        // handleSingleCheck={this.handleSingleCheck}
        // handleMultipleCheck={this.handleMultipleCheck}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        checkColumn={false}
        sortable={false}
        actionColumn={true}
        action="pack"
        isLoading={isLoading}
      />
      {orders.length > 0 && total > itemsPerPage && (
        <div style={{ marginTop: "1em" }}>
          <Pagination2
            activePage={currentPage}
            totalPages={Math.ceil(total / itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          />
        </div>
      )}
    </section>
  );
}
export default (PackingList);
