import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { getBusinessClients } from "../../actions/businessclient";
import { useUpdateContext } from "../../context/UpdateContext";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
}
function BusinessclientFilter({
  name = "businessclient",
  value,
  fluid = false,
  style = {itemStyle},
  placeholder = "businessclient...",
  selectOnBlur = false,
  closeOnChange = true,
  disabled = false,
  multiple = false,
  className,
  label = "Businessclient",
  clearable = true,
  handleChange,
  useFilter = true,
  options=[]
}) {
  const { handleClientChange, businessclient,handleSupplierChange,supplier,setDropList } = useUpdateContext();

  const [searchTerm, setSearch] = useState();
  const [list,setList] = useState([])
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
      if (localStorage.clientsList && !searchTerm) {
        const list = JSON.parse(localStorage.getItem("clientsList") || "[]");
        setList(list.map((item) => ({
          value: item._id,
          text: item.name,
          key: item._id,
        })))
    } else{
      setIsloading(true)
      getBusinessClients({ searchTerm })
        .then((data) => {
          setIsloading(false)
          setDropList({name:`clientsList`,list: JSON.stringify(data.list)});
            setList(data.list.map((item) => ({
              value: item._id,
              text: item.name,
              key: item._id,
            })))
        })
        .catch((error) => error.message);
    }
      
  }, [searchTerm]);

  return (
    <Dropdown
      loading={isLoading}
      name={name}
      multiple={multiple}
      clearable={clearable}
      style={style}
      selection={true}
      fluid={fluid}
      disabled={disabled}
      placeholder={placeholder}
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      className={className}
      label={label}
      search
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
      value={
        !useFilter
          ? value
          : name === "businessclient"
          ? businessclient
          : supplier
        // multiple && Array.isArray(item) ? item : multiple && !Array.isArray(item)?[item]:item
      }
      options={useFilter?list:[...options,...list]}
      // loading={products.length == 0}
      onChange={(e, data) => {
        const { name, value } = data;

        if (useFilter) {
          if (name === "businessclient") handleClientChange(value);
          else handleSupplierChange(value);
        } else handleChange(value);
      }}
    />
  );
}


export default (BusinessclientFilter);
