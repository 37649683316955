import React, { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import Moment from 'react-moment'
// import ReactJson from 'react-json-view'

import { getErrorLogs } from "../../actions/error";

function Errorlist({
  isLoading,
  errors = [],
  getErrorLogs
}) {
  useEffect(()=> getErrorLogs(),[]);
  // if (isLoading)
  //   return (
  //     <div style={{ marginTop: "3em", marginBottom: "4em" }}>
  //       <Loader active inline="centered" />
  //     </div>
  //   );
 const style ={
 fontWeight:"bold"
 }


 
 
  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={1} textAlign="left">#</Table.HeaderCell>
      <Table.HeaderCell width={1}>Service</Table.HeaderCell>
      <Table.HeaderCell width={1}>Date</Table.HeaderCell>
      <Table.HeaderCell width={5}>Error</Table.HeaderCell>
      <Table.HeaderCell width={2}>Body</Table.HeaderCell>
      {/* <Table.HeaderCell>User</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Severity</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Is Done</Table.HeaderCell> */}
    </Table.Row>
  );

  const tableBody = errors.map(
    ({ severity,status,service,url,body,user,error,createdAt,code}, i) => {

      let requestBody ={}

      typeof(body) ==='string' ? requestBody =JSON.parse(body):requestBody=body

      return (
      <Table.Row key="_id" negative ={['High'].includes(severity)}>
        <Table.Cell textAlign="left">{i + 1}.</Table.Cell>
        <Table.Cell>{service}</Table.Cell>
        <Table.Cell><Moment format='DD/MM/YYYY HH:mm'>{createdAt}</Moment></Table.Cell>
        <Table.Cell width={2}>
          <span style={style}>URL:</span>{url} <br></br>
          <span style={style} >Message:</span>{error} <br></br>
          <span style={style} >User:</span>{user ? user.username:'unknown'} <br></br>
          <span style={style} >Severity:</span>{severity} <br></br>
          <span style={style} >Code:</span>{code} <br></br>
          
          </Table.Cell>

        {/* <Table.Cell><ReactJson src={requestBody}></ReactJson></Table.Cell> */}
        {/* <Table.Cell>
          <Checkbox></Checkbox>
        </Table.Cell> */}


      </Table.Row>
    )}
  );

  return (
    <div className="table-wrapper--horizontal-scroll ">
      <Table fixed loading={isLoading} >
        <Table.Header >{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
}

function mapStateToProps(ReduxState) {
  return {
    errors: ReduxState.errorList.list,
    isLoading: ReduxState.errorList.isLoading,
  };
}

export default connect(mapStateToProps, { getErrorLogs })(Errorlist);
      
