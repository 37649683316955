import React, { useState } from "react";
import { Header, Input, Segment } from "semantic-ui-react";

import CountryFilter from "../../Filters/CountryFilter";
import SkuFilter from "../../Filters/SkuFilter";

function Filters({ updateFilter, filters }) {
  const handleFilterChange = (e, { name, value }) =>
    updateFilter({ [name]: value, currentPage: 1 });

  const [searchState, setSearchState] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const handleSearch = (e, { value }) => {
    // if (isLoading) return;
    if (value.length === 1 && searchState.length === 0) {
      setSearchState(value);
      return;
    }
    setSearchState(value);
    if (!requestSent) {
      updateFilter({ web: value, currentPage: 1 });
      setRequestSent(true);
      setTimeout(() => setRequestSent(false), 600);
    }
  };
  return (
    <Segment>
      <Header as="h4">Filters:</Header>
      <div style={{ display: "inline-block", marginRight: "1em" }}>
        <CountryFilter
          value={filters.country}
          handleChange={handleFilterChange}
        />
      </div>
      <div style={{ display: "inline-block", marginRight: "1em" }}>
        <Input
          // loading={isLoading}
          placeholder="web ..."
          // icon="search"
          value={searchState}
          onChange={handleSearch}
        />
      </div>
      <SkuFilter
        placeholder="sku ..."
        value={filters.sku}
        handleChange={handleFilterChange}
      />
    </Segment>
  );
}

export default Filters;
