import React, { useState } from "react";
import { Header, Form, Divider, Message } from "semantic-ui-react";
import Modal from "react-responsive-modal";
// import DatePicker from "react-datepicker";
import { inventoryCount } from "../../actions/inventory";
import "./inventory.scss";
import ProductFilterList from "../Filters/ProductFilterList";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import WarehouseFilter from "../Filters/WarehouseFilter";
import { Alert } from "rsuite";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
const initState = {
  position: "",
  toDate: null,
  quantity_available: null,
  quantity_blocked: null,
  quantity_damaged: "",
};

const Inventorycount = (props) => {
  const [state, setState] = useState(initState);

  const handleAdd = () => {
    //query : {product,warehouse,quantity_available,quantity_blocked,quantity_damaged}
    const query = { ...state };
    inventoryCount(query).then((resp) => Alert.success("Updated."));

    // setState(initState);
  };

  const handleChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  // const handlePositionChange=(e, { name, value })=> {
  //   setState({ ...state,[name]: value });
  // }

  // const handleDateChange=(value) =>{
  //   setState({...state, toDate: value });
  // }

  const { isOpen } = props;

  const {
    // position,
    // toDate,
    quantity_available,
  } = state;
  return (
    <div>
      <Modal
        open={isOpen}
        showCloseIcon={true}
        onClose={() => props.showModal()}
        center
        classNames={{
          modal: "inventoryModal",
        }}
      >
        <Header as="h2">Add movement</Header>
        <Divider></Divider>

        <WarehouseFilter
          style={{ width: "100%" }}
          useFilter={false}
          multiple={false}
          value={state.warehouse}
          handleChange={(value) => handleChange({ name: "warehouse", value })}
        ></WarehouseFilter>

        <BusinessclientFilter
          style={{ width: "100%" }}
          useFilter={false}
          value={state.businessclient}
          name="businessclient"
          handleChange={(value) =>
            handleChange({ name: "businessclient", value })
          }
        ></BusinessclientFilter>

        <ProductFilterList
          style={{ width: "100%" }}
          client={state.businessclient}
          disabled={!state.businessclient}
          useFilter={false}
          fluid={true}
          value={state.product}
          handleChange={(value) => handleChange({ name: "product", value })}
        ></ProductFilterList>

        {/* <Form.Group widths="equal">
              <div className="inventory-count-position-and-date">
                <Form.Input
                  name="position"
                  value={position}
                  label="Position"
                  placeholder="position..."
                  onChange={handlePositionChange}
                />
                <div className="inventory-count-date">
                  <label>At date:</label>
                  <DatePicker
                    selected={toDate}
                    isClearable={true}
                    dateFormat="MMMM d, yyyy"
                    onChange={handleDateChange}
                    placeholderText="at date ..."
                  />
                </div>
              </div>
            </Form.Group> */}

        <Divider horizontal>Quantity</Divider>

        <Form.Input
          inline
          required
          label="Total counted"
          placeholder="quantity"
          name="quantity_available"
          value={state.quantity_available}
          width={2}
          onChange={(e, { value }) =>
            handleChange({ name: "quantity_available", value })
          }
          type="number"
          error={state.warehouse === null}
        ></Form.Input>

        <Message hidden={!props.succMsg} positive info>
          {/* <Message.Header>Was this what you wanted?</Message.Header> */}
          <p>Inventory updated successfuly!</p>
        </Message>

        <div className="flex_alignLeft">
          <Form.Button onClick={() => props.showModal()}>Close</Form.Button>
          <Form.Button
            color="green"
            onClick={handleAdd}
            disabled={
              !(
                state.warehouse &&
                state.businessclient &&
                state.product &&
                quantity_available >= 0
              )
            }
          >
            {" "}
            Add
          </Form.Button>
        </div>
      </Modal>
    </div>
  );
};

export default Inventorycount;
