import React from "react";
import {
  Divider,
  Header,
  // Segment,
  // Button,
  Loader,
  Dimmer,
  Pagination
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  getAllPickingList,
  updateFilterPickingList
} from "../../../../actions/picking";

import PickedListItem from "./PickedListItem";
import FiltersBar from "./FiltersBar";
import CanViewPage from "../../../../role-based-access/CanViewPage";

class PickingListPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.handleSearchProduct = this.handleSearchProduct.bind(this);
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this);
  }

  handleUpdateFilter = newFilterObj => {
    this.props.updateFilterPickingList(newFilterObj);
  };

  handleSearchProduct(e, { name, value }) {
    this.handleUpdateFilter({ [name]: value });
  }

  handleItemsPerPageChange(e, { value }) {
    this.handleUpdateFilter({ itemsPerPage: value, currentPage: 1 });
  }

  componentDidMount() {
    this.props.getAllPickingList();
  }

  componentDidUpdate(prevProps) {
    const atLeastOneFilterChanges =
      prevProps.filters &&
      Object.keys(prevProps.filters).find(
        key => prevProps.filters[key] !== this.props.filters[key]
      );
    if (atLeastOneFilterChanges) {
      this.props.getAllPickingList();
    }
  }

  render() {
    const { filters, total, isLoading, listOfPicked } = this.props;

    return (
      <CanViewPage path="/picker/pickedlist">
        <>
          <div
            className="a-wrapper"
            style={{
              marginTop: "1em",
              marginBottom: "0.8em",
              alignItems: "flex-end"
            }}
          >
            <div className="d-flex--jc-between ai-center">
              <Header as="h3">Product Picking 3</Header>
              <Link to="/picker/orders">Picker Orders</Link>
            </div>
          </div>
          <FiltersBar
            filters={filters}
            total={total}
            handleUpdateFilter={this.handleUpdateFilter}
            handleSearchProduct={this.handleSearchProduct}
            handleItemsPerPageChange={this.handleItemsPerPageChange}
          />
          <div
            className="a-wrapper"
            style={{ marginTop: "4em", marginBottom: "7em" }}
          >
            {isLoading && (
              <Dimmer active inverted>
                <Loader content="Loading" />
              </Dimmer>
            )}

            {listOfPicked.length === 0 && !isLoading && (
              <Header as="h4">There are no picking lists found</Header>
            )}
            {listOfPicked.length > 0 &&
              !isLoading &&
              listOfPicked.map((item, index) => {
                if (index === 0) {
                  return (
                    <>
                      <h5 style={{ color: "#54947a" }}>
                        Last Saved Picking List
                      </h5>
                      <div
                        style={{
                          marginBottom: "5em",
                          padding: "1em",
                          border: "1px solid #92e8cc",
                          borderRadius: "0.3em",
                          backgroundColor: "#a1edc0"
                        }}
                      >
                        <PickedListItem
                          isLastAdded={true}
                          item={listOfPicked[0]}
                        />
                      </div>
                      <Divider
                        style={{ marginBottom: "4em", marginTop: "3em" }}
                      />
                    </>
                  );
                } else {
                  return (
                    <div style={{ marginBottom: "5em" }}>
                      {/* <Segment> */}
                      <PickedListItem
                        key={item._id}
                        item={item}
                        isLastAdded={false}
                      />
                      {/* </Segment> */}
                    </div>
                  );
                }
              })}

            {total > filters.itemsPerPage && !isLoading && (
              <Pagination
                style={{ marginTop: "1em" }}
                activePage={filters.currentPage}
                totalPages={Math.ceil(total / filters.itemsPerPage)}
                boundaryRange={1}
                siblingRange={1}
                firstItem={null}
                lastItem={null}
                onPageChange={(e, { activePage }) =>
                  this.handleUpdateFilter({ currentPage: activePage })
                }
              />
            )}
          </div>
        </>
      </CanViewPage>
    );
  }
}

const mapStateToProps = reduxState => {
  return {
    listOfPicked: reduxState.picking.listOfPicked,
    total: reduxState.picking.pickedTotal,
    filters: reduxState.picking.filters,
    isLoading: reduxState.picking.isLoading
  };
};

export default connect(mapStateToProps, {
  getAllPickingList,
  updateFilterPickingList
})(PickingListPage);
