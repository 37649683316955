import React, { useState, useEffect } from "react";
import { Header, Label, Button, Segment, Divider } from "semantic-ui-react";
import { Alert } from "rsuite";
// import Moment from "react-moment";
import { Link } from "react-router-dom";
import ProductsList from "./ProductsList";
import AddProduct from "./AddProduct";
import ChangeLogs from "./ChangeLogs";
import {
  getPo,
  updatePo,
  addPoItem,
  deletePoItem,
  blockGoods,
  cancelBlockedGoods,
  addPosShipment,
  updatePoShippedQuantity
} from "../../../../actions/pos";

import WarehouseFilter from "../../../Filters/WarehouseFilter";
import Comments from "../../../Comments";
import BusinessclientFilter from "../../../Filters/BusinessclientFilter";
import ExportButton from "./export";

const color = {
  New: "red",
  "Goods Blocked": "green",
  "No goods": "yellow",
};

const Purchaseorder = (props) => {
  const [po, setPO] = useState(null);
  // const [isFormTouched, setFormTouched] = useState(false);

  const [addProduct, setAddproduct] = useState(false);
  const [updateField, setShowUpdateField] = useState({
    name: null,
    value: null,
  });

  const [updatingSegment, setUpdatingSegment] = useState();
  const [isLoading, setLoading] = useState(false);

  const handleUpdateField = (segment) => {
    setLoading(true);

    setUpdatingSegment(segment);
    updatePo({
      query: { [updateField.name]: updateField.value },
      id: po._id,
    })
      .then((po) => {
        setLoading(false);

        setPO(po);
        setUpdatingSegment(null);
        setAddproduct(false);
      })
      .catch((err) => {
        setLoading(false);

        Alert.error(err, 5000);
        setUpdatingSegment(null);
      });

    setShowUpdateField({ name: null, value: null });
  };

  const handleAddProduct = (item) => {
    setLoading(true);

    setUpdatingSegment("products");
    addPoItem({ id: po._id, item })
      .then((po) => {
        setPO(po);
        setUpdatingSegment(false);
        setLoading(false);
      })
      .catch((error) => {
        setUpdatingSegment(false);
        setLoading(false);

        Alert.error(error, 5000);
      });
  };

  const handleBlockGoods = () => {
    setLoading(true);

    blockGoods({ id: po._id })
      .then((resp) => {
        setLoading(false);

        setPO(resp);
      })
      .catch((error) => {
        setLoading(false);

        Alert.error(error);
      });
  };

  const handleRelease = () => {
    setLoading(true);

    cancelBlockedGoods({ id: po._id })
      .then((resp) => {
        setLoading(false);
        setPO(resp);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error(err);
      });
  };

  const handleDeleteItem = ({ item_id }) => {
    setLoading(true);
    setUpdatingSegment("products");
    deletePoItem({
      po_id: po._id,
      item_id,
    })
      .then((po) => {
        setLoading(false);

        setPO(po);
        setUpdatingSegment(false);
      })
      .catch((error) => {
        setLoading(false);

        setUpdatingSegment(false);
        Alert.error(error, 5000);
      });
  };

 const handleCreateOutBountShipment=()=> {
    const { history } = props;
            setLoading(true);

    addPosShipment({ pos: [po._id], history }).catch(err=>{
      Alert.error(err)
              setLoading(false);

    })
  }

  // const handleInvoice = () => {
  //   setCreatingInvoice(true);
  //   createInvoice(props.match.params.id)
  //     .then((resp) => {
  //       setPO(resp);
  //       setCreatingInvoice(false);
  //     })
  //     .catch((error) => Alert.error(error.message));
  // };

  useEffect(() => {
    getPo(props.match.params.id).then((resp) => setPO(resp));
  }, [props.match.params.id]);

  if (!po) return <div>loading...</div>;

  return (
    <div className="flex--column" style={{ maxWidth: "900px", margin: "15px" }}>
      <segment name="errors">
        {po.errMsgs &&
          po.errMsgs.map((item, index) => (
            <div className="alert alert-danger" key={index}>
              {item}
            </div>
          ))}
      </segment>

      <div className="flex--between vertical--center">
        <Header className="form__header" as="h2">
          PURCHASE ORDER <Label color={color[po.status]}>{po.status}</Label>
          <Header.Subheader>{po.numericId}</Header.Subheader>
        </Header>
        <div className="flex--left">
          {(po.status === "New" || po.status === "No goods") && (
            <Button color="green" onClick={() => handleBlockGoods()}>
              Block Goods
            </Button>
          )}
          {po.status === "Goods Blocked" && (
            <Button color="orange" onClick={handleCreateOutBountShipment}>
              Create Shipment
            </Button>
          )}
          {po.status === "Goods Blocked" && (
            <Button color="red" onClick={() => handleRelease()}>
              Release Goods
            </Button>
          )}
          <ExportButton data={po}></ExportButton>
        </div>
      </div>

      <Divider></Divider>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          columnGap: "20px",
          justifyContent: "space-between",
          marginRight: "20px",
        }}
      >
        <Segment
          name="address"
          loading={updatingSegment === "address" || isLoading}
          style={{ maxWidth: "400px", width: "350px", marginTop: "14px" }}
        >
          <div>
            <Header>Ship To Warehouse</Header>
            <tr id="shipTo" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Ship To </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipTo" && (
                  <div>
                    <WarehouseFilter
                      useFilter={false}
                      defaultOpen={true}
                      multiple={false}
                      value={updateField.value}
                      handleChange={(value) => {
                        setShowUpdateField({
                          name: "shipTo",
                          value,
                        });
                      }}
                    ></WarehouseFilter>
                  </div>
                )}
                {updateField.name !== "shipTo" &&
                po.shipTo &&
                po.shipTo.name
                  ? po.shipTo.name
                  : null}
              </td>

              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipTo" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipTo")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipTo" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipTo",
                        value: po.shipTo,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
          </div>

          <Divider></Divider>
          {po.shipTo && (
            <table>
              <tr id="fistName" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>
                  Contact name
                </td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo && po.shipTo.name ? po.shipTo.name : ""}
                </td>
              </tr>
              <tr id="phone" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>
                  Contact phone
                </td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.phone
                    ? po.shipTo.address.phone
                    : ""}
                </td>
              </tr>

              <tr id="country" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>Country</td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.country}
                </td>
              </tr>
              <tr id="city" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>City</td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.city}
                </td>
              </tr>
              <tr id="street" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>Street</td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.street}
                </td>
              </tr>
              <tr id="streetNr" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>StreetNr</td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.streetNr}
                </td>
              </tr>
              <tr id="postcode" style={{ height: "33px" }}>
                <td style={{ width: "110px", fontWeight: "bold" }}>Postcode</td>
                <td style={{ textAlign: "left" }}>
                  {po.shipTo.address && po.shipTo.address.postcode}
                </td>
              </tr>
            </table>
          )}
          {!po.shipTo && (
            <div>
              Don't Panic! Its ok that no address is displayed here. We are
              transitioning to goods in tranzit between internal warehouses
              reporting.
            </div>
          )}
        </Segment>

        <Segment
          name="shipping"
          loading={updatingSegment === "shipping" || isLoading}
          style={{ maxWidth: "540px" }}
        >
          <Header as="h3">Shipping data</Header>
          <Divider />

          <table>
            {po.shipment && (
              <tr>
                <td style={{ width: "120px", fontWeight: "bold" }}>Shipment</td>
                <Link to={`/shipments/outbound/${po.shipment}`}> View</Link>
              </tr>
            )}
            <tr>
              <td style={{ width: "120px", fontWeight: "bold" }}>Client</td>
              <Link to={`/businessclients/${po.businessclient._id}`}>
                {po.businessclient.name}
              </Link>
            </tr>

            <tr id="supplier" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Supplier </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "supplier" && (
                  <div>
                    <BusinessclientFilter
                      defaultOpen={true}
                      multiple={false}
                      value={updateField.value}
                      handleChange={(e, { value }) => {
                        setShowUpdateField({
                          name: "supplier",
                          value,
                        });
                      }}
                    ></BusinessclientFilter>
                  </div>
                )}
                {updateField.name !== "supplier" && (
                  <Link to={`/businessclients/${po.supplier._id}`}>
                    {po.supplier.name}
                  </Link>
                )}
              </td>

              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "supplier" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("supplier")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "supplier" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "supplier",
                        value: po.supplier,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>

            <tr id="shipFrom" style={{ height: "33px" }}>
              <td style={{ width: "120px", fontWeight: "bold" }}>Ship From </td>
              <td style={{ textAlign: "left" }}>
                {updateField.name === "shipFrom" && (
                  <div>
                    <WarehouseFilter
                      useFilter={false}
                      defaultOpen={true}
                      multiple={false}
                      value={updateField.value}
                      handleChange={(value) => {
                        setShowUpdateField({
                          name: "shipFrom",
                          value,
                        });
                      }}
                    ></WarehouseFilter>
                  </div>
                )}
                {updateField.name !== "shipFrom" &&
                po.shipFrom &&
                po.shipFrom.name
                  ? po.shipFrom.name
                  : null}
              </td>

              <td style={{ textAlign: "right", width: "150px" }}>
                {updateField.name === "shipFrom" && (
                  <div>
                    <Button
                      size="mini"
                      basic
                      color="red"
                      onClick={() => {
                        setShowUpdateField({ name: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="mini"
                      basic
                      color="green"
                      onClick={() => handleUpdateField("shipFrom")}
                    >
                      Save
                    </Button>
                  </div>
                )}

                {updateField.name !== "shipFrom" && (
                  <Button
                    icon="edit"
                    basic
                    size="mini"
                    onClick={() => {
                      setShowUpdateField({
                        name: "shipFrom",
                        value: po.shipFrom,
                      });
                    }}
                  ></Button>
                )}
              </td>
            </tr>
          </table>
        </Segment>

        <Segment
          name="products"
          loading={updatingSegment === "products" || isLoading}
          style={{
            maxWidth: "900px",
            width: "100%",
          }}
        >
          <div className="flex--between">
            <Header>
              Products
              <Header.Subheader>{po.supplier.name}</Header.Subheader>
            </Header>
            <Button basic onClick={() => setAddproduct(!addProduct)}>
              {addProduct ? "Hide" : "Add"}
            </Button>
          </div>

          <Divider></Divider>
          {addProduct && (
            <AddProduct
              handleAddProduct={(item) => handleAddProduct(item)}
              businessclient={po.supplier._id}
              warehouse={po.shipFrom ? po.shipFrom._id : null}
            />
          )}
          <ProductsList
            po_id={po._id}
            items={po.items}
            handleDeleteItem={handleDeleteItem}
            updatePoShippedQuantity={updatePoShippedQuantity}
            setPO={setPO}
          />
        </Segment>

        <div
          name="messages"
          style={{ marginTop: "14px", width: "900px" }}
          loading={updatingSegment == "messsages"}
        >
          <Comments
            trigger={"Purchaseorder"}
            triggerId={props.match.params.id}
          ></Comments>
        </div>
        <Segment name="logs" style={{ maxWidth: "900px", width: "900px" }}>
          <ChangeLogs changeLogs={po.changeLogs} />
        </Segment>
      </div>
    </div>
  );
};

export default Purchaseorder;
