import React, { useEffect,useState } from "react";
import { Button,  Table } from "semantic-ui-react";

import { getReportTemplates ,deleteReportTemplate} from "../../../actions/couriers";

function ReportList(props) {
  const {
    courierId,
    openEditModal,
  } = props;

  const [templates,setTemplates]=useState([])
  useEffect(() => {
    if (courierId) getReportTemplates(courierId).then(resp=>setTemplates(resp))
  }, [courierId]);

  if (!templates.length)
    return <p className="center--text">No templates saved yet</p>;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>Name</Table.HeaderCell>
      {/* <Table.HeaderCell>Courier</Table.HeaderCell> */}
      <Table.HeaderCell>Paired key</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Column Number</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = templates.map(
    ({ name, _id, pairedKey,  columnPosition }) => (
      <Table.Row key={_id}>
        <Table.Cell>{name}</Table.Cell>
        {/* <Table.Cell>{courier}</Table.Cell> */}
        <Table.Cell>{pairedKey}</Table.Cell>
        <Table.Cell textAlign="center">{columnPosition}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            basic
            compact
            size="tiny"
            icon="pencil"
            onClick={() =>
              openEditModal(true, {reportId:_id, name, pairedKey, columnPosition })
            }
          />
          <Button basic compact size="tiny" icon="trash" 
            onClick ={()=>{
              // alert('ok')  
              deleteReportTemplate({id:courierId,reportId:_id})
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very">
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}


export default (ReportList);
