import React,{} from "react";
import { Button, } from "semantic-ui-react";

import { Alert } from "rsuite";
import { Exportorders } from "../actions/orders";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";


function ExportButton({
  filters,
  url,
  method = "post",
  fileName,
  total,
  onClick=()=>null,
  text,
  disabled=false,
  style={maxWidth:"290px"}
}) {

  function getData(body) {
    Alert.info('Exporting...',100000)
    // console.log("!!!getting data...");
    const finalUrl = `${process.env.REACT_APP_API}${url}`;
    // getFileToDownload({apiUrl:completeUrl,filters})
     Exportorders({method, url:finalUrl, body})

      .then((response) => {
        if(response.error) {
          Alert.closeAll()
          Alert.error(response.error.message)}
        else
       { createExcelFile(response);
        Alert.closeAll()
        onClick()}
      })
      .catch((err) => {
                Alert.closeAll()

        Alert.error(err.message,5000);
      });
  }

  function createExcelFile(dataForExport) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
    // setTimeout(closePopup, 7000);
  }

  

  return (
    <Button fluid basic color="grey" 
      disabled={disabled} onClick={()=>{getData(filters)}}
      style={style}
      >
      {/* <Icon name="excel file" /> */}
       {text?`${text} `:''}{total ? `(${total})` : ""}

       
    </Button>
  );
}

export default (ExportButton);
