import React, { useState, useEffect } from "react";
import { Segment, Button, Header, Checkbox, Divider } from "semantic-ui-react";
import EditInputField from "./../../components/Order/v2/Vieworder/editfield";

import { Alert } from "rsuite";
import {
  getWarehouses,
  updateWarehouse,
  getSettings,
  updateSetting,
} from "../../actions/warehouses";
import CountryFilter from "../Filters/CountryFilter";

function Warehouse({ match }) {
  const [warehouse, setWarehouse] = useState();
  const [updateField, setShowUpdateField] = useState({
    name: null,
    value: null,
  });
  const [settings, setSettings] = useState({
    move_packed_orders_to_shipping: false,
  });

  const handleUpdateField = (segment) => {
    updateWarehouse({
      updates: { [updateField.name]: updateField.value },
      id: warehouse._id,
    })
      .then((warehouse) => {
        setWarehouse(warehouse);
      })
      .catch((err) => Alert.error(err.message));

    setShowUpdateField({ name: null, value: null });
  };

  useEffect(() => {
    getWarehouses({ warehouse: match.params.id }).then((data) =>
      setWarehouse(data[0])
    );

    getSettings(match.params.id).then((data) => {
      if (data) setSettings(data);
    });
  }, [match.params.id]);

  if (!warehouse) return <div>No warehouse</div>;
  return (
    <section style={{ margin: "25px",maxWidth:'500px' }}>
      <Header as="h2">{warehouse.name}</Header>
      <Segment>
        <table>
          <EditInputField
            text="First name"
            name="name"
            value={warehouse.name}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="name"
            updateFieldBlock="address"
          />

          <tr id="countries_supply " style={{ height: "33px" }}>
            <td style={{ width: "100px", fontWeight: "bold" }}>
              Countries Supply
            </td>
            <td style={{ textAlign: "left" }}>
              {updateField.name === "countries_supply" && (
                <div>
                  <CountryFilter
                    multiple={true}
                    style={{ maxWidth: "200px" }}
                    useFilter={false}
                    value={updateField.value}
                    handleChange={(value) => {
                      setShowUpdateField({ ...updateField, value });
                    }}
                  />
                </div>
              )}
              {updateField.name !== "countries_supply" &&
                warehouse.countries_supply.map((item) => `${item}, `)}
            </td>
            <td style={{ textAlign: "right", width: "150px" }}>
              {updateField.name === "countries_supply" && (
                <div>
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => {
                      setShowUpdateField({ name: null });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="mini"
                    basic
                    color="green"
                    onClick={() => handleUpdateField("address")}
                  >
                    Save
                  </Button>
                </div>
              )}

              {updateField.name !== "countries_supply" && (
                <Button
                  icon="edit"
                  basic
                  size="mini"
                  onClick={() => {
                    setShowUpdateField({
                      name: "countries_supply",
                      value: warehouse.countries_supply,
                    });
                  }}
                ></Button>
              )}
            </td>
          </tr>
          <Divider></Divider>
          <h5>Address</h5>
          <EditInputField
            text="Company"
            name="address.company"
            value={warehouse.address.company}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="company"
            updateFieldBlock="address"
          />

          <EditInputField
            text="Email"
            name="address.email"
            value={warehouse.address.email}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="email"
            updateFieldBlock="address"
          />

          <EditInputField
            text="Phone"
            name="address.phone"
            value={warehouse.address.phone}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="phone"
            updateFieldBlock="address"
          />

          <tr id="country " style={{ height: "33px" }}>
            <td style={{ width: "100px", fontWeight: "bold" }}>Country</td>
            <td style={{ textAlign: "left" }}>
              {updateField.name === "address.country" && (
                <div>
                  <CountryFilter
                    style={{ maxWidth: "200px" }}
                    useFilter={false}
                    value={updateField.value}
                    handleChange={(value) => {
                      setShowUpdateField({ ...updateField, value });
                    }}
                  />
                </div>
              )}
              {updateField.name !== "address.country" &&
                warehouse.address.country}
            </td>
            <td style={{ textAlign: "right", width: "150px" }}>
              {updateField.name === "address.country" && (
                <div>
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => {
                      setShowUpdateField({ name: null });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="mini"
                    basic
                    color="green"
                    onClick={() => handleUpdateField("address")}
                  >
                    Save
                  </Button>
                </div>
              )}

              {updateField.name !== "address.country" && (
                <Button
                  icon="edit"
                  basic
                  size="mini"
                  onClick={() => {
                    setShowUpdateField({
                      name: "address.country",
                      value: warehouse.address.country,
                    });
                  }}
                ></Button>
              )}
            </td>
          </tr>

          <EditInputField
            text="City"
            name="address.city"
            value={warehouse.address.city}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="city"
            updateFieldBlock="address"
          />
          <EditInputField
            text="Street"
            name="address.street"
            value={warehouse.address.street}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="street"
            updateFieldBlock="address"
          />

          <EditInputField
            text="Street Nr."
            name="address.streetNr"
            value={warehouse.address.streetNr}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="streetNr"
            updateFieldBlock="address"
          />

          <EditInputField
            text="Post code"
            name="address.post"
            value={warehouse.address.post}
            updateField={updateField}
            setShowUpdateField={setShowUpdateField}
            handleUpdateField={handleUpdateField}
            id="post"
            updateFieldBlock="address"
          />
        </table>
        <Divider></Divider>
        <div className="flex--column">
          <Checkbox
            label="Partner Warehouse"
            checked={warehouse.isPartner}
            name="isPartner"
            onChange={() =>
              updateWarehouse({
                updates: { isPartner: !warehouse.isPartner },
                id: warehouse._id,
              })
                .then((warehouse) => {
                  setWarehouse(warehouse);
                })
                .catch((err) => Alert.error(err.message))
            }
          />
          <Checkbox
            label="Move packed Orders to shipping at 22:00."
            checked={settings ? settings.move_packed_orders_to_shipping : false}
            name="isPartner"
            onChange={() =>
              updateSetting({
                id: match.params.id,
                body: {
                  move_packed_orders_to_shipping:
                    !settings.move_packed_orders_to_shipping,
                },
              }).then((data) => setSettings(data))
            }
          />
        </div>
      </Segment>
    </section>
  );
}

export default Warehouse;
