import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Header,
  Button,
  Segment,
  // Accordion,
  Tab,
} from "semantic-ui-react";
import { Alert } from "rsuite";
import {
  getProduct,
  updateProduct,
  getMovements,
  deleteProduct,
} from "../../../actions/product";
import CanView from "../../../role-based-access/CanView.jsx";

import CanViewPage from "../../../role-based-access/CanViewPage";
import Documents from "../../../components/Documents";
import Relatedproducts from "./related";
import ProductInfoGrid from "./ProductInfoGrid";
import { Stockmovements } from "./stockmovements";
import Stockavailable from "./stockavailable";

import "../product.scss";
// import { ReactComponent as NoImg } from "../../../no-img.svg";

import Pricing from "./pricing/Pricing";

const EditViewProduct = (props) => {
  const [state, setState] = useState({
    updates: {},
    productForm: {
      proprities: { dimentions: { height: "" } },
      businessclient: null,
      _id: null,
      codes: { sku: null },
    },
  });

  const [stocks, setStockmoves] = useState({
    warehouseTotal: [],
    stockmoves: { total: 0, movements: [] },
  });

  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    clientOrderNr: "",
    currentPage: 1,
    itemsPerPage: 10,
  });

  const [isLoading, setLoading] = useState(true);

  const handleDeleteProduct = () => {
    deleteProduct({
      id: props.match.params.id,
      history: props.history,
    }).then((resp) => {});
  };
  const handleTabChange = (e, { activeIndex }) => {
    props.history.push(`/products/${props.match.params.id}`);
    setState({ activeIndex });
  };

  const handleFormChange = (e, { name, value }) => {
    updateProduct(props.match.params.id, { [name]: value }).then((resp) => {
      if (resp._id)
        setState({
          ...state,
          formHasChanges: false,
          productForm: resp,
          updates: {},
        });

      setLoading(false);
    });
  };

  const { id } = props.match.params;

  useEffect(() => {
    getProduct(id)
      .then((resp) => {
        setState({ ...state, productForm: resp.foundProduct });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    id &&
      getMovements({ id, filters }).then((resp) => {
        setLoading(false);
        setStockmoves(resp);
      });
  }, [filters, id]);

  const { productForm, activeIndex } = state;

  const productInfo = (
    <div className="">
      {state.productForm.codes.sku && (
        <ProductInfoGrid
          handleFormChange={handleFormChange}
          productForm={productForm}
        />
      )}
    </div>
  );

  const panes = [
    {
      menuItem: "General",
      render: () => (
        <div className="product">
          {productInfo}

          {/* <Productcards
              getCardData={(data)=>console.log(data)}
              products={productsdata}
            /> */}

          <Pricing
            isLoading={props.isLoading}
            productId={props.match.params.id}
          />

          <Stockavailable id={props.match.params.id} />
          <Documents
            trigger="Product"
            triggerId={props.match.params.id}
            businessclient={productForm.businessclient}
          />
          <Stockmovements
            filters={filters}
            setFilters={(data) => setFilters(data)}
            stockMovements={stocks.stockmoves}
            loading={isLoading}
          />
        </div>
      ),
    },
    {
      menuItem: "Promos",
      render: () => <div>test</div>,
    },
    {
      menuItem: "Related Products",
      render: () => (
        <div>
          <Segment>
            <Relatedproducts
              // relatedProducts={product.relatedProducts}
              businessclient={productForm.businessclient._id}
              productId={productForm._id}
            ></Relatedproducts>
          </Segment>
        </div>
      ),
    },
  ];

  return (
    <CanViewPage path="/products/:id">
      <div style={{ maxWidth: "1100px", margin: "30px" }}>
        <div className="flex_spaceBetween">
          <div className="">
            <Header as="h1">
              {/* <span className="grey--text">Product: </span>  */}
              {productForm.name}
            </Header>
            <span className="smallinfo">
              {productForm.businessclient && productForm.businessclient.name}
            </span>
          </div>
          <CanView path="/products/:id" action="delete">       
          
               <Button basic color="red" onClick={handleDeleteProduct}>
              Delete product
            </Button>
          </CanView>
        </div>

        {/* <div className="product">
            {productInfo}
            <Pricing
              isLoading={props.isLoading}
              pricing={props.product.pricing}
              productId={props.product._id}
            />


            <Stockavailable stockTotals={props.stockTotals} />
            <Stockmovements movements={stockMovements} />
          </div> */}

        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </div>
    </CanViewPage>
  );
};

export default connect(null, {})(EditViewProduct);
