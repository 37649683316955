import {
    apiCall
} from "../services/api"


export const getProductsWithMoves = (filter) => {
  const url = `${process.env.REACT_APP_API}/dashboard`;
  return apiCall("post", url, filter) 
    .then((data) => {
       return data})
    .catch((err) => {
    return (err.message)});
};


export const getProductLine_chartsjs = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/linechart`
    return apiCall('post', url, filter)  
            .then(data => (data))
            .catch(err => (err.message))

}

export const getStockbalanceLine = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/stockbalanceline`
    return apiCall('post', url, filter)  
        .then(data => (data))
        .catch(err => ((err.message)))

}

export const getBarchartProductSales = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/barchartsales`
    return apiCall('post', url, filter)
        .then(data => (data))
        .catch(err => ((err.message)))

}
export const getBar2periods = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/barchart2periods`
    return apiCall('post', url, filter)
        .then(data => (data))
        .catch(err => ((err.message)))

}
export const getPieProductpercent = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/productpercent`
    return apiCall('post', url, filter)
        .then(data => (data))
        .catch(err => ((err.message)))

}


export const getPieProductStatuses = (filter) => {

    const url = `${process.env.REACT_APP_API}/dashboard/productstatus`
    return apiCall('post', url, filter)
        .then(data => (data))
        .catch(err => ((err.message)))

}



