import {
  GET_STOCKMOVEMENT,
  GET_INVENTORY,
  WAREHOUSE_FILTER,
} from "./actionTypes";

import { apiCall } from "../services/api";

import { addError, removeError, addSuccessMsg } from "./error";
import {Alert} from 'rsuite'

export const searchSku = (query) => (dispatch) => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/warehouse/${query.warehouse_id}/inventory/${query.sku}`;

  apiCall("post", url)
    .then((resp) => dispatch(getAllInventoryAction(resp)))
    .catch((err) => dispatch(addError(err.message)));
};


export const createWarehouse = (body) => (dispatch) => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/warehouse`;
  apiCall("post", url, body)
    .then((resp) => {
      dispatch(addSuccessMsg("The warehouse was successfully created"));
      dispatch({ type: "CREATE_WAREHOUSE", payload: resp });
    })
    .catch((err) => dispatch(addError(err.message)));
};


export const getStockMovements = ({ limit, skip, warehouse_id, sku }) => (
  dispatch
) => {
  const url = `${process.env.REACT_APP_API}/warehouse/${warehouse_id}/inventory/${sku}/movement/${skip}/${limit}`;
  apiCall("get", url)
    .then((resp) => dispatch(getStockMovementAction(resp)))
    .catch((err) => dispatch(addError(err.message)));
};
const getStockMovementAction = (data) => ({
  type: GET_STOCKMOVEMENT,
  data,
});

export const getAllInventory = (query) => {
  const url = `${process.env.REACT_APP_API}/inventorycards/stocks`;

  return apiCall("post", url, query)
    .then((resp) => ((resp)))
    .catch((err) => (Alert.error(err.message)));
};

const getAllInventoryAction = (data) => ({
  type: GET_INVENTORY,
  data,
});
export const uploadPhotos = ({ id, files, removedFiles }) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/inventorycards/${id}/photos`;

  apiCall("post", url, { files, removedFiles })
    .then((resp) => dispatch(getAllInventoryAction(resp)))
    .catch((err) => dispatch(addError(err.message)));
};

export const updateFilterAction = (filter) => ({
  type: WAREHOUSE_FILTER,
  filter,
});

// export const updateFilter = (filter) => (dispatch, getState) => {
//   const updateFilterPromise = new Promise((resolve, reject) => {
//     resolve(dispatch(updateFilterAction(filter)));
//   });
//   updateFilterPromise.then(() => {
//     const filters = getState().inventory.filters;
//     dispatch(getAllInventory(filters));
//   });
// };

export const inventoryCount = (query) =>  {
  //query : {product,warehouse,quantity_available,quantity_blocked,quantity_damaged}
return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/warehouse/inventorycount`;

  apiCall("post", url, query)
    .then((resp) =>resolve (resp)
    )
    .catch((err) => reject(err.message))
})

};

/// Inventory Counts
export const getInventoryCounts = (filters) =>  {
  return new Promise((resolve,reject)=>{
    const filtersUrl = filters
    ? `/?currentPage=${filters.currentPage}&itemsPerPage=${filters.itemsPerPage}`
    : "";
  
  apiCall("get", `${process.env.REACT_APP_API}/inventorycount${filtersUrl}`)
    .then((resp) => resolve(resp))
    .catch((err) => {
        reject(err.message)
    });
  })

};

export const updateInventoryCard = ({id,body})=>{

  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/inventorycards/${id}/update`;
    apiCall('put',url,body)
    .then(resp=>resolve(resp))
    .catch(err=>reject(err.message))
  })
  
}