import React  from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import CreateUpdateTime from "../../CreateUpdateTime";

function CourierTable(props) {

  const { openModal, couriers }  = props;

  // const {itemsPerPage,currentPage,searchTerm,country} =filters



  if (!couriers.length)
    return <p className="center--text">No couriers found</p>;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell collapsing>Default</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Date</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = couriers.map(
    ({ _id, name, country, isDefault, createdAt, updatedAt }, index) => (
      <Table.Row key={index}>
        <Table.Cell textAlign="right" verticalAlign="middle">
          {index + 1}
        </Table.Cell>
        <Table.Cell verticalAlign="middle">
          <Link to={`/couriers/${_id}`}>
            <span className="bold--text x-large--text">{name}</span>
          </Link>
        </Table.Cell>
        <Table.Cell verticalAlign="middle" collapsing>
          <span>{isDefault ? <Icon name="check" color="grey" /> : ""}</span>
        </Table.Cell>
        <Table.Cell verticalAlign="middle">
          <span>{country}</span>
        </Table.Cell>
        <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell>
        <Table.Cell textAlign="right" verticalAlign="middle">
          <Button
            basic
            compact
            size="tiny"
            icon="pencil alternate"
            onClick={() => openModal(true, { _id, name, country, isDefault })}
          />
        </Table.Cell>
      </Table.Row>
    )
  );
  return (
    <Table unstackable basic="very" selectable>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

const mapStateToProps = reduxState => ({
  filters: reduxState.filtersmain
});

export default connect(mapStateToProps,{})(CourierTable);
