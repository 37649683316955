import React from "react";
import { Header, Segment, Table, Dimmer, Loader } from "semantic-ui-react";
import Moment from "react-moment";

function ShaversTable({ shavers, isLoading, currentPage, itemsPerPage }) {
  if (isLoading)
    return (
      <Segment style={{ paddingTop: "4em", paddingBottom: "4em" }}>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </Segment>
    );

  if (!shavers.length && !isLoading) {
    return (
      <Segment>
        <Header
          style={{ paddingTop: "2em", paddingBottom: "1em" }}
          as="h4"
          textAlign="center"
          color="grey"
        >
          No shavers found
        </Header>
      </Segment>
    );
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Sku</Table.HeaderCell>
      <Table.HeaderCell>Web</Table.HeaderCell>
      <Table.HeaderCell>CreatedAt</Table.HeaderCell>
      <Table.HeaderCell>Target Rate (Total Orders)</Table.HeaderCell>
      <Table.HeaderCell>End_Rate (Approved Orders)</Table.HeaderCell>
      <Table.HeaderCell>Setup Range</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = shavers.map(
    (
      {
        _id,
        country,
        sku,
        min_rate,
        max_rate,
        target_rate,
        web,
        total_approved,
        total_orders,
        createdAt,
        end_rate,
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{sku}</Table.Cell>
        <Table.Cell>{web}</Table.Cell>
        <Table.Cell>
          <Moment format="DD/MM/YYYY">{createdAt}</Moment>
        </Table.Cell>
        <Table.Cell>
          {target_rate} / ({total_orders})
        </Table.Cell>
        <Table.Cell>
          {end_rate} / ({total_approved})
        </Table.Cell>
        <Table.Cell>
          {min_rate} - {max_rate}
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Table>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default ShaversTable;
