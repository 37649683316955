import React, { useState, useEffect } from "react";
import { Button, Header,  Form } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
// import Dropzone from "react-dropzone";
// import { ExcelRenderer } from "react-excel-renderer";
import { Alert } from "rsuite";
import {
  // changeStatusFromFile,
  changeStatusFromSelection,
  resetChangeStatus,
} from "../../../actions/orders";
// import ExportExcel from "../../ExportExcel";
// import { useExcelOrderData } from "./useExcelOrderData";
// import CourierFilter from "../../Filters/CourierFilter";
import "../../Dropzone/dropzone.scss";
import OrderStatusFilter from "../../Filters/OrderStatusFilter";

// const initDragText = "Drag 'n' drop the file or click here";

// const statuses = [
//   "Product Picking",
//   "Order Ready for picking",
//   "Packed",
//   "Shipping",
//   "Unsuccessful attempt",
//   "Delivered",
//   "COD RECEIVED",
//   "COD WAITING",
//   "COD TRANFFERED TO CLIENT",
//   "No goods",
//   "Damaged with courier",
//   "Done",
//   "Canceled",
//   "New",
//   "Delivery date",
//   "At Courier",
//   "Not shipped",
//   "Error",
//   "Courier data received - no dispached yet",
//   "At parcelshop",
// ];

const statusListToShowConfirm = ["Canceled"];

function ChangeStatusModal(props) {
  const {
    isOpen,
    checkedItems,
    // handleSearchOrders,
    closeStatusModal,
  } = props;

  const [selectedStatus, setStatus] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleStatusChange = (e, { value }) => setStatus(value);
  // const handleCourierChange = (e, { value }) => setCourier(value);

  const submitStatusChange = (e) => {
    // setLoading(true)
    if (e) e.preventDefault();
    Alert.info("Updating...");
    closeStatusModal();

    changeStatusFromSelection({
      arrayOfIds: checkedItems,
      newStatus: selectedStatus,
    }).then((resp) => {
      setLoading(false);
      // handleClearCheckedItems()
      // alert("Status updated.")
    });
  };

  useEffect(() => {
    if (statusListToShowConfirm.includes(selectedStatus)) {
      const confirmed = window.confirm(
        `Are you sure you want to change the status to ${selectedStatus}?`
      );
      if (confirmed) {
        // submitStatusChange();
        return;
      } else setStatus("");
    }
  }, [selectedStatus]);

  // console.log({ data, selectedStatus, courierId });
  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={closeStatusModal}
      center
      classNames={{
        modal: "",
      }}
    >
      <Header as="h4">Change Status</Header>
      <Form
        loading={isLoading}
        style={{
          padding: "0.8em 0.4em 0.1em",
        }}
        className="change-status-form"
      >
        <div className="change-status-form__section">
          <p>Select new status</p>
          {/* <Dropdown
            placeholder="new status..."
            name="status"
            fluid
            search
            clearable
            selection
            closeOnBlur
            closeOnChange
            value={selectedStatus}
            onChange={handleStatusChange}
            options={statuses.map((status, i) => ({
              key: i,
              value: status,
              text: status,
            }))}
          /> */}
          <OrderStatusFilter
            value={selectedStatus}
            name="status"
            handleChange={handleStatusChange}
          ></OrderStatusFilter>
        </div>

        <div style={{ marginTop: "2.7em" }} className="right--text">
          <Button size="large" onClick={closeStatusModal}>
            Cancel
          </Button>
          <Button
            // loading={isLoading}
            size="large"
            color="orange"
            disabled={!selectedStatus}
            onClick={submitStatusChange}
          >
            Change Status
          </Button>
        </div>
        {/* {!feedbackStatus && (
          <p
            style={{ marginTop: "0.4em" }}
            className="small--text center--text"
          >
            ! This action ignores any order's businesslogic
          </p>
        )} */}
      </Form>

      {/* {feedbackStatus && (
        <div style={{ maxWidth: "274px" }}>
          <Segment
            secondary
            textAlign="center"
            style={{
              backgroundColor: feedbackStatusColor[feedbackStatus],
              marginBottom: "0em",
              marginTop: "2em",
            }}
          >
            <p>{message}</p>
            {failedOrders.length > 0 && (
              <ExportExcel
                filename="orders_status_not_changed"
                excelSheetName="orders"
                dataSet={failedOrdersExcelData}
                colNames={colNames}
                button={
                  <Button color="yellow">
                    <Icon name="file excel" />
                    Download orders
                  </Button>
                }
              />
            )}
          </Segment>
        </div>
      )} */}
    </Modal>
  );
}

export default connect(null, {
  // changeStatusFromFile,
  resetChangeStatus,
})(ChangeStatusModal);
