import React,{useState,useEffect} from 'react'

import {Segment,Header} from 'semantic-ui-react'
import {getUnbilledServices} from '../../../actions/billableservices'

const Unbilledservices =(props)=>{

const [services,setServices] = useState([])

    useEffect(()=>{

        getUnbilledServices().then(data=>setServices(data))
    },[])

    const items = services.map(item=>{
       return  <ClientCurrencyGroup item={item}/>

        
    })

    return (
        <Segment style={{margin:'20px',maxWidth:'900px'}}>
            <Header>Unbiled services list</Header>
           <div className='flex--between'> {items} </div> 
           
        </Segment>
    )
}

function ClientCurrencyGroup({item}){
    
    return (
      <div style={{ maxHeight: "500px", width: "400px",  margin: "5px" }}>
        <Segment>
          <h4>{item.businessclient_details.name}</h4>
          <div className="flex--between">
            <div></div>
            <h5>
              {item._id.currency} {Math.round(item.grandTotal)}
            </h5>
          </div>

          {item.services.map((item, i) => {
            return (
              <tr className="flex--between">
                <td>{item.service_details[0].name}</td>
                <td>{Math.round(item.total)}</td>
              </tr>
            );
          })}
        </Segment>
      </div>
    );
}

export default Unbilledservices