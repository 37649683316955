import React, { useEffect } from "react";
import { Header, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import CanViewPage from "../../../role-based-access/CanViewPage";
import Userlist from "./userlist";
// import Filters from "./Filters";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";

import { updateFilters, getOnlineusers } from "../../../actions/onlineusers";

function Onlineusers({
  users,
  filters,
  isLoading,
  total,
  updateFilters,
  getOnlineusers,
}) {
  useEffect(() => getOnlineusers(filters), [filters]);
  const { itemsPerPage, currentPage } = filters;

  const handleFilterChange = (e, { name, value }) => {
    updateFilters({ [name]: value, currentPage: 1 });
  };

  const handlePageChange = ({ currentPage }) => {
    updateFilters({ currentPage });
  };

  return (
    <CanViewPage path="/services">
      <main className="page-wrapper">
        <div className="flex--between">
          <Header style={{ marginBottom: 0 }}>Online users</Header>
          {/* <Link to="/billing">See Bills</Link> */}
        </div>
        {/* <Filters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    updateFilters={updateFilters}
                /> */}
        <Segment>
          <div className="flex--right">
            {/* <ExportButton /> */}
            <ItemsPerPage
              style={{ marginLeft: "10px" }}
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
            />
          </div>
          <Userlist
            users={users}
            isLoading={isLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            // isHeaderChecked={isHeaderChecked}
            // checkedItems={checkedItems}
            // handleSingleCheck={handleSingleCheck}
            // handleMultipleCheck={handleMultipleCheck}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={handlePageChange}
            />
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  filters: reduxState.onlineusers.filters,
  isLoading: reduxState.onlineusers.isLoading,
  users: reduxState.onlineusers.list,
  total: reduxState.onlineusers.total,
});
export default connect(mapStateToProps, { updateFilters, getOnlineusers })(
  Onlineusers
);
