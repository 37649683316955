import React, { useEffect } from "react";
import { Segment,  Loader, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  getPromo,
  updatePromo,
  addPromoProducts,
  removePromoProducts,removePromoDisplayProducts,addPromoDisplayProducts,removePromo
} from "../../../../actions/promo";

import CanViewPage from "../../../../role-based-access/CanViewPage";

import ProductGroupInfo from "./ProductGroupInfo";
import "../../productGroup.scss";

function EditViewPromo(props) {
  const {
    promo = { products: [] },
    isLoading,
    getPromo,
    updatePromo,
    addPromoProducts,
    removePromoProducts,
    addPromoDisplayProducts,
    removePromoDisplayProducts,
    match,
    history,
    businessclient,
  } = props;

  useEffect(() => getPromo(match.params.id), [match.params.id]);

  const addProducts = product =>addPromoProducts({ id: match.params.id, product });
  const removeProduct = product =>removePromoProducts({ id: match.params.id, product });
  const addPromoDisplays = products =>addPromoDisplayProducts({ _id: match.params.id, products });
  const removePromoDispays = products =>removePromoDisplayProducts({ _id: match.params.id, products });



  const productsFromPG = promo.promoProducts;
   
  // promo.promoProducts && promo.promoProducts.length > 0
  //     ? promo.promoProducts.map(({ product }) => product._id)
  //     : [];

  // console.log({ productsFromPG });

  if (!promo) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else {
    return (
      <CanViewPage path="/promos/:id">
        <main className="a-wrapper product-group-container">
          <Link to="/products/list/promos">
            <Icon name="arrow left" /> Product Related List
          </Link>

          <Segment>
            <ProductGroupInfo
              addDispProducts={addPromoDisplays}
              
              productsFromPG={productsFromPG}
              businessclient={businessclient}
              addPromoProducts={addProducts}
              productGroup={promo}
              removeProduct={removeProduct}
              removeDispProduct={removePromoDispays}
              updatePromo={updatePromo}
              history={history}
              isLoading={isLoading}
            />
          </Segment>
        </main>
      </CanViewPage>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  promo: reduxState.promos.openpromo,
  isLoading: reduxState.loading.loading,
  businessclient: reduxState.promos.openpromo.businessclient,
});
export default connect(mapStateToProps, {
  getPromo,
  updatePromo,
  addPromoProducts,
  removePromoProducts,
  removePromoDisplayProducts,addPromoDisplayProducts,removePromo
})(EditViewPromo);
