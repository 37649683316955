import React,{useEffect,useState} from "react";
import { Dropdown } from "semantic-ui-react";
import { getOrderStatuses } from "../../actions/enums";


function OrderStatusFilter({
  value,
  name = "status",
  handleChange,
  multiple,
  fluid=true,
  orderStatuses=[],
  setDropList,
  style={},
}) {


  const [searchTerm,setSearch] = useState()
  const [list, setlist] = useState([])

  
  useEffect(()=>{
   if (localStorage.orderStatuses && !searchTerm) {
     const list = JSON.parse(localStorage.getItem("orderStatuses") || "[]");
     setlist(list);
   } else
   
     getOrderStatuses().then((list) =>{
       setlist(list);
       if (!searchTerm)
         localStorage.setItem(`orderStatuses`, JSON.stringify(list));
     }
      
     );
  },[searchTerm])


  const options = list.map((status, i) => ({
    key: i,
    value: status,
    text: status,
  }));

  return (
    <Dropdown
      style={style}
      placeholder="status..."
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
    />
  );
}

export default (OrderStatusFilter);
