import {
  apiCall
} from "../services/api";
// import axios from "axios";
import {
  addError,
  // removeError,

} from "./error";
// import { Operator } from "../setup";
import {
  Alert
} from 'rsuite'

// const startLoading = { type: "LOADING" };
// const stopLoading = { type: "STOP_LOADING" };

// const getUsersAction = (data) => {
//   return {
//     type: "GET_USERS",
//     data,
//   };
// };



export const getUsers = ({
  currentPage,
  itemsPerPage,
  businessclient,
  role
}, searchTerm) => {

  return new Promise((resolve, reject) => {

    apiCall(
        `get`,
        `${process.env.REACT_APP_API}/users?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&searchTerm=${searchTerm}&businessclient=${businessclient}&role=${role}`
      )
      .then((users) => resolve(users))
      .catch((error) => reject(error.message));
  })

};

export const getUser = (userId) => {

  return apiCall(`get`, `${process.env.REACT_APP_API}/users/${userId}`)
    .then((user) => user)
    .catch((error) => {
      Alert.error(error.message)
    });
};



export const getUserProfile = () => {

  return new Promise((resolve, reject) => {
    apiCall(`get`, `${process.env.REACT_APP_API}/users/ownprofile`)
      .then((user) => resolve(user))
      .catch((error) => reject(error.message));
  })

};

export const updateOwnUser = (query) => {
  // debugger
  return new Promise((resolve, reject) => {
    apiCall("put", `${process.env.REACT_APP_API}/users/ownprofile`, query)
      .then((resp) => resolve(resp))
      .catch((error) => reject(error.message));
  })

};

export const updateUser = ({
  query,
  id
}) => {
  // debugger

  return new Promise((resolve, reject) => {
    apiCall("put", `${process.env.REACT_APP_API}/users/${id}`, query)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
       
      });
  })
};

export const createUser = (newUser) => {

  return new Promise((resolve, reject) => {
    apiCall("post", `${process.env.REACT_APP_API}/users`, newUser)
      .then((resp) => resolve(resp))
      // history.push("/users");)
      .catch((error) => reject(error.message));
  })

};

// export const deleteUser = (usernameToDelete) => (dispatch) => {
//   dispatch(startLoading);
//   axios
//     .delete(`${process.env.REACT_APP_API}/users/`, {
//       data: { usernameToDelete },
//     })
//     .then((users) => {
//       dispatch(removeError());
//       if (users.foundUsers && users.foundUsers.length > 0) {
//         let userlist = users.foundUsers.map((user) => new Operator(user));
//         dispatch(
//           getUsersAction({
//             userlist,
//             viewPermissions: users.routePermission,
//           })
//         );
//       }
//     })
//     .catch((error) => {
//       dispatch(addError(error.message));
//       dispatch(stopLoading);
//     });
// };

export const getPackers = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/users/get/packers`;
  apiCall("get", url)
    .then((res) => dispatch({
      type: "GET_PACKERS",
      payload: res
    }))
    .catch((error) => dispatch(addError(error.message)));
};

export const getRoles = () => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/settings/role`;
    apiCall("get", url)
      .then((res) => resolve(res))
      .catch((error) => reject(error.message))
  })

};

export const getRolesSettings = () => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/settings/role/rules`;
    apiCall("get", url)
      .then((res) => resolve(res))
      .catch((error) => reject(error.message))
  })

};

export const addRoleSettings = (history, {
  role,
  roleSettings
}) => (
  dispatch
) => {
  const url = `${process.env.REACT_APP_API}/settings/role/`;
  apiCall("post", url, {
      [role]: roleSettings
    })
    .then((res) => {
      // dispatch({ type: "GET_ROLES_SETTINGS", payload: res })
      history.push("/roles");
    })
    .catch((error) => dispatch(addError(error.message)));
};

export const updateRoleSettings = ({
  type,
  payload
}) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/settings/role/`;
  apiCall("put", url, {
      type,
      payload
    })
    .then((res) => {
      // dispatch({ type: "GET_ROLES_SETTINGS", payload: res })
    })
    .catch((error) => dispatch(addError(error.message)));
};