import { apiCall } from "../services/api";
import {Alert} from 'rsuite'

export const getLocation = ({ id, spacename }) => {
  const url = `${process.env.REACT_APP_API}/warehouse/${id}/${spacename}`;

  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
  

export const addStock = ({ id, spacename,body }) => {
  const url = `${process.env.REACT_APP_API}/warehouse/${id}/${spacename}/add`;

  return apiCall("post", url,body)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
  

export const removeStock = ({ id, spacename ,body}) => {
  const url = `${process.env.REACT_APP_API}/warehouse/${id}/${spacename}/remove`;

  return apiCall("post", url, body)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
  

export const changeLocation = ({ id, spacename,body }) => {
  const url = `${process.env.REACT_APP_API}/warehouse/${id}/${spacename}/move`;

  return apiCall("post", url, body)
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message));
};
  