import React, { useState } from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import { useToggleModalNewEdit } from "../../../../hooks/useToggleModalNewEdit";
import { useTableCheckbox } from "../../../../hooks/useTableCheckbox";
import PricingTable from "./PricingTable";
import PricingForm from "./PricingForm";


const defaultInitFormValues = {
  country: "",
  currency: "",
  brutto_price: "",
  VAT: ""
};

function Pricing(props) {
  const {  productId, isLoading } = props;

  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal
  } = useToggleModalNewEdit(defaultInitFormValues);

  const {
    isHeaderChecked,
    checkedItems,
    handleSingleCheck,
    handleMultipleCheck,
    handleClearCheckedItems
  } = useTableCheckbox();

  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const openCopyModal = () => setCopyModalOpen(true);
  const closeCopyModal = () => {
    setCopyModalOpen(false);
    handleClearCheckedItems();
  };

  return (
    <section loading={isLoading} className="card_">
      <div className="flex--between vertical--bottom">
        <Header as="h4" style={{ display: "block", margin: 0 }}>
          Pricing
        </Header>
        <div>
          {checkedItems.length > 0 && (
            <Button basic onClick={openCopyModal}>
              Copy Pricing
            </Button>
          )}
          <Button disabled={true} onClick={() => openModal(false)}>Add New</Button>
        </div>
      </div>
      <Segment>
        <PricingTable
          productId={productId}
          openEditModal={openModal}
          isHeaderChecked={isHeaderChecked}
          checkedItems={checkedItems}
          handleSingleCheck={handleSingleCheck}
          handleMultipleCheck={handleMultipleCheck}
        />
      </Segment>

      {/* Create/Edit Pricing Modal */}
      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={false}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        <PricingForm
          initFormValues={initFormValues}
          handleCloseModal={closeModal}
          isEditModeOn={isEditModeOn}
          productId={productId}
        />
      </Modal>

      {/* Copy Pricing to Other Products */}
      <Modal
        open={isCopyModalOpen}
        showCloseIcon={true}
        closeOnEsc={false}
        onClose={closeCopyModal}
        center
        classNames={{ modal: "" }}
      >
        {/* <PricingCopyToOther
          checkedItems={checkedItems}
          handleClearCheckedItems={handleClearCheckedItems}
          handleCloseModal={closeCopyModal}
          pricing={pricing}
        /> */}
      </Modal>
    </section>
  );
}

export default Pricing;
