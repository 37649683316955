import React, { useState, useEffect } from "react";
import { getDraftOrders } from "../../../actions/packing";
import OrdersTable from "../../Order/ListOrders/OrdersTable";


function DraftList(props) {
  const [orders,setOrders] = useState([])
  const [isLoading,setIsloading] = useState(false)
  
  
  useEffect(() => {
    setIsloading(true)
    getDraftOrders().then(resp=>{
      setIsloading(false)
       setOrders(resp)
    })
  }, []);

  return (
    <section>
      <OrdersTable
        orders={orders}
        // checkedItems={this.state.checkedItems}
        // headerIsChecked={this.state.headerIsChecked}
        // handleSingleCheck={this.handleSingleCheck}
        // handleMultipleCheck={this.handleMultipleCheck}
        currentPage={1}
        itemsPerPage={orders.length}
        checkColumn={false}
        actionColumn={false}
        isLoading={isLoading}
        orderLink="/packer/orders/"
        sortable={false}
      />
    </section>
  );
}

export default (DraftList);
