import React, { useEffect,useState } from "react";
import { Table, Button, Loader } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import {
  getSequences,
  createSequence,
  updateSequence,
  deleteSequence,
} from "../../../../actions/businessclient";
import { useToggleModalNewEdit } from "../../../../hooks/useToggleModalNewEdit";
import SequencesModal from "./SequencesModal";

const defaultInitialFormValues = {
  name: "",
  country: "",
  currency:"",
  type: "",
  identifier: 10,
  value: 1,
  positions: 6,
};

function SequencesTable({
  bclientId,
    
}) {
  const [isLoading,setIsloading] = useState(false)
  const [sequences,setSequences] = useState([])

  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitialFormValues);

  useEffect(() => getSequences(bclientId).then(resp=>setSequences(resp)), [bclientId]);

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Type</Table.HeaderCell>
      <Table.HeaderCell>Identifier</Table.HeaderCell>
      <Table.HeaderCell>Value</Table.HeaderCell>
      <Table.HeaderCell>Positions</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = sequences.map(
    ({ _id, name, country, currency, type, identifier, value, positions }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>

        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell>{identifier}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
        <Table.Cell>{positions}</Table.Cell>
        <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() => deleteSequence(_id).then(resp=>getSequences(bclientId).then(resp=>setSequences(resp)))}
          />
          <Button
            compact
            basic
            color="grey"
            icon="pencil"
            size="mini"
            onClick={() =>
              openModal(true, {
                _id,
                name,
                country,
                currency,
                type,
                identifier,
                value,
                positions,
              })
            }
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      {!sequences.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>No sequences saved yet.</p>
          <Button
            style={{ marginTop: "0.5em" }}
            compact
            content="Add sequence"
            onClick={() => openModal(false)}
          />
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content="Add new"
            onClick={() => openModal(false)}
          />
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        <SequencesModal
          initFormValues={initFormValues}
          handleClose={closeModal}
          isEditModeOn={isEditModeOn}
          create={(query) =>
            createSequence({ ...query, businessclient: bclientId }).then(resp=>getSequences(bclientId).then(resp=>setSequences(resp)))
          }
          edit={updateSequence}
        />
      </Modal>
    </>
  );
}


export default (SequencesTable);
