import React, { useState } from "react";
import {
  Button,
  Header
} from "semantic-ui-react";
import Modal from "react-responsive-modal";
import CourierFilter from '../../Filters/CourierFilter'
import { apiCall } from "../../../services/api";
import {Alert} from 'rsuite'
function AssignCourierModal(props) {
  const {
    handleCloseModal,
    isModalOpen,
    checkedOrders,
    
  } = props;
  const [courier, setCourier] = useState("");

  
  const submitUserAssignment = e => {
    Alert.info('Working...')    
    const url =`${process.env.REACT_APP_API}/orders/multiple/setcourier`
    
    apiCall("post", url, {courier,arrayOfIds:checkedOrders})
      .then((resp) => {
        handleCloseModal();
        Alert.success('Done')})
      .catch((err) => {
        handleCloseModal();

        Alert.error(err.message);
      });
  };


  return (
    <Modal
      open={isModalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{
        modal: ""
      }}
    >
      <Header as="h4">Select courier</Header>
      <div
        style={{
          padding: "0.8em 0.4em 0.1em"
        }}
        className="change-status-form"
      >
       <CourierFilter
        style={{width:"100%"}}
        useFilter={false}
        handleChange={(value)=>setCourier(value)}
       >

       </CourierFilter>
        
        <div style={{ marginTop: "2.7em" }} className="right--text">
          <Button size="large" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            size="large"
            color="orange"
            disabled={!courier}
            onClick={submitUserAssignment}
          >
            Assign
          </Button>
        </div>
      </div>
    </Modal>
  );
}


export default (AssignCourierModal);
