import React from "react";
import { Segment,Checkbox } from "semantic-ui-react";
// import { DateRangePicker } from "rsuite";

// import CountryFilter from '../Filters/CountryFilter'
// import ClientFilter from "../Filters/ClientFilter";
// import ServiceTypes from "../Filters/ServiceTypes";

function Filters({
  //  country ,
  //  setCountry,
   onlyRelative,
   setOnlyRelative,
   onlyShipping,
   setOnlyShipping
  }) {

  
  return (
    <Segment>
    <div className='flex--left'>
    <Checkbox
       onChange={()=>{
         
        if(onlyRelative!=='relative')setOnlyRelative('relative')
        else setOnlyRelative('')
      }
      }
       checked={onlyRelative==='relative'}
       label='only relative rates'
    ></Checkbox>
    <div style={{marginLeft:'10px'}}>
    <Checkbox
       onChange={()=>{
         
        if(onlyShipping!=='shipping')setOnlyShipping('shipping')
        else setOnlyShipping('')
      }}
       checked={onlyShipping==='shipping'}
       label='only shipping'
    ></Checkbox>
     <Checkbox
       onChange={()=>{
         
        if(onlyShipping!=='warehouse')setOnlyShipping('warehouse')
        else setOnlyShipping('')
      }}
       checked={onlyShipping==='warehouse'}
       label='only warehouse'
    ></Checkbox>
    </div>
    </div>    
    </Segment>
    
  );
}
export default Filters;
