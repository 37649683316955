
import { apiCall } from "../services/api";
import { addError } from "./error";
import { Alert } from "rsuite";

export const getAllOrders = ({ currentPage, itemsPerPage }) => {
  
  const url = `${process.env.REACT_APP_API}/packer/orders/?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`;
  
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => {
     Alert.error(err.message)
    });
};

export const getDraftOrders = () => {
 
  const url = `${process.env.REACT_APP_API}/packer/orders/draft`;

return  apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => {
    Alert.error(err.message)
    });
};

export const getAssignedOrders = () =>  {
 
  const url = `${process.env.REACT_APP_API}/packer/orders/assigned`;
  
  return apiCall("get", url)
    .then((resp) =>resp
    )
    .catch((err) => {
      Alert.error(err.message)
    });
};

export const getPackerOrder = (id) => {
  const url = `${process.env.REACT_APP_API}/packer/orders/${id}`;

 return new Promise((resolve,reject)=>{
   apiCall("get", url)
    .then((resp) => resolve(resp))
      
    .catch((err) => reject(err));
 }) 
};
export const getDocumentsToPrint = ({id,triggerIds,country}) => {
  const url = `${process.env.REACT_APP_API}/packer/orders/${id}/printdocuments`;
  return new Promise((resolve,reject)=>{
    apiCall("post", url,{triggerIds,country})
    .then((resp) => resolve(resp))
    .catch((err) => {reject(err)});
  })
  
};

export const getNextOrder = ({ history, orderId }) => {
  const url = `${process.env.REACT_APP_API}/packer/orders/get/nextorder/?id=${orderId}`;
  // dispatch(startLoading());
  // dispatch(removeError());
  // dispatch({type:"CLEAR_STATE_PPL"})
  return new Promise((resolve,reject)=>{
          apiCall("get", url)
              .then((resp) => {resolve(resp)

               
              })

              .catch((err) => {
                  reject(err)
              });
            })
  
};

export const orderAction = ({ action, rejectReason, orderId }) => {
  
  const url = `${process.env.REACT_APP_API}/packer/orders/${orderId}`;

  return new Promise((resolve,reject)=>{
    apiCall("post", url, { action, rejectReason })
    .then((resp) => {
      resolve(resp)
    })
    .catch((err) => reject(err))
       })

      }
// PRINT
export const printJob = (query, whatToPrint) => (dispatch) => {
  const url = `${process.env.REACT_APP_PRINTER_SRV}`;
  // console.log("##### PRINTING ON SERVER:", whatToPrint, query);
  dispatch({ type: `PRINT_${whatToPrint}_START` });
  apiCall("post", url, query)
    .then((resp) => {
      if (resp.status === "success") {
        dispatch({ type: `PRINT_${whatToPrint}_SUCCESS`,payload:resp });
        //if Got tracking, but no dataUrl, repeat with TrackingId to get data for printing
        if(resp.trackingId&&!resp.dataUrl) {
          apiCall("post", url, {...query,
                                  order:{...query.order,
                                          shipping:{
                                            ...query.order.shipping,  
                                          trackingId:resp.trackingId}}})
        }
      }
    })
    .catch((err) => {
      dispatch(
        addError(`Printer Server Error.
                        ${err.message}`)
      );
      dispatch({ type: `PRINT_${whatToPrint}_STOP` });
      // console.log('WHAT TO PRINT:',whatToPrint)
      if (whatToPrint === 'AWB') dispatch({
        type: 'PRINT_ERROR',
        message: err.message
      })
    });
};

export function clearState() {
  return {
    type: "CLEAR_STATE",
  };
}


