import React, { useState, useEffect } from "react";
import { Input, Button, Form, FormField, Divider } from "semantic-ui-react";
import Modal from "react-responsive-modal";

function Barcodemanualy({ showModal, setEnterBarcodeManualy }) {
  const [barcode, setBarcode] = useState("");
  const [foundProduct, setFoundProduct] = useState({
    product: null,
    unit_price: null,
  });
  const [quantity, setQuantity] = useState();

  useEffect(() => {
    setBarcode();
    setFoundProduct();
    setQuantity();
  }, [showModal]);

  const submitBarcodeToDatabase = (scannedBarcode) => {
    // Example: Send barcode to backend API to save in the database

    setFoundProduct({
      name: "Test product",
      unit_price: 2,
    });
    fetch("/api/saveBarcode", {
      method: "POST",
      body: JSON.stringify({ barcode: scannedBarcode }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFoundProduct(foundProduct);
        } else {
          console.error("Failed to submit barcode to the database.");
        }
        setBarcode("");
      })
      .catch((error) => {
        console.error("Error submitting barcode:", error);
        setBarcode("");
      });
  };

  return (
    <Modal
      styles={{ modal: { width: "400px" } }}
      open={showModal}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => {
        setEnterBarcodeManualy(false);
      }}
      center
      classNames={{
        modal: "",
      }}
    >
      <div>
        <h5>BARCODE MANUALY</h5>
        <Divider style={{ marginTop: "10px" }}></Divider>
        <Input
          //  fluid
          name="barcode_scan_input"
          style={{ margin: "5px" }}
          type="text"
          value={barcode}
          onChange={(e, { value }) => setBarcode(value)}
          placeholder="start typing..."
        />
        <Button onClick={() => submitBarcodeToDatabase()}>Find</Button>
        <Divider></Divider>
        {foundProduct && (
          <div>
            {foundProduct.name} - {foundProduct.unit_price} EUR
          </div>
        )}
        {foundProduct && (
          <Form>
            <FormField>
              <label>Quantity</label>
              <Input
                placeholder="quantity"
                value={quantity}
                onChange={(e, { value }) => setQuantity(value)}
              />
            </FormField>

            <Button
              type="submit"
              onClick={() => {
                setEnterBarcodeManualy(false);
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </div>
    </Modal>
  );
}

export default Barcodemanualy;
