import React, { useEffect, useState } from "react";
import { Header, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";

import { getRolesSettings, updateRoleSettings } from "../../../actions/user";
// import { getInfoTags } from "../../../actions/notifications";
import CanViewPage from "../../../role-based-access/CanViewPage";
import RoleSettings from "./RoleSetting";
import "./roles-settings-list.scss";

function ListRoles(props) {

  const [openedRole, setRoleOpen] = useState(null);
  const [settings,setSettings] = useState({})
  
  useEffect(() => {

    getRolesSettings().then(data=>setSettings(data));
  }, [openedRole]);

  // useEffect(() => {
  //   getInfoTags();
  // }, []);

  return (
    <CanViewPage path="/roles">
      <main className="page-wrapper a-wrapper roles">
        <div className="flex--between">
          <Header as="h1">Role Settings</Header>
          <Link to="/roles/create/new">
            <Button primary>Add Role</Button>
          </Link>
        </div>

        <section className="rules__section">
          <ul className="rules__list">

            {Object.entries(settings).map(([role, roleSettings]) => (
              <li key={role} className="rules__list-item">
                <div
                  className="aside-menu__header"
                  onClick={() =>
                    setRoleOpen((prev) => (prev === role ? null : role))
                  }
                >
                  <span className="aside-menu__header-text">{role}</span>
                  <Icon
                    name={`angle ${openedRole === role ? "up" : "down"}`}
                    size="large"
                    color="grey"
                    className="aside-menu__header-toggle"
                  />
                </div>
                {openedRole === role && (
                  <RoleSettings
                    key={role}
                    role={role}
                    settings={roleSettings}
                    adminSettings={settings.super_admin}
                    callback={updateRoleSettings}
                    // infotags={infotags}
                  />
                )}
              </li>
            ))}
          </ul>
        </section>
      </main>
    </CanViewPage>
  );
}


export default (ListRoles);
