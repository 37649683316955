import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../../../../ExportExcel";

function ExportButton(props) {
  const { filename,data,style={} } = props;
  const [dataSet, setData] = useState();

  const colNames = [

    { label: "Business Client", value: "businessclient" },
    { label: "Transfer number", value: "tranfferNr" },
    { label: "Status", value: "status" },
    { label: "Currency", value: "currency" },
    { label: "Amount", value: "amount" },
    { label: "CreatedAt", value: "createdAt" },
    
  ];

  useEffect(() => {
    const received = data.map(({businessclient,tranfferNr,status,currency,amount,createdAt})=>({
      businessclient:businessclient.name,
      tranfferNr,status,currency,amount,createdAt

    }))
      setData(received)

  }, [data]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button style ={style} basic color="grey" disabled={!data||!data.length}>
          <Icon name="excel file" />
          Export {`${data&&data.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
