import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getRelatedProductGroups, deleteRelatedProducGroup } from "../../../../actions/product";
import CreateUpdateTime from "../../../CreateUpdateTime";

function RelatedproductList(props) {
  const {
    productGroups,
    getRelatedProductGroups,
    deleteRelatedProducGroup,
    productId=null,
    businessclient=null,
  } = props;
  const [showAllProd, setShowAll] = useState("");
  

  useEffect(() => getRelatedProductGroups({product:productId,businessclient}), []);

  const toggleExpand = _id => {
    if (_id === showAllProd) {
      setShowAll("");
    } else {
      setShowAll(_id);
    }
  };

  const productsList = (_id, products) => {
    const len = showAllProd === _id ? products.length : 3;
    return products
      .filter((item, i) => i < len)
      .map(({ name, codes }, i) => (
        <li className="small--text grey--text" key={i}>
          <span className="bold--text">{products[i] && products[i].name}</span>{" "}
          - {products[i] && products[i].codes && products[i].codes.sku}
        </li>
      ));
  };

  const tableHead = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Products</Table.HeaderCell>
      <Table.HeaderCell>Dates</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );
  const tableBody = productGroups.map(
    ({ _id, name, products, createdAt, updatedAt }, index) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">
          <span style={{ lineHeight: "1.6" }}>{index + 1}</span>
        </Table.Cell>
        <Table.Cell>
          <p className="large--text bold--text">
            <Link to={`/relatedproducts/${_id}`}>{name}</Link>
          </p>
        </Table.Cell>
        <Table.Cell>
          {products.length} product{products.length !== 1 && "s"}
          <ol style={{ marginBottom: 0 }}>{productsList(_id, products)}</ol>
          {products.length > 3 && (
            <button
              className="pg-ellipsis-btn"
              onClick={() => toggleExpand(_id)}
            >
              <Icon
                name={showAllProd === _id ? "angle up" : "ellipsis horizontal"}
              />
            </button>
          )}
        </Table.Cell>
        <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell>
        <Table.Cell>
          <Button size="tiny" onClick={() => deleteRelatedProducGroup(_id)}>
            Delete
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  );

  if (!productGroups.length) return null;
  return (
    <div
      className="table-wrapper--horizontal-scroll"
      style={{ marginTop: "1em" }}
    >
      <Link to="/productgroup/create/new">
        <Button color="blue" basic>Add NEW</Button>
      </Link>
      <Table unstackable basic="very" selectable>
        <Table.Header>{tableHead}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
}

const mapStateToProps = reduxState => ({
  productGroups: reduxState.inventory.productGroups,
  isLoading: reduxState.loading.loading
});

export default connect(mapStateToProps, {
  getRelatedProductGroups,
  deleteRelatedProducGroup
})(RelatedproductList);
