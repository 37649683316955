import React from "react";
import { Label, Button } from "semantic-ui-react";

function View({ country, coid, taxid, _id, i, deleteClientIds }) {
  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />

        <br />
        {_id && (
          <Button
            compact
            circular
            basic
            className="list-item__delete-btn"
            size="small"
            icon="trash"
            onClick={() => deleteClientIds(_id)}
          />
        )}
      </div>

      <div className="view-grid view-grid_list-item ">
        <div className="view-grid__item">
          <label className="view-grid__label">Country:</label>
          <span className="view-grid__content">{country}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Company ID:</label>
          <span className="view-grid__content">{coid}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">TAX ID:</label>
          <span className="view-grid__content">{taxid}</span>
        </div>
      </div>

      {/* <Divider /> */}
    </div>
  );
}

export default View;
