import React, { useEffect, useState } from "react";
import {
  Header,
  Input,
  Form,
  Checkbox,
  Button,
  Divider
} from "semantic-ui-react";

import { createCourier, editCourier } from "../../../actions/couriers";
import CountryFilter from "../../Filters/CountryFilter";
import {Alert} from 'rsuite'

function CourierForm(props) {
  const {
    courier,
    isEditModeOn,
    handleClose,

  } = props;

  const [state, setState] = useState({
    name: "",
    country: "",
    isDefault: false
    // prices: [""]
  });

  useEffect(() => setState(courier), [courier]);

  const handleFieldChange = (e, { name, value }) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };
  const hanldleCheck = () => {
    setState(prevState => ({ ...prevState, isDefault: !prevState.isDefault }));
  };
  const handleSubmit = e => {
    // e.prevenDefault();
    isEditModeOn
      ? editCourier({ id: courier._id, updates: state }).then(resp=>{
        
        setState(resp)
        Alert.success('Updated')
      })
      : createCourier(state).then(resp=>Alert.success('Created'))
    handleClose();
  };
  const handleCancel = e => {
    // e.prevenDefault();
    handleClose();
  };

  //   console.log(state);
  return (
    <section>
      <Header as="h4">{isEditModeOn ? "Edit" : "New"} Courier</Header>
      <Divider />
      <div>
        <Form.Field className="courier-form__field">
          <label>Name:</label>
          <Input
            name="name"
            value={state.name}
            placeholder="name ..."
            onChange={handleFieldChange}
          />
        </Form.Field>
        <Form.Field className="courier-form__field">
          <label>Country:</label>
          <CountryFilter
            useFilter={false}
            value={state.country}
            handleChange={(value)=>handleFieldChange(null,{name:'country',value})}
          />
        </Form.Field>
        <Form.Field className="courier-form__field">
          <Checkbox
            label="Default"
            checked={state.isDefault}
            onChange={hanldleCheck}
          />
        </Form.Field>

        <div className="flex--right mt--1">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button color="blue" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </section>
  );
}

export default (CourierForm);
