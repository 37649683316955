import {
  ADD_INBOUND_SHIPMENT,
  ADD_INBOUND_SHIPMENT_PHOTOS,
} from "./actionTypes";

import { apiCall } from "../services/api";
import { addError, removeError } from "./error";


const stopLoading = { type: "STOP_LOADING" };

export const createInboundShipment = ({ shipment, history }) => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/parcels/inbound/create/new`;
  apiCall("post", url, shipment)
    .then(resp => {
      dispatch(addShipmentAction(resp));
      history.push(`/parcels/inbound/${resp._id}`);
    })
    .catch(error => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
const addShipmentAction = shipment => ({
  type: ADD_INBOUND_SHIPMENT,
  shipment
});



export const getShipment = id =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}`;
    apiCall("get", url)
      .then(resp => resolve(resp))
      .catch(error => reject(error.message));
  })

};

export const updateShipment = ({ id, query }) =>  {
return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}`;
  apiCall("put", url, query)
    .then(resp => resolve(resp))
    .catch(error => reject(error.message));
})

};

export const deleteShipment = id => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}`;
    apiCall("delete", url)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })
 
};

export const uploadFiles = ({ id, files }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}/documents`;
  apiCall("post", url, files)
    .then(photos => dispatch(addFilesAction(photos)))
    .catch(err => dispatch(addError(err.message)));
};

const addFilesAction = photos => ({
  type: ADD_INBOUND_SHIPMENT_PHOTOS,
  photos
});

export const createInventoryCard = ({
  id,
  item
}) => {

  return new Promise((resolve, reject) => {

    const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}/goodsreceived`;
    apiCall("post", url, item)
      .then(data => resolve(data))
      .catch(err => reject(err));

  })

};

export const updateInventorycard = ({ id, cardId, query }) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}/inventorycard/${cardId}`;
    apiCall("put", url, query)
      .then(data => resolve(data))
      .catch(err => reject(err.message));
  })

};


export const removeInventoryCard = ({
  id,
  itemId
}) => {

  return new Promise((resolve, reject) => {

    const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}/goodsreceived/${itemId}`;
    apiCall("delete", url)
      .then(goodsreceived => resolve(goodsreceived))
      .catch(err => reject(err))
  })

};

export const getShipments = filter => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/parcels/inbound`;
    apiCall("post", url, filter)
      .then(shipments => resolve(shipments))
      .catch(error => reject(error.message));
  })  

};
