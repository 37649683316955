import React, { useState } from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import UserAddModal from "./UserAddModal";
import UsersTable from "./UsersTable";


function Users({ courierId, refresh }) {
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => setAddModalOpen(false);

  return (
    <section>
      <div className="flex--between vertical--center mt--2">
        <Header as="h2">Courier Users</Header>
        <Button compact onClick={openAddModal}>
          Add New
        </Button>
      </div>
      <Segment padded>
        <UsersTable courierId={courierId} refresh={refresh} />
      </Segment>

      <Modal
        open={isAddModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeAddModal}
        center
        classNames={{ modal: "" }}
      >
        <UserAddModal handleCloseModal={closeAddModal} courierId={courierId} />
      </Modal>
    </section>
  );
}

export default Users;
