import {
  ADD_INBOUND_SHIPMENT,

  ADD_INBOUND_SHIPMENT_PHOTOS,
  INBOUND_SHIPMENT_GOODSRECEIVED,

} from "./actionTypes";

import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = { type: "START_LOADING" };
const stopLoading = { type: "STOP_LOADING" };

export const createInboundShipment = ({ shipment, history }) => dispatch => {
  dispatch(startLoading);

  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/shipments/inbound/create/new`;
  apiCall("post", url, shipment)
    .then(resp => {
      dispatch(addShipmentAction(resp));
      history.push(`/shipments/inbound/${resp._id}`);
      dispatch(stopLoading);

    })
    .catch(error => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
      
    });
};
const addShipmentAction = shipment => ({
  type: ADD_INBOUND_SHIPMENT,
  shipment
});


export const getShipment = id => {
  return new Promise(
    (resolve,reject)=>{

    const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
        apiCall("get", url)
          .then(resp => resolve(resp))
          .catch(error => reject((error.message)))
        
          }
        )
}

export const updateShipment = ({ id, query }) =>  {
  
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
    return apiCall("put", url, query)
      .then(resp => resolve(resp))
      .catch(error => reject(error.message));
  })
  
};

export const deleteShipment = id =>{
    return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
      apiCall("delete", url)
        .then(resp => resolve(resp))
        .catch(err => reject(err.message));
  })
  
};

export const uploadFiles = ({ id, files }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/documents`;
  apiCall("post", url, files)
    .then(photos => dispatch(addFilesAction(photos)))
    .catch(err => dispatch(addError(err.message)));
};

const addFilesAction = photos => ({
  type: ADD_INBOUND_SHIPMENT_PHOTOS,
  photos
});

export const createInventoryCard_old= ({ id, item }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived`;
  apiCall("post", url, item)
    .then(goodsreceived => dispatch(addInventoryToShipment(goodsreceived)))
    .catch(err => dispatch(addError(err.message)));
};

export const createInventoryCard= ({ id, item }) => {

  return new Promise((resolve,reject)=>{
      
    const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived`;
      apiCall("post", url, item)
        .then(data => resolve(data))
        .catch(err =>reject(err));

      })
  
};

export const grlineUpdate= ({id,lineId,query}) => {

  return new Promise((resolve,reject)=>{
      
    const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived/${lineId}`;
      apiCall("put", url, query)
        .then(data => resolve(data))
        .catch(err =>reject(err));

      })
  
};

const addInventoryToShipment = goodsreceived => ({
  type: INBOUND_SHIPMENT_GOODSRECEIVED,
  goodsreceived
});

export const updateInventorycard = ({ id, cardId, query }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/inventorycard/${cardId}`;
  apiCall("put", url, query)
    .then(data => dispatch(setGoodsreceived(data)))
    .catch(err => dispatch(addError(err.message)));
};


export const removeInventoryCard_old = ({ id, item }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived/${item}`;
  apiCall("delete", url)
    .then(goodsreceived => dispatch(setGoodsreceived(goodsreceived)))
    .catch(err => dispatch(addError(err.message)));
};



export const removeInventoryCard = ({ id, itemId })  => {

  return new Promise((resolve,reject)=>{

  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived/${itemId}`;
  apiCall("delete", url)
    .then(goodsreceived =>resolve (goodsreceived))
    .catch(err => reject(err))
  })

};

const setGoodsreceived = goodsreceived => ({
  type: INBOUND_SHIPMENT_GOODSRECEIVED,
  goodsreceived
});

export const getShipments = filter =>  {
  
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/shipments/inbound`;
    apiCall("post", url, filter)
      .then(shipments => resolve(shipments))
      .catch(error => {
        reject(error.message)
      });
  })

};



//Version 2
//Add items (no stock movements), only counting
// With in Inboud shipment form --> action the stocking procedure to warehouse address

export const addItem =({id,item})=>{
  return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/items`;
  
  apiCall("post", url, {item})
    .then(data =>resolve(data))
    .catch(err =>reject(err));
    
  })
}

export const updateItem = ({ id, item, inventory }) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/items/${inventory}`;

    apiCall("put", url, { item })
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const deleteItem =({id,itemId})=>{
  return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/items/${itemId}`;
  
  apiCall("delete", url)
    .then(data =>resolve(data))
    .catch(err =>reject(err));
    
  })
}

export const addItemToStock =({id,data})=>{

  const {warehouse,storagespace,items} = data

  return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/itemstostock`;
  
  apiCall("post", url, {warehouse,storagespace,items})
    .then(data =>resolve(data))
    .catch(err =>reject(err));
    
  })
}


export const getNotinstockbalance =({id})=>{
  return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/itemstostock`;
  
  apiCall("get", url)
    .then(data =>resolve(data))
    .catch(err =>reject(err));
    
  })
}
