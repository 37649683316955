import React,{useState} from "react";
import { Divider } from "rsuite";
import { Card } from "semantic-ui-react";

const Deliveryratecards = ({isMobile, list}) => {
  let items = [];

  const [viewMore,setViewMore] = useState(false)

  for (let j = 0; j < list.length; j++) {
    const item = list[j];

    let lines = [];

    for (let i = 0; i < item.statuses.length; i++) {
      const status = item.statuses[i];

      const newLine = (
        <tr>
          <th style={{ textAlign: "left" }}>
            {status.group} ({status.groupTotal})
          </th>
          <th style={{ width: "40px" }}>{Math.round(status.percentage)} %</th>
        </tr>
      );

      lines = [...lines, newLine];
    }

    const newItem = (
      <Card fluid={isMobile}>
        <Card.Content>
          <Card.Header>
            {item.courier} ({item.statuses[0].courierTotal})
          </Card.Header>
          <Card.Description>
            <table>{lines}</table>
          </Card.Description>
        </Card.Content>
      </Card>
    );
    items = [...items, newItem];

    if (!viewMore && j === 2) break;
  }

  return (
    <div>
      <Card.Group >{items}</Card.Group>
     {list.length>3&& <button style={{float:'right'}} onClick={()=>setViewMore(!viewMore)}>view {!viewMore?`more ${list.length-3}`:'less'}...</button>}
     {list.length>0&&  <Divider></Divider>}
    </div>
  );
};

export default Deliveryratecards;
