import React, { useEffect, useState } from "react";
import { Header, Segment, Icon, Button, Label } from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { getBillServices, deleteBill } from "../../../actions/services";
import {
  getBill,
  updateBill,
  addItem,
  removeItem,
} from "../../../actions/bills";
import CanViewPage from "../../../role-based-access/CanViewPage";
import ExportButton from "../../ExportButton";
import EditableText from "../../EditableText";
import ItemsList from "../CreateBill/ItemsList";
import Servicesummary from "./runbillsummary";
import AddItem from "../CreateBill/AddItem";
import Modal from "react-responsive-modal";
import Documents from "../../../components/Documents";
import "../bills.scss";

// const statusColor = {
//   Paid: "green",
//   "Not paid - In due": "yellow",
//   "Not paid- Over due": "red",
// };

function ViewBill({ match, history }) {
  const [bill, setBill] = useState({});
  const [servicesSummary, setServices] = useState([]);
  const [servicesTotal, setServiceTotal] = useState(0);

  const {
    sequenceNumber,
    isPaid,
    // dates = {},
    period = {},
    businessclient = {},
    issuer = {},
    country,
    currency,
    totals = {},
    creditNote,
  } = bill;

  const billId = match.params.id;

  const [isLoading, setLoading] = useState(false);
  const [invoiceNr, setInvoiceNr] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  const addItemAction = (item) => {
    setLoading(true);
    setModalOpen(false);

    addItem({ query: item, id: bill._id }).then((resp) => {
      setLoading(false);
      setBill(resp);
    });
  };

  const removeItemAction = (itemId) => {
    setLoading(true);
    setModalOpen(false);
    removeItem({ itemId, id: bill._id }).then((resp) => {
      setLoading(false);
      setBill(resp);
    });
  };

  const handleUpdate = (invoiceNr) => {
    setLoading(true);
    updateBill(billId, { sequenceNumber: invoiceNr }).then((resp) => {
      setLoading(false);
      setBill(resp);
    });
  };

  useEffect(() => {
    setInvoiceNr(sequenceNumber);
  }, [sequenceNumber, bill]);

  useEffect(() => {
    setLoading(true);
    getBill(billId).then((resp) => {
      setLoading(false);
      setBill(resp.bill);
    });

    getBillServices(billId).then((resp) => {
      setServices(resp.servicesSummary);
      setServiceTotal(resp.total);
    });
  }, [billId]);

  return (
    <CanViewPage path="/billing/:id">
      <Modal
        open={isModalOpen}
        showCloseIcon={true}
        closeOnEsc={false}
        onClose={() => setModalOpen(false)}
        center
        classNames={{ modal: "" }}
      >
        <div>
          <AddItem addItem={addItemAction} withAddVisible={true} />
        </div>
      </Modal>

      <main className="page-wrapper a-wrapper">
        <Link to="/billing">
          <Icon name="arrow left" /> Bills List
        </Link>

        <div className="flex--between">
          <div className="flex--left mt--1">
            <Header
            // style={{ display: "block", margin: "0 1em 0 0" }}
            >
              <div className="flex--between">
                <label className="view-transfer__label">Number:</label>
                <EditableText
                  loading={isLoading}
                  maxWidth="200px"
                  placeholder="amount..."
                  name="amount"
                  value={invoiceNr}
                  handleChange={(e, { value }) => {
                    if (value) setInvoiceNr(value);
                  }}
                />
                {/* {(sequenceNumber !== invoiceNr)&& ( */}
                <Button
                  basic
                  icon="save"
                  style={{ height: "30px" }}
                  size="tiny"
                  onClick={() => handleUpdate(invoiceNr)}
                ></Button>
                {/* )} */}
              </div>
            </Header>
          </div>
          <div>
            <Link to={`/billing/${billId}/pdf`}>
              View <Icon name="file" />
            </Link>

            <Button
              basic
              size="small"
              icon="trash"
              onClick={() => deleteBill({ id: billId, history: history })}
            ></Button>
          </div>
        </div>

        <Segment padded loading={isLoading}>
          <div className="bill-grid">
            <section className="bill-grid__details">
              <Description
                label="Status: "
                text={
                  <Label
                    color={isPaid ? "green" : "yellow"}
                    compact
                    size="small"
                  >
                    {isPaid ? "Paid" : "Not - Paid"}
                  </Label>
                }
              />
              <Description
                label="Billing Period: "
                text={
                  <>
                    <Moment utc format="DD/MM/YYYY">
                      {period.start}
                    </Moment>{" "}
                    - {/* <div>{period.end}</div> */}
                    <Moment utc format="DD/MM/YYYY">
                      {period.end}
                    </Moment>
                  </>
                }
              />
              <Description
                label="Issuer: "
                text={
                  issuer && (
                    <Link to={`/businessclients/${issuer._id}`}>
                      {issuer.name}
                    </Link>
                  )
                }
              />
              <Description
                label="Client: "
                text={
                  businessclient && (
                    <Link to={`/businessclients/${businessclient._id}`}>
                      {businessclient.name}
                    </Link>
                  )
                }
              />
            </section>
            <section className="bill-grid__details">
              <Description label="Country: " text={country} />
              <Description label="Curency: " text={currency} />
              <Description
                label="Credit note: "
                text={
                  creditNote && (
                    <Link to={`/creditnote/${creditNote._id}`}>
                      {creditNote.sequenceNumber}
                    </Link>
                  )
                }
              />
            </section>
            <section className="bill-grid__details">
              <Description
                label="VAT total: "
                text={totals.VAT_total && totals.VAT_total.toFixed(2)}
              />
              <Description
                label="NETTO total: "
                text={totals.NETTO_total && totals.NETTO_total.toFixed(2)}
              />
              <Description
                label="BRUTTO total: "
                text={totals.BRUTTO_total && totals.BRUTTO_total.toFixed(2)}
              />
            </section>
          </div>
        </Segment>

        {!isPaid && (
          <Button basic onClick={() => setModalOpen(true)}>
            Add Item
          </Button>
        )}

        <Segment padded loading={isLoading}>
          <Header as="h4">Items</Header>
          <ItemsList
            items={bill.items}
            isPaid={isPaid}
            removeItemAction={removeItemAction}
          />
        </Segment>
        {bill.businessclient && (
          <Documents
            businessclient={bill.businessclient}
            trigger="Bill"
            triggerId={billId}
          ></Documents>
        )}

        {servicesSummary && servicesSummary.length > 0 && (
          <Segment loading={isLoading} secondary style={{ marginTop: "3em" }}>
            <div className="flex--between">
              <Header as="h4">Services</Header>

              <div style={{ width: "100px" }}>
                <ExportButton
                  fileName={`${bill.sequenceNumber}_services`}
                  total={servicesTotal}
                  filters={{}}
                  url={`/bills/${billId}/services/export`}
                />
              </div>
            </div>

            <Servicesummary services={servicesSummary} />
          </Segment>
        )}
      </main>
    </CanViewPage>
  );
}

export default ViewBill

const Description = ({ label, text, children }) => (
  <div className="bill-description">
    <span className="bill-description__label">{label}</span>
    <span className="bill-description__text">{text}</span>
    {children && (
      <div className="bill-description bill-subdescription">{children}</div>
    )}
  </div>
);
