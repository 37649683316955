import React from "react";
import { Button, Icon } from "semantic-ui-react";
import ExportExcel from "../../ExportExcel";

function ExportButton({ list,days }) {
  
  

  const colNames = [
    { label: "Name", value: "name" },
    { label: "Country", value: "country" },
    { label: "Leads", value: "leads" },
    { label: "Stock", value: "stock" },
  ];

  // console.log("DATA EXPORT:", list);

    let tempDataSet = [];
    const lines = Object.keys(list);

    for (let i = 0; i < lines.length; i++) {
      const key = lines[i];
      let item = list[key];

      tempDataSet.push({
        name: item.product,
        country: JSON.stringify(item.warehouse),
        leads: item.leads,
        stock: item.stock,
      });

    
    }


  return (
    <ExportExcel
      filename={`lead_stock_${days}`}
      excelSheetName="xdays"
      dataSet={tempDataSet}
      colNames={colNames}
      button={
        <Button basic color="grey">
          <Icon name="excel file" />
          Export_per_warehouse
        </Button>
      }
    />
  );
}

//
export default ExportButton;
