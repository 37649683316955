import {
  UPDATE_ORDERS_FILTERS,
  GET_ORDER,
  CREATE_ORDER,

} from "./actionTypes";
import {
  apiCall
} from "../services/api";
import {
  removeError,
  addSuccessMsg
} from "./error";
import {
  startLoading,
  stopLoading
} from "./loading";
import {
  Alert
} from 'rsuite'


export const updateFilter = (newFilter) => {
  return {
    type: UPDATE_ORDERS_FILTERS,
    payload: newFilter,
  };
};


export const getOrders = (filters) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/orders`;
    apiCall("post", url, filters)
      .then((resp) => (resolve(resp)))
      .catch((err) => reject(err.message))
  })

};

export const getTotals = (filters) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/orders/totals`;
    apiCall("post", url, filters)
      .then((resp) => (resolve(resp)))
      .catch((err) => reject(err.message))
  })
}

export const getOrdersListIds = (clientOrderIds) => {

  const url = `${process.env.REACT_APP_API}/orders/find/clientOrderIds`;
  return apiCall("post", url, {
      clientOrderIds
    })
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message))
};
export const getOrdersSearch = (searchTerm) => {

  const url = `${process.env.REACT_APP_API}/orders/find/search`;
  return apiCall("post", url, {
      searchTerm
    })
    .then((resp) => resp)
    .catch((err) => Alert.error(err.message))
};



export const updateOrderV2 = ({
  query,
  id
}) => {

  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  return apiCall("put", url, query)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message)
    });
};

export const createOrder = (query, history) => (dispatch) => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/orders/create/new`;
  apiCall("post", url, query)
    .then((resp) => {
      dispatch({
        type: CREATE_ORDER,
        payload: resp
      });
      dispatch(addSuccessMsg("The order was created"));
      history.push(`/orders/${resp._id}`);
    })
    .catch((err) => Alert.error(err.message));
};



export const addOrderProductV2 = (id, item) => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/items`;
  return apiCall("post", url, {
      item
    })
    .then((order) => order)
    .catch((err) => {
      Alert.error(err.message)
    });
};


export const deleteOrderProductV2 = ({
  orderId,
  itemId,
  name,
  sku,
  quantity
}) => {
  const url = `${process.env.REACT_APP_API}/orders/${orderId}/items/${itemId}?name=${name}&sku=${sku}&quantity=${quantity}`;
  return apiCall("delete", url)
    .then((order) => order)
    .catch((err) => {
      Alert.error(err.message);
    });


}

export const manualAssing = ({
  orderIds,
  toUserId
}) => {

  return new Promise((resolve, reject) => {
    apiCall(`post`, `${process.env.REACT_APP_API}/orders/multiple/manualassign`, {
        orderIds,
        toUserId,
      })
      .then((resp) => resolve(resp))
      .catch((error) => reject(error.message));
  })


}

export const cancelOrder = (id) => (dispatch) => {
  dispatch(startLoading());
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/orders/${id}/cancel`;
  apiCall("post", url)
    .then((resp) => {
      dispatch({
        type: "CANCEL_ORDER",
        payload: resp
      });
      dispatch(addSuccessMsg(resp.status));
      dispatch(stopLoading());
    })
    .catch((err) => {
      dispatch(stopLoading());
      Alert.error(err.message);
    });
};

export const pauseOrder = (id) => (dispatch) => {
  dispatch(startLoading());
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/orders/${id}/pause`;
  apiCall("post", url)
    .then((resp) => {
      dispatch({
        type: "CANCEL_ORDER",
        payload: resp
      });
      dispatch(addSuccessMsg(resp.status));
      dispatch(stopLoading());
    })
    .catch((err) => {
      dispatch(stopLoading());
      Alert.error(err.message);
    });
};

export const changeStatusFromFile = (body) => (dispatch, getState) => {

  const filters = getState().filtersmain;

  // const url = `${process.env.REACT_APP_API}/orders/multiple/status/fromfile`;
  const url = 'http://localhost:8083/api/orders/multiple/status/fromfile'
  dispatch(removeError());
  dispatch(startLoading());
  apiCall("post", url, body) // body = {data, newStatus, courierId}
    .then((resp) => dispatch({
      type: "CHANGE_ORDERS_STATUS",
      payload: resp
    }))
    .then(() => {
      dispatch(getOrders(filters));
    })
    .catch((err) => {
      dispatch(stopLoading());
      Alert.error(err.message);
    });
};

export const changeStatusFromSelection = (body) => {

  // const filters = getState().filtersmain;
  const url = `${process.env.REACT_APP_API}/orders/multiple/status/selected`;
  return apiCall("post", url, body) // body = {arrayOfIds, newStatus}
    .then((resp) => resp)
    .catch((err) => {

      Alert.error(err.message);
    });
};

export const changeShipFromSelection = (body) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/orders/multiple/shipfrom`;

    apiCall("post", url, body) // body = {arrayOfIds, newStatus}
      .then((resp) => resolve(resp))
      .catch((err) => reject(err.message));
  })

};

export const changeStatusFromInput = (body) => {

  // const filters = getState().filtersmain;

  const url = `${process.env.REACT_APP_API}/orders/multiple/status/frominput`;


  return apiCall("post", url, body) // body = {arrayOfIds, newStatus}
    .then((resp) => resp)

    .catch((err) => {

      Alert.error(err.message);
    });
};

export const releaseUser = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/releaseuser`;
  dispatch(removeError());
  dispatch(startLoading());
  apiCall("put", url) // body = {arrayOfIds, newStatus}
    .then((resp) =>
      dispatch({
        type: GET_ORDER,
        payload: resp,
      })
    )
    .catch((err) => {
      dispatch(stopLoading());
      Alert.error(err.message);
    });
}

export const getNoGoodsOrders = (history) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/orders/validateandblockgoodsorders`;
  dispatch(removeError());
  dispatch({
    type: "NOGOODS_VALIDATION_START",
  });
  apiCall("get", url) // body = {arrayOfIds, newStatus}
    .then((resp) => {
      if (resp.status === "success") {
        dispatch({
          type: "NOGOODS_VALIDATION_DONE"
        });
      }
    })
    .catch((err) => {
      dispatch(stopLoading());
      Alert.error(err.message);
    });
};

export const resetChangeStatus = () => ({
  type: "RESET_ORDERS_STATUS"
});

// INVOICES

export const createInvoice = (id) => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/invoice`;
  return new Promise((resolve, reject) => {
    apiCall('post', url)
      .then(resp => resolve(resp))
      .catch(err => reject(err))
  })

}


export const getInvoices = (filters) => (dispatch) => {
  dispatch(removeError());
  dispatch(startLoading());
  const filtersUrl = filters ?
    "?" +
    Object.entries(filters)
    .filter(([key, value]) => value)
    .map(([key, value]) => key + "=" + value)
    .join("&") :
    "";
  const url = `${process.env.REACT_APP_API}/invoices${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => dispatch({
      type: "GET_INVOICES",
      payload: resp
    }))
    .catch((err) => {
      Alert.error(err.message);
      dispatch(stopLoading());
    });
};

export const getInvoice = (id) => {
  // dispatch(startLoading());
  const url = `${process.env.REACT_APP_API}/invoices/${id}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message);
      // dispatch(stopLoading());
    });
};



export const getOfficesV2 = ({
  country,
  courierId
}) => {

  const url = `${process.env.REACT_APP_API}/orders/getoffices`;

  return apiCall("post", url, {
      country,
      courierId
    })
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message);
    });
}

export const orderActionV2 = ({
  action,
  orderId
}) => {

  const url = `${process.env.REACT_APP_API}/orders/${orderId}/action`;
  return apiCall("post", url, {
      action
    })
    .then((resp) => resp)
    .catch((err) => err);
};

export const duplicateOrder = id => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/duplicate`;

  return apiCall("get", url)
    .then(resp => window.open(`/orders/${resp._id}`))
    .catch(err => Alert.error(err.message));
};

export const clearFilters = () => (dispatch => {

  dispatch({
    type: "CLEAR_ORDERS_FILTERS"
  })
})

export const Exportorders = ({
  method = 'post',
  url,
  body
}) => {
  return new Promise((resolve, reject) => {
    apiCall(method, url, body)
      .then(resp => resolve(resp))
      .catch(error => reject(error))
  })
}


export const getAwb = (id) => {

  const url = `${process.env.REACT_APP_API}/orders/${id}/getawb`
  return apiCall('get', url)
    .then(resp => resp)
    .catch((err) => Alert.error(err.message))
}


//V2 ORder actions

export const getOrderV2 = (id) => {

  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  return apiCall("get", url)
    .then((resp) => (resp))
    .catch((err) => {
      Alert.error(err.message);
    });
};