import React from "react";
import { Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";
// import { connect } from "react-redux";
// import { cancelOrder } from "../../../actions/orders";

function ModalOnDelete(props) {
  const {
    isModalOpen,
    deleteAction,
    handleCloseModal,
    modalText = "Are you sure you want to delete this?",
    primaryBtnText = "Delete",
    secondaryBtnText = "Cancel"
  } = props;

  const handleDelete = () => {
    deleteAction();
    handleCloseModal();
  };

  return (
    <Modal
      open={isModalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{ modal: "" }}
    >
      <div style={{ padding: "2.5em 0.7em 1em" }}>
        <p>{modalText}</p>
        <div style={{ marginTop: "1.5em" }} className="flex--right">
          <Button onClick={handleCloseModal}>{secondaryBtnText}</Button>
          <Button color="red" onClick={handleDelete}>
            {primaryBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

// export default connect(() => {}, { cancelOrder })(ModalOnDelete);
export default ModalOnDelete;
