import React, { useState } from "react";
import { Checkbox, Button, Segment,Input, Dropdown } from "semantic-ui-react";

import CountryFilter from "../Filters/CountryFilter";
import WarehouseFilter from "../Filters/WarehouseFilter";
import ProductFilter from "../Filters/ProductFilter";
import RolesFilter from "../Filters/RolesFilter";
import DepartmentFilter from "../Filters/DepartmentFilter";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import {statuses} from '../../statuses'


const statusOptions=statuses.map(item=>({key:item,text:item,value:item}))

function FilterItem({
  adminFilterName,
  stateFilterValues,
  handleFilterToggle,
  handleFilterSelection,
  infotags,
}) {
  const [isInfoOpen, setInfoOpen] = useState(false);

  const renderOptionsDropdown = (filterName, filterValues) => {
    switch (filterName) {
      case 'homepage':
        return (
          <Input
          name={filterName}
          value={filterValues}
          onChange={(e, { name, value }) =>
          handleFilterSelection({ filter: name, value })
        }
           ></Input>
        )
      case "countries":
        return (
          <CountryFilter
            multiple
            fluid
            selection={false}
            name={filterName}
            value={filterValues}
            handleChange={(e, { name, value }) =>
              handleFilterSelection({ filter: name, value })
            }
          />
        );

      // case "shipTo":
      case "shipFrom":
      case "warehouse":
        return (
          <WarehouseFilter
            multiple
            fluid
            selection={false}
            name={filterName}
            value={filterValues}
            handleChange={(e, { name, value }) =>
              handleFilterSelection({ filter: name, value })
            }
          />
        );

      case "products":
        return (
          <ProductFilter
            multiple
            fluid
            selection={false}
            name={filterName}
            value={filterValues}
            handleChange={(e, { name, value }) =>
              handleFilterSelection({ filter: name, value })
            }
          />
        );

      case "roles":
        return (
          <RolesFilter
            multiple
            fluid
            selection={false}
            placeholder="role..."
            name={filterName}
            value={filterValues}
            handleChange={(e, { name, value }) =>
              handleFilterSelection({ filter: name, value })
            }
          />
        );

      case "departments":
        return (
          <DepartmentFilter
            multiple
            fluid
            selection={false}
            name={filterName}
            value={filterValues}
            handleChange={(e, { name, value }) =>
              handleFilterSelection({ filter: name, value })
            }
          />
        );

      case "businessclients":
      case "suppliers":

        return (
          <BusinessclientFilter
              multiple={true}
              fluid
              name={filterName}
              value={filterValues}
              handleChange={(e, { name, value }) =>
                  handleFilterSelection({ filter: name, value })
                }  
          />
        )    
         case "shipToAddress":
                break;
        case "statuses":
           return    <Dropdown
                          options={statusOptions}
                          placeholder='select...'
                          name={filterName}
                          value={filterValues}
                          fluid
                          multiple
                          onChange={(e,{value,name})=>handleFilterSelection({filter:name,value})}
                        />

          
            
     default:
        return null;
    }
  };

  return (
    <li className="filter-item">
      <span className="filter-item__name">
        {adminFilterName}{" "}
        {adminFilterName === "countries" && (
          <Button
            style={{ marginLeft: "1em" }}
            circular
            compact
            icon={isInfoOpen ? "times" : "info"}
            size="mini"
            onClick={() => setInfoOpen((prev) => !prev)}
          />
        )}
        {isInfoOpen && (
          <div className="role-settings-info-tag">
            <Segment primary>
              {infotags["role-settings/filters/countries"]}
            </Segment>
          </div>
        )}
      </span>

      <div className="filter-item__toggle">
        <Checkbox
          toggle
          checked={!!stateFilterValues}
          onChange={(e, { checked }) =>
            handleFilterToggle({
              filter: adminFilterName,
              checked,
            })
          }
        />
      </div>
      {stateFilterValues && (
        <>
          <div className="filter-item__all-check">
            <span>All</span>
            <Checkbox disabled readOnly checked={!stateFilterValues.length} />
          </div>
          <div className="filter-item__options">
            {renderOptionsDropdown(adminFilterName, stateFilterValues)}
          </div>
        </>
      )}
    </li>
  );
}

export default FilterItem;
