import React, { useEffect } from "react";
import { Header, Button, Input,  Divider } from "semantic-ui-react";
import { useState } from "react";
import {Alert} from 'rsuite'

import {
  editCourierUser,
  addCourierUserClient,
  removeCourierUserClient,
} from "../../../actions/couriers";
import SelectBclients from "./SelectBclients";
import "../courier.scss";

function UserEditModal(props) {
  const {
    initCourierUserFormValues,
    courierId,
    isLoading,
    account,
    handleFieldChange
  } = props;

  const { _id: userId } = initCourierUserFormValues;

  const [username, setUsername]                       = useState({
    value: initCourierUserFormValues.username,
    isTouched: false,
  });
  const [{ newPassword, repeatPassword }, setPassword] = useState({
    newPassword: "",
    repeatPassword: "",
  });
  const [ businessclients, setBClients]               = useState([]);


  useEffect(() => {
    setUsername((s) => ({ value: initCourierUserFormValues.username }));
  }, [initCourierUserFormValues]);
  
  const handleChangeUsername = (e, { value }) => {
    setUsername({ value, isTouched: true });
                                                };
  
  const handleSubmitUsername = (e) => {
    e.preventDefault();
    editCourierUser({
      id: courierId,
      userId,
      query: { username: username.value },
    }).then((resp) => Alert.succes("Done")).catch(err=>Alert.error(err))
  };

  const handlePasswordChange = (e, { name, value }) =>
    setPassword((s) => ({ ...s, [name]: value }));
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    editCourierUser({
      id: courierId,
      userId,
      query: { newPassword },
    }).then(resp=>Alert.success(resp)).catch(err=>Alert.error(err));
  };

  const addClient = (
    listofClientIds,
    listOfClientsObj // [{_id, name}]
  ) => {
    setBClients((s) => {
      if (s.businessclients && s.businessclients.length > 0)
        return {
          ...s,
          businessclients: [...s.businessclients, ...listOfClientsObj],
        };});
   
    addCourierUserClient({
      id: courierId,
      userId,
      query: listofClientIds,
    }).then(resp=>alert('Done'));
  };

  const deleteClient = (clientId) => {
    setBClients((s) => ({
      ...s,
      businessclients: s.businessclients.filter((bc) => bc._id !== clientId),
    }));
    removeCourierUserClient({
      id: courierId,
      userId,
      clientId,
    }).then((resp) => Alert.succes("Done")).catch(err=>Alert.error(err))
  };

  return (
    <section className="courier-report-template">
      <Header as="h3">Edit Courier User</Header>
      <Divider />

      <form autoComplete="off" className="courier-users__grid">
        <label className="courier-users__label">Username:</label>
        <div>
          <Input
            className="mr--1"
            type="text"
            placeholder="Username..."
            name="username"
            value={username.value}
            onChange={handleChangeUsername}
          />
          {username.value && username.isTouched && (
            <Button
              onClick={handleSubmitUsername}
              disabled={!username}
              content="Save"
            />
          )}
        </div>
        <label className="courier-users__label">Account:</label>
        <Input
          type="text"
          placeholder="account nr. with courier"
          name="account"
          value={account}
          onChange={handleFieldChange}
        />
        {/* <label className="courier-users__label">Old Password:</label> */}
        {/* <Input
          style={{ display: "block" }}
          type="password"
          placeholder="Password..."
          name="oldPassword"
          value={oldPassword}
          onChange={handlePasswordChange}
        /> */}

        <label className="courier-users__label">New password:</label>
        <Input
          style={{ display: "block" }}
          type="password"
          placeholder="Password..."
          name="newPassword"
          value={newPassword}
          onChange={handlePasswordChange}
        />

        <label className="courier-users__label">Repeat password:</label>
        <div style={{ width: "100%" }}>
          <Input
            className="mr--1"
            type="password"
            placeholder="Password..."
            name="repeatPassword"
            value={repeatPassword}
            onChange={handlePasswordChange}
          />
          {
            newPassword &&
            repeatPassword &&
            newPassword === repeatPassword && (
              <Button
                // style={{ display: "block" }}
                onClick={handleSubmitPassword}
                content="Save"
              />
            )}
          {repeatPassword && repeatPassword !== newPassword && (
            <p className="user-form__error-msg">Passwords do not match</p>
          )}
        </div>
      </form>

      <label className="courier-users__label">Businessclients:</label>
      <SelectBclients
        businessclients={businessclients}
        isLoading={isLoading}
        deleteAction={deleteClient}
        add={addClient}
      />

      {/* <div className="flex--right">
        <Button onClick={handleCancel}>Close</Button>

        <Button
          color="blue"
          onClick={handleSubmit}
          disabled={password !== repeatPassword}
        >
          Save
        </Button>
      </div> */}
    </section>
  );
}


export default (UserEditModal);
