import React, {  useEffect } from "react";
import { Header, Icon, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";

import {
  createShaverSettings,
  getAllShaversSettings,
  updateShaverSettings,
  deleteShaverSettings,
  updateSettingsFilter,
} from "../../../actions/shavers";
import "../shavers.scss";
import { useToggleModalNewEdit } from "../../../hooks/useToggleModalNewEdit";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import SettingsTable from "./SettingsTable";
import SettingsModal from "./SettingsModal";
import CanViewPage from "../../../role-based-access/CanViewPage";

const defaultInitFormValues = {
  country: "",
  sku: "",
  web: "",
  min_rate: 0,
  max_rate: 0,
};

function ShaversSettings({
  shaversSettings,
  isLoading,
  createShaverSettings,
  getAllShaversSettings,
  updateShaverSettings,
  deleteShaverSettings,
  updateSettingsFilter,
  filters: { itemsPerPage, currentPage },
  total,
  history,
}) {
  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitFormValues);

  useEffect(() => getAllShaversSettings(), []);

  const handleItemsPerPageChange = (e, { value }) =>
    updateSettingsFilter({ itemsPerPage: value, currentPage: 1 });

  return (
    <CanViewPage path="/settings/shavers/settings">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between">
          <Link to="/settings/shavers/list">
            <Icon name="arrow left" /> Shavers List
          </Link>
          <Button onClick={() => openModal(false)}>+ Add</Button>

          <Modal
            open={isModalOpen}
            showCloseIcon={true}
            closeOnEsc={false}
            onClose={closeModal}
            center
            classNames={{ modal: "" }}
          >
            <SettingsModal
              initFormValues={initFormValues}
              isEditModeOn={isEditModeOn}
              handleClose={closeModal}
              create={createShaverSettings}
              update={updateShaverSettings}
            />
          </Modal>
        </div>
        <Segment padded>
          <div className="flex--between">
            <Header as="h4">Shavers Settings List</Header>
            <ItemsPerPage
              total={total}
              value={itemsPerPage}
              handleChange={handleItemsPerPageChange}
            />
          </div>
          <SettingsTable
            shaversSettings={shaversSettings}
            isLoading={isLoading}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            openEditModal={openModal}
            deleteShaverSettings={deleteShaverSettings}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              total={total}
              updateFilter={updateSettingsFilter}
            />
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  // shaversSetting: reduxState.shavers.openShaverSetting,
  shaversSettings: reduxState.shavers.shaversSettings,
  total: reduxState.shavers.settingsTotal,
  filters: reduxState.shavers.settingsFilters,
  isLoading: reduxState.shavers.isLoading,
});
export default connect(mapStateToProps, {
  createShaverSettings,
  getAllShaversSettings,
  updateShaverSettings,
  deleteShaverSettings,
  updateSettingsFilter,
})(ShaversSettings);
