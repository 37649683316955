import React, { useState, useEffect, createRef } from "react";
import { Icon, Input, Button } from "semantic-ui-react";

function EditableText({
  placeholder,
  value,
  name,
  handleChange,
  style = {
    minWidth:'300px',
    maxWidth:'400px',
  },
  disabled = false,
  loading = false,
  fluid = true,
  showButton = false,
  handleAdd,
}) {
  const [isHovered, setHovered] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [text, setText] = useState("");
  const input = createRef();

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleEdit = () => {
    setIsEditable(true);
  };

  useEffect(() => {
    if (isEditable) input.current.focus();
  }, [isEditable]);

  const hadleInternChange = (e, d) => {
    setText(d.value);
  };

  const handleSubmit = () => {
    setIsEditable(false);
    setHovered(false);
    // console.log("edit", name);
    handleChange(null, { name, value: text });
  };

  if (!isEditable) {
    return (
      <div className="flex--left">
        <div
          className={`editable  editable-field__text  `}
          onClick={handleEdit}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={style}
        >
          {text}
          <span
            className={`editable-field__icons ${
              !isHovered && "editable-field__icons_invisible"
            }`}
          >
            <Icon
              name="pencil alternate"
              color="grey"
              style={{ marginRight: "-7px" }}
              onClick={handleEdit}
            />
          </span>
        </div>
        {showButton && (
          <Button
            basic
            size="tiny"
            style={{ marginLeft: "4px" }}
            onClick={() => {
              handleAdd(text);
            }}
          >
            Add{" "}
          </Button>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Input
          style={style}
          loading={loading}
          disabled={disabled}
          autoComplete="nope"
          ref={input}
          fluid={fluid}
          placeholder={placeholder}
          value={text}
          name={name}
          onChange={hadleInternChange}
          onBlur={handleSubmit}
        />
      </div>
    );
  }
}

export default EditableText;
