import {
    apiCall
} from "../services/api";
import {
    addError
} from "./error";

const stopLoadingTurnOver = {
    type: "STOP_LOADING"
};


export const getOnlineusers = (filters) => dispatch => {
    // dispatch(startLoadingTurnOver)
    const url = `${process.env.REACT_APP_API}/reports/onlineusers`

    apiCall('get', url)
        .then(resp => dispatch(loadUsers(resp)))
        .catch((error) => {
            dispatch(stopLoadingTurnOver);
            dispatch(addError(error.message));
        });
}
const loadUsers = (payload) => ({
    type: "GET_ONLINE_USERS",
    payload
})
export const updateFilters = (newFilter) => ({
    type: "UPDATE_FILTER",
    payload: newFilter,
});