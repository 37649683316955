import React, { useState, useEffect } from "react";
import { Input, Button, Divider } from "semantic-ui-react";
import Modal from "react-responsive-modal";

function Cashpay({
  showCashPay,
  setShowCashpay,
  topay,
  inputCashRef,
  handlePaid,
  setOpenConfirmation
}) {
  const [received, setReceived] = useState();

  useEffect(() => {}, [showCashPay]);

  useEffect(() => {
    if (inputCashRef.current) inputCashRef.current.focus();
    if (!showCashPay) setReceived();
  }, [inputCashRef, showCashPay]);

  return (
    <Modal
      styles={{ modal: { width: "400px" } }}
      open={showCashPay}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => {
        setShowCashpay(false);
      }}
      center
      classNames={{
        modal: "",
      }}
    >
      <div>
        <div style={{ fontSize: "50px" }}>
          Total: <span>{topay&&topay.toFixed(2)}</span>
        </div>

        <Divider />

        <div>
          <Input
            ref={inputCashRef}
            placeholder="received"
            fluid
            value={received}
            onChange={(e, { value }) => setReceived(value)}
            style={{ fontSize: "50px" }}
          ></Input>

          <div style={{ fontSize: "50px" }} className="flex--between">
            <span>{received > topay ? "Return " : "Get "}</span>
            <span style={{ color: received > topay ? "green" : "red" }}>
              {topay&&received ? (received - topay).toFixed(2) : -topay}
            </span>{" "}
          </div>
        </div>
        <Divider></Divider>
        <Button
          color="green"
          disabled={!received||(received < topay)}
          size="big"
          style={{ float: "right" }}
          onClick={() => setOpenConfirmation(true)}
        >
          PAID
        </Button>
      </div>
    </Modal>
  );
}

export default Cashpay;
