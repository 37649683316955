import { apiCall } from "../services/api";
import { addError, addSuccessMsg } from "./error";

const startLoading = { type: "START_LOADING" };
const stopLoading = { type: "STOP_LOADING" };

export const getTickets = (filters) => (dispatch) => {
  dispatch(startLoading);

  const filtersUrl = Object.entries(filters)
    .filter(([key, value]) => value)
    .map(([key, value]) => key + "=" + value)
    .join("&");

  const url = `${process.env.REACT_APP_API}/tickets/?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => {
      dispatch({ type: "GET_TICKETS", payload: resp });
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateFilters = (filters) => ({
  type: "UPDATE_FILTERS",
  payload: filters,
});

export const getTotals = (filters) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/totals?status=${filters.status}&activeTab=${filters.activeTab}&assignedTo=${filters.assignedTo}&ccstatus=${filters.ccstatus}`;
  apiCall("get", url)
    .then((resp) => {
      dispatch({ type: "GET_TOTALS", payload: resp });
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const setSearchState = (str) => ({
  type: "SET_SEARCH_STATE",
  payload: str,
});

export const getTicket = (id) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/${id}`;
  apiCall("get", url)
    .then((resp) => {
      dispatch({ type: "GET_TICKET", payload: resp });
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const createTicket = (body, history) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/`;
  apiCall("post", url, body)
    .then((resp) => {
      dispatch({ type: "CREATE_TICKET", payload: resp });
      dispatch(addSuccessMsg("Ticket was created!"));
      history.push(`/tickets/${resp._id}`);
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateTicket = ({ id, query }) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/${id}`;
  apiCall("put", url, query)
    .then((resp) => {
      dispatch({ type: "UPDATE_TICKET", payload: resp });
      dispatch(addSuccessMsg("Ticket was updated!"));
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const deleteTicket = ({ id, history }) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/${id}`;
  apiCall("delete", url)
    .then((resp) => {
      dispatch({ type: "DELETE_TICKET", payload: resp });
      dispatch(addSuccessMsg("Ticket was deleted!"));
      history.push("/tickets");
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const closeTicket = (id) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/${id}/close`;
  apiCall("put", url)
    .then((resp) => {
      dispatch({ type: "UPDATE_TICKET", payload: resp });
      dispatch(addSuccessMsg("Ticket was closed!"));
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const reopenTicket = (id) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/tickets/${id}/reopen`;
  apiCall("put", url)
    .then((resp) => {
      dispatch({ type: "UPDATE_TICKET", payload: resp });
      dispatch(addSuccessMsg("Ticket was reopend!"));
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const addComment = ({ id, comment }) => (dispatch) => {
  dispatch({ type: "START_LOADING_COMMENT" });
  const url = `${process.env.REACT_APP_API}/tickets/${id}/comment`;
  apiCall("post", url, comment)
    .then((resp) => dispatch({ type: "ADD_TICKET_COMMENT", payload: resp }))
    .catch((error) => {
      dispatch({ type: "STOP_LOADING_COMMENT" });
      dispatch(addError(error.message));
    });
};

export const addReply = ({ ticketId, commentId, reply }) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/tickets/${ticketId}/comment/${commentId}`;
  apiCall("post", url, reply)
    .then((resp) => dispatch({ type: "ADD_TICKET_REPLY", payload: resp }))
    .catch((error) => dispatch(addError(error.message)));
};

///////////////////////////////////////////////////////////////////////////////////
// TICKET CATEGORIES
export const getTicketCategories = () => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/ticketcategories`;
  apiCall("get", url)
    .then((resp) => {
      dispatch({ type: "GET_TICKET_CATEGORIES", payload: resp });
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

// export const getTicketCategory = id => dispatch => {
//   dispatch(startLoading);
//   const url = `${process.env.REACT_APP_API}/ticketcategories/${id}`;
//   apiCall("get", url)
//     .then(resp => {
//       dispatch({ type: "GET_TICKET_CATEGORY", payload: resp });
//     })
//     .catch(error => {
//       dispatch(stopLoading);
//       dispatch(addError(error.message));
//     });
// };

export const createTicketCategory = (body) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/ticketcategories/`;
  apiCall("post", url, body)
    .then((resp) => {
      dispatch({ type: "CREATE_TICKET_CATEGORY", payload: resp });
      dispatch(addSuccessMsg("Category was created!"));
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateTicketCategory = ({ id, query }) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/ticketcategories/${id}`;
  apiCall("put", url, query)
    .then((resp) => {
      dispatch({ type: "UPDATE_TICKET_CATEGORY", payload: resp });
      dispatch(addSuccessMsg("Category was updated!"));
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getDepartments = () => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/ticketcategories/departments`;
  apiCall("get", url)
    .then((resp) => {
      dispatch({ type: "GET_DEPARTMENTS", payload: resp });
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};


export const updateMany = ({ids,query,filters})=>(dispatch)=>{
      dispatch(startLoading);
    
  const url = `${process.env.REACT_APP_API}/tickets/updatemany`;
  apiCall("put", url,{query,ids})
    .then((resp) => {
            const filtersUrl = Object.entries(filters)
              .filter(([key, value]) => value)
              .map(([key, value]) => key + "=" + value)
              .join("&");

            const url = `${process.env.REACT_APP_API}/tickets/?${filtersUrl}`;
            
            apiCall("get", url)
              .then((resp) => {
                dispatch({ type: "GET_TICKETS", payload: resp });
              })
              .catch((error) => {
                dispatch(stopLoading);
                dispatch(addError(error.message));
              });
            

      }
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
}