import React, { useState, useEffect } from "react";
import {
  Divider,
  Header,
  Button,
  Pagination,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getShipments, deleteShipment } from "../../../actions/inboundparcel";

import ExportButton from "./ExportButton";
import CanViewPage from "../../../role-based-access/CanViewPage";
import { ShipmentTable } from "./ShipmentTable";
import Filters from "./Filters";
import "../inbound.scss";
import { Alert } from "rsuite";
import { useUpdateContext } from "../../../context/UpdateContext";

const Inboundparcels = (props) => {
  const { businessclient, warehouse, dateFrom, dateTo,handleDateFromChange,handleDateToChange } = useUpdateContext();

  const [itemsPerPage,setItemsPerPage] = useState(10)
  const [currentPage,setCurrentPage] = useState(1)
  const [isLoading, setIsloading] = useState(false);
  const [data, setList] = useState({ list: [], total: 0 });

  const handleDelete = (id) => {
    setIsloading(true);
    deleteShipment(id)
      .then((resp) => {
        getShipments({
          businessclient,
          warehouse,
          dateFrom,
          dateTo,
          currentPage,
          itemsPerPage,
        })
          .then((data) => {
            setList(data);
            setIsloading(false);
          })
          .catch((err) => {
            setIsloading(false);
            Alert.error(err);
          });
      })
      .catch((error) => Alert.error(error));
  };

  const handleSearch =(value)=>{
    setIsloading(true);
    getShipments({
      orderNr:value
    })
      .then((data) => {
        setList(data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err);
      });
  }
  useEffect(() => {
    setIsloading(true);
    getShipments({
      businessclient,
      warehouse,
      dateFrom,
      dateTo,
      currentPage,
      itemsPerPage,
    })
      .then((data) => {
        setList(data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err);
      });
  }, [
    
    currentPage,
    itemsPerPage,
    businessclient,
    warehouse,
    dateFrom,
    dateTo,
  ]);

  return (
    <CanViewPage path="/parcels/inbound">
      <div  style={{maxWidth:'1100px',margin:'20px'}}>
        <div className="flex_spaceBetween">
          <Header as="h3">Inbound Parcels</Header>
          <Link to="/parcels/inbound/add/new">
            <Button>+Add New</Button>
          </Link>
        </div>
        <Divider />

        <div className="flex--between">
          <Filters dateFrom={dateFrom}
            dateTo={dateTo}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
            handleSearch={handleSearch}
            />
          <ExportButton
            data={data.list}
            filename={`receivedParcels_${dateFrom}`}
          ></ExportButton>
        </div>

        <Segment padded>
          <ShipmentTable
            isLoading={isLoading}
            shipments={data.list}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            deleteShipment={(id) => handleDelete(id)}
            total={data.total}
          />

          {/* <Pagination
            items={total}
            onChangePage={onChangePage}
            pageSize={filters.itemsPerPage}
          /> */}

          {data.list.length > 0 && data.total > itemsPerPage && (
            <Pagination
              style={{ marginTop: "1em" }}
              activePage={currentPage}
              totalPages={Math.ceil(data.total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) =>
                setCurrentPage(activePage)
              }
            />
          )}
        </Segment>
      </div>
    </CanViewPage>
  );
};


export default connect(null, {
  deleteShipment,
})(Inboundparcels);
