import React from "react";
import { Input, Grid } from "semantic-ui-react";
import CountryFilter from "../../Filters/CountryFilter";

function Address({ handleAddressChange, address }) {
  // console.log(address);
  return (
    <div className="businessclient__form">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <label>Country</label>
            <CountryFilter
              useFilter={false}
              fluid
              style={{maxWidth:'200px'}}
              name="country"
              value={address.country}
              handleChange={(value)=>handleAddressChange(null,{name:"country",value})}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>Region</label>
            <Input
              fluid
              type="text"
              name="region"
              value={address.region}
              placeholder="region..."
              onChange={handleAddressChange}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>Province</label>
            <Input
              fluid
              type="text"
              name="province"
              value={address.province}
              placeholder="province..."
              onChange={handleAddressChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <label>City</label>
            <Input
              fluid
              type="text"
              name="city"
              value={address.city}
              placeholder="city..."
              onChange={handleAddressChange}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>Street</label>
            <Input
              fluid
              type="text"
              name="street"
              value={address.street}
              placeholder="street..."
              onChange={handleAddressChange}
            />
          </Grid.Column>

          <Grid.Column width={2}>
            <label>Street Nr.</label>
            <Input
              fluid
              type="text"
              name="streetNr"
              value={address.streetNr}
              placeholder="street nr..."
              onChange={handleAddressChange}
            />
          </Grid.Column>

          <Grid.Column width={3}>
            <label>Postcode</label>
            <Input
              fluid
              type="text"
              name="postcode"
              value={address.postcode}
              placeholder="postcode..."
              onChange={handleAddressChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Address;
