import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon,Input } from "semantic-ui-react";
import { getWarehouses } from "../../actions/warehouses";


function WarehouseList() {

  const [searchTerm,setSearchTerm] = useState()
  const [warehouses,setWarehouses] = useState([])
  
  useEffect(() => {
    // alert('getting')
    getWarehouses({searchTerm})
    .then(list=>setWarehouses(list))
  }, [searchTerm]);


  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Countries</Table.HeaderCell>
      <Table.HeaderCell>Adress</Table.HeaderCell>
      <Table.HeaderCell>Partner</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = warehouses.map(
    ({ name, address, countries_supply, _id, isPartner }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}.</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{countries_supply.join(", ")}</Table.Cell>
        <Table.Cell>
          {address && (
            <div className="table-cell-grid">
              {address.country && (
                <>
                  <span className="table-cell-grid__label">country:</span>
                  <span className="table-cell-grid__value">
                    {address.country}
                  </span>
                </>
              )}
              {address.company && (
                <>
                  <span className="table-cell-grid__label">company:</span>
                  <span className="table-cell-grid__value">
                    {address.company}
                  </span>
                </>
              )}
              {address.city && (
                <>
                  <span className="table-cell-grid__label">city:</span>
                  <span className="table-cell-grid__value">{address.city}</span>
                </>
              )}
              {address.street && (
                <>
                  <span className="table-cell-grid__label">street:</span>
                  <span className="table-cell-grid__value">
                    {address.street}
                  </span>
                </>
              )}
              {address.streetNr && (
                <>
                  <span className="table-cell-grid__label">street Nr:</span>
                  <span className="table-cell-grid__value">
                    {address.streetNr}
                  </span>
                </>
              )}
              {address.post && (
                <>
                  <span className="table-cell-grid__label">postcode:</span>
                  <span className="table-cell-grid__value">{address.post}</span>
                </>
              )}
              {address.phone && (
                <>
                  <span className="table-cell-grid__label">phone:</span>
                  <span className="table-cell-grid__value">
                    {address.phone}
                  </span>
                </>
              )}
              {address.email && (
                <>
                  <span className="table-cell-grid__label">email:</span>
                  <span className="table-cell-grid__value">
                    {address.email}
                  </span>
                </>
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          {isPartner && <Icon name="check" size="small" color="blue" />}
        </Table.Cell>
        <Table.Cell>
          <Button
            color="blue"
            size="tiny"
            basic
            compact
            // onClick={() =>
            //   openModal(true, {
            //     name,
            //     countries_supply,
            //     _id,
            //     ["address.country"]: (address && address.country) || "",
            //     ["address.city"]: (address && address.city) || "",
            //     ["address.street"]: (address && address.street) || "",
            //     ["address.streetNr"]: (address && address.streetNr) || "",
            //     ["address.post"]: (address && address.post) || "",
            //     ["address.phone"]: (address && address.phone) || "",
            //     ["address.email"]: (address && address.email) || "",
            //     ["address.company"]: (address && address.company) || "",
            //     isPartner: isPartner || false,
            //   })
            // }
          >
            <Icon name="pencil alternate" />
            <Link to={`/settings/warehouses/${_id}`}>Edit</Link>
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <div className="table-wrapper--horizontal-scroll ">
       <Input
            style={{ marginRight: "1em" }}
            
            placeholder="Search..."
            icon="search"
            value={searchTerm}
            onChange={(e, d) => setSearchTerm(d.value) }
          />

      <Table basic="very" selectable unstackable>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </div>
  );
}



export default (WarehouseList);
