import React, { useEffect, useState } from "react";
// import { Button,Dropdown } from "semantic-ui-react";
import Moment from "react-moment";
import {Alert} from 'rsuite'
import { getCompanies } from "../../../actions/company";
// import WarehouseFilter from '../../Filters/WarehouseFilter'
// import BusinessclientFilter from '../../Filters/BusinessclientFilter'
import EditableText from "../../EditableText";
// import EditableDropdownV2 from "../../EditableDropdownV2";

function ShipmentInfo(props) {
  const {
    openShipment,
    handleChange,

  } = props;
  
const [companies,setCompanies]=useState([])

useEffect(()=>{
  
  if(openShipment.client._id) getCompanies({client:openShipment.client._id}).then(resp=>setCompanies(resp)).catch(err=>Alert.error(err.message))
},[openShipment.client])
  return (
    <div className="edit-inbound-shipment__grid">
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Warehouse:</label>

          {/* {openShipment.items.length===0&&    <WarehouseFilter
            multiple={false}
            warehouse={openShipment.warehouse}
            handleChange={handleChange}
            disabled={openShipment.items.length>0}
          ></WarehouseFilter>} */}

          {openShipment.warehouse.name && (
            <div> {openShipment.warehouse.name}</div>
          )}
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Client:</label>
          {/* {
          openShipment.items.length===0&&    <BusinessclientFilter
               value={openShipment.client._id}
               handleChange={handleClientChange}
               disabled={openShipment.items.length>0}
            ></BusinessclientFilter>} */}
          {openShipment.client.name && <div>{openShipment.client.name}</div>}
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Supplier:</label>
          {openShipment.seller && <div>{openShipment.seller.name}</div>}

          {/* {openShipment.items.length===0 && <Dropdown
                disabled={openShipment.items.length>0}
                
                search
                selection
                fluid
                placeholder="seller..."
                name="seller"
                value={openShipment.seller}
                options={companies.map(item=>({value:item._id,text:item.name,key:item._id}))}
                onChange={handleChange}
                // onBlur={handleBlur}
              />}
               {openShipment.client && openShipment.items.length==0
              &&(
              <Button
                // style={{ marginLeft: "1.4em", marginTop: "-5px" }}
                //   circular
                //   basic

                compact
                icon="plus"
                size="mini"
                // className="parties__addbtn"
                onClick={toggleCompanyModal}
              > Add Supplier</Button>
            )} */}
        </div>
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Transporter:</label>
          <EditableText
            placeholder="transporter company ..."
            name="transporter"
            value={openShipment.transporter}
            handleChange={(e, { name, value }) =>
              handleChange({ [name]: value })
            }
          />
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Nr. of packs:</label>
          <EditableText
            placeholder="nr of packs ..."
            name="nrOfPacks"
            value={openShipment.nrOfPacks}
            handleChange={(e, { name, value }) =>
              handleChange({ [name]: value })
            }
          />
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Weight (kg):</label>
          <EditableText
            placeholder="weight in kg ..."
            name="weightKg"
            value={openShipment.weightKg}
            handleChange={(e, { name, value }) =>
              handleChange({ [name]: value })
            }
          />
        </div>
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Updated at:</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.updatedAt}</Moment>
          </span>
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Created at:</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.createdAt}</Moment>
          </span>
        </div>
      </div>
    </div>
  );
}




export default (ShipmentInfo);
