import React,{useState} from "react";
import { Table, Header, Label, Icon, Button } from "semantic-ui-react";

import Dropzone from "react-dropzone";
import { ExcelRenderer } from "react-excel-renderer";
import ExportExcel from "../../ExportExcel";

import { uploadProdFromXLS } from "../../../actions/product";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import { dataSet, colNames } from "./uploadProductsTemplate";
import "../../Dropzone/dropzone.scss";

const initState = {
  dragContent: "Drag 'n' drop the file or click here",
  fileName: "",
  formatError: false,
  data: [],
  errors: {},
  businessclient: null,
};


const AddProducts =(props)=>{
  
  const [state,setState]=useState(initState)

  const handleBusinessclient=(value) =>{
    setState({ ...state,businessclient: value });
  }

  const fileHandler=(files)=> {
    let fileObj = files[0];
    if (!fileObj.name.includes(".xlsx")) {
      setState({
        ...state,
        formatError: true,
        fileName: "",
        data: [],
        dragContent: "Drag 'n' drop the file or click here",
      });
      return;
    }
    const requiredKeys = ["name", "codes.sku", "codes.barcode"];
    const keyNames = [
      "name",
      "codes.sku",
      "codes.barcode",
      "color",
      "size",
      "proprities.weight",
      "box_units",
      "box_height",
      "box_lenght",
      "box_width",
      "volume"
    ];

    function mapRows(arr) {
      let data = [];
      let errors = {};
      arr.shift();
      arr.forEach((row, i) => {
        let product = {};
        let productErrs = [];
        for (let j = 0; j < row.length; j++) {
          product[keyNames[j]] = row[j];
          if (requiredKeys.includes(keyNames[j]) && row[j] === undefined) {
            productErrs.push(keyNames[j]);
          }
        }
        data.push(product);
        if (productErrs.length) errors[i] = productErrs;
      });
      return [data, errors];
    }

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        const [data, errors] = mapRows(resp.rows);
        setState({...state,
          fileName: fileObj.name,
          formatError: false,
          data,
          errors,
        });
      }
    });
  }

const  removeFile=()=> {
    setState({ ...initState });
  }

 function handleSaveProducts() {
    const { businessclient, errors, data } = state;
    const products = data.filter((prod, i) => !errors[i]);
    uploadProdFromXLS({ products, businessclient }).then((resp) => {
      alert("Uploading done.");
    }).catch(error=>alert(error))
  }

  
    // const { errors } = props;
    const { businessclient } = state;
    // console.log(state);

    const tableHeadRow = (
      <Table.Row>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Valid</Table.HeaderCell>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell>SKU</Table.HeaderCell>
        <Table.HeaderCell>Barcode</Table.HeaderCell>
        <Table.HeaderCell>Color</Table.HeaderCell>
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Weight</Table.HeaderCell>
        <Table.HeaderCell>box_units</Table.HeaderCell>
        <Table.HeaderCell>box_height </Table.HeaderCell>
        <Table.HeaderCell>box_lenght</Table.HeaderCell>
        <Table.HeaderCell>box_width</Table.HeaderCell>
        <Table.HeaderCell>volume</Table.HeaderCell>
      </Table.Row>
    );

    const tableRows = state.data.map((p, i) => (
      <Table.Row key={i} error={!!state.errors[i]}>
        <Table.Cell>{i + 1}</Table.Cell>
        <Table.Cell collapsing>
          {state.errors[i] ? (
            <Icon name="remove circle" color="red" size="large" />
          ) : (
            <Icon name="check circle" color="green" size="large" />
          )}
          {/* <Label circular color="green">
            <Icon name="check" />
          </Label> */}
        </Table.Cell>

        {p.name === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p.name}</Table.Cell>
        )}
        {p["codes.sku"] === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p["codes.sku"]}</Table.Cell>
        )}

        {p["codes.barcode"] === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p["codes.barcode"]}</Table.Cell>
        )}
        <Table.Cell>{p.color}</Table.Cell>
        <Table.Cell>{p.size}</Table.Cell>
        <Table.Cell>{p["proprities.weight"]}</Table.Cell>
        <Table.Cell>{p["box_units"]}</Table.Cell>
        <Table.Cell>{p["box_height"]}</Table.Cell>
        <Table.Cell>{p["box_lenght"]}</Table.Cell>
        <Table.Cell>{p["box_width"]}</Table.Cell>
        <Table.Cell>{p["box_width"]*p["box_lenght"]*p["box_height"]/1000000} m3</Table.Cell>
        
      </Table.Row>
    ));

    return (
      <div className="products-from-excel">
        <div className="flex--left">
          <div style={{ marginRight: "1em" }}>
            <ExportExcel
              filename="products-template"
              excelSheetName="upload-products-template"
              dataSet={dataSet}
              colNames={colNames}
              button={
                <Button.Group basic>
                  <Button>
                    <Icon name="download" />
                    Download Exemple
                  </Button>
                </Button.Group>
              }
            />
          </div>
          <BusinessclientFilter
            useFilter={false}
            value={businessclient}
            handleChange={(value) => handleBusinessclient(value)}
          />
        </div>

        <section className="upload-file">
          <Header>1. Upload a file with products (.xlsx format)</Header>
          {/* <form className="upload-file__form">
            <label className="upload-file__input" for="file-upload">
              Upload file
              <input
                id="file-upload"
                type="file"
                placeholder="Add Excel File"
                onChange={e => fileHandler(e.target)}
              />
            </label>
          </form> */}

          {!state.fileName && (
            <Dropzone
              onDrop={(acceptedFiles) => fileHandler(acceptedFiles)}
              onDragEnter={() =>
                setState({ ...state,dragContent: "Drop here ..." })
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section className="upload-file__drop-container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{state.dragContent}</p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}

          {state.fileName && (
            <div className="upload-file__file-name">
              <Label image>
                <Icon name="file" />
                {state.fileName}
                <Icon name="delete" onClick={removeFile} />
              </Label>
            </div>
          )}
          {state.formatError && (
            <p className="upload-file__error">
              * Must be an Excel file ( .xlsx )
            </p>
          )}
        </section>

        {/* View Table */}
        <section className="view-table">
          {state.data && state.data.length > 0 && (
            <div className="table-wrapper--horizontal-scroll">
              <Table unstackable celled>
                <Table.Header>{tableHeadRow}</Table.Header>
                <Table.Body>{tableRows}</Table.Body>
              </Table>
            </div>
          )}
        </section>

        {/* Feedback */}
        {state.data &&
          state.data.length > 0 &&
          !props.status &&
          (!Object.keys(state.errors).length ? (
            <section className="feedback-section feedback-section_success">
              <p className="feedback-section__success">
                <Icon name="check" color="green" />
                There are no errors. The file seems to pass the requirements.
                <br /> You can save the products now.
              </p>
            </section>
          ) : (
            <section className="feedback-section feedback-section_errors">
              <p className="feedback-section__errors">
                <Icon name="warning sign" color="yellow" />
                There are some errors.
                <br /> The products with missing required information will not
                be saved.
                <br /> If you want to add them too, please fill in the missing
                cells and reupload the file.
              </p>
            </section>
          ))}

        {/* Save Products */}
        <section className="save-products">
          <Header>2. Save the added products</Header>
          {props.status === "success" && (
            <Button color="green" size="huge">
              Products Saved
            </Button>
          )}
          {props.status !== "success" && (
            <Button
              color="teal"
              basic
              // disabled={state.data && !state.data.length}
              loading={props.isLoading}
              size="huge"
              onClick={handleSaveProducts}
            >
              Save Products
            </Button>
          )}
        </section>

        {/* Server Response */}
        {props.unsuccessful &&
          props.unsuccessful.length > 0 &&
          props.status === "fail" &&
          state.fileName && (
            <section className="feedback-section feedback-section_server-response">
              <div className="table-wrapper--horizontal-scroll">
                <Table unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Error</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.unsuccessful &&
                      props.unsuccessful.map((p, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {p.name} - {p["codes.sku"]}
                          </Table.Cell>
                          <Table.Cell>{p.error}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </section>
          )}
        {props.status === "success" && state.fileName && (
          <section className="feedback-section feedback-section_success feedback-section_server-response">
            <p className="feedback-section__success">
              <Icon name="check" color="green" />
              All product successfully saved
            </p>
          </section>
        )}
      </div>
    );
  }


export default (AddProducts);
