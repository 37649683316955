import React, { useState, useEffect } from "react";
import { Button, Divider } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import { copyServiceQuotations } from "../../actions/servicequotation";
import { Alert } from "rsuite";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import CourierFilter from "../Filters/CourierFilter";

const Copyquoatations = ({ showCopymodal, setShowcopymodal }) => {
  // const [open,setOpen] = React.useState(false)

  const [toBusinessclient, setToBusinessclient] = useState(null);
  const [fromBusinessclient, setFromBusinessclient] = useState(null);
  const [fromCourier, setFromCourier] = useState(null);
  const [toCourier, setToCourier] = useState(null);

  useEffect(() => {
    setShowcopymodal(showCopymodal);
  }, [showCopymodal, setShowcopymodal]);
  return (
    <div>
      <Modal
        styles={{ modal: { width: "400px" } }}
        showCloseIcon={true}
        closeOnEsc={true}
        center
        open={showCopymodal}
        onClose={() => setShowcopymodal(false)}
      >
        <div>
          <h5>Copy All quotations</h5>
          <Divider></Divider>

          <div>
            <BusinessclientFilter
              useFilter={false}
              handleChange={(value) => setFromBusinessclient(value)}
              placeholder="FROM BUSINESSCLIENT..."
              value={fromBusinessclient}
            />
            <BusinessclientFilter
              useFilter={false}
              handleChange={(value) => setToBusinessclient(value)}
              placeholder="TO BUSINESSCLIENT..."
              value={toBusinessclient}
            />
            <div>
              {!fromCourier && (
                <CourierFilter
                  useFilter={false}
                  handleChange={(value) => setFromCourier(value[0])}
                  placeholder="from courier"
                  value={fromCourier}
                />
              )}

              {fromCourier && (
                <div>
                  {fromCourier} -{" "}
                  <span>
                    <button onClick={() => setFromCourier(null)}>x</button>
                  </span>
                </div>
              )}
            </div>
            {!toCourier && (
              <CourierFilter
                useFilter={false}
                handleChange={(value) => setToCourier(value[0])}
                placeholder="to courier"
                value={toCourier}
              />
            )}
            {toCourier && (
              <div>
                {toCourier} -{" "}
                <span>
                  <button onClick={() => setToCourier(null)}>x</button>
                </span>
              </div>
            )}
          </div>
          <Button
            onClick={(e) => {
              console.log("clicked:", e);
              setShowcopymodal(false);
            }}
            color="red"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              copyServiceQuotations({
                fromCourier,
                toCourier,
                fromBusinessclient,
                toBusinessclient,
              })
                .then((resp) =>
                  Alert.success("Done. U can now refresh the list.")
                )
                .catch((err) => Alert.error(err));

              setShowcopymodal(false);
            }}
            color="green"
          >
            Copy
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Copyquoatations;
