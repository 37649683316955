// import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

function CanViewMenuLink({ user, path, pathList, children }) {
  // const [allowed, setAllowed] = useState(false);
  // useEffect(() => {
  //   if (user && user.views && user.views[path])
  //     setAllowed(user.views[path].includes("r"));
  //   else setAllowed(false);
  // }, [user, path]);

  // console.log(user.views);
  // console.log({ path, allowed });

  if (
    user.views &&
    (user.views[path] || (pathList && pathList.find(path => user.views[path])))
    // if can view menu item OR
    // if it is a menu item with submenu and can view at least one submenu item -> render children
  )
    return children;

  return null;
}
const mapStateaToProps = reduxState => ({
  user: reduxState.user.currentUser
});
export default connect(mapStateaToProps, {})(CanViewMenuLink);
