
import React from "react";
// import Moment from "react-moment";
// import 'moment-timezone';
import {Divider} from 'semantic-ui-react'

const Servicesummary = ({ services }) => {
  console.log(services);
  // if(foundServices.length===0) return <div>No services provided.</div>

  // const body = Object.entries(foundServices).map(([key, value]) => (
  //   <tr key={key}>
  //     <td>{key}</td>
  //     <td>{isNaN(value) ? value : value.toFixed(2)}</td>
  //   </tr>
  // ));
  const tablesServices = services.map((currencyGroups) => {
    let body = [];

    for (let i = 0; i < currencyGroups.services.length; i++) {
      const { service_details, total } = currencyGroups.services[i];

      if (service_details.category !== "goods") {
        const newItem = (
          <tr>
            <td>{service_details.name}</td>
            <td></td>
            <td>{total}</td>
          </tr>
        );
        body = [...body, newItem];
      }

      if (service_details.category === "goods") {
        body = currencyGroups.products.map(
          ({ product, total, quantity }) =>
            product.length > 0 && (
              <tr>
                <td>
                  {product[0].name} (sku:{product[0].codes.sku})
                </td>
                <td>{quantity}</td>
                <td>{total.toFixed(2)}</td>
              </tr>
            )
        );
      }
    }
    return (
      <div style={{ width: "600px" }}>
        <Divider></Divider>
        <table>
          <tr>
            <td style={{ fontWeight: "bold", width: "400px" }}>Name</td>
            <td style={{ fontWeight: "bold", width: "200px" }}>Quantity</td>
            <td style={{ fontWeight: "bold", width: "400px" }}>
              {currencyGroups._id}
            </td>
          </tr>

          {body}
          <tr>
            <td style={{ fontWeight: "bold" }}>
              Total: {currencyGroups.grandTotal.toFixed(2)}
            </td>
            <td></td>
            <td style={{ fontWeight: "bold" }}></td>
          </tr>
        </table>
      </div>
    );
  });

  return (
    <div>
      {/* <table>
                    <tr>
                      <td  style={{fontWeight:'bold'}}>From:</td>
                      <td  style={{fontWeight:'bold'}}>
                      <Moment local format="DD-MM-YY HH:mm">{dateFrom}</Moment>
                    </td>
                  </tr>
                  <tr>
                    <td  style={{fontWeight:'bold'}}>To:</td>
                    <td  style={{fontWeight:'bold'}}>
                      <Moment utc format="DD-MM-YY HH:mm">{dateTo}</Moment>
                    </td>
                  </tr>
                
                  </table> */}
      {tablesServices}
      <br></br>
      {/* {goodsTable} */}
    </div>
  );
};

export default Servicesummary;
