import React from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import "./warehouseSettings.scss";
import { useToggleModalNewEdit } from "../../hooks/useToggleModalNewEdit";
import CanViewPage from "./../../role-based-access/CanViewPage";
import WarehouseList from "./WarehouseList";
import WarehouseModal from "./WarehouseModal";

const defaultInitFormValues = {
  name: "",
  address: {},
  "address.country": "",
  "address.city": "",
  "address.street": "",
  "address.streetNr": "",
  "address.post": "",
  "address.company": "",
  "address.phone": "",
  "address.email": "",
  isPartner: false,
  countries_supply: [],
};

function WarehouseSettings() {
  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitFormValues);

return (
    <CanViewPage path="/settings/warehouses">
      <main className="a-wrapper page-wrapper">
        <div className="flex--between">
          <Header>Warehouses</Header>
          <Button onClick={() => openModal(false)}>+ Add</Button>
        </div>
    

        <Segment padded>
          <WarehouseList openModal={openModal} />
        </Segment>

        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={false}
          onClose={closeModal}
          center
          classNames={{ modal: "" }}
        >
          <WarehouseModal
            initFormValues={initFormValues}
            isEditModeOn={isEditModeOn}
            handleClose={closeModal}
          />
        </Modal>
      </main>
    </CanViewPage>
  );
}

export default WarehouseSettings;
