import React, { useState, useEffect } from "react";
import { Icon, Segment, Header, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getShipment, updateShipment,  removeInventoryCard,createInventoryCard
} from "../../../actions/inboundparcel";

import ShipmentInfo from "./ShipmentInfo";
import Images from "./Images";
// import GoodsReceived from "../Items/GoodsReceived";
import CanViewPage from "../../../role-based-access/CanViewPage";
import Findorders from "./findOrder";
import Documents from "../../../components/Documents";
import ChangeLogs from "../../ChangeLogs";
import { Alert } from "rsuite";

import ItemList from "./items"

function Inboundparcel(props) {
  const {match} = props;

  const [openShipment, setShipment] = useState();
  const [shipmentWithUpdates, setUpdates] = useState({
    warehouse: "",
    businessclient: null,
    transporter: "",
    nrOfPacks: "",
    weightKg: "",
  });
  const [fieldHasChanged, setFieldChanged] = useState({
    warehouse: false,
    businessclient: false,
    transporter: false,
    nrOfPacks: false,
    weightKg: false,
  });

  const [isCompanyModalOpen, setCompanyModal] = useState(false);
  const [reloadShipment, setReloadShipment] = useState(true);
  const [relatedOrder, setRelatedOrder] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [resetList, setResetList] = useState(false);

  const handleFieldChange = (e, { name, value }) => {
    setUpdates({ ...shipmentWithUpdates, [name]: value });
    if (openShipment[name] !== value) {
      // if different value -> changed = true
      setFieldChanged({ ...fieldHasChanged, [name]: true });
    } else {
      // if it was changed, but now returned to the original value
      // -> changed = false
      if (fieldHasChanged[name] === true)
        setFieldChanged({ ...fieldHasChanged, [name]: false });
    }
  };


  const handleClientChange = (e, { value }) => {
    setUpdates({ ...shipmentWithUpdates, businessclient: value, seller: "" });
    if (openShipment.businessclient !== value) {
      // if different value -> changed = true
      setFieldChanged({
        ...fieldHasChanged,
        businessclient: true,
        seller: true,
      });
    } else {
      // if it was changed, but now returned to the original value
      // -> changed = false
      if (fieldHasChanged.client === true)
        setFieldChanged({
          ...fieldHasChanged,
          businessclient: false,
          seller: true,
        });
    }
  };

  const handleAddItem=(item)=>{
    setIsloading(true)
    createInventoryCard({   
                id:match.params.id,
                item,
                })
        .then(data=>{
          setIsloading(false)
          Alert.success("Item added")
          setReloadShipment(!reloadShipment)
          }
          )
        .catch(err=>{
          setIsloading(false)
              Alert.error(err.message)
            })
    }


  const handleDeleteItem = (lineId)=>{
    
      setIsloading(true)
          removeInventoryCard({ id:openShipment._id, itemId:lineId, })
          .then(data=>{
            setIsloading(false)
            Alert.success('Item removed')
            setReloadShipment(!reloadShipment)
          })
          .catch(err=>{
            setIsloading(false)
            Alert.error(JSON.stringify(err),5000)})
  


  }
  
  const toggleCompanyModal = () => {
    setCompanyModal(!isCompanyModalOpen);
  };

  const handleUpdateShipment = (query) => {
    setIsloading(true)
    updateShipment({ id: match.params.id, query }).then(resp=>{
      setIsloading(false)
      setShipment(resp)
      setResetList(true)

    }).catch(error=>{
      Alert.error(error)
      setIsloading(false)
    })
  };

  useEffect(() => {
    setIsloading(true)

      getShipment(match.params.id)
        .then((resp) => {
          setIsloading(false)
          setShipment(resp)
        })
        .catch((error) => {
          setIsloading(false)

          Alert.error(error)});

  }, [match.params.id,reloadShipment]);

  
  useEffect(() => {
    if (relatedOrder){
      setIsloading(true)
      updateShipment({ id: match.params.id, query: { order: relatedOrder } }).then(resp=>{
        setIsloading(false)
        setShipment(resp)
        setResetList(true)
      }).catch(error=>{
        setIsloading(false)
        Alert.error(error)})

    }

  }, [match.params.id, relatedOrder]);

  if(!openShipment) return (<div>loading...</div>)
  
  return (
    <CanViewPage path="/parcels/inbound/:id">
      <main className="page-wrapper a-wrapper">
        <Link to="/parcels/inbound/">
          <Icon name="arrow left" /> Inbound Parcels List
        </Link>

        <Segment padded loading={isLoading}>
          <div className="flex--between">
            <div>
              <Header as="h3" className="edit-inbound-shipment__header">
                Inbound Parcel
              </Header>
              {openShipment._id && (
                <div className="smallinfo">ID: {openShipment._id}</div>
              )}
            </div>
            {/* <Button color="blue" onClick={handleUpdateShipment}>
              Save updates
            </Button> */}
          </div>

          <ShipmentInfo
            shipmentWithUpdates={shipmentWithUpdates}
            openShipment={openShipment}
            handleFieldChange={handleFieldChange}
            handleClientChange={handleClientChange}
            toggleCompanyModal={toggleCompanyModal}
          />

          {openShipment.order && (
            <div>
              <div>Related order </div>
              <Link to={`/orders/${openShipment.order._id}`}>
                {openShipment.order.clientOrderNr}
              </Link>
              <button
                onClick={() => {
                  handleUpdateShipment({order: null });
                }}
              >
                delete
              </button>
            </div>
          )}
        </Segment>

        <Segment id="findorders">
          <Findorders
            resetList={resetList}
            getOrder={(orders) => setRelatedOrder(orders)}
            businessclient={openShipment.businessclient}
          />
        </Segment>

        <Images
          setReloadShipment={setReloadShipment}
          openShipment={openShipment}
        />
        <Documents
          businessclient={openShipment.businessclient}
          trigger="Inboundparcel"
          triggerId={match.params.id}
        />
{/* 
        <GoodsReceived
          openShipment={openShipment}
          goodsreceived={openShipment.goodsreceived}
          setShipment={setShipment}
        /> */}

      <ItemList
          goodsreceived={openShipment.goodsreceived}
          handleDelete={handleDeleteItem}
          handleAddItem={handleAddItem}
          businessclient={openShipment.businessclient._id}
          id={openShipment._id}
          loading={isLoading}
          >

      </ItemList>
        <Divider></Divider>
        <ChangeLogs
          changeLogs={openShipment.changeLogs ? openShipment.changeLogs : []}
        ></ChangeLogs>
      </main>
    </CanViewPage>
  );
}

export default (Inboundparcel);
