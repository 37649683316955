import React, { Component } from "react";
import { Form, Button, Divider, Header } from "semantic-ui-react";
import "./components.scss";
import { countries } from "../countries";

class Companyform extends Component {
  constructor() {
    super();
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleAddCo = this.handleAddCo.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleCountryChange(e, { value }) {
    this.setState({ "address.country": value });
  }
  handleAddCo() {

    this.props.createCo({...this.state,businessclient:this.props.company});

    this.props.closeForm();
  }
  render() {
    return (
      <div className="company-form">
        <Header as="h4">Add Company</Header>
        <Divider />
        <Form>
          <div className="company-form__row_big-mb">
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="name"
                fluid
                label="Name"
                placeholder="Company Name"
                width={8}
                onChange={this.handleChange}
              />
              <Form.Select
                name="country"
                fluid
                label="Country"
                placeholder="country..."
                width={8}
                search
                options={countries.map(country => ({
                  text: country,
                  value: country
                }))}
                onChange={this.handleCountryChange}
              />
            </Form.Group>
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="taxid"
                width={8}
                placeholder="Tax ID "
                onChange={this.handleChange}
              />
            </Form.Group>
          </div>

          <div className="company-form__row">
            <label>Address</label>
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="address.city"
                placeholder="City"
                width={9}
                onChange={this.handleChange}
              />
              <Form.Input
                name="address.postcode"
                placeholder="Postcode"
                width={7}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="address.street"
                placeholder="Street"
                width={13}
                onChange={this.handleChange}
              />
              <Form.Input
                name="address.streetNr"
                placeholder="Nr"
                width={3}
                onChange={this.handleChange}
              />
            </Form.Group>
          </div>

          <div className="company-form__row_big-mb">
            <label>Contact</label>
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="contact.email"
                placeholder="Email"
                width={8}
                onChange={this.handleChange}
              ></Form.Input>
            </Form.Group>
            <Form.Group className="company-form__input-row">
              <Form.Input
                name="contact.phone"
                placeholder="Phone"
                width={8}
                onChange={this.handleChange}
              ></Form.Input>
              <Form.Input
                name="contact.name"
                placeholder="Name"
                width={8}
                onChange={this.handleChange}
              ></Form.Input>
            </Form.Group>
          </div>

          <Button onClick={this.handleAddCo} size="tiny" color="blue">
            Submit
          </Button>
          <Button onClick={this.props.closeForm} size="tiny">
            Close
          </Button>
        </Form>
      </div>
    );
  }
}
export default Companyform;
