import { apiCall } from "../services/api";
import {
  Alert
} from 'rsuite'

const createFilterUrl = (filters) =>
filters
  ? Object.entries(filters)
      .filter(([key, value]) => value)
      .map(([key, value]) => key + "=" + value)
      .join("&")
  : "";



export const getInvoices = (filters) =>  {

    const filtersUrl = createFilterUrl(filters);
    const url = `${process.env.REACT_APP_API}/invoices?${filtersUrl}`;
    return apiCall("get", url)
                  .then((resp) => resp)
                  .catch((err) => {
                     Alert.error(err.message)
                  });
      
  };

export const getInvoice= (id) => {

  const url = `${process.env.REACT_APP_API}/invoices/${id}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message)
    });

};


export const updateInvoice=({id,query}) =>{

   const url = `${process.env.REACT_APP_API}/invoices/${id}`;
   return apiCall("put", url, query)
     .then((resp) => resp)
     .catch((err) => {
       Alert.error(err.message)
     });

}

export const addItem = ({
  id,
  query
}) => {

  const url = `${process.env.REACT_APP_API}/invoices/${id}`;
  return apiCall("post", url, query)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message)
    });

}

export const removeItem = ({
  id,
  itemId
}) => {

  const url = `${process.env.REACT_APP_API}/invoices/${id}/${itemId}`;
  return apiCall("post", url)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message)
    });

}

export const cancelInvoice = (id) =>  {
  
  const url = `${process.env.REACT_APP_API}/invoices/${id}`;
  return apiCall("delete", url)
    .then((resp) =>resp)
    .catch((err) => {
      Alert.error(err.message);
      // dispatch(stopLoading());
    });
};

export const exportInvoices = (filters) =>  {
  
  const url = `${process.env.REACT_APP_API}/invoices/export`;
  return apiCall("post",url,filters)
    .then((resp) =>resp)
    .catch((err) => {
      Alert.error(err.message);
      // dispatch(stopLoading());
    });
};
