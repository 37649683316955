import React from "react";
import { Segment,Checkbox } from "semantic-ui-react";
// import { DateRangePicker } from "rsuite";

import CountryFilter from '../Filters/CountryFilter'
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import ServiceTypes from "../Filters/ServiceTypes";
import CourierFilter from "../Filters/CourierFilter";

function Filters({
   businessclient, 
   serviceType, 
   country ,
   setBusinesclient,
   setCountry,
   setServicetype ,
   onlyStandard,
   setOnlyStandard,
   onlyActive,
   setOnlyActive,
   courier,
   setCourier
  }) {

  
  return (
    <Segment>
      <div className='flex--between'>
     
      <div className='flex--between'> 
      <BusinessclientFilter
      
        name="businessclient"

        
      />

      <CountryFilter
        name='county'
       
        multiple={false}
      />
      <CourierFilter
        name='courier'
        multiple={false}
      />
    
    <ServiceTypes
       handleChange={(value)=>setServicetype(value)}
       value={serviceType}
       multiple={false}
       loadAll={true}
    />
    </div>
    <div>
    <Checkbox
       onChange={()=>setOnlyStandard(!onlyStandard)}
       checked={onlyStandard}
       label='only standard rates'
    ></Checkbox>
    <Checkbox
       onChange={()=>setOnlyActive(!onlyActive)}
       checked={onlyActive}
       label='only active'
    ></Checkbox>
      {/* <div className="services-filters-date">
        <DateRangePicker
          placeholder="Select Date Range"
          block
          // value={date}
          onChange={([dateFrom, dateTo]) =>
            updateFilters({ dateFrom, dateTo, currentPage: 1 })
          }
        />
      </div> */}
</div>
</div>
    </Segment>
  );
}
export default Filters;
