import React from "react";
import { Table, Header } from "semantic-ui-react";

class ViewListTable extends React.Component {
  render() {
    const { selection } = this.props;

    // {
    //   _id: "5ddbeb6348159e96f0eafca1",
    //   quantity: 4,
    //   orders: ["5ddbebb52ddfae982a493c79"],
    //   details: { warehouse: "5ddbe4b848159e96f0eafc8c", inhouse_position: null },
    //   product: {
    //     _id: "5ddbeb2e2ddfae982a493c68",
    //     name: "Immortal Shoes-43-Green",
    //     codes: { sku: "2045", barcode: "4534331233112" }
    //   }
    // }

    const headers = (
      <Table.Row>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell>SKU</Table.HeaderCell>
        <Table.HeaderCell>Position</Table.HeaderCell>
        <Table.HeaderCell>#Products</Table.HeaderCell>
        <Table.HeaderCell>#Pick Orders</Table.HeaderCell>
      </Table.Row>
    );

    const rows = selection.map(
      ({ _id, details, product, quantity, orders }, i) => (
        <Table.Row key={i}>
          <Table.Cell>{product.name[0]}</Table.Cell>
          <Table.Cell>{product.codes[0]}</Table.Cell>
          <Table.Cell>{details.inhouse_position}</Table.Cell>
          <Table.Cell>{quantity}</Table.Cell>
          <Table.Cell>{orders.length}</Table.Cell>
        </Table.Row>
      )
    );

    return (
      <Table celled fixed unstackable className="fixedHTable">
        <Table.Header>{headers}</Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  }
}

export default ViewListTable;
