import { apiCall } from "../services/api";
import { addError} from "./error";
// import history from "../history";

import {
// CREATE_FULFILPRODUCT,
DELETE_FULFILPRODUCT,
UPDATE_FULFILPRODUCT,
ADDPRODUCT_FULFILPRODUCT,
REMOVEPRODUCT_FULFILPRODUCT,
GET_FULFILPRODUCT,
GET_FULFILPRODUCTLIST,

} from "./actionTypes";


// //////////////////////////////////////////////////////////////////////////////
// Product Group

export const createFulfilProduct = (history, body) => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts`;
  apiCall(`post`, url, body)
    .then(resp => {
      // dispatch({ type: CREATE_PRODUCT_GROUP, payload: resp });
      history.push(`/settings/fulfilproducts/${resp._id}`);
    })
    .catch(err => dispatch(addError(err.message)));
};

export const getFulfilProduct = _id => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts/${_id}`;
  apiCall(`get`, url)
    .then(resp => dispatch({
      type: GET_FULFILPRODUCT,
      payload: resp
    }))
    .catch(err => dispatch(addError(err.message)));
};

export const getFulfilProductList = () => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts`;
  apiCall(`get`, url)
    .then(resp => dispatch({
      type: GET_FULFILPRODUCTLIST,
      payload: resp
    }))
    .catch(err => dispatch(addError(err.message)));
};

export const updateFulfilProduct = ({ _id, name,isActive }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts/${_id}`;
  apiCall(`put`, url, { name ,isActive})
    .then(resp => dispatch({
      type: UPDATE_FULFILPRODUCT,
      payload: resp
    }))
    .catch(err => dispatch(addError(err.message)));
};

export const deleteFulfilproduct = (id, history) => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts/${id}`;
  apiCall(`delete`, url)
    .then(resp => dispatch({
      type: DELETE_FULFILPRODUCT,
      payload: resp
    }))
    .then(() => history && history.push("/settings/fulfilproducts"))
    .catch(err => dispatch(addError(err.message)));
};

export const addProductsProductGroup = ({ _id, products }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts/${_id}/addproducts`;
  apiCall(`post`, url, { products })
    .then(resp => dispatch({
      type: ADDPRODUCT_FULFILPRODUCT,
      payload: resp
    }))
    .catch(err => dispatch(addError(err.message)));
};

export const removeProductsProductGroup = ({ _id, productId }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/fulfilproducts/${_id}/removeproduct`;
  apiCall(`post`, url,{productId})
    .then(resp =>
      dispatch({
        type: REMOVEPRODUCT_FULFILPRODUCT,
        payload: resp
      })
    )
    .catch(err => dispatch(addError(err.message)));
};

