import React, { useState } from "react";
import { Button, Header, TextArea,Divider,Checkbox } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import ExportButton from "../../ExportButton";
import CourierFilter from "../../Filters/CourierFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

function Exportids({openModal=false,setExportInputOpen}) {

  const [data, setData] = useState();
  // const [selectedStatus, setStatus] = useState("");
  const [selectedClient, setBusinessclient] = useState(null);
  const [selectedCourier, setCourier] = useState("");
  const [type, setType] = useState("internalNumericId");

  const handleInput        = (e,{value})    =>setData(value)

  const modal = (
    <Modal
      open={openModal}
      showCloseIcon={false}
      closeOnEsc={true}
      onClose={() => setExportInputOpen(false)}
      center
      classNames={{
        modal: "",
      }}
    >
      <Header as="h4">Export Input Ids</Header>
      <div className="flex--column" style={{ minWidth: "220px" }}>
        <Checkbox
          style={{ marginBottom: "5px" }}
          label="Input internalNumericId"
          name="internalNumericId"
          checked={type === "internalNumericId"}
          onChange={(e, data) => setType("internalNumericId")}
        ></Checkbox>

        <Checkbox
          style={{ marginBottom: "5px" }}
          label="Input client Order number"
          name="clientOrderNr"
          checked={type === "clientOrderNr"}
          onChange={(e, data) => setType("clientOrderNr")}
        ></Checkbox>

        <Checkbox
          style={{ marginBottom: "5px" }}
          label="Input Courier Tracking Id"
          name="trackingId'"
          checked={type === "trackingId"}
          onChange={(e, data) => setType("trackingId")}
        ></Checkbox>
      </div>
      {type === "trackingId" && (
        <>
          <Divider></Divider>

          <CourierFilter
            useFilter={false}
            multiple={false}
            value={selectedCourier}
            fluid={true}
            handleChange={(value) => {
              setCourier(value);
            }}
          />
        </>
      )}

      {type === "clientOrderNr" && (
        <>
          <Divider></Divider>

          <BusinessclientFilter
            useFilter={false}
            multiple={false}
            // value={selectedClient}
            fluid={true}
            handleChange={(value) => {
              setBusinessclient(value);
            }}
          />
        </>
      )}

      <Divider></Divider>

      <TextArea
        style={{ width: "100%" }}
        placeholder="insert orders [space] separated"
        value={data}
        onChange={handleInput}
      ></TextArea>

      <div style={{ marginTop: "2.7em" }} className="right--text"></div>
      <ExportButton
        // total={0}
        filters={{
          data,
          businessclient: selectedClient,
          courier: selectedCourier,
          type,
        }}
        fileName={`orders_input`}
        url="/orders/export/ids"
        text="Export"
        onClick={() => {
          setExportInputOpen(false);
          setData(null);
        }}
      />
    </Modal>
  );
  return (

    <div>
        <Button fluid color="grey"  onClick={()=>setExportInputOpen(true)}>
            Export From Input ids
          </Button>     
           {modal}
    </div>
   
  );
}

export default (Exportids);
