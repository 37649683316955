import React, { useState } from "react";
import { Input, Grid, Button } from "semantic-ui-react";

import CountryFilter from "../../Filters/CountryFilter";

const initFormValues = {
  country: "",
  coid: "",
  taxid: "",
};

function AddClientIds({ addClientIds }) {
  const [clientIds, setClientIds] = useState(initFormValues);

  const handleChange = (e, { name, value }) =>
    setClientIds((prev) => ({ ...prev, [name]: value }));

  const clearForm = () => setClientIds(initFormValues);

  return (
    <div className="businessclient__form">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <label>Country</label>
            <CountryFilter
              useFilter={false}
              fluid
              name="country"
              value={clientIds.country}
              placeholder="country..."
              style={{maxWidth:"200px"}}
              handleChange={(value) =>
                handleChange(null, { name: "country", value })
              }
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <label>Company ID</label>
            <Input
              fluid
              type="text"
              name="coid"
              value={clientIds.coid}
              placeholder="CO ID..."
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <label>TAX ID</label>
            <Input
              fluid
              type="text"
              name="taxid"
              value={clientIds.taxid}
              placeholder="TAX ID..."
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={15}>
            <Button
              style={{ marginTop: "1em" }}
              compact
              basic
              floated="right"
              content="Clear"
              onClick={clearForm}
            />
            <Button
              style={{ marginTop: "1em" }}
              compact
              floated="right"
              content="Add"
              onClick={() => {
                // console.log('adding client:',clientIds)
                addClientIds(clientIds);
                clearForm();
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default AddClientIds;
