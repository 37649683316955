import React,{useState} from 'react'
import Modal from "react-responsive-modal";
import {Header,Form,Divider,Button} from 'semantic-ui-react'
import {addItem} from '../../../actions/inboundshipment'
import {Alert} from 'rsuite'
import DatePicker from "react-datepicker";
// import ProductFilterList from '../../Filters/ProductFilterList'

const Additem = ({openModal,closeModal,businessclient,id,cb})=>{

  console.log("IN AddItem:",businessclient)
    
    const [item,setItem] =useState()
    const [quantity,setQuantity] = useState()
    // const [damaged,setDamaged] = useState(0)
    // const [description,setDescription] = useState('')
    const [expiration,setExpiration] = useState(null)
    const [lot,setLot] = useState(null)


const addCountedItem=()=>{

    addItem({   
                id,
                item:{product:item, quantity,expiration,lot}
                })
        .then(data=>{
            closeModal(false)
            cb(data)}
          )
        .catch(err=>Alert.error(err.message))
    }

    const newItemMode = (
        <div id="product_label">
          <Header as="h4">Add New xxx Item</Header>
  
          <Divider />
          <Form widths="equal" autoComplete="off">
            <Form.Group>
              {/* <ProductFilterList  
                  useFilter={false}
                  client={businessclient}
                  handleChange={(value)=>setItem(value)}
                  fluid={true}
                  disabled={!businessclient}

              ></ProductFilterList> */}
              {/* <Form.Select
                search
                onSearchChange={(event,data)=>setSearch(data.searchQuery)}
                label="Product"
                name="product"
                value={item}
                options={products.map((product) => ({
                                                    value: product._id,
                                                    text: product.name,
                                                    key: product._id,
                                                    }))}
                loading={products.length == 0}
                onChange={(e,{value})=>setItem(value)}
                // value={product}
              ></Form.Select> */}
              {/* <Button label='scan'>Scan</Button> */}
            </Form.Group>
            {/* <Form.Group> */}
              <Form.Input
                fluid
                autocomplete="off"
                label="Quantity Available"
                placeholder="quantity"
                // value={quantity_available}
                name="quantity"
                onChange={(e,{value})=>setQuantity(value)}
              />
  
              {/* <Form.Input
                fluid
                autocomplete="off"
                label="Quantity Damaged"
                placeholder="quantity"
                name="quantity_damaged"
                onChange={(e,{value})=>setDamaged(value)}
                // value={quantity_damaged}
              /> */}
              <Form.Input
                fluid
                autocomplete="off"
                label="Lot"
                placeholder="lot number"
                name="lot"
                onChange={(e,{value})=>setLot(value)}
                // value={quantity_damaged}
              />

          <p>Expiration date</p>
          <DatePicker
                autoComplete="off"
                selected={expiration}
                isClearable={true}
                dateFormat="MMMM d, yyyy"
                onChange={(date)=>setExpiration(date)}
                placeholderText="expiration"
                name="expiration"
                selectsEnd
                // minDate={filters.startDate}
              ></DatePicker>
           
            {/* <Form.Field>
              <TextArea
                rows="4"
                cols="50"
                label="Description"
                name="description"
                // value={description}
                onChange={(e,data)=>console.log(data)}
              />
            </Form.Field> */}
  
  
      
            <div className="flex--right">
              {/* <Button onClick={() => this.handleClose()}>Cancel</Button> */}
              <Button fluid color="blue" 
              onClick={addCountedItem}
              >
                Add Item
              </Button>
            </div>
          </Form>
        </div>
      );

    const modal =  <Modal
                        open={openModal}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                            closeModal(false);
                        }}
                        center
                        classNames={{
                        modal: ""
                        }}
                        >
                    {newItemMode}
                    </Modal>

  
      return (<div>
          {modal}
      </div>)
}

export default Additem
