import React, { Component } from "react";
import {
  Table,
  Button,
  Checkbox,
  Input,
  Label,
  List,
  Icon,
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export const statusColor = {
  New: "red",
  pending: "orange",
  Shipping: "blue",
  "No goods": "yellow",
  "Goods Blocked": "olive",
  Delivered: "green",
  "Partially Delivered": "teal",
};

class PoListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GR: false,
      gr_edit_id: "",
      inv_edit_mode: false,
      items: this.props.po ? this.props.po.items : [],
      invoicenumber: this.props.po.documents
        ? this.props.po.documents.invoice
        : "N/A",
      showAllProd: "",
    };

    this.handleGoodsReceived = this.handleGoodsReceived.bind(this);
    this.handleInvoiceReceived = this.handleInvoiceReceived.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateInvoice = this.handleUpdateInvoice.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleGoodsReceived() {
    this.setState({ GR: !this.state.GR });
  }
  handleInvoiceReceived() {
    this.setState({
      inv_edit_mode: !this.state.inv_edit_mode,
    });
  }
  handleUpdateGR(item_id) {
    const query = {
      item_id,
      po_id: this.props.po._id,
      quantity: this.state.goodsReceived,
    };
    this.props.updateGR(query);

    this.setState({
      gr_edit_id: "",
    });
  }
  handleUpdateInvoice() {
    this.setState({
      inv_edit_mode: !this.state.inv_edit_mode,
    });

    const query = {
      id: this.props.po._id,
      documents: {
        invoice: this.state.invoicenumber,
      },
    };

    this.props.updatePo(query);
  }

  toggleExpand = (_id) => {
    if (_id === this.state.showAllProd) {
      this.setState({ showAllProd: "" });
    } else {
      this.setState({ showAllProd: _id });
    }
  };

  render() {
    const { po, handleDeletePO, handleSelected, selected, index } = this.props;
    const { invoicenumber, showAllProd } = this.state;


let productsList =[]
for (let i =0;i<po.items.length;i++){
  let item=po.items[i]
  if(i===3) break
  productsList.push(
    <li key={i}>
             {item.product&&item.product.name}- x {item.quantity} <br />
             {item.quantity_shipped && (
               <label className="smallinfo">
                 shipped:{item.quantity_shipped}{" "}
               </label>
             )}
           </li>
  )

}
    return (
      <Table.Row>
        <Table.Cell>
          {["Goods Blocked", "New"].includes(po.status) ? (
            <Checkbox
              onChange={(e, data) => handleSelected(po._id, data.checked)}
              checked={selected.includes(po._id)}
            ></Checkbox>
          ) : (
            <Icon name="angle double right" color="grey" />
          )}
        </Table.Cell>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>
          <Link to={`/pos/${po._id}`}>
            {po.numericId ? po.numericId : po._id.substr(-6)}
          </Link>
        </Table.Cell>
        <Table.Cell textAlign="left">
          <Label color={statusColor[po.status]}>{po.status}</Label>
          <br />
          {po.shipment && (
            <div className="smallinfo">
              Shipment:{" "}
              <Link to={`/shipments/outbound/${po.shipment}`}>
                {po.shipment.slice(-6)}
              </Link>
            </div>
          )}
          {po.comments.length > 0 && (
            <div className="smallinfo">comments {po.comments.length}</div>
          )}
        </Table.Cell>
        <Table.Cell textAlign="left">
          <List style={{ marginBottom: 0 }}>{productsList}</List>
          {po.items.length > 3 && (
            <button
              className="pg-ellipsis-btn"
              style={{ marginLeft: "-0.5em" }}
              onClick={() => this.toggleExpand(po._id)}
            >
              <Icon
                name={
                  showAllProd === po._id ? "angle up" : "ellipsis horizontal"
                }
              />
            </button>
          )}
        </Table.Cell>

        <Table.Cell textAlign="left">
          {po.businessclient && po.businessclient.name}
        </Table.Cell>
        <Table.Cell textAlign="left">
          {po.supplier && po.supplier.name}
          <div>
            {po.tracking && po.tracking.emailed_supplier ? (
              <Label color="green" size="mini">
                email sent
              </Label>
            ) : po.tracking && !po.tracking.emailed_supplier ? (
              <Label color="grey" size="mini">
                email not sent
              </Label>
            ) : (
              <div>sending email...</div>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {po.shipTo && (
            <div className="flex--column">
              <div>{po.shipTo.address.country}</div>
              <div className="smallinfo">{po.shipTo.address.city}</div>
              <div className="smallinfo">
                {po.shipTo.address.street},{po.shipTo.address.streetNr}
              </div>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          Created: <Moment format="DD/MM/YY HH:mm">{po.createdAt}</Moment>
          <br></br>
          {po.tracking && po.tracking.shippedDate && (
            <div>
              Shipped:{" "}
              <Moment format="DD/MM/YY HH:mm">{po.tracking.shippedDate}</Moment>
              <br></br>
            </div>
          )}
          {po.tracking && po.tracking.deliveredDate && (
            <div>
              Delivered:{" "}
              <Moment format="DD/MM/YY HH:mm">
                {po.tracking.deliveredDate}
              </Moment>
            </div>
          )}
        </Table.Cell>
        {/* <Table.Cell>{po.supplier.name}</Table.Cell> */}

        <Table.Cell>
          Invoice: {this.state.invoicenumber}
          <a onClick={this.handleInvoiceReceived}>{` | edit`}</a>
          <br />
          {this.state.inv_edit_mode && (
            <Input
              disabled={!this.state.inv_edit_mode}
              size="mini"
              action={{
                // disabled:!trackingNr,
                color: "teal",
                // labelPosition: 'right',
                icon: "save",
                // content: 'product.quantity.received',
                onClick: this.handleUpdateInvoice,
              }}
              name="invoicenumber"
              value={invoicenumber}
              onChange={this.handleChange}
              placeholder="invoice nr"
            />
          )}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            icon="pencil"
            compact
            size="tiny"
            // color = 'grey'
            // id = 'polistrow_action'
            basic
            onClick={() => this.props.history.push(`/pos/${po._id}`)}
          />
          {(po.status === "New" || po.status === "No goods") && (
            <Button
              icon="trash alternate outline"
              compact
              size="tiny"
              // color = 'grey'
              // id = 'polistrow_action'
              basic
              onClick={() => handleDeletePO(po._id)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default PoListItem;
