import React, { useEffect } from "react";
import { Header, Button, Input, Dropdown, Divider } from "semantic-ui-react";
import { useState } from "react";
import { connect } from "react-redux";

import {
  createReportTemplate,
  editReportTemplate,
} from "../../../actions/couriers";
import CourierFilter from "../../Filters/CourierFilter";
import OrderStatusFilter from "../../Filters/OrderStatusFilter";

import "../courier.scss";

const pairedKeyOptions = ["clientOrderNr", "trackingId"].map((item, i) => ({
  key: i,
  value: item,
  text: item,
}));

// const initState = {
//   name: "",
//   courier: "",
//   pairedKey: "clientOrderNr",
//   columnPosition: "1"
// };

function ReportModal(props) {
  const {
    initReportFormValues,
    handleCloseModal,
    isEditModeOn,
    courierId,
    createReportTemplate,
    editReportTemplate,
  } = props;

  const [report, setReport] = useState(initReportFormValues);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => setReport(initReportFormValues), [initReportFormValues]);

  useEffect(() => {
    const atLeastOneFieldNotCompleted = Object.keys(report).find(
      (key) => !report[key]
    );
    setSaveDisabled(atLeastOneFieldNotCompleted);
  }, [report]);

  const handleFieldChange = (e, { name, value }) =>
    setReport((r) => ({ ...r, [name]: value }));

  const handleCancel = () => handleCloseModal();

  const handleSubmit = () => {
    isEditModeOn
      ? editReportTemplate({ id: courierId, query: report })
      : createReportTemplate({ id: courierId, query: report });
    handleCloseModal();
  };

  return (
    <section className="courier-report-template">
      <Header as="h3">
        {isEditModeOn ? "Edit" : "New"} Courier Reports Template
      </Header>
      <Divider />

      <form autoComplete="off" className="courier-report-template__grid">
        <div className="courier-report-template__field">
          <label className="courier-report-template__label">Name:</label>
          <OrderStatusFilter
            name="name"
            value={report.name}
            handleChange={handleFieldChange}
          />
        </div>
        {/* <div className="courier-report-template__field">
          <label className="courier-report-template__label">Courier:</label>
          <CourierFilter
            value={report.courier}
            handleChange={handleFieldChange}
          />
        </div> */}
        <div className="courier-report-template__field">
          <label className="courier-report-template__label">Paired key:</label>
          <Dropdown
            selection
            search
            placeholder="paired key..."
            name="pairedKey"
            value={report.pairedKey}
            onChange={handleFieldChange}
            options={pairedKeyOptions}
          />
        </div>
        <div className="courier-report-template__field">
          <label className="courier-report-template__label">
            Column number:
          </label>
          <Input
            placeholder="1"
            name="columnPosition"
            type="number"
            min="1"
            value={report.columnPosition}
            onChange={handleFieldChange}
          />
        </div>
      </form>
      <div className="flex--right">
        <Button onClick={handleCancel}>Cancel</Button>

        <Button color="blue" onClick={handleSubmit} 
          // disabled={saveDisabled}
          >
          Save
        </Button>
      </div>
    </section>
  );
}

export default connect(null, { createReportTemplate, editReportTemplate })(
  ReportModal
);
