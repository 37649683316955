import React from "react";

import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import EditableText from "../../EditableText";
import { updateInventoryCard } from "../../../actions/inventory";

import { grlineUpdate } from "../../../actions/inboundshipment";
import { DatePicker } from "rsuite";

const ItemList = ({
  goodsreceived,
  id,
  handleDelete,
  setShipment,
  reload,
  setReload
}) => {

  const updateInv = ({ id, body }) => {
    updateInventoryCard({ id, body })
      .then((resp) => {setReload(!reload)})
      .catch((err) => console.log(err));
  };

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>IDS</Table.HeaderCell>
      {/* <Table.HeaderCell>Cost</Table.HeaderCell> */}
      {/* <Table.HeaderCell>Currency</Table.HeaderCell> */}
      <Table.HeaderCell>LOT</Table.HeaderCell>
      <Table.HeaderCell>Expiration</Table.HeaderCell>
      <Table.HeaderCell>Counted</Table.HeaderCell>
      <Table.HeaderCell>Packlist</Table.HeaderCell>
      <Table.HeaderCell>Diff</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = goodsreceived.map((item, index) => {
    // const path =
    //   item.stockMovement.inventory.photos &&
    //   item.stockMovement.inventory.photos.files.length > 0
    //     ? `${process.env.REACT_APP_API_FILES_VIEW}/${item.stockMovement.inventory.photos.files[0].path}`
    //     : null;

    let product = {};
    if (!item.product) {
      product = {
        name: "NA",
        codes: { sku: "NA", barcode: "NA", clientsku: "NA" },
      };
    } else product = item.product;

    return (
      <Table.Row>
        <Table.Cell>{index + 1}</Table.Cell>

        <Table.Cell>
          {item.product && (
            <Link to={`/products/${item.product._id}`} target="_blank">
              {product.name}
            </Link>
          )}
          {/* {product.name} */}
          {!item.product && (
            <Button basic compact size="tiny">
              Pair
            </Button>
          )}
        </Table.Cell>
        <Table.Cell>
          <span className="small--text bold--text">
            <span className="normal--text inbound-goodsreceived__label">
              Barcode:
            </span>
            {product.codes?.barcode || ""}
            <br />
            <span className="normal--text inbound-goodsreceived__label">
              Sku:
            </span>
            {product.codes?.sku || ""}
            {/* <br />
            <span className="normal--text">Client:</span>{" "}
            {product.codes.clientsku || ""} */}
          </span>
        </Table.Cell>
        <Table.Cell>
          {" "}
          <EditableText
            style={{ width: "80px" }}
            placeholder={"lot..."}
            value={item.inventoryCard.lot}
            name={"lot"}
            handleChange={(e, { value }) => {
                updateInv({
                body: { lot: value },
                id: item.inventoryCard._id,
              })
            }}
            transparentBackground={true}
          />
        </Table.Cell>
        <Table.Cell>
          {" "}
          <DatePicker
            style={{ width: "200px" }}
            oneTap
            // placeholder="select"
            value={item.inventoryCard.expiration}
            format="DD-MM-YYYY"
            // ranges={[dateFrom,dateTo]}
            onChange={(value) =>{
                updateInv({
                body: { expiration: value },
                id: item.inventoryCard._id,
              })
            }
            
            }
          />
        </Table.Cell>
        <Table.Cell>
          {item.stockMovement.quantity}
          {item.stockMovement.quantity_damaged
            ? `/${item.stockMovement.quantity_damaged}`
            : ""}
        </Table.Cell>

        <Table.Cell>
          <EditableText
            style={{ width: "100px" }}
            name="packingListQuantity"
            value={item.packingListQuantity}
            handleChange={(e, { name, value }) => {
              console.log(id, item._id);
              console.log(name, value);

              grlineUpdate({
                id,
                lineId: item._id,
                query: {
                  packingListQuantity: value,
                  countedQuantity: item.stockMovement.quantity,
                },
              }).then((resp) => setShipment(resp));
            }}
          />
        </Table.Cell>
        <Table.Cell>{item.packingListDifference}</Table.Cell>
        <Table.Cell>
          <Button
            basic
            icon="trash"
            size="tiny"
            onClick={() => handleDelete(item._id)}
          />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table basic="very" style={{ marginTop: "2em" }}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
};

export default ItemList;
