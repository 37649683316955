import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { getCouriers } from "../../actions/couriers";
import { useUpdateContext } from "../../context/UpdateContext";


function CourierFilter({
  useFilter = true,
  value,
  multiple = true,
  fluid = false,
  error = false,
  style = {
    margin: "2px",
    maxWidth: "200px",
    border: "none !important",
    color: "#3F72AF !important"
  },
  defaultOpen = false,
  country = null,
  disabled = false,
  handleChange,
  placeholder = "courier...",
}) {
  const [searchTerm, setSearch] = useState();
  const [courierList, setList] = useState([]);
  const [isLoading, setIsloading] = useState();

  const { handleCourierChange, courier, setDropList } = useUpdateContext();

  useEffect(() => {
    if (localStorage.courierList && !searchTerm) {
      const list = JSON.parse(localStorage.getItem("courierList") || "[]");
      setList(list);
    } else {
      setIsloading(true);
      getCouriers({ searchTerm, country })
        .then((list) => {
          setIsloading(false);
          setDropList({ name: "courierList", list: JSON.stringify(list) });
          setList(list);
        })
        .catch((error) => error.message);
    }
  }, [country, searchTerm]);

  return (
    <Dropdown
      disabled={disabled}
      defaultOpen={defaultOpen}
      loading={isLoading}      
      style={style}
      placeholder={placeholder}
      name="courier"
      value={useFilter?courier:value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={(e, { value }) =>{useFilter? handleCourierChange(value):handleChange(value)}}
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
      options={courierList.map((item) => ({
        value: item._id,
        text: item.name,
        key: item._id,
      }))}
    />
  );
}

export default CourierFilter;
