import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { Divider, Form, Button, Segment, Checkbox } from "semantic-ui-react";

import { Alert } from "rsuite";

import Fileupload from "./Uploader/files";
import { removeFiles, getFiles, updateFile } from "../actions/files";

// const format_options = [
//   {
//     label: "A4",
//     value: "A4",
//     role: "Master",
//   },
//   {
//     label: "A6",
//     value: "A6",
//     role: "Master",
//   },
// ];
function Documents({
  category="businessclients",
  style = {},
  triggerId,
  trigger,
  businessclient,
  scope = "print",
  accept = ".pdf,.xlsx,.xls,.dot,.jpeg,.jpg",
}) {
  const [country, setCountry] = useState();
  const [description, setDescription] = useState();
  const [url, setUrl] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [docs, setDocs] = useState([]);
  const [refresh, refreshFileList] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    setIsloading(true);

    setTimeout(() => {
      getFiles({ triggerId, trigger, filetype: "document" }).then((resp) => {
        setDocs(resp);
        setIsloading(false);
      });
    }, 500);
  }, [triggerId, country, refresh, trigger]);

  // const handleUploadFile = (id) => {
  //   setSelectedDoc(id);
  //   setShowUpload(true);
  // };

  // const handleSaveForm = () => {
  //   setIsloading(true);

  //   handleClose();
  // };


  const handleUpdatefile = ({ checked, _id }) => {
    updateFile({ print: checked, id: _id })
      .then(resp=>refreshFileList(!refresh))
      // .catch(er=>alert('error:',er.message))
  };

  const handleClose = () => {
    setModalOpen(false);
    setDescription("");
    setUrl("");
    setCountry(null);
  };

  const handleRemoveFile = (file) => {
    setIsloading(true);
    removeFiles([file])
      .then((resp) => {
        refreshFileList(!refresh);
        Alert.success("File Deleted");
      })
      .catch((error) => Alert.error(JSON.stringify(error)));
  };

  const items = docs.map((file, index) => (
    <tr key={index}>
      <td>{index + 1}</td>

      {/* <td>{item.country}</td> */}
      {/* <td>{item.desctiption}</td> */}
      <td className="flex--left" style={{ margin: "3px" }}>
        <div>{file.originalname}</div>
        <div>
          <a href={file.publicurl}> - view</a>
        </div>
      </td>
      <td>
        {file.country?file.country:''}
      </td>
      <td>
        <button onClick={() => handleRemoveFile(file._id)}>x</button>
      </td>
    
      <td>
        <Checkbox
          checked={file.print}
          onChange={(e, { checked }) =>
            handleUpdatefile({ checked, _id: file._id })
          }
        ></Checkbox>
      </td>
    </tr>
  ));

  const handleUploadedFiles = (files) => {
    setModalOpen(false);
    refreshFileList(!refresh);
  };

  const documents = (
    <table style={{ width: "100%",textAlign:'left' }}>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Country</th>
        <th>Actions</th>
        {/* <th>URL</th> */}
        {/* <th>Action</th> */}
        <th>Print</th>
      </tr>
      {items}
    </table>
  );

  const addModal = (
    <Modal
      styles={{ modal: { width: "400px" } }}
      open={modalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => {
        handleClose();
      }}
      center
      classNames={{
        modal: "",
      }}
    >
      <div id="product_label">
        <div className="flex_spaceBetween">
          <h3>Add Files</h3>
        </div>
        <Divider></Divider>
        <Form>
          {/* <Form.Field>
            <label>Description</label>
            <Input
              onChange={(e, { value }) => setDescription(value)}
              name="description"
              value={description}
              placeholder="type of label"
            />
          </Form.Field> */}

          {/* <Form.Field>
              <label>Description</label>
              <textarea rows="4" cols="50"
                        
              ></textarea>
            </Form.Field> */}
          <Fileupload
            category={category}
            businessclient={
              businessclient && businessclient._id
                ? businessclient._id
                : businessclient
            }
            trigger={trigger}
            triggerId={triggerId}
            accept={accept}
            // filetype=".pdf"
            scope={scope}
            setIsloading={setIsloading}
            getUploadedFiles={(files) => {
              handleUploadedFiles(files);
            }}
          ></Fileupload>

          {/* <Button type="submit" onClick={handleSaveForm}>Save</Button> */}
        </Form>
      </div>
    </Modal>
  );

  return (
    <Segment loading={isLoading} style={style}>
      {addModal}
      {/* <Segment> */}
      <div className="flex_spaceBetween">
        <h3>Files</h3>{" "}
        {items.length > 0 && (
          <Button type="submit" onClick={() => setModalOpen(true)}>
            Add
          </Button>
        )}
      </div>
      <Divider></Divider>
      {/* 
      <CountryFilter
        value={country}
        handleChange={(e, { value }) => setCountry(value)}
      /> */}

      {items.length === 0 && (
        <div>
          {/* <h6>Add first document:</h6> */}
          <Button basic type="submit" onClick={() => setModalOpen(true)}>
            Add first document
          </Button>
        </div>
      )}
      {items.length > 0 && documents}
    </Segment>
  );
}

export default Documents;
