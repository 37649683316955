import React, {useEffect,useState,useRef} from 'react'
import {Input,Divider,Header,Segment} from 'semantic-ui-react'
import {changeLocation} from '../actions/storagelocation'
import {Alert} from 'rsuite'

const data=[{product:{_id:'5fe9b5545c947ee00cc35211',name:"xyz",codes:{barcode:"8594001021758"}},inventorycard:{_id:"61d217cf3f0bff9f5f50d620",quantity:110}},
            {product:{_id:'6054b2bb55b8f54aed36feac',name:"product 2",codes:{barcode:"6926984000833"}},inventorycard:{_id:"61eab07a12a77918e9f89fb6",quantity:50}}]

const ChangeStockStorageLocation = ({
                                    items=data,
                                    from_location='test2',
                                    triggredBy='Inboundshipment',
                                    triggerId='61d217c63f0bff9f5f50d61e'})=>{

  const [to_location, setLocation ]   = useState(null)
  const [hideLocationinput, setHideLocationInput ]   = useState(false)
  const [product,  setProduct  ]   = useState('')
  const [scanlist,setScanlist]     = useState([])
  const searchInput = useRef(null);


  const handleMoveGoods =()=>{
    setProduct('')
    const body={
      items:scanlist,
      new_storageSpace:to_location,
      triggredBy,
      triggerId
    }
    
      console.log(body)

      changeLocation({
          id:'5fd8892dc025d33095070925',//warehouse id
          spacename:from_location,
          body
        }).then(data=>Alert.success('Stock moved.'))
  }
  useEffect(()=>{
    // current property is refered to input element
   if(to_location) setTimeout(()=>{
     
    searchInput.current.focus()
    setHideLocationInput(true)
  },1000)
 },[to_location])

  var expr = new RegExp(product, "gi");

  const body= <Segment style={{
                    width:'95%',
                    margin:'10px',
                    marginTop:'20px'
                  }}>
            
            <segment id='header'>

              <Header> Move Goods From Location: {from_location}</Header>
            
            </segment>
            <Divider></Divider>
            <segment >
                 {!hideLocationinput&& <Input 
                                          fluid
                                          placeholder='scan new to_location..' 
                                          onChange={(e,{value})=>{
                                                                  setLocation(value)
                                                                  }}></Input>}
                  {hideLocationinput&&<div className='flex--between'>
                  
                                        <div>New Location: {to_location}</div>
                                        <button onClick={()=>{
                                                setHideLocationInput(false)
                                                setLocation(null)
                                                
                                        }}>reset</button>
                                        </div>}
                </segment>

                
                  {to_location&&  <segment>

                    <Input placeholder='scan product...' 
                           ref={searchInput}
                           fluid 
                           value={product}
                           onChange={(e,{value})=>setProduct(value)}></Input>

                  {product&& <segment className='items list'>
                            {items.map((elem) => {
                              if(expr.test(elem.product.codes.barcode) ) {

                                            setTimeout(()=>{
                                                  setProduct('')
                                                  setScanlist([...scanlist,elem])
                                                },1*1000)
                                              // eslint-disable-next-line array-callback-return
                                              return 
                                              
                                                                  }
                                                                  // eslint-disable-next-line array-callback-return
                                                                  return
                              })}
                            
                    </segment>}

                 {scanlist.length>0&& <div>
                   <Divider></Divider>
                 <div className='flex--between'>
                   <Header>Selected product</Header>
                   
                   <button onClick={()=>{
                  handleMoveGoods()
                      // setScanlist([])
                      
                      }}>Move list</button>                   </div> 
                  <div>{scanlist.map(item=><div className='flex--between'>
                                            <li>{item.product.name}</li>
                                            <Input  
                                                  style={{width:'80px',height:'30px'}}
                                                  name={item.product.name} 
                                                  value={item.inventorycard.quantity}
                                                  onChange={(e,{name,value})=>{
                                                            //find the item
                                                          const modifiedlist = scanlist.map(i=>{
                                                            if(i.product.name===name) {
                                                                      i.inventorycard.quantity=value
                                                              }
                                                            return i
                                                          }
                                                          )
                                                          setScanlist(modifiedlist)  
                                                    }}

                                                    ></Input>
                                            </div> )}</div>

                  <div className='flex--between'>
                    <div></div>
                    
                    <button onClick={()=>setScanlist([])}>clear list</button>

                  </div>
                  
                  
                  </div>
                    }


                  <Divider></Divider>
                

                </segment>}
                <div style={{margin:'10px'}}>
                  <Header size='h3'>Loaded Products(from position scan || inbound || other)</Header>
                        {items.map(item=>
                                <div className='flex--between'>
                                    <li>
                                        {item.product.name}({item.product.codes.barcode}) -{item.inventorycard.quantity}
                                    </li>
                                 <button onClick={()=>{setScanlist([...scanlist,item])}}>add</button>
                                </div>)}
                  
                        ---------------
                      <div>
                        
                       {to_location&& <button onClick={()=>{setScanlist(items)}}>move all</button>}
                      </div>
                  </div>
                </Segment>

               

return (
  <div>
                {body}
    </div>
)
}


export default ChangeStockStorageLocation