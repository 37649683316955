import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import EditableText from "../../../EditableText";

function ProductsList(props) {
  const { items, handleDeleteItem, updatePoShippedQuantity, setPO, po_id } =
    props;
  

  if (items.length === 0) return null;

  const tableRows = items.map((item, i) => (
    <ProductItem
      po_id={po_id}
      item_id={item._id}
      stockAvailable={item.stockAvailable}
      sku={item.sku}
      quantity={item.quantity}
      quantity_shipped={item.quantity_shipped}
      i={i}
      product={item.product}
      handleDeleteItem={handleDeleteItem}
      updatePoShippedQuantity={updatePoShippedQuantity}
      setPO={setPO}
    />
  ));


  return (
    <div>
      <Table striped textAlign="left">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>SKU</Table.HeaderCell>
            <Table.HeaderCell>Quantity Ordered</Table.HeaderCell>
            <Table.HeaderCell>Quantity Shipped</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </div>
  );
}

export default ProductsList;

function ProductItem({
  po_id,
  i,
  product: { name, _id },
  stockAvailable,
  quantity,
  sku,
  handleDeleteItem,
  item_id,
  quantity_shipped,
 
  updatePoShippedQuantity,
  setPO,
}) {
  return (
    <Table.Row key={i} className="itemlist_row">
      <Table.Cell>{i + 1}</Table.Cell>
      <Table.Cell>
        {!stockAvailable && <Icon name="attention" color="yellow" />}
        {stockAvailable && <Icon name="checkmark" color="olive" />}
        <Link to={`/products/${_id}`} target="_blank">
          {name}
        </Link>
      </Table.Cell>
      <Table.Cell>{sku}</Table.Cell>

      <Table.Cell>{quantity}</Table.Cell>
      <Table.Cell>
        <EditableText
          name="quantity_shipped"
          value={quantity_shipped}
          handleChange={(e, { value, name }) => {
            updatePoShippedQuantity({
              po_id,
              item_id,
              quantity_shipped: value,
            }).then((resp) => setPO(resp));
          }}
        />
      </Table.Cell>

      <Table.Cell>
        <Button
          color="grey"
          basic
          compact
          size="tiny"
          onClick={() => handleDeleteItem({ item_id })}
        >
          <Icon name="trash alternate" />
          Delete
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}
