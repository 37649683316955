import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Loader,Input } from "semantic-ui-react";
import Modal from "react-responsive-modal";

import {
  getStoragesettings,
  deleteStoragesetting,
  updateStoragesetting,
} from "../../../../actions/businessclient";
import { useToggleModalNewEdit } from "../../../../hooks/useToggleModalNewEdit";
import ServiceTypes from "../../../Filters/ServiceTypes";

const defaultInitialFormValues = {
  country: "",
  cycles: "Once in two weeks",
  daysToDue: 14,
  vat_apply: true,
  vat: 0,
};

function Storagesettings({ bclientId, businessclient }) {
  const { isModalOpen, openModal, closeModal } = useToggleModalNewEdit(
    defaultInitialFormValues
  );

  const [isLoading, setIsloading] = useState(false);
  const [storageSetting, setstorageSetting] = useState([]);

  const handleGetAll = () => {
    setIsloading(true);
    getStoragesettings({ id: bclientId }).then((resp) => {
      setIsloading(false);
      setstorageSetting(resp);
    });
  };

  const handleUpdate = ({ set_id, name, checked }) => {
    setIsloading(true);
    updateStoragesetting({
      query: { [name]: checked },
      id: bclientId,
      set_id,
    }).then((resp) => {
      handleGetAll();
    });
  };

  useEffect(() => {
    setIsloading(true);
    getStoragesettings({ id: bclientId }).then((resp) => {
      setIsloading(false);
      setstorageSetting(resp);
    });
  }, [bclientId]);

  //   const [isViewOpen, setViewOpen] = useState([]);

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>

      <Table.HeaderCell>Service Type</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Volume(m3) convertor</Table.HeaderCell>
      <Table.HeaderCell>Billing Cycle</Table.HeaderCell>
      <Table.HeaderCell>Activ</Table.HeaderCell>
      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = storageSetting.map(
    (
      { description, m3_volume_convertor, cycle, _id, serviceType, isActive },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>

        <Table.Cell>{serviceType.name}</Table.Cell>
        <Table.Cell>{description}</Table.Cell>
        <Table.Cell>{m3_volume_convertor}</Table.Cell>
        <Table.Cell>{cycle}</Table.Cell>
        <Table.Cell>
          <Checkbox
            toggle
            disabled={false}
            name="isActive"
            checked={isActive}
            onChange={(e, { name, checked }) =>
              handleUpdate({ name, checked, set_id: _id })
            }
          />
        </Table.Cell>
        <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() => {
              setIsloading(true);
              deleteStoragesetting({
                id: bclientId,
                set_id: _id,
              }).then((resp) => handleGetAll());
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      {!storageSetting.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>No storage type setup yet.</p>
          <Button
            style={{ marginTop: "0.5em" }}
            compact
            content="Add billing"
            onClick={() => openModal(false)}
          />
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content="Add new"
            onClick={() => openModal(false)}
          />
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        // open={true}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeModal}
        center
        classNames={{ modal: "" }}
      >
        <div>
          <p>Service Type</p>
          <ServiceTypes handleChange={()=>{}}
          ></ServiceTypes>

          <p>Description</p>
          <Input></Input>

          <p></p>
        </div>
      </Modal>
    </>
  );
}

export default Storagesettings;
