import React from "react";
import { Form, Header, Dropdown, Divider, Checkbox } from "semantic-ui-react";

import { countries } from "../../../countries";

const optionsCountries = countries.map((c) => ({ key: c, value: c, text: c }));

class Adress extends React.Component {
  render() {
    const {
      header,
      displayHeader,
      isShippingAdress,
      country,
      province,
      city,
      street,
      streetNr,
      postcode,
      country_name,
      province_name,
      city_name,
      street_name,
      streetNr_name,
      postcode_name,
      validationErrors = {},
      handleChange,
    } = this.props;

    return (
      <div className="form-columns__adress">
        {displayHeader && (
          <>
            {/* <Header className="form__sub-header">{header}</Header> */}
            <Divider horizontal className="form__sub-header">
              {header}
            </Divider>
          </>
        )}

        <Form.Group>
          <Form.Field required={isShippingAdress}>
            <label>Country</label>
            <Dropdown
              search
              selection
              error={validationErrors.sh_country}
              name={country_name}
              label="Country"
              options={optionsCountries}
              placeholder="Country"
              width={8}
              value={country}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Input
            autoComplete="nope"
            name={province_name}
            label="Province"
            placeholder="Province"
            width={8}
            value={province}
            onChange={handleChange}
          ></Form.Input>
        </Form.Group>
        <Form.Group unstackable>
          <Form.Input
            autoComplete="nope"
            required={isShippingAdress}
            error={validationErrors.sh_city}
            name={city_name}
            label="City"
            placeholder="City"
            width={9}
            value={city}
            onChange={handleChange}
          ></Form.Input>
          <Form.Input
            autoComplete="nope"
            required={isShippingAdress}
            error={validationErrors.sh_postcode}
            name={postcode_name}
            label="Post Code"
            placeholder="Post Code"
            width={7}
            value={postcode}
            onChange={handleChange}
          ></Form.Input>
        </Form.Group>
        <Form.Group unstackable>
          <Form.Input
            autoComplete="nope"
            required={isShippingAdress}
            error={validationErrors.sh_street}
            label="Street"
            placeholder="street"
            name={street_name}
            width={12}
            value={street}
            onChange={handleChange}
          />
          <Form.Input
            autoComplete="nope"
            label="Nr"
            placeholder="Nr"
            name={streetNr_name}
            width={4}
            onChange={handleChange}
            value={streetNr}
          />
        </Form.Group>
      </div>
    );
  }
}

export default Adress;
