import React from "react";
import {  Input,Form } from "semantic-ui-react";
import ProductFilterList from '../Filters/ProductFilterList'
import BusinessclientFilter from '../Filters/BusinessclientFilter'
import WarehouseFilter from '../Filters/WarehouseFilter'



const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

const Filters = ({ setProductSku,productSKU,reload,setReload }) => {


  // useEffect(()=>{

  //   if(!search) {
  //     handleUpdateFilter(null, {value:'clear',
  //     name:'productSKU'});}
  // },[search])

  return (
    // <Segment>
    <div >
      <div >
        <WarehouseFilter style={itemStyle} multiple={true} />

        <BusinessclientFilter
          multiple={false}
          name="businessclient"
          style={itemStyle}
        ></BusinessclientFilter>

        <ProductFilterList
          multiple={true}
          style={itemStyle}
        ></ProductFilterList>

        <div className="filter_status">
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Form
              onSubmit={
                () => setReload(!reload)
                
              }
            >
              <Input
                clearable
                fluid
                style={itemStyle}
                // loading={productSKU}
                placeholder="product SKU..."
                name="productSKU"
                value={productSKU}
                onChange={(e, { value }) => {
                  setProductSku(value);
                }}
              />
              {/* {search&&<button
                onClick={(e)=>{
                  handleUpdateFilter(null, {value:search,
                                        name:'productSKU'});
                  // alert('go')
                }}
              >Go</button>} */}
            </Form>
          </div>
        </div>
      </div>
    </div>
    // </Segment>
  );
};

export default Filters;
