import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import {apiCall}  from '../../services/api'

// import { createOptions } from "../../helpers/createOptions";

function ServiceTypes({
  value,
  handleChange,
  multiple=false,
  fluid,
  error = false,
  category,
  billing_type,
  disabled=false,
  loadAll = false,
  style={}
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API}/services/types?category=${category}&billing_type=${billing_type}`;
  
  apiCall('get',url)
          .then(resp=>{
            // console.log(resp)
            const options = resp.map(item=>({
              key:          item._id,
              text:         item.name,
              value:        item._id,
              billing_type: item.billing_type
            }))

            setOptions(options)
          })
          .catch(error=>alert(error.message))

  }, [loadAll,category,billing_type]);
 
 

  return (
    <Dropdown
      disabled={disabled}
      placeholder="service types..."
      name="serviceTypes"
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={(e,{value})=>handleChange(value)}
      options={options}
      style={style}
    
    />
  );
}

export default (ServiceTypes);
