import React, { } from "react";
import { Table, Checkbox,  Loader, Header } from "semantic-ui-react";
import PoListItem from "./polistitem";
import "./polist.scss";

const  PoTable=(props)=>{
  
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>
          <Checkbox
            onChange={(e, data) => this.props.handleSelectAll(data.checked)}
          ></Checkbox>
        </Table.HeaderCell>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell textAlign="left">Status</Table.HeaderCell>
        <Table.HeaderCell textAlign="left">Goods Ordered</Table.HeaderCell>
        <Table.HeaderCell textAlign="left">Customer</Table.HeaderCell>
        <Table.HeaderCell textAlign="left">Supplier</Table.HeaderCell>
        <Table.HeaderCell>Ship To</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Documents</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
      </Table.Row>
    );

    const { pos=[], loading, itemsPerPage, currentPage } = props;

    const tableRows = pos.map((po, index) => (
      <PoListItem
        po={po}
        index={(currentPage - 1) * itemsPerPage + index}
        selected={props.selected}
        history={props.history}
        handleDeletePO={props.handleDeletePO}
        handleEdit={props.handleEdit}
        handleSelected={props.handleSelected}
        updatePo={props.updatePo}
        updateGR={props.updateGR}
      />
    ));

    return (
      <div className="table-wrapper--horizontal-scroll mb--1">
        <Table
          verticalAlign="middle"
          selectable
          basic="very"
          striped
          unstackable={true}
          textAlign="left"
        >
          <Table.Header>{tableHeader}</Table.Header>
          <Table.Body>{!loading && pos.length && tableRows}</Table.Body>
        </Table>

        {loading && (
          <div style={{ marginTop: "2em", marginBottom: "2em" }}>
            <Loader active inline="centered" />
          </div>
        )}

        {!loading && pos.length === 0 && (
          <Header
            style={{ paddingTop: "2em", paddingBottom: "1em" }}
            as="h4"
            textAlign="center"
            color="grey"
          >
            No Orders Found
          </Header>
        )}
      </div>
    );
  }


export  {PoTable}
  