import React from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { getProductList } from "../../../../actions/product.js";

function ProductFilter({
  getProductList,
  products,
  searchedProducts,
  handleSearchProduct
}) {
  React.useEffect(() => getProductList({}), []);

  return (
    <div className="top-filters__box">
      <span>Product:</span>
      <br />
      <Dropdown
        multiple
        search
        placeholder="product..."
        value={searchedProducts}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleSearchProduct}
        options={products.map(product => ({
          key: product._id,
          value: product._id,
          text: product.name
        }))}
        closeOnChange={true}
        name="product"
      />
    </div>
  );
}

function mapStateToProps(ReduxState) {
  return {
    products: ReduxState.inventory.products
  };
}

export default connect(mapStateToProps, { getProductList })(ProductFilter);
