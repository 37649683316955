import React, { useState } from "react";
import CountryFilter from "../Filters/CountryFilter";
import BusinessClientFilter from "../Filters/BusinessclientFilter";
import { DatePicker } from "rsuite";
import Invoicestable from "./table";
import { Header, Divider, Segment } from "semantic-ui-react";
import CanViewPage from "../../role-based-access/CanViewPage";
import moment from "moment";
import { connect } from "react-redux";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

const from = new Date(moment(new Date()).utc().startOf("month"));

const Invoices = ({ filters }) => {
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(new Date());

  return (
    <CanViewPage path="/billing">
      <div
        className="flex--column"
        style={{ maxWidth: "1100px", margin: "20px" }}
      >
        <segment name="header">
          <Header>Invoices</Header>
        </segment>
        <Divider></Divider>

        <segment name="Filters">
          <CountryFilter style={itemStyle} />
          <BusinessClientFilter style={itemStyle}></BusinessClientFilter>

          <DatePicker
        
            oneTap
            value={dateFrom}
        
            cleanable={false}

            format="DD-MM-YYYY"
            onChange={(date) => setDateFrom(date)}
            placeholder="from..."
            style={itemStyle}

          ></DatePicker>

          <DatePicker
            // disabled={isLoading}
            oneTap
            value={dateTo}
            cleanable={false}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => setDateTo(date)}
            placeholder="to..."
            style={itemStyle}
          ></DatePicker>
        </segment>

        <Segment>
          {/* <div className='flex--between'>
            <div></div>
            <ItemsPerPage
                total={invoices.total}
                style={{ height: "1em" }}
                value={itemsPerPage}
                handleChange={(e,{value})=>setItemsPerpage(value)}
              />

          </div> */}

          <Invoicestable dateFrom={dateFrom} dateTo={dateTo}></Invoicestable>
        </Segment>
      </div>
    </CanViewPage>
  );
};

function mapStateToProps(reduxState) {
  return {
    filters: reduxState.filtersmain,
  };
}
export default connect(mapStateToProps, {})(Invoices);
