/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from "react";
import {
  Table,
  Checkbox,
  Alert,
  Icon,
  DatePicker,
  Input,
  InputGroup,
  InputPicker,
  Dropdown,
  Tag,
  Divider,
} from "rsuite";
import {
  getRefunds,
  updateRefundStatusSelected,
  deleteRefund,
} from "../../actions/refunds";
import Viewrefund from "./editRefund";
import CanView from "../../role-based-access/CanView";

import Moment from "react-moment";
import ExportButton from "../ExportButton";
import BusinessclientFilter from "../Filters/BusinessclientFilter";
import CountryFilter from "../Filters/CountryFilter";

const { Column, HeaderCell, Cell, Pagination: TablePagination } = Table;

const itemStyle = {
  margin: "5px",
  minWidth: "300px",
  border: "none !important",
  color: "#3F72AF !important",
};

const statuses = [
  {
    value: "New",
    label: "New",
  },
  {
    value: "inreview",
    label: "In review",
  },
  {
    value: "Approved",
    label: "Approved",
  },
  { value: "not sent", label: "not sent" },
  {
    value: "Rejected",
    label: "Rejected",
  },
  {
    value: "Pending Bank Confirmation",
    label: "Pending Bank Confirmation",
  },
  {
    value: "duplicate",
    label: "Duplicate",
  },
  {
    value: "Refunded",
    label: "Refunded",
  },
  {
    value: "error",
    label: "error",
  },
];

const badgeColor = {
  New: "blue",
  error: "red",
  Approved: "cyan",
  Refunded: "green",
  duplicate: "orange",
  Rejected: "orange",
  "Pending Bank Confirmation": "violet",
  "Pending Confirmation": "violet",
};
const Refundstable = function ({
  updateListParent,
  updateListChild,
  sendPayment,
}) {
  const [searchTerm, setSearchterm] = useState();
  const [sortSetting, setsortSetting] = useState({
    sortType: "desc",
    sortColumn: "createdAt",
    dateFrom: "",
    dateTo: "",
    status: "",
    businessclient: "",
  });
  const [viewRefund, setViewrefund] = useState(false);
  const [updateList, setupdateList] = useState(true);
  const [refundId, setRefundId] = useState(null);
  const [checkedKeys, setChecked] = useState([]);
  const [allChecked, setAllchecked] = useState(false);
  const [page, setPage] = useState(1);
  const [resultsPage, setResultsPage] = useState(10);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if(!updateList) return
    setLoading(true);

    getRefunds({
      currentPage: page,
      limit: resultsPage,
      searchTerm,
      ...sortSetting
      // sortColumn: sortSetting.sortColumn,
      // sortType: sortSetting.sortType,
      // dateFrom: sortSetting.dateFrom,
      // dateTo: sortSetting.dateTo,
      
      // status: sortSetting.status,
      // businessclient: sortSetting.businessclient,
      // country: sortSetting.country,
    })
      .then((resp) => {
        setData(resp.list);
        setTotal(resp.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message);
      });
  }, [
    updateList,
    page,
    resultsPage,
    sortSetting,
    updateListParent,
    searchTerm,
  ]);

  const updateStatusSelected = (status) => {
    setLoading(true);

    updateRefundStatusSelected({ refunds: checkedKeys, status })
      .then((resp) => {
        setLoading(false);
        setupdateList(!updateList);
        updateListChild();

        Alert.success("Refund was updated", 5000);
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message, 5000);
      });
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setsortSetting({ sortColumn, sortType });
  };
  const handleDeleteRefund = (id) => {
    deleteRefund(id)
      .then((resp) => {
        setupdateList(!updateList);
        Alert.success("Refund deleted.");
      })
      .catch((error) => Alert.error(error.message));
  };

  const handleCheckAll = (value, checked) => {
    if (checked) {
      const allChecked = data.map((item) => item._id);
      // console.log(checked,allChecked)
      setAllchecked(true);
      setChecked(allChecked);
    } else {
      setAllchecked(false);
      setChecked([]);
    }
  };

  const handleCheck = (value, checked) => {
    // console.log(value,checked)

    if (checked) setChecked([...checkedKeys, value]);
    else {
      const i = checkedKeys.indexOf(value);
      if (i >= 0) {
        setChecked(checkedKeys.splice(i + 1, 1));
      }
    }
  };

  return (
    <div style={{ margin: "10px" }} className="flex--column">
      <segment id="filters" className="flex--between">
        <div className="flex--left">
          <div id="refundserach">
            <InputGroup
              style={{
                width: 300,
                marginBottom: 10,
              }}
            >
              <Input
                value={searchTerm}
                onChange={(value) => {
                  setSearchterm(value);
                  if (!value) setupdateList(!updateList);
                }}
                placeholder="IBAN,Order,Name..."
              />

              <InputGroup.Button>
                <Icon
                  onClick={() => setupdateList(!updateList)}
                  icon="search"
                />
              </InputGroup.Button>
            </InputGroup>
          </div>
        </div>

        <div className="flex--left">
          <CountryFilter
            useFilter={false}
            style={{ maxHeight: "25px", minWidth: "300px", margin: "5px" }}
            handleChange={(value) =>
              setsortSetting({ ...sortSetting, country: value })
            }
          ></CountryFilter>

          <BusinessclientFilter
            useFilter={false}
            style={{ maxHeight: "25px", minWidth: "300px", margin: "5px" }}
            value={sortSetting.businessclient}
            handleChange={(value) =>
              setsortSetting({ ...sortSetting, businessclient: value })
            }
          ></BusinessclientFilter>

          <InputPicker
            placeholder="status..."
            creatable
            data={statuses}
            onChange={(value) =>
              setsortSetting({ ...sortSetting, status: value })
            }
            style={{ margin: "5px", minWidth: "300px" }}
          />

          <div id="refundcalendar">
            <DatePicker
              disabled={loading}
              oneTap
              value={sortSetting.dateFrom}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) =>
                setsortSetting({ ...sortSetting, dateFrom: date })
              }
              placeholder="from..."
              style={itemStyle}
            ></DatePicker>

            <DatePicker
              disabled={loading}
              oneTap
              value={sortSetting.dateTo}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) =>
                setsortSetting({ ...sortSetting, dateTo: date })
              }
              placeholder="to..."
              style={itemStyle}
            ></DatePicker>
          </div>
        </div>
      </segment>

      <Divider></Divider>
      <div className="flex--between">
        <CanView action="approve" path="/refunds">
          <div id="selectedrefunds" style={{ marginRight: "10px" }}>
            <Dropdown
              appearance="ghost"
              disabled={checkedKeys.length === 0}
              title={`Selected ${
                checkedKeys.length > 0 ? checkedKeys.length : ""
              }`}
            >
              <Dropdown.Item onClick={() => updateStatusSelected("New")}>
                New
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateStatusSelected("Approved")}>
                Approve
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateStatusSelected("inreview")}>
                In review
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateStatusSelected("Refunded")}>
                Refunded
              </Dropdown.Item>

              <Dropdown.Item onClick={() => updateStatusSelected("Rejected")}>
                Reject
              </Dropdown.Item>
            </Dropdown>
          </div>
        </CanView>

        <ExportButton
          disabled={loading}
          total={total}
          filters={{
            total: total,
            sortColumn: sortSetting.sortColumn,
            sortType: sortSetting.sortType,
            dateFrom: sortSetting.dateFrom,
            dateTo: sortSetting.dateTo,
            searchTerm,
            status: sortSetting.status,
            businessclient: sortSetting.businessclient,
          }}
          fileName={`refunds_${sortSetting.sortColumn}`}
          url="/refunds/export"
          text="export"
          style={{ width: "130px", margin: "5px" }}
        />
      </div>

      <div id="refundtable">
        <Table
          sortType={sortSetting.sortType}
          sortColumn={sortSetting.sortColumn}
          wordWrap
          loading={loading}
          autoHeight
          // height={420}
          // width={800}
          data={data}
          id="table"
          onSortColumn={handleSortColumn}
        >
          <Column width={50} align="center">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: "40px" }}>
                <Checkbox
                  inline
                  checked={allChecked}
                  onChange={(value, checked) => handleCheckAll(value, checked)}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="_id"
              checkedKeys={checkedKeys}
              onChange={(value, checked) => handleCheck(value, checked)}
            />
          </Column>
          <Column width={105} resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="refundid"></Cell>
          </Column>
          <Column width={123} resizable>
            <HeaderCell>BusinessClient</HeaderCell>
            <Cell>{(rowData) => rowData.businessclient.name}</Cell>
          </Column>
          <Column width={150} align="center" resizable>
            <HeaderCell>Order Number</HeaderCell>
            <Cell>
              {(rowData) => (
                <div>
                  {rowData.order && (
                    <a href={`/orders/${rowData.order._id}`}>
                      {rowData.order.clientOrderNr}
                    </a>
                  )}
                </div>
              )}
            </Cell>
          </Column>
          <Column width={134} resizable sortable>
            <HeaderCell>Dates</HeaderCell>
            <Cell dataKey="createdAt">
              {(rowData) => (
                <div style={{ fontSize: "12px" }} className="flex--column">
                  <div>
                    created:
                    <Moment utc format="DD-MM-YYYY">
                      {rowData.createdAt}
                    </Moment>
                  </div>
                  <div>
                    updated:
                    <Moment utc format="DD-MM-YYYY">
                      {rowData.updatedAt}
                    </Moment>
                  </div>
                </div>
              )}
            </Cell>
          </Column>
          <Column resizable sortable width={190}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status">
              {(rowData) => (
                <div>
                  <Tag color={badgeColor[rowData.status]}>{rowData.status}</Tag>
                  {/* <p>{rowData.status}</p> */}
                  <p style={{ fontSize: "10px" }}>{rowData.error_msgs}</p>
                </div>
              )}
            </Cell>
          </Column>
          <Column width={160} resizable>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name"></Cell>
          </Column>

          <Column width={120} resizable>
            <HeaderCell>Country</HeaderCell>
            <Cell>{(rowData) => `${rowData.country}`}</Cell>
          </Column>

          <Column width={93} resizable sortable>
            <HeaderCell>Amount</HeaderCell>
            <Cell dataKey="amount">
              {(rowData) => `${rowData.amount} ${rowData.currency}`}
            </Cell>
          </Column>

          <Column width={300} resizable>
            <HeaderCell>Account</HeaderCell>
            <Cell>
              {(rowData) =>
                rowData.IBAN
                  ? rowData.IBAN
                  : `${rowData.account_nr}/${rowData.account_code}`
              }
            </Cell>
          </Column>

          <Column width={90} fixed="right">
            <HeaderCell>Action</HeaderCell>

            <Cell>
              {(rowData) => {
                return (
                  <span>
                    <CanView action="edit" path="/refunds">
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon="edit2"
                        onClick={() => {
                          setViewrefund(true);
                          setRefundId(rowData._id);
                        }}
                      />
                      |{" "}
                    </CanView>

                    <CanView action="delete" path="/refunds">
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon="trash-o"
                        onClick={() => handleDeleteRefund(rowData._id)}
                      />
                      |{" "}
                    </CanView>
                    <CanView action="send" path="/refunds">
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon="send"
                        onClick={() =>
                          sendPayment([rowData._id], rowData.amount)
                        }
                      />
                    </CanView>
                  </span>
                );
              }}
            </Cell>
          </Column>
        </Table>
        <TablePagination
          lengthMenu={[
            {
              value: 10,
              label: 10,
            },
            {
              value: 20,
              label: 20,
            },
            {
              value: 1000,
              label: "All",
            },
          ]}
          activePage={page}
          displayLength={resultsPage}
          total={total}
          onChangePage={(value) => setPage(value)}
          onChangeLength={(value) => setResultsPage(value)}
        />
      </div>
      <div></div>
      <Viewrefund
        show={viewRefund}
        closeModal={() => setViewrefund(false)}
        refundId={refundId}
        updateList={() => setupdateList(!updateList)}
      ></Viewrefund>
    </div>
  );
};

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.indexOf(rowData[dataKey]) >= 0}
      />
    </div>
  </Cell>
);

export default Refundstable;
