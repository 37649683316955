import React, { useState, useEffect } from "react";
import {
  Button,
  Header,
  TextArea,
  Dropdown,
  Checkbox,
  Divider
} from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import {
  // changeStatusFromFile,
  changeStatusFromInput,
  resetChangeStatus
} from "../../../actions/orders";
import {Alert} from 'rsuite'
import ExportButton from "../../ExportButton";

import CourierFilter from "../../Filters/CourierFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import OrderStatusFilter from "../../Filters/OrderStatusFilter";


const statusListToShowConfirm = ["Canceled"];

function ChangeStatusFromInputModal(props) {
  const {
    isOpen,
    isDnDdisplayed = false,
    handleToggleModal,
    // openPopup,
    resetChangeStatus,
    filters
  } = props;

  const [data, setData] = useState();
  const [selectedStatus, setStatus] = useState("");
  const [selectedClient, setBusinessclient] = useState(null);
  const [selectedCourier, setCourier] = useState("");
  const [type, setType] = useState("internalNumericId");

  const handleStatusChange = (e, { value }) => setStatus(value);

  const handleInput = (e, { value }) => setData(value);

  const submitStatusChange = e => {
    if (e) e.preventDefault();
    Alert.info('Updating...')
    handleCloseModal();

   changeStatusFromInput({
          data,
          newStatus: selectedStatus,
          businessclient: selectedClient,
          courier: selectedCourier,
          type,
        }).then(resp =>Alert.success('Updating status done.'))
    // changeStatusFromInput({
    //       data,
    //       newStatus: selectedStatus,
    //     });
  };

  const handleCloseModal = () => {
    handleToggleModal();
    resetChangeStatus();
  };

  useEffect(() => {
    setData();
    setType("internalNumericId");
    setBusinessclient(filters.businessclient);
    setCourier(filters.courier);
  }, [isOpen]);

  useEffect(() => {
    if (statusListToShowConfirm.includes(selectedStatus)) {
      const confirmed = window.confirm(
        `Are you sure you want to change the status to ${selectedStatus}?`
      );
      if (confirmed) {
        // submitStatusChange();
        return;
      } else setStatus("");
    }
  }, [selectedStatus]);

  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{
        modal: ""
      }}
    >
      <div style={{ width: "300px" }}>
        <Header as="h4">Change Status from Input</Header>
        <form
          style={{
            padding: "0.8em 0.4em 0.1em"
          }}
          className="change-status-form"
        >
          {/* <BusinessclientFilter
            handleChange={(e,{value})=>setBusinessclient(value)}
            value={businessclient}
            
        ></BusinessclientFilter> */}

          <Checkbox
            label="Input internalNumericId"
            name="internalNumericId"
            checked={type === "internalNumericId"}
            onChange={(e, data) => setType("internalNumericId")}
          ></Checkbox>

          <Checkbox
            label="Input client Order number"
            name="clientOrderNr"
            checked={type === "clientOrderNr"}
            onChange={(e, data) => setType("clientOrderNr")}
          ></Checkbox>

          <Checkbox
            label="Input Courier Tracking Id"
            name="trackingId'"
            checked={type === "trackingId"}
            onChange={(e, data) => setType("trackingId")}
          ></Checkbox>

          {type === "trackingId" && (
            <>
              <Divider></Divider>

              <CourierFilter
                multiple={false}
                value={selectedCourier}
                fluid={true}
                useFilter={false}
                handleChange={(value) => {
                  setCourier(value);
                }}
              />
            </>
          )}

          {type === "clientOrderNr" && (
            <>
              <Divider></Divider>

              <BusinessclientFilter
                useFilter={false}
                multiple={false}
                value={selectedClient}
                fluid={true}
                handleChange={(value) => {
                  setBusinessclient(value);
                }}
              />
            </>
          )}

          <Divider></Divider>

          <TextArea
            style={{ width: "100%" }}
            placeholder="insert ids [space] separated"
            value={data}
            onChange={handleInput}
          ></TextArea>
          <div className="change-status-form__section">
            <p>{isDnDdisplayed && "2.  "} Select new status</p>
            
          
              <OrderStatusFilter
                value={selectedStatus}
                name="status"
                handleChange={handleStatusChange}
          ></OrderStatusFilter>
          </div>

          <div style={{ marginTop: "2.7em" }} className="right--text flex--between">
            <Button size="large" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              // loading={isLoading}
              size="large"
              color="orange"
              // disabled={!data || !selectedStatus || (isDnDdisplayed)}
              onClick={submitStatusChange}
            >
              Change Status
            </Button>
          </div>
          {/* {!feedbackStatus && (
          <p
            style={{ marginTop: "0.4em" }}
            className="small--text center--text"
          >
            ! This action ignores any order's businesslogic
          </p>
        )} */}
        </form>
        <div style={{ margin: "10px" }}>
          <ExportButton
            // total={0}
            filters={{
              data,
              businessclient: selectedClient,
              courier: selectedCourier,
              type
            }}
            fileName={`orders_input`}
            url="/orders/export/ids"
            text="Export"
          />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, {
  // changeStatusFromFile,
  // openPopup,
  resetChangeStatus
})(ChangeStatusFromInputModal);
