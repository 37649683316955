import React, { useState } from "react";
import {
  Button,
  Segment,
  Input,
  Divider,
  Header,
  Table,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BusinessclientFilter from '../../../Filters/BusinessclientFilter'
import {
  createProductGroup,
  getFilteredProductList
} from "../../../../actions/product";

import CanViewPage from "../../../../role-based-access/CanViewPage";
import AddProducts from "../EditViewPG/AddProducts";
import "../../productGroup.scss";

function CreateNewPG(props) {
  const { createProductGroup, history } = props;
  const [products, setProducts] = useState([]);
  const [viewProducts, setViewProducts] = useState([]);
  const [name, setName] = useState("");
  const [businessclient,setClient] = useState("")

  const handleNameChange = (e, { value }) => setName(value);
  

  const addProducts = (arr, objArr) => {
    setProducts([...products, ...arr]);
    setViewProducts([...viewProducts, ...objArr]);
  };
  const removeProduct = id => {
    setProducts([...products.filter(i => i !== id)]);
    setViewProducts([...viewProducts.filter(({ _id }) => _id !== id)]);
  };
  const handleSubmit = e => e.preventDefault();
  const handleCreate = () => createProductGroup(history, { products, name,businessclient });

  const tableBody = viewProducts.map(({ _id, name, sku }, index) => (
    <Table.Row key={_id}>
      <Table.Cell verticalAlign="bottom">{index + 1}</Table.Cell>
      <Table.Cell verticalAlign="middle">
        <span>{name}</span> - <span className="grey--text">{sku}</span>
      </Table.Cell>

      <Table.Cell textAlign="right">
        <Button
          size="tiny"
          color="red"
          basic
          icon="trash"
          onClick={() => removeProduct(_id)}
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <CanViewPage path="/productgroup/create/new">
      <main className="a-wrapper product-group-container">
        <Link to="/products/list/group">
          <Icon name="arrow left" /> Product group List
        </Link>

        <Segment>
          <div
            className="flex--between vertical--bottom"
            style={{ padding: "1em 1.3em 0em" }}
          >
            <h1>Create new product group</h1>

            <Button color="green" onClick={handleCreate}>
              Create Group
            </Button>
          </div>
          <Divider
            style={{
              marginLeft: "1.3em",
              marginRight: "1.3em",
              marginBottom: "0em",
            }}
          />

          <div className="product-group-columns">
            <div className="product-group-columns__info">
              <form autocomplete="off" onSubmit={handleSubmit}>
                <Header> Product-group name</Header>
                <Input
                  name="name"
                  placeholder="product-group name..."
                  focus
                  onChange={handleNameChange}
                />
              </form>
              <br />
              <BusinessclientFilter
                value={businessclient}
                handleChange={(e, { value }) => setClient(value)}
              ></BusinessclientFilter>
              {viewProducts.length > 0 && (
                <Table unstackable selectable style={{ marginTop: "1.3em" }}>
                  <Table.Body>{tableBody}</Table.Body>
                </Table>
              )}
            </div>
            <div className="product-group-columns__add-prod">
              {businessclient && (
                <AddProducts
                  addProducts={addProducts}
                  productsFromPG={products}
                  businessclient={businessclient}
                />
              )}
            </div>
          </div>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default connect(null, { createProductGroup, getFilteredProductList })(
  CreateNewPG
);
