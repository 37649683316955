import React from "react";
import { Label, Button } from "semantic-ui-react";

import { countries } from "../../../countries";
import FormField from "./FormField";

function EditClientIds({
  country,
  coid,
  taxid,
  i,
  bclientId,
  id,
  handleDeleteId,
  updateClientIds,
  vat,
}) {
  const countriesOptions = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  const handleUpdate = (name, value) =>
    updateClientIds({
      clientId: bclientId,
      id,
      update: { [name]: value },
    });

  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
        <br />
        <Button
          compact
          circular
          basic
          className="list-item__delete-btn"
          size="small"
          icon="trash"
          onClick={() => handleDeleteId({ clientId: bclientId, id })}
        />
      </div>

      <div className="view-grid">
        <FormField
          label="Country"
          name="country"
          placeholder="country ..."
          value={country}
          handleChange={handleUpdate}
          options={countriesOptions}
        />

        <FormField
          label="Company ID"
          name="coid"
          placeholder="company ID ..."
          value={coid}
          handleChange={handleUpdate}
        />

        <FormField
          label="TAX ID"
          name="taxid"
          placeholder="TAX ID ..."
          value={taxid}
          handleChange={handleUpdate}
        />
        <FormField
          label="VAT"
          name="vat"
          placeholder="Vat %"
          value={vat}
          handleChange={handleUpdate}
        />
      </div>
      {/* <Divider /> */}
    </div>
  );
}

export default EditClientIds;
