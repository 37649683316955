import { addError, removeError } from "./error";
import { apiCall, setTokenHeader } from "../services/api";
import history from "../history";
// import {socket} from '../containers/Main'

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}
export const setCurrentUser = currentUser => ({
  type: "SET_CURRENT_USER",
  currentUser
});

export const signUpAction = authInfo => dispatch =>
  apiCall(`post`, `${process.env.REACT_APP_API}/auth/signup`, authInfo)
    .then(currentUser => {
      dispatch(removeError());
      dispatch(setCurrentUser(currentUser));
    })
    .catch(err => {
      dispatch(addError(err.message));
    });

export const signInAction = authInfo => dispatch =>
  apiCall(`post`, `${process.env.REACT_APP_API}/auth/signin`, authInfo)
    .then(currentUser => {
      
      const token = currentUser.token;

      localStorage.setItem(`jwtToken`, token);
      setAuthorizationToken(token);
      dispatch(removeError());
      dispatch(setCurrentUser(currentUser));
    })

    .catch(error => {
      console.log('user login error:',error)
      dispatch(addError(error.message));
    });

export const logOutAction = () => dispatch => {
  dispatch({type:'USER_LOGOUT'})
  localStorage.clear();
  // dispatch(removeError());
  setAuthorizationToken(false); //delete from axios default header
  // dispatch(setCurrentUser({}));
  history.push(`/signin`);
};
