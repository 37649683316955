import React from "react";
import {
  Table,
  Input,
  Icon,
  Segment,
  Header,
  Button,
  Dropdown
} from "semantic-ui-react";

const PickerListByProduct = ({
  list,
  handleInputOrders,
  handleViewList,
  selection,
  courierOptions,
  changeCourier
}) => {
  const [isEditable, setIsEditable] = React.useState({});

  // Group by : Product
  // [
  //   {
  //     product: {
  //         name,
  //         _id
  //     },
  //     couriers: [{
  //         courier_name,
  //         courier_counTable.Rowy,
  //         courier_id,
  //         prod_orders,
  //         prod_color,
  //         prod_quantity
  //     }]
  //   }
  // ]

  const headers = (
    <Table.Row>
      <Table.HeaderCell>Courier</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Quantity</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">#Orders</Table.HeaderCell>
      <Table.HeaderCell>#Pick</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );
  const items = list.map(({ product, couriers }) => (
    <div style={{ marginTop: "2.5em" }} key={product._id}>
      <Header as="h4">{product.name}</Header>

      <Segment>
        <div style={{ margin: "1em 0.5em -0.3em" }}>
          <Table fixed unstackable basic="very">
            <Table.Header>{headers}</Table.Header>
            <Table.Body>
              {couriers.map(
                ({ courier_name, courier_id, prod_quantity, prod_orders }) => (
                  <Table.Row key={courier_id}>
                    <Table.Cell
                      verticalAlign="middle"
                      className="d-flex--jc-between courier-cell"
                      style={{ position: "relative" }}
                    >
                      {isEditable.courier === courier_id &&
                      isEditable.product === product._id ? (
                        <Dropdown
                          placeholder={courier_name}
                          fluid
                          selection
                          defaultOpen
                          onClose={() => setIsEditable({})}
                          onChange={(e, { name, value }) => {
                            changeCourier({
                              product: product._id,
                              orders:
                                selection[product._id] &&
                                selection[product._id][courier_id],
                              oldCourier: courier_id,
                              newCourier: value
                            });
                            setIsEditable({});
                          }}
                          options={courierOptions}
                        />
                      ) : (
                        <>
                          {courier_name}
                          {/* Down Arrow Icon */}
                          <Icon
                            onClick={() => {
                              setIsEditable({
                                product: product._id,
                                courier: courier_id
                              });
                            }}
                            name="chevron down"
                            color="grey"
                            className="caret-down"
                          />
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell verticalAlign="middle" textAlign="center">
                      {prod_quantity}
                    </Table.Cell>
                    <Table.Cell verticalAlign="middle" textAlign="center">
                      {prod_orders}
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        type="number"
                        min="0"
                        max={prod_orders}
                        fluid
                        id={product._id}
                        name={courier_id}
                        onChange={handleInputOrders}
                        value={
                          selection[product._id] &&
                          selection[product._id][courier_id]
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() =>
                          handleViewList({
                            _id: courier_id,
                            name: courier_name
                          })
                        }
                      >
                        <Icon name="th list" />
                        Start Picking
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </div>
  ));

  return <>{items}</>;
};

export default PickerListByProduct;
