import React,{useState} from "react";
import { Segment, Button,Table } from "semantic-ui-react";
import Additem from './additem'
import EditableText from '../../EditableText'
import {Alert,DatePicker} from 'rsuite'
import {deleteItem,updateItem} from '../../../actions/inboundshipment'
import { Link } from "react-router-dom";

function Items({items=[],businessclient,id,setShipment}) {

const [openModal,setModalOpen] = useState(false)

const updateLine =(inventory,data)=>{


  updateItem({id,item:data,inventory})
  .then(data=>{
      setShipment(data)
      Alert.success('updated.')})
  .catch(err=>Alert.error(err.message))
}

const deleteLine =(itemId)=>{
  deleteItem({id,itemId})
  .then(data=>{
    setShipment(data)
    Alert.success('deleted')})
  .catch(err=>Alert.error(err.message))
}
const rows = items.map((item,index)=>(
  <Table.Row>
    <Table.Cell>{index+1}</Table.Cell>
    <Table.Cell><Link to={`/products/${item.product._id}`} target='_blank'>{item.product.name}</Link></Table.Cell>
    <Table.Cell>{item.product.codes?item.product.codes.sku:''}</Table.Cell>
    <Table.Cell>
      
      {/* {item.quantity} */}
      <EditableText
        name ='quantity'
        value={item.inventorycard.quantity}
        handleChange={({value,name})=>updateLine(item.inventorycard._id,{[name]:value})}
      >

      </EditableText>
      </Table.Cell>

      <Table.Cell>
      
      {/* {item.lot} */}
      <EditableText
        name ='lot'
        value={item.inventorycard.lot}
        handleChange={(e,{value,name})=>updateLine(item.inventorycard._id,{[name]:value})}
      >

      </EditableText>
      </Table.Cell>

      <Table.Cell>
      
      {/* {item.expiration} */}
      <DatePicker
              value={item.inventorycard.expiration}
              // isClearable={true}
              placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => updateLine(item.inventorycard._id,{expiration:date})}
              placeholder="from..."
            ></DatePicker>

      </Table.Cell>
    <Table.Cell><Button>Add to Shelf</Button></Table.Cell>
    <Table.Cell><Button
            basic
            icon="trash"
            size="tiny"
            onClick={() => deleteLine(item.inventorycard._id)}
          /></Table.Cell>
  </Table.Row>
))
  return (
    <Segment>
      Do not use: BETA
      <div className="flex--between vertical--middle">
        {/* <Header as="h3">Count Items (packing list)</Header> */}
        <Button onClick = {()=>setModalOpen(true)}>Add Inventory card</Button>
      </div>
    
    <Table singleLine>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell>Codes</Table.HeaderCell>
        <Table.HeaderCell>Quantity</Table.HeaderCell>
        <Table.HeaderCell>Lot</Table.HeaderCell>
        <Table.HeaderCell>Expiration</Table.HeaderCell>
        <Table.HeaderCell>Position</Table.HeaderCell>
        <Table.HeaderCell>Action</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
   {rows}
    </Table.Body>
  </Table>


  <Additem
      openModal={openModal}
      closeModal={()=>setModalOpen(false)}
      businessclient={businessclient}
      id={id}
      cb={(data)=>setShipment(data)}        
  ></Additem>
    </Segment>
  );
}

export default Items;
