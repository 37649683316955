import React, { useState } from "react";
import {
  Button,
  Segment,
  Input,
  Divider,
  Header,
  Table,
  Icon,Dropdown
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  createFulfilProduct,
  getFulfilProductList
} from "../../../actions/fulfilproducts";
import { countries } from "../../../countries";

import CanViewPage from "../../../role-based-access/CanViewPage";
import AddProducts from "../EditViewPG/AddProducts";
import "../productGroup.scss";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";


const optionsCountries = [
  
  ...countries.map((c) => ({ key: c, value: c, text: c })),
];



function CreateNewFulfilmentproduct(props) {
  const { createFulfilProduct, history } = props;
  
  const [products, setProducts] = useState([]);
  const [businessclient, setClient] = useState();
  const [warehouse, setWarehouse] = useState();
  const [country, setCountry] = useState();
  const [viewProducts, setViewProducts] = useState([]);
  const [name, setName] = useState("");

  const handleNameChange = (e, { value }) => setName(value);
  const addProducts = (arr, objArr) => {
    setProducts([...products, ...arr]);
    setViewProducts([...viewProducts, ...objArr]);
  };
  const removeProduct = id => {
    setProducts([...products.filter(i => i !== id)]);
    setViewProducts([...viewProducts.filter(({ _id }) => _id !== id)]);
  };
  const handleSubmit = e => e.preventDefault();
  const handleCreate = () => createFulfilProduct(history, { products, name,country,businessclient,warehouse });
  const handleBusinessclient = (e, { value }) => setClient(value)
  const handleChange = (e, { value }) => setCountry(value)
  const handleWarehouse = (e, { value }) => setWarehouse(value)

  const tableBody = viewProducts.map(({ _id, name, sku }, index) => (
    <Table.Row key={_id}>
      <Table.Cell verticalAlign="bottom">{index + 1}</Table.Cell>
      <Table.Cell verticalAlign="middle">
        <span>{name}</span> - <span className="grey--text">{sku}</span>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          size="tiny"
          color="red"
          basic
          icon="trash"
          onClick={() => removeProduct(_id)}
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <CanViewPage path="/settings/fulfilproducts/create/new">
      <main className="a-wrapper product-group-container">
        <Link to="/settings/fulfilproducts">
          <Icon name="arrow left" /> Fulfilment settings
        </Link>
        <Segment>
          <div
            className="flex--between vertical--bottom"
            style={{ padding: "1em 1.3em 0em" }}
          >
            <h1>Create New Fulfilment Rule</h1>
            <Button color="green" onClick={handleCreate}>
              Create Rule
            </Button>
          </div>
          <Divider
            style={{
              marginLeft: "1.3em",
              marginRight: "1.3em",
              marginBottom: "0em"
            }}
          />

          <div className="product-group-columns">
            <div className="product-group-columns__info">
              <form autocomplete="off" onSubmit={handleSubmit}>
                <Header> Rule name</Header>
                <Input
                  name="name"
                  placeholder="rule name..."
                  focus
                  onChange={handleNameChange}
                />
                <BusinessclientFilter
                  value={businessclient}
                  handleChange={handleBusinessclient}
                />
                <Dropdown
                  search
                  selection
                  error={!country}
                  name="shipping.shipTo.country"
                  label="Country"
                  options={optionsCountries}
                  placeholder="Country"
                  width={6}
                  value={country}
                  onChange={handleChange}
                />
                <WarehouseFilter
                  value = {warehouse}
                  handleChange={handleWarehouse}>

                </WarehouseFilter>
              </form>
              <br />

              {viewProducts.length > 0 && (
                <Table unstackable selectable style={{ marginTop: "1.3em" }}>
                  <Table.Body>{tableBody}</Table.Body>
                </Table>
              )}
            </div>
            <div className="product-group-columns__add-prod">
              <AddProducts
                addProducts={addProducts}
                productsFromPG={products}
                businessclient={businessclient}
          
              />
            </div>
          </div>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default connect(null, { createFulfilProduct, getFulfilProductList })(
  CreateNewFulfilmentproduct
);
