import React, { useState } from "react";
import { Input, Grid, Button } from "semantic-ui-react";

const initFormValues = {
  email: "",
  phone: "",
  name: "",
  position: "",
};

function Contact({ addContact }) {
  const [contacts, setContacts] = useState(initFormValues);

  const handleChange = (e, { name, value }) =>
    setContacts((prev) => ({ ...prev, [name]: value }));

  const clearForm = () => setContacts(initFormValues);

  return (
    <div className="businessclient__form">
      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column>
            <label>Email</label>
            <Input
              fluid
              type="email"
              name="email"
              value={contacts.email}
              placeholder="email..."
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Phone</label>
            <Input
              fluid
              type="phone"
              name="phone"
              value={contacts.phone}
              placeholder="phone..."
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column>
            <label>Name</label>
            <Input
              fluid
              type="text"
              name="name"
              value={contacts.name}
              placeholder="contact person's name..."
              onChange={handleChange}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <label>Position</label>
            <Input
              fluid
              type="text"
              name="position"
              value={contacts.position}
              placeholder="contact person's position..."
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={15}>
            <Button
              style={{ marginTop: "1em" }}
              compact
              basic
              floated="right"
              content="Clear"
              onClick={clearForm}
            />
            <Button
              style={{ marginTop: "1em" }}
              compact
              floated="right"
              content="Add"
              onClick={() => {
                addContact(contacts);
                clearForm();
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default Contact;
