import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Loader,
  Header,
  Divider,
  Input,
} from "semantic-ui-react";
import Modal from "react-responsive-modal";

import {
  getShipfromsettings,
  addShipfromsetting,
  deleteShipfromsetting,
} from "../../../../actions/businessclient";
import CountryFilter from "../../../Filters/CountryFilter";
import WarehouseFilter from "../../../Filters/WarehouseFilter";
import { Alert } from "rsuite";
import CourierFilter from "../../../Filters/CourierFilter";
import ProductFilterList from "../../../Filters/ProductFilterList";
import { useUpdateContext } from "../../../../context/UpdateContext";

function ShipFromSettings({ bclientId }) {
  const { handleClientChange, product } = useUpdateContext();

  const [isLoading, setIsloading] = useState(false);
  const [shipFrom, setshipFrom] = useState([]);
  const [isModalOpen, openModal] = useState(false);
  const [warehouse, setWarehouse] = useState();
  const [country, setCountry] = useState();
  const [province, setProvinces] = useState();
  const [courier, setCourier] = useState();

  const handleGetAll = () => {
    setIsloading(true);
    getShipfromsettings({ businessclient: bclientId })
      .then((resp) => {
        setIsloading(false);
        setshipFrom(resp);
      })
      .catch((error) => Alert.error(error));
  };

  const handleAddNew = () => {
    addShipfromsetting({
      warehouse,
      businessclient: bclientId,
      country,
      courier,
      province: province?province.split(" "):[],
      products: product,
    })
      .then((resp) => handleGetAll())
      .catch((error) => Alert.error(error, 5000));
  };

  useEffect(() => {
    handleClientChange(bclientId);

    setIsloading(true);
    getShipfromsettings({ businessclient: bclientId }).then((resp) => {
      setIsloading(false);
      setshipFrom(resp);
    });
  }, [bclientId]);

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>

      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Province</Table.HeaderCell>
      <Table.HeaderCell>Ship From Warehouse</Table.HeaderCell>
      <Table.HeaderCell>Courier</Table.HeaderCell>
      <Table.HeaderCell>Products</Table.HeaderCell>
      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = shipFrom.map(
    ({ warehouse, _id, country, province = [], courier, products }, i) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">{i + 1}</Table.Cell>

        <Table.Cell>{country}</Table.Cell>
        <Table.Cell>{province&&province.join(",")}</Table.Cell>
        <Table.Cell>{warehouse.name}</Table.Cell>
        <Table.Cell>{courier.name}</Table.Cell>
        <Table.Cell>
          {products && products.map((item) => <div>{item.name}</div>)}
        </Table.Cell>

        <Table.Cell>
          <Button
            compact
            basic
            color="red"
            icon="trash"
            size="mini"
            onClick={() => {
              setIsloading(true);
              deleteShipfromsetting(_id).then((resp) => handleGetAll());
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <>
      {!shipFrom.length ? (
        <div className="center--text" style={{ padding: "2em" }}>
          <p>Default settings are beeing used.</p>
          <Button
            style={{ marginTop: "0.5em" }}
            compact
            content="Add New"
            onClick={() => openModal(true)}
          />
        </div>
      ) : (
        <div style={{ padding: "1em" }}>
          <Button
            compact
            floated="right"
            style={{ marginBottom: "1em" }}
            content="Add new"
            onClick={() => openModal(true)}
          />
          <Table basic="very">
            <Table.Header>{tableHeader}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>
        </div>
      )}

      <Modal
        open={isModalOpen}
        // open={true}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={() => openModal(false)}
        center
        classNames={{ modal: "" }}
      >
        <div>
          <Header as="h2">Set Ship From</Header>

          <p>Ship to Country</p>

          <CountryFilter
            useFilter={false}
            value={country}
            handleChange={(value) => setCountry(value)}
          ></CountryFilter>

          <p>Ship to Province (not mandatory)</p>

          <Input
            onChange={(e, { value }) => setProvinces(value)}
            placeholder="multiple space separate"
          ></Input>

          <p>Ship From Warehouse</p>
          <WarehouseFilter
            multiple={false}
            useFilter={false}
            value={warehouse}
            handleChange={(value) => setWarehouse(value)}
          ></WarehouseFilter>

          <p>Select Courier</p>
          <CourierFilter
            multiple={false}
            useFilter={false}
            value={courier}
            handleChange={(value) => setCourier(value)}
          ></CourierFilter>

          <p>Select Products (not mandatory)</p>
          <ProductFilterList
            multiple={true}
            useFilter={true}
            // handleChange={(value) => setCourier(value)}
          ></ProductFilterList>
          <Divider></Divider>

          <Button fluid onClick={handleAddNew}>
            Add
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ShipFromSettings;
