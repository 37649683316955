import React,{useState} from "react";
import { Table, Button, Segment,Header,Divider,Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductFilterList from '../../Filters/ProductFilterList'
import Modal from "react-responsive-modal";


const ItemList = ({
  goodsreceived,
  handleDelete,
  handleAddItem,
  businessclient,
  loading
}) => {


  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>IDS</Table.HeaderCell>
      <Table.HeaderCell>Counted</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );

 const  [showMdodal,setAddItemModal] = useState(false)
 const  [item,setItem]                = useState()
 const  [quantity,setQuantity]        = useState()

//   const handleAddItem=()=>{
//     setLoading(true)
//     createInventoryCard({   
//                 id,
//                 item:{
//                       product:item, 
//                       quantity_available:quantity,
//                       // quantity_damaged:damaged,
//                     //   description
//                     }
//                 })
//         .then(data=>{
//           setLoading(false)
//           Alert.success("Item added")
//           setAddItemModal(false)
            
//           }
//           )
//         .catch(err=>{
//               setLoading(false)
//               Alert.error(err.message)
//             })
//     }


  const tableBody = goodsreceived.map((item, index) => {
   

    let product = {};
    if (!item.product) {
      product = {
        name: "NA",
        codes: { sku: "NA", barcode: "NA", clientsku: "NA" }
      };
    } else product = item.product;

    return (
      <Table.Row>
        <Table.Cell>{index + 1}</Table.Cell>
        
        <Table.Cell>
          {item.product && (
            <Link to={`/products/${item.product._id}`} target="_blank">
              {product.name}
            </Link>
          )}
   
        </Table.Cell>
        <Table.Cell>
          <span className="small--text bold--text">
            <span className="normal--text inbound-goodsreceived__label">
              Barcode:
            </span>
            {product.codes?.barcode || ""}
            <br />
            <span className="normal--text inbound-goodsreceived__label">
              Sku:
            </span>
            {product.codes?.sku || ""}
            {/* <br />
            <span className="normal--text">Client:</span>{" "}
            {product.codes.clientsku || ""} */}
          </span>
        </Table.Cell>

        <Table.Cell>
          {item.quantity?item.quantity:
          item.inventoryCard&&item.inventoryCard.quantity_available?item.inventoryCard.quantity_available:'na'}
          
        </Table.Cell>

        <Table.Cell>
          <Button
            basic
            icon="trash"
            size="tiny"
            onClick={() => handleDelete(item._id)}
          />
        </Table.Cell>
      </Table.Row>
    );
  });

  const newItemMode = (
    <div id="product_label">
      <Header as="h4">Add New Item</Header>

      <Divider />
      <Form widths="equal" autoComplete="off" loading={loading}>
        <Form.Group>
            <ProductFilterList  
             useFilter={false}
              client={businessclient}
              handleChange={(value)=>setItem(value)}
              fluid={true}
              disabled={!businessclient}

          ></ProductFilterList>
          
        </Form.Group>
        <Form.Group>
          <Form.Input
            fluid
            autocomplete="off"
            label="Quantity Available"
            placeholder="quantity"
            // value={quantity_available}
            name="quantity_available"
            onChange={(e,{value})=>setQuantity(value)}
          />

        
        </Form.Group>
        
  
        <div className="flex--right">
          {/* <Button onClick={() => this.handleClose()}>Cancel</Button> */}
          <Button fluid color="blue" 
                onClick={()=>handleAddItem({
                    product:item, 
                    quantity_available:quantity,
                    
                  })}
          >
            Add Item
          </Button>
        </div>
      </Form>
    </div>
  );

        const modal =  <Modal
                            open={showMdodal}
                            // open={true}
                            showCloseIcon={true}
                            closeOnEsc={true}
                            onClose={() => {
                                setAddItemModal(false);
                            }}
                            center
                            classNames={{
                            modal: ""
                            }}
                            >
                        {newItemMode}
                        </Modal>


  return (
    <Segment loading={loading}>
         <div className="flex--between">
            <Header as="h3">Goods Received</Header>
            <Button onClick={()=>setAddItemModal(true)}>Add Item</Button>
          </div>
    <Table basic="very"  style={{ marginTop: "2em" }} >
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
    {modal}
    </Segment>
  );
};

export default ItemList;
