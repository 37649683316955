import React from "react";
import { Table, Loader, Header, Checkbox } from "semantic-ui-react";
import { Link } from "react-router-dom";

import CreateUpdateTime from "../../CreateUpdateTime";
// import { ReactComponent as NoImg } from "../../../no-img.svg";

function ExpandedTable(props) {
  const {
    products,
    isLoading,
    itemsPerPage,
    currentPage,
    isHeaderChecked,
    checkedItems,
    handleSingleCheck,
    handleMultipleCheck
  } = props;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="center" className="index-col-padding">
        #
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" className="index-col-padding ">
        <Checkbox checked={isHeaderChecked} onChange={handleMultipleCheck} />
      </Table.HeaderCell>
      <Table.HeaderCell>Photo</Table.HeaderCell>
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>Time</Table.HeaderCell>
      <Table.HeaderCell>Options</Table.HeaderCell>
      <Table.HeaderCell>Properties</Table.HeaderCell>
      <Table.HeaderCell>Storage</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = products.map(
    (
      {
        _id,
        name,
        codes = {
          sku: "",
          barcode: ""
        },
        businessclient = { name: "" },
        photos = [],
        color,
        size,
        proprities = {
          dimensions: {
            height: "",
            width: "",
            lenght: ""
          },
          weight: ""
        },
        createdAt,
        updatedAt,
        storage = {
          temp: "",
          note: ""
        }
      },
      index
    ) => (
      <Table.Row key={_id} className="prod-list__item product-row">
        <Table.Cell
          collapsing
          textAlign="center"
          verticalAlign="middle"
          className="index-col-padding"
        >
          {itemsPerPage * (currentPage - 1) + index + 1}
        </Table.Cell>

        <Table.Cell collapsing verticalAlign="middle">
          <Checkbox
            checked={checkedItems.includes(_id)}
            onChange={() => handleSingleCheck(_id)}
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <div className="product-row__img-container">
            {photos.lenght ? <img alt={name} src={photos[0]} /> : <div />}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="product-row__identif">
            <p className="product-row__row" style={{ marginBottom: "2em" }}>
              <Link
                className="x-large--text bold--text"
                to={`/products/${_id}`}
              >
                {name}
              </Link>
              <br />
              <span className="product-row__label">
                {businessclient && businessclient.name}
              </span>
            </p>
            <p className="product-row__row">
              <span className="product-row__label">SKU:</span> {codes.sku}
            </p>
            <p className="product-row__row">
              <span className="product-row__label">Barcode:</span>{" "}
              {codes.barcode}
            </p>
          </div>
        </Table.Cell>
        <Table.Cell collapsing>
          <CreateUpdateTime
            createdAt={createdAt}
            updatedAt={updatedAt}
            newLine={true}
          />
        </Table.Cell>
        <Table.Cell>
          <div className="product-row__options">
            {size && (
              <p className="product-row__row">
                <span className="product-row__label">Size:</span> {size}
              </p>
            )}
            {color && (
              <p className="product-row__row">
                <span className="product-row__label">Color:</span> {color}
              </p>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {proprities && (
            <div className="product-row__options">
              {proprities.weight && (
                <p className="product-row__row">
                  <span className="product-row__label">Weight:</span>{" "}
                  {proprities.weight}g
                </p>
              )}

              {proprities.dimentions && proprities.dimentions.height && (
                <p className="product-row__row">
                  <span className="product-row__label">Height:</span>{" "}
                  {proprities.dimentions.height}cm
                </p>
              )}
              {proprities.dimentions && proprities.dimentions.lenght && (
                <p className="product-row__row">
                  <span className="product-row__label">Length:</span>{" "}
                  {proprities.dimentions.lenght}cm
                </p>
              )}
              {proprities.dimentions && proprities.dimentions.width && (
                <p className="product-row__row">
                  <span className="product-row__label">Width:</span>{" "}
                  {proprities.dimentions.width}cm
                </p>
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          {storage && (
            <div className="product-row__storage">
              {storage.temp && (
                <p className="product-row__row">
                  <span className="product-row__label">Temperatura:</span>{" "}
                  {storage.temp} °C
                </p>
              )}
              {storage.note && (
                <p className="product-row__row">
                  <span className="product-row__label">Note:</span>{" "}
                  {storage.note}
                </p>
              )}
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <div className="table-wrapper--horizontal-scroll">
      {!isLoading && products.length === 0 && (
        <Header textAlign="center" color="grey">
          No products found
        </Header>
      )}
      {isLoading && products.length === 0 && (
        <Loader active inline="centered" />
      )}
      {products.length > 0 && (
        <Table
          unstackable
          inveted
          basic="very"
          padded
          selectable
          className="prod-list"
        >
          <Table.Header color="teal" className="prod-list__header">
            {tableHeader}
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      )}
    </div>
  );
}

export default ExpandedTable;
