import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";

import { Alert } from "rsuite";
import { uploadDocuments } from "../../actions/files";
import "../Dropzone/dropzone.scss";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #d1cfcf",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 10,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const Previews = ({
  setLoading,
  businessclient,
  trigger,
  triggerId,
  getUploadedFiles,
}) => {
  const [files, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState([]);

  const onDrop = (acceptedFiles) => {
    const files = acceptedFiles.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    setFiles(files);
  };

  const removeItem = (index) => {
    const updateList = files;
    updateList.splice(index, 1);
    setFiles([...updateList]);
  };

  useEffect(() => {
    const thumbs = files.map((file, index) => (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img alt={file.name} src={file.preview} style={img} />
          </div>
        </div>
        <Button
          icon="times"
          compact
          size="mini"
          // className="drop_button"
          onClick={() => removeItem(index)}
        />
      </div>
    ));

    setThumbs(thumbs);
  }, [files]);

  const handleUpload = async () => {
    setLoading(true);
    // console.log('uploading....')
    // this.props.uploadExecuted()

    const data = new FormData();

    data.append(
      `path`,
      `businessclients/${businessclient}/${trigger}/${triggerId}`
    );
    data.append(`trigger`, trigger);
    data.append(`triggerId`, triggerId);
    data.append(`businessclient`, businessclient);
    data.append(`filetype`, "image");

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      data.append(`name`, file.name);

      //resize the file before append
      const image = await resizeFile(file);

      // console.log('appending file:...',file)

      data.append(`file`, image);
    }
    // console.log('data:',data)
    uploadDocuments({ files: data })
      .then((resp) => {
        Alert.success("Files Uploaded");
        setFiles([]);
        getUploadedFiles();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error("File not uploaded");
      });
  };

  return (
    <div>
      <Dropzone onDrop={(files) => onDrop(files)} accept="image/*">
        {({ getRootProps, getInputProps }) => (
          <section className="drop_container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </section>
        )}
      </Dropzone>
      <div className="flex_alignRight">
        {files.length > 0 && (
          <Button
            fluid
            color="green"
            className="flex_"
            onClick={() => handleUpload()}
          >
            Upload photos
          </Button>
        )}
      </div>
    </div>
  );
};

export default Previews;
