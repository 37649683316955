import { apiCall } from "../services/api";


export const createBalance =({businessclient,currency})=>{

    const url = `${process.env.REACT_APP_API}/accountbalances/`
    return new Promise ((resolve,reject)=>{    
        apiCall('post',url,{businessclient,currency})
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}
export const getBalances =({businessclient,currency})=>{

    const url = `${process.env.REACT_APP_API}/accountbalances?businessclient=${businessclient}&currency=${currency}`
    return new Promise ((resolve,reject)=>{    
        apiCall('get',url)
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}

export const getBalance =(id)=>{

    const url = `${process.env.REACT_APP_API}/accountbalances/${id}/moves`
    return new Promise ((resolve,reject)=>{    
        apiCall('get',url)
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}

export const getBalanceMoves =(id)=>{

    const url = `${process.env.REACT_APP_API}/accountbalances/${id}/moves`
    return new Promise ((resolve,reject)=>{    
        apiCall('get',url)
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}
export const addBalance =({id,amount,trigger,triggerId})=>{

    const url = `${process.env.REACT_APP_API}/accountbalances/${id}/add`
    return new Promise ((resolve,reject)=>{    
        apiCall('post',url,{amount,trigger,triggerId})
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}
export const removeBalance =({id,amount,trigger,triggerId})=>{

    const url = `${process.env.REACT_APP_API}/accountbalances/${id}/remove`
    return new Promise ((resolve,reject)=>{    
        apiCall('post',url,{amount,trigger,triggerId})
            .then(resp =>resolve(resp))
            .catch(error=>reject(error))
        })
}