import React from "react";
import { Label } from "semantic-ui-react";

function ViewBankAccount({
  bankname,
  currency,
  accountNr,
  banckCode,
  IBAN,
  i,
  ...props
}) {
  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
      </div>
      <div className="view-grid view-grid_list-item">
        <div className="view-grid__item">
          <label className="view-grid__label">Bank Name:</label>
          <span className="view-grid__content">{bankname}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Country:</label>
          <span className="view-grid__content">{props["address.country"]}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">City:</label>
          <span className="view-grid__content">{props["address.city"]}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Currency:</label>
          <span className="view-grid__content">{currency}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Account Nr:</label>
          <span className="view-grid__content">{accountNr}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Bank Code:</label>
          <span className="view-grid__content">{banckCode}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">IBAN:</label>
          <span className="view-grid__content">{IBAN}</span>
        </div>
      </div>
      {/* <Divider /> */}
    </div>
  );
}

export default ViewBankAccount;
