import React, { useState } from "react";
import { Header, Input } from "semantic-ui-react";
import ProductFilterList from "../../Filters/ProductFilterList";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import WarehouseFilter from "../../Filters/WarehouseFilter";
import { DatePicker } from "rsuite";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

const Filters = (props) => {
  const {
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
    handleSearch,
  } = props;
  const [search, setSearch] = useState({ orderNr: "" });

  return (
    <div className="filters_wrapper">
      <Input
        name="orderNr"
        style={{ minWidth: "345px" }}
        onChange={(e, { value }) => setSearch(value)}
        action={{
          icon: "search",
          onClick: (e, d) => handleSearch(search),
        }}
      ></Input>

      <div className="flex_alignLeft">
        <BusinessclientFilter
          style={itemStyle}
          multiple={false}
        ></BusinessclientFilter>

        <ProductFilterList placeholder="select..." multiple style={itemStyle} />

        <WarehouseFilter style={itemStyle}></WarehouseFilter>

        <div style={{ zIndex: 0 }}>
          <DatePicker
            // disabled={isLoading}
            oneTap
            value={dateFrom}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => handleDateFromChange(date)}
            placeholder="from..."
            style={itemStyle}
          ></DatePicker>
        </div>
        <div style={{ marginRight: "0", zIndex: 0 }}>
          <DatePicker
            // disabled={isLoading}
            oneTap
            value={dateTo}
            isClearable={true}
            // placement="leftStart"
            format="DD-MM-YYYY"
            onChange={(date) => handleDateToChange(date)}
            placeholder="to..."
            style={itemStyle}
          ></DatePicker>
        </div>
      </div>
    </div>
  );
};

export default Filters;
