import React, { useState } from "react";
import { Segment, Button, Dropdown } from "semantic-ui-react";
import { DatePicker } from "rsuite";
import BusinessclientFilter  from "../../../Filters/BusinessclientFilter";

function TopBar({
  list,
  chooseGroupBy,
  groupBy,
  searchedProducts,
  addSearchProduct,
  handleClearAll,
  handleBusinessclient,
  businessclient,
  dateTo,
  setDateTo,
}) {
  const optionsProduct = [];
  // c = courier, p = product
  for (const c of list) {
    for (const p of c.products) {
      if (!optionsProduct.find((op) => op.key === p._id))
        // if current product  is not in optionsProduct add it to the list
        optionsProduct.push({ key: p._id, value: p._id, text: p.name });
    }
  }

  return (
    <Segment>
      <div className="a-wrapper d-flex--jc-between">
        <div className="top-filters__container">
          <div className="top-filters__box">
            <span style={{ marginRight: "1.5em" }}>Group By</span>
            <div className="top-filters__column">
              <Button.Group>
                <Button
                  style={{ marginBottom: "0.4em" }}
                  onClick={() => chooseGroupBy("courier")}
                  // basic={groupBy !== "courier"}
                  color={groupBy === "courier" ? "yellow" : ""}
                >
                  Courier
                </Button>
                <Button.Or text="or" />
                <Button
                  style={{ marginBottom: "0.4em" }}
                  onClick={() => chooseGroupBy("product")}
                  color={groupBy === "product" ? "yellow" : ""}
                >
                  Product
                </Button>
              </Button.Group>
            </div>
          </div>

          <div className="top-filters__box" style={{ marginBottom: "0.4em" }}>
            {/* <span style={{ marginRight: "1.8em" }}>Filter By</span> */}

            <Dropdown
              multiple
              search
              placeholder="product..."
              value={searchedProducts}
              selectOnBlur={false}
              clearable
              selection
              onChange={addSearchProduct}
              options={optionsProduct}
              closeOnChange={true}
              name="product"
            />
            <BusinessclientFilter
              useFilter={false}
              handleChange={(value)=>handleBusinessclient(value)}
              value={businessclient}
            ></BusinessclientFilter>
            {/* <DatePicker
              // disabled={isLoading}
              oneTap
              value={dateTo}
              isClearable={true}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => setDateTo(date)}
              placeholder="to..."
              style={{ width: "200px",marginLeft:"20px" }}
            ></DatePicker> */}
          </div>
        </div>

        <div className="d-flex--column">
          {/* <Link to="/orders">
            <Button color="teal">Recieved Orders</Button>
          </Link> */}
          <Button basic onClick={handleClearAll}>
            Clear All
          </Button>
        </div>
      </div>
    </Segment>
  );
}

export default TopBar;
