import React from "react";
import Barcode from "react-barcode";
// import { Link } from "react-router-dom";
import {Label} from 'semantic-ui-react'
// import ChangeCourier from "./ChangeCourier";
import qrCode from './qr_link.jpeg'

const statusColor = {
  Error: "red",
  "No goods": "orange",
  "Order Ready for picking": "teal",
  "Product Picking": "blue",
  Packed: "green",
  Draft: "yellow",
  "Not shipped": "olive"
};

function TopBar({ openOrder }) {
  return (
    <div className="order-info a-wrapper">
      <div className="order-info__item">
        {!!(
          openOrder.shipping &&
          openOrder.shipping.shipTo &&
          openOrder.shipping.shipTo.client &&
          (openOrder.shipping.shipTo.client.firstName ||
            openOrder.shipping.shipTo.client.lastName)
        ) && (
          <>
            <span className="order-info__label order-info__label_client">
              Client:
            </span>
            <span className="order-info__text order-info__text_client">
              {`${openOrder.shipping.shipTo.client.firstName || ""} ${
                openOrder.shipping.shipTo.client.lastName || ""
              }`}
            </span>
            <br />
          </>
        )}

        <span className="order-info__text">
          <a href={`/orders/${openOrder._id}`}>{openOrder.clientOrderNr}</a> -{" "}
          <Label color={statusColor[openOrder.status]}>
            {openOrder.status}
          </Label>
        </span>
      </div>

      <div className="order-info__item flex--between">
        <Barcode
          value={openOrder.internalNumericId}
          height={40}
          width={1}
          fontSize={16}
        />
        <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}>
          <div
            style={{
              alignSelf: "center",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
          WWW.WYZBOX.COM
          </div>
          <div
            style={{
              alignSelf: "center",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            DO NOT COVER!
          </div>
          <div
            style={{
              alignSelf: "center",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            NIE ZAKLEJAĆ KODU!
          </div>

          
        </div>
        <img src={qrCode} alt="QR CODE" width="80" height="80"></img>
      </div>

      <div className="order-info__item courier flex--between vertical--center">
        <div className="courier__logo">
          {/* <img
            className="courier__img"
            src="https://cdn.dribbble.com/users/2909777/screenshots/5734269/untitled-12-01_1x.jpg"
          /> */}
        </div>

        <div className="courier__change">
          {/* <span className="order-info__label">Change courier:</span>
          <ChangeCourier
            currentCourier={openOrder.shipping.courier._id}
            _id={openOrder._id}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default TopBar;
