import React, { useState, useEffect } from "react";
import {
  Header,
  Label,
  Segment,
  Button,
  Icon,
  
  // Divider,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { createBillManually } from "../../../actions/services";
// import { getBusinessClient } from "../../../actions/businessclient";
import {apiCall} from "../../../services/api"
import CanViewPage from "../../../role-based-access/CanViewPage";
import CountryFilter from "../../Filters/CountryFilter";
import CurrencyFilter from "../../Filters/CurrencyFilter";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";
import AddItem from "./AddItem";
import ItemsList from "./ItemsList";
import {DateRangePicker} from 'rsuite'
import {Alert} from 'rsuite'

const initialState = {
  issuer: "",
  selectedIssuerId: "",
  client: "",
  selectedClientId: "",
  country: "",
  currency: "",
  items: [],
  dateFrom: new Date(),
  dateTo: new Date(),
};

function CreateBill({

  history,
}) {
  const [
    {
      issuer,
      selectedClientId,
      selectedIssuerId,
      client,
      country,
      items,
      currency,
      dateFrom,
      dateTo,

    },
    setState,
  ] = useState(initialState);

  const [loading,setLoading] = useState(false)


  const getBusinessClient = ({party,id}) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
    apiCall("get", url).then((resp) =>{

       if (resp.companyids && country) {
      const companyIds = resp.companyids.find(
        (coids) => coids.country === country
      );
      setCompanyIds({ party, companyIds });
    }
      
    setState((s) => ({ ...s, [party]: resp }));
    }
   
    );
    //  .catch((error) => {
    //    dispatch(stopLoading);
    //    dispatch(addError(error.message));
    //  });

  };

  const setCompanyIds = ({ party, companyIds }) => {
    if (party === "client") setClientIds(companyIds);
    if(party==='issuer')    setissuerId(companyIds);
  };

  const handleChange = ( { name, value }) => {
    setState((s) => ({ ...s, [name]: value }));
  };
const handleSetDate =({ dateFrom, dateTo})=>{
  setState((s)=>({...s,dateFrom,dateTo}))
}

  const [clientIds, setClientIds] = useState(null);
  const [issuerId, setissuerId] = useState(null);

  useEffect(() => {
     getBusinessClient({ party: "client", id: selectedClientId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, country]);

  useEffect(() => {
    getBusinessClient({ party: "issuer", id: selectedIssuerId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIssuerId, country]);

  const addItem = (item) =>
    setState((s) => ({ ...s, items: [...s.items, item] }));
  const deleteItem = (i) =>
    setState((s) => ({
      ...s,
      items: [...s.items.slice(0, i), ...s.items.slice(i + 1)],
    }));
  const updateItem = (i, { name, value }) =>
    setState((s) => ({
      ...s,
      items: s.items.map((item, index) =>
        index === i ? { ...item, [name]: value } : item
      ),
    }));

  const handleClear = () => {
    setState(initialState);
  };

  return (
    <CanViewPage path="/billing/manually">
      <main className="a-wrapper page-wrapper">
        <Link to="/billing">
          <Icon name="arrow left" /> Bills List
        </Link>

        <Segment padded loading={loading}>
          <Header>Manual Bill</Header>

          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              1
            </Label>
            <label className="create-bill__label">Select country:</label>
            <CountryFilter
              useFilter={false}
              value={country}
              handleChange={(value)=>handleChange({name:"country",value})}
              className="mr--1"
            />
            <CurrencyFilter 
            useFilter={false}
            value={currency} 
            handleChange={(value)=>handleChange({name:"currency",value})}
            />
          </div>

          {/* Issuer invoice data */}
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              2
            </Label>
            <label className="create-bill__label">Select Issuer:</label>

            <BusinessclientFilter
              useFilter={false}
              placeholder="issuer..."
              disabled={!country}
              name="selectedIssuerId"
              value={selectedIssuerId}
              handleChange={(value)=>handleChange({name:"selectedIssuerId",value})}
            />
            {issuer && issuer._id && (
              <div className="client-info">
                <div className="client-info__seaction">
                  <Header as="h5">Address</Header>
                  {issuer.address && selectedIssuerId && (
                    <p>
                      {issuer.address.country && (
                        <p>Country: {issuer.address.country}</p>
                      )}
                      {issuer.address.region && (
                        <p>Region: {issuer.address.region}</p>
                      )}
                      {issuer.address.province && (
                        <p>Province: {issuer.address.province}</p>
                      )}
                      {issuer.address.city && (
                        <p>City: {issuer.address.city}</p>
                      )}
                      {issuer.address.street && (
                        <p>Street: {issuer.address.street}</p>
                      )}
                      {issuer.address.streetNr && (
                        <p>Street Nr: {issuer.address.streetNr}</p>
                      )}
                      {issuer.address.postcode && (
                        <p>Postcode: {issuer.address.postcode}</p>
                      )}
                    </p>
                  )}
                </div>
                <div className="client-info__seaction">
                  <Header as="h5">Company IDs</Header>
                  {issuerId ? (
                    <>
                      <p>Country: {issuerId.country}</p>
                      <p>Company ID: {issuerId.coid}</p>
                      <p>TAX ID: {issuerId.taxid}</p>
                    </>
                  ) : (
                    <p>Nothing found for the selected country</p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* CLIENT INOVICE DATA */}
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              2
            </Label>
            <label className="create-bill__label">Select businessclient:</label>

            <BusinessclientFilter
              useFilter={false}
              placeholder="client..."
              disabled={!country}
              name="selectedClientId"
              value={selectedClientId}
              handleChange={(value)=>handleChange({name:"selectedClientId",value})}
            />
            {client && client._id && selectedClientId && (
              <div className="client-info">
                <div className="client-info__seaction">
                  <Header as="h5">Address</Header>
                  {client.address && (
                    <p>
                      {client.address.country && (
                        <p>Country: {client.address.country}</p>
                      )}
                      {client.address.region && (
                        <p>Region: {client.address.region}</p>
                      )}
                      {client.address.province && (
                        <p>Province: {client.address.province}</p>
                      )}
                      {client.address.city && (
                        <p>City: {client.address.city}</p>
                      )}
                      {client.address.street && (
                        <p>Street: {client.address.street}</p>
                      )}
                      {client.address.streetNr && (
                        <p>Street Nr: {client.address.streetNr}</p>
                      )}
                      {client.address.postcode && (
                        <p>Postcode: {client.address.postcode}</p>
                      )}
                    </p>
                  )}
                </div>
                <div className="client-info__seaction">
                  <Header as="h5">Company IDs</Header>
                  {clientIds ? (
                    <>
                      <p>Country: {clientIds.country}</p>
                      <p>Company ID: {clientIds.coid}</p>
                      <p>TAX ID: {clientIds.taxid}</p>
                    </>
                  ) : (
                    <p>Nothing found for the selected country</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              3
            </Label>
            <label className="create-bill__label">Period:</label>

            <DateRangePicker
              placeholder="Select Date Range"
              format="DD-MM-YYYY"
              // ranges={[dateFrom,dateTo]}
              onChange={([dateFrom, dateTo]) =>
                handleSetDate({ dateFrom, dateTo, currentPage: 1 })
              }
            />
          </div>
          <div className="create-bill__section">
            <Label circular color="blue" className="create-bill__number">
              4
            </Label>
            <label className="create-bill__label">Add items:</label>
            <AddItem addItem={addItem} withAddVisible={true} />
            <div className="create-bill-items-table">
              <ItemsList
                editable
                items={items}
                deleteItem={deleteItem}
                updateItem={updateItem}
              />
            </div>
          </div>
      
      {/* <div> */}

      {/* <div>Input specific order client number Ids (optional)</div>
      <TextArea
            style={{ width: "100%" }}
            placeholder="insert orders [space] separated"
            value={ids}
            onChange={ (e,{value})    =>setIds(value)}
          ></TextArea>
      </div> */}
        

          <div className="flex--right">
            <Button onClick={handleClear}>Cancel</Button>
            <Button
              disabled={!selectedClientId || !country || !currency}
              primary
              onClick={() =>{
                setLoading(true)

                createBillManually(
                  {
                    issuer,
                    issuerId,
                    businessclientId:clientIds,
                    businessclient: client,
                    country,
                    currency,
                    items,
                    period: { start: dateFrom, end: dateTo },
                    // ids,
                  },
                  history
                ).then(resp=>{
                  setLoading(false)
                  if (resp._id) {
                   
                    history.push(`/billing/${resp._id}`);
                  }
                })
                .catch(error=>{
                  setLoading(false)
                  Alert.error(error)})
              }
           
              }
            >
              Create
            </Button>
          </div>
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default (CreateBill);
