import React from "react";
import {Header} from "semantic-ui-react";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      requestingNext: false,
    };
    this.handleLetStart = this.handleLetStart.bind(this);
  }
  handleLetStart() {
    this.props.getNextOrder();
    this.setState({ requestingNext: true });
    // get next order
    // if order exist > link to that order
    // if not , throw error
  }

  componentWillReceiveProps(nextProps) {
    let { statusCounter } = nextProps;

    if (statusCounter) {
      let newOrders = statusCounter.New;
      let { toprocess } = statusCounter;
      if (newOrders === 0 && toprocess === 0) {
        this.setState({ disabled: true });
      } else {
        this.setState({ disabled: false });
      }
    }
  }
  render() {
    const {
      currentUser,
      // 
      isAuthenticated,
      history,
      openOrder,
    } = this.props;
    const { requestingNext } = this.state;

    if (!isAuthenticated) {
      this.props.history.push("/signin");
      return <div>not signed in.</div>;
    }

    if (requestingNext && openOrder) {
      this.props.history.push(`/orders/${openOrder.orderNr}`);
    }
    const { username } = currentUser;

    return (
      <div>
        {currentUser&&currentUser.views && currentUser.views.homepage && history.push(currentUser.views.homepage)}
        <Header as="h1"> Hello, {username} </Header>

        <div>Oops, this page is not right. Please go ahead and choose the correct one from the menu.</div>
      </div>
    );
  }
}

export default Home;
