import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { setDropList } from "../../actions/filtersmain";
import { getUsers } from "../../actions/user";
import { useUpdateContext } from "../../context/UpdateContext";

function UserFilter({
  name = "user",
  useFilter =true,
  fluid = false,
  style = {
    margin: "2px",
    minWidth: "330px",
    maxWidth: "360px",
    border: "none !important",
    color: "#3F72AF !important"
  },
  placeholder = "users...",
  selectOnBlur = false,
  closeOnChange = true,
  disabled = false,
  multiple = false,
  className,
  label = "User",
  clearable = true,
  usersList,
  setDropList,
  handleChange,
  value
}) {
  const [searchTerm, setSearch] = useState();
  const { handleUserChange, user } = useUpdateContext();

  const userOptions = [
    { value: "unassinged", text: "unassinged", key: "unassinged" },
    ...usersList.map((item) => ({
      value: item._id,
      text: item.username,
      key: item._id,
    })),
  ];

  useEffect(() => {
    if (localStorage.usersList && !searchTerm) {
      const list = JSON.parse(localStorage.getItem("usersList") || "[]");
      setDropList({ name: "users", list });
    } else
      getUsers({}, searchTerm)
        .then((data) => {
          setDropList({ name: "users", list: data.foundUsers });
          if (!searchTerm)
            localStorage.setItem(`usersList`, JSON.stringify(data.foundUsers));
        })
        .catch((error) => error.message);
  }, [searchTerm]);

  return (
    <Dropdown
      name={name}
      multiple={multiple}
      clearable={clearable}
      style={style}
      selection={true}
      fluid={fluid}
      disabled={disabled}
      placeholder={placeholder}
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      className={className}
      label={label}
      search
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
      defaultValue={user}
      value={useFilter?user:value}
      onChange={(e, { value }) => {
          if(useFilter)handleUserChange(value)
          else handleChange(value)
          }}
      options={userOptions}
    />
  );
}

function mapStateToProps(reduxState) {
  return {
    usersList: reduxState.droplists.users,
  };
}

export default connect(mapStateToProps, { setDropList })(UserFilter);
