import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import Target from "../DnD/Target";

const PickerListByCourier = ({
  list,
  handleInputOrders,
  hanldeInputOrdersClear,
  handleViewList,
  selection
}) => {
  //  Group by : Couriers
  //  [
  //    {
  //     courier: {
  //         name,
  //         country,
  //         _id
  //     },
  //     products: [{
  //         name,
  //         _id,
  //         size,
  //         orders,
  //         color,
  //         quantity
  //     }]
  //   }
  // ]

  const items = list.map(({ courier, products }, i) => (
    <Target
      key={i}
      courier={courier}
      products={products}
      selection={selection}
      handleInputOrders={handleInputOrders}
      hanldeInputOrdersClear={hanldeInputOrdersClear}
      handleViewList={handleViewList}
    />
  ));

  return <DndProvider backend={HTML5Backend}>{items} </DndProvider>;
};

export default PickerListByCourier;
