import React from "react";
import { Header, Divider } from "semantic-ui-react";

function Head({ errors, headerText, withDivider = true, children }) {
  return (
    <header>
      <div className="head-component a-wrapper flex--between vertical--center">
        <Header as="h3">{headerText}</Header>
        {children}
      </div>
      {withDivider && <Divider />}
    </header>
  );
}

export default Head;
