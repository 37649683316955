// import {
//     GET_BUSINESSCLIENTS,
//   } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError, addSuccessMsg } from "./error";
import { Alert } from "rsuite";

const startLoading = { type: "START_SERVICES_LOADING" };
const stopLoading = { type: "STOP_SERVICES_LOADING" };

export const getTransfers = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";
  const url = `${process.env.REACT_APP_API}/transffers?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => {
      dispatch(stopLoading);
      dispatch({ type: "GET_TRANSFERS", payload: resp })})
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
export const getTransfersV2 = (filters)  => {
  
  const filtersUrl = filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";
  const url = `${process.env.REACT_APP_API}/transffers?${filtersUrl}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => Alert.error(error.message))
};

export const updateFilters = (newFilter) => ({
  type: "UPDATE_TRANSFER_FILTERS",
  payload: newFilter,
});

export const createTransfer = (newTransfer, history) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/transffers`;
  apiCall("post", url, newTransfer)
    .then((resp) => {
      dispatch(stopLoading);
      if (resp._id) {
        dispatch(addSuccessMsg("Transfer was created!"));
        history.push(`/transfers/${resp._id}`);
      }
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const createTransferV2 = (newTransfer) =>  {

  const url = `${process.env.REACT_APP_API}/transffers/createreport`;
  return  apiCall("post", url, newTransfer)
      .then((resp) => resp)
      .catch((error) => {
      Alert.error(error.message)
      });
};

export const getTransfer = (id) =>  {
 
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
   Alert.error(error.message)
    });
};

export const deleteTransfer = (id) => (dispatch, getState) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
  apiCall("delete", url)
    .then((resp) => {
      dispatch(stopLoading);
      if (resp.status === "success") {
        const { filters } = getState().transfers;
        dispatch(getTransfers(filters));
      }
    })
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getNewCODs = ({
    courier,dateTo = new Date()
  }) => {
 
  const url = `${process.env.REACT_APP_API}/transffers/getnewcods?dateTo=${dateTo}&courier=${courier}`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
      Alert.error(error.message) 
    });
};
export const deleteTransferV2 = (id) => {
 
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
  return apiCall("delete", url)
    .then((resp) => resp)
    .catch((error) => {
      Alert.error(error.message) 
    });
};

export const updateTransfer = (id, update) => {
  
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
  return apiCall("put", url, update)
    .then((resp) => resp)
    .catch((error) => {
      Alert.error(error.message)
    });
};

export const updateTransferV2 = (id, update) =>  {
 
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
 return  apiCall("put", url, update)
    .then((resp) => resp)
    .catch((error) => Alert.error(error.message))
}
