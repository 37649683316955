import React from "react";
import { Dropdown } from "semantic-ui-react";
import { DateRangePicker } from "rsuite";

import BusinessclientFilter from "../../Filters/BusinessclientFilter";

const itemStyle = {
  margin: "2px",
  minWidth: "345px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};
function Filters({ filters, handleFilterChange, handleDateUpdate }) {
  const { status } = filters;

  // console.log("STATUS:", status);
  return (
    <div className='flex--left'style={{ margin: "5px" }}>
      <BusinessclientFilter style={itemStyle} name="supplier" placeholder="issuer..." />
      <BusinessclientFilter style={itemStyle} name="businessclient" placeholder="client..." />

      <DateRangePicker
        placeholder="Select Date Range"
        block
        style={itemStyle}
        onChange={([dateFrom, dateTo]) => {
          handleDateUpdate({ dateFrom, dateTo });
        }}
      />

      <Dropdown
        style={itemStyle}
        placeholder="status..."
        name="status"
        value={status}
        clearable
        selection
        selectOnBlur={false}
        closeOnChange={true}
        onChange={(e, { name, value }) => handleFilterChange({ name, value })}
        options={["Not paid - In due", "Not paid- Over due", "Paid"].map(
          (option, i) => ({ key: i, value: option, text: option })
        )}
      />
    </div>
  );
}
export default Filters;
