import React, {} from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import NotFoundPage from "../components/NotFoundPage";

function CanViewPage({ user, path, children }) {
  // const [viewAllowed, setAllowed] = useState(false);
  // console.log(path, user.views["/orders/:id"], viewAllowed);

  // useEffect(() => {
  //   if (user && user.views) {
  //     const pathActions = user.views[path];
  //     if (pathActions) setAllowed(!!pathActions);
  //   }
  // }, [user, path]);

  if (user.views && user.views[path]) return children;
  // else return <div></div>;
  else return <NotFoundPage />;
}
const mapStateaToProps = reduxState => ({
  user: reduxState.user.currentUser
});
export default connect(mapStateaToProps, {})(CanViewPage);
// export default withRouter(connect(mapStateaToProps, {})(CanViewPage));
