import React, { useState, useEffect } from "react";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { closePopup } from "../actions/notifications";
import { addError } from "../actions/error";
import { apiCall } from "../services/api";
import {changeStatusFromFile} from '../actions/orders'

function UploadWindow({ url, body, closePopup, addError,changeStatusFromFile }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [{ failedOrders, message, status }, setResp] = useState({
    failedOrders: [],
    message: "",
    status: "",
  });

  function getData() {
    const finalUrl = `${process.env.REACT_APP_API}${url}`;

    // console.log('FILAN URL :',finalUrl)
    // changeStatusFromFile(body)
    apiCall("post", finalUrl, body)
      .then((response) => {
        // console.log("RESPONSE:", response);
        setResp(response);
        setIsProcessing(false);
      })
      .catch((err) => {
        addError(err.message);
        closePopup();
      });
  }

  function createExcelFile(dataForExport) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "orders-with-unchanged-status" + fileExtension);
    // setTimeout(closePopup, 7000);
  }

  useEffect(() => {
    setIsProcessing(true);
    getData();
  }, []);

  if (isProcessing)
    return (
      <p className="notification--text-on">
        <span className="blink_me">Uploading...</span>
      </p>
    );
  else
    return (
      <p className="notification--text-on">
        {status === "success" && <Icon name="check" />}
        {message}{" "}
        {failedOrders.length > 0 && (
          <Button
            basic
            size="mini"
            onClick={() => createExcelFile(failedOrders)}
          >
            Download
          </Button>
        )}
      </p>
    );
}

const mapStateToProps = (reduxState) => ({
  isOpen: reduxState.notifications.isPopupOpen,
  url: reduxState.notifications.popupProps.url,
  body: reduxState.notifications.popupProps.body,
});
export default connect(mapStateToProps, { closePopup, addError,changeStatusFromFile })(UploadWindow);
