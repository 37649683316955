import {
  apiCall
} from "../services/api";

export const getShipments = filters => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/outbound`;
    apiCall("post", url, filters)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })

};

export const getShipment = id => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/outbound/${id}`;
    apiCall("get", url)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message));
  })

};

export const updateShipment = query => {

  return new Promise((resolve, reject) => {
    const {
      _id
    } = query;
    const url = `${process.env.REACT_APP_API}/shipments/outbound/${_id}`;
    apiCall("put", url, query)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message))
  })

};

export const deleteShipment = id => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/outbound/${id}`;
    apiCall("delete", url)
      .then(resp => {
        if (resp.status === "ok") {
          resolve(id)
        }
      })
      .catch(err => reject(err.message))
  })

};

export const getPickups = () => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/address/pickups`;

    apiCall("get", url)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message))
  })

};


export const createPickup = data => {
  // debugger
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shipments/address/pickups`;
    apiCall("post", url, data)
      .then(resp => resolve(resp))
      .catch(err => reject(err.message))
  })

};