import React from "react";
import { Label } from "semantic-ui-react";

function ViewContact({ email, phone, name, position, i }) {
  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
      </div>
      <div className="view-grid view-grid_list-item ">
        <div className="view-grid__item">
          <label className="view-grid__label">Email:</label>
          <span className="view-grid__content">{email}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Phone:</label>
          <span className="view-grid__content">{phone}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Name:</label>
          <span className="view-grid__content">{name}</span>
        </div>

        <div className="view-grid__item">
          <label className="view-grid__label">Position:</label>
          <span className="view-grid__content">{position}</span>
        </div>
      </div>

      {/* <Divider /> */}
    </div>
  );
}

export default ViewContact;
