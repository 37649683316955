import React, { useState, useEffect } from "react";
import { Button, Input, Divider } from "semantic-ui-react";
// import { connect } from "react-redux";
// import { getClientProductList } from "../../../actions/product.js";
import "./productlist.scss";
import ProductFilter from "../../../Filters/ProductFilter";
// import { getQuotations } from "../../../../actions/product";
// import ProductFilterList from "../../../Filters/ProductFilterList";

const initState = {
  product: null,
  quantity: 1,
  price: 0,
  codes: "",
  name: "",
  weight: "",
};

const AddProduct = (props) => {
  const [state, setState] = useState(initState);

  const handleSubmit = () => {
    const prodToAdd = {
      product: state.product,
      quantity: Number(state.quantity),
      price: Number(state.price) * Number(state.quantity),
      sku: state.codes.sku,
      name: state.name,
      weight: state.weight,
    };
    // console.log(`in submit`,prodToAdd)
    props.handleAddProduct(prodToAdd);
    setState(initState);
  };

  const { businessclient, warehouse } = props;
  const { quantity, price } = state;

  return (
    <div>
      <div>
        <ProductFilter
          style={{ width: "100%", height: "35px", background: "white" }}
          businessclient={businessclient}
          warehouse={warehouse}
          // fluid={true}
          disabled={!businessclient}
          handleChange={(value) => {
            setState({ ...state, product: value });
          }}
        />

        {/* <ProductFilterList
                useFilter={false}
                client={businessclient}
                disabled={!businessclient}
                handleChange={(value) => {
                  setState({ ...state, product: value });
                }}             
                   // style={{width:'200px',height:'35px',background:'white'}}
                fluid={true}
            ></ProductFilterList> */}
        <Divider></Divider>

        {state.product && (
          <div className="flex--between">
            {/* <span>Quotation price </span> 
          
          <Input
            type="number"
            min="0"
            placeholder="getting price ..."
            name="price"
            value={price}
            onChange={(e, d) => setState({ ...state, price: d.value })}
          /> */}
            <span>Quantity</span>
            <Input
              // fluid
              type="number"
              min="1"
              placeholder="quantity"
              name="quantity"
              value={quantity}
              onChange={(e, d) => setState({ ...state, quantity: d.value })}
            />

            <Button
              className="add-product-button"
              color="grey"
              basic
              content="ADD"
              onClick={handleSubmit}
              style={{
                height: "30px",
                float: "right",
                margin: "5px 0px 5px 0px",
              }}
              // disabled={!state.product || !state.quantity}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddProduct;
