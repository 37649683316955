import React, { useState } from "react";
import {  Tab } from "semantic-ui-react";
// import CanViewPage from "../../../role-based-access/CanViewPage";
import Head from "../../Head";
import ListTransfers from '../Finance/Transfers/ListTransfers'


const linkToTab = {
  product: 0,
  group: 1,
  relatedproduct:2,
  promos:3
};

function Finance({ match, history }) {
  const initialIndex = linkToTab[match.params.activeTab]; 
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const handleTabChange = (e, { activeIndex }) => {
    // history.push(`/products/list/${Object.keys(linkToTab)[activeIndex]}`);
    setActiveIndex(activeIndex);
  };

  const panes = [
    // {
    //   menuItem: "ORDERS",
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //         <div>Orders here</div>
    //     </Tab.Pane>
    //   ),
    // },
    {
      menuItem: "COD TRANSFFERS",
      render: () => (
        <Tab.Pane attached={false}>
            <ListTransfers></ListTransfers>        
            
            </Tab.Pane>
      ),
    }
    
   
  ];

  return (
    // <CanViewPage path="/products/list">
      <>
        <Head headerText={`Finances`}>
  
        </Head>
        <main  style={{maxWidth:'1200px',margin:'10px'}}>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          />
        </main>
      </>
    // </CanViewPage>
  );
}

export default Finance;
