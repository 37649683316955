import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Icon,Header,Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getFulfilProductList, deleteFulfilproduct,updateFulfilProduct } from "../../../actions/fulfilproducts";
// import CreateUpdateTime from "../../CreateUpdateTime";
import CanViewPage from "../../../role-based-access/CanViewPage";
// import Modal from "react-responsive-modal";

function Fulfilmentproduct(props) {
  const { list, getFulfilProductList, deleteFulfilproduct,updateFulfilProduct } = props;
  const [showAllProd, setShowAll] = useState("");

  useEffect(() => getFulfilProductList(), []);

  const toggleExpand = _id => {
    if (_id === showAllProd) {
      setShowAll("");
    } else {
      setShowAll(_id);
    }
  };

  const productsList = (_id, products) => {
    const len = showAllProd === _id ? products.length : 3;
    return products
      .filter((item, i) => i < len)
      .map(({ name, codes }, i) => (
        <li className="small--text grey--text" key={i}>
          <span className="bold--text">{products[i] && products[i].name}</span>{" "}
          - {products[i] && products[i].codes && products[i].codes.sku}
        </li>
      ));
  };

  const tableHead = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell>Client</Table.HeaderCell>
      <Table.HeaderCell>Products</Table.HeaderCell>
      <Table.HeaderCell>ShipTo</Table.HeaderCell>
      <Table.HeaderCell>ShipFrom</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );
  const tableBody = list.map(
    ({ _id, name, products, createdAt, updatedAt,warehouse,businessclient,country,isActive }, index) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="right">
          <span style={{ lineHeight: "1.6" }}>{index + 1}</span>
        </Table.Cell>
      
        <Table.Cell>
          <p className="large--text bold--text">
            <Link to={`/settings/fulfilproducts/${_id}`}>{name}</Link>
          </p>
        </Table.Cell>
        <Table.Cell>
          {businessclient.name}
        </Table.Cell>
        <Table.Cell>
          {products.length} product{products.length !== 1 && "s"}
          <ol style={{ marginBottom: 0 }}>{productsList(_id, products)}</ol>
          {products.length > 3 && (
            <button
              className="pg-ellipsis-btn"
              onClick={() => toggleExpand(_id)}
            >
              <Icon
                name={showAllProd === _id ? "angle up" : "ellipsis horizontal"}
              />
            </button>
          )}
        </Table.Cell>
        <Table.Cell>
          {country}
        </Table.Cell>
        <Table.Cell>
          {warehouse.name}
        </Table.Cell>
        <Table.Cell>

         
           {/* <Button icon onClick={() => deleteFulfilproduct(_id)}> */}
            {isActive &&<Icon bordered color ='blue'  name='pause circle outline' onClick={() => updateFulfilProduct({_id,isActive:false})}></Icon>}
            {!isActive&&<Icon bordered color ='green' name='play circle outline'  onClick={() => updateFulfilProduct({_id,isActive:true})}></Icon>}

             <Icon bordered color ='red' name='trash alternate outline' onClick={() => deleteFulfilproduct(_id)}></Icon>
          {/* </Button> */}
        </Table.Cell>
      </Table.Row>
    )
  );

  // if (!list.length) return null;
  return (
    <CanViewPage path="/settings/fulfilproducts">

      <main className="page-wrapper a-wrapper">
        <div className="flex--between">
          <Header>Fulfilment Rules</Header>
          <Link to="/settings/fulfilproducts/create/new">ADD+</Link>
        </div>

       
    <Segment>
          {list.length===0 &&<div>no rules.</div>}
          {list.length > 0 && <Table unstackable basic="very" selectable>
            <Table.Header>{tableHead}</Table.Header>
            <Table.Body>{tableBody}</Table.Body>
          </Table>}
         
        </Segment>
      </main>
    </CanViewPage>
  );

}

const mapStateToProps = reduxState => ({
  list: reduxState.fulfilproducts.list,
  isLoading: reduxState.loading.loading
});

export default connect(mapStateToProps, {
  getFulfilProductList,
  deleteFulfilproduct,
  updateFulfilProduct
})(Fulfilmentproduct);
