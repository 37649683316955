import React, { useState, useEffect } from "react";
import { Icon, Button, Header, Segment, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { getTransfer, updateTransfer } from "../../../../../actions/transfer";
// import { getBusinessClients_old } from "../../../../../actions/businessclient";
// import { createOptions } from "../../../../../helpers/createOptions";
import { currency } from "../../../../Filters/data";
import CanViewPage from "../../../../../role-based-access/CanViewPage";
import EditableDropdown from "../../../../EditableDropdown";
import EditableText from "../../../../EditableText";
// import TransferOrders from "./TransferOrders";
import ExportButton from "../../../../ExportButton";
import Documents from "../../../../Documents";

const initialState = {
  businessclient: "",
  tranfferNr: null,
  toBankAccount: {
    accountNr: "",
    bankCode: "",
    IBAN: "",
    SWIFT: "",
  },
  amount: 0,
  currency: "",
  status: "",
  notes: "",
};

const currencyOptions = currency.map((currency) => ({
  key: currency,
  value: currency,
  text: currency,
}));

function ViewTransfer({ match }) {
  //Get transfer
  const transferId = match.params.id;

  //Transfer State

  const [transfer, setTransfer] = useState({
   
      businessclient: "",
      tranfferNr: null,
      toBankAccount: {
        accountNr: "",
        bankCode: "",
        IBAN: "",
        SWIFT: "",
      },
      amount: 0,
      currency: "",
      status: "",
      notes: "",
  });

    const [ordersCount, setOrderCount] = useState(0);

  const {
    businessclient = null,
    tranfferNr,
    toBankAccount = [],
    amount,
    currency,
    status,
    notes,
    _id
  } = transfer;

  const [touched, setTouched] = useState([]);

  const resetState = () => {
    setTransfer({ ...initialState, ...transfer });
    setTouched([]);
  };

  useEffect(() => {
    getTransfer(match.params.id).then((resp) => {
      setTransfer(resp.foundTransfer)
      setOrderCount(resp.ordersCount);
    });
  }, []);


  const handleChange = (e, { name, value }) => {
      setTransfer(({ ...transfer, [name]: value }))
      setTouched([...touched, name])
    };
  
  const handleBankAccountChange = (e, { name, value }) => {
    setTransfer((s) => ({
      ...transfer,
      toBankAccount: { ...transfer.toBankAccount, [name]: value },
    }));
      setTouched((s) => [...touched, "toBankAccount"]);
  };
  
  const handleUpdate = () => {
    let updates = {};
    for (let field of touched) {
        updates[field] = transfer[field];
    }
    updateTransfer(_id, updates);
  };

  return (
    <CanViewPage path="/transfers/:id">
      <main className="page-wrapper a-wrapper">
        <Link to="/reports/finance">
          <Icon name="arrow left" /> Transfers List
        </Link>

        <Segment padded>
          <div className="flex_spaceBetween">
            <Header>Transfer {tranfferNr}</Header>

            <div style={{ width: "100px" }}>
              <ExportButton
                fileName={`${tranfferNr}`}
                total={ordersCount}
                // filters={filters}
                // mapData={mapData}
                url={`/transffers/${transferId}/orders/export`}
              />
            </div>
          </div>

          <div className="view-transfer__field">
            <label className="view-transfer__label">Status:</label>
            <EditableDropdown
              placeholder="status..."
              name="status"
              value={status}
              options={["draft", "sent"].map((status) => ({
                key: status,
                value: status,
                text: status,
              }))}
              handleChange={handleChange}
            />
          </div>
          <div className="view-transfer__field">
            <label className="view-transfer__label">Businessclient:</label>
            <div>{businessclient.name}</div>
          </div>

          {/* Amount */}
          <div className="view-transfer__amount">
            <div className="view-transfer__field">
              <label className="view-transfer__label">Amount:</label>
              <EditableText
                maxWidth="80px"
                placeholder="amount..."
                name="amount"
                value={amount}
                handleChange={handleChange}
              />
            </div>
            <div className="view-transfer__field">
              <label
                className="view-transfer__label"
                style={{ paddingLeft: "1em" }}
              >
                Currency:
              </label>
              <EditableDropdown
                placeholder="currency..."
                name="currency"
                value={currency}
                options={currencyOptions}
                handleChange={handleChange}
              />
            </div>
          </div>
          {/* Bank account */}
          <label>Bank account:</label>
          <div className="view-transfer__bankaccount">
            {toBankAccount &&
              [
                { key: "accountNr", label: "Account Nr." },
                { key: "bankCode", label: "Bank code" },
              ].map(({ key, label }) => (
                <div key={key} className="view-transfer__bank-field">
                  <span>{label}:</span>
                  <EditableText
                    name={key}
                    value={toBankAccount[key]}
                    handleChange={handleBankAccountChange}
                  />
                </div>
              ))}
          </div>
          <div key={"SWIFT"} className="view-transfer__bank-field">
            <span>SWIFT:</span>
            <EditableText
              maxWidth={"150px"}
              name={"SWIFT"}
              value={toBankAccount["SWIFT"]}
              handleChange={handleBankAccountChange}
            />
          </div>

          <div key={"IBAN"} className="view-transfer__bank-field">
            <span>IBAN:</span>
            <EditableText
              maxWidth={"200px"}
              name={"IBAN"}
              value={toBankAccount["IBAN"]}
              handleChange={handleBankAccountChange}
            />
          </div>

          {/* Note */}
          <div className="view-transfer__notes">
            <Form>
              <label>Notes:</label>
              <Form.TextArea
                placeholder="notes..."
                name="notes"
                value={notes}
                onChange={handleChange}
              />
            </Form>
          </div>

          <Documents
            businessclient={businessclient}
            trigger="Transffer"
            triggerId={match.params.id}
          />

          {touched.length > 0 && (
            <div className="mt--2">
              <Button onClick={resetState}>Cancel</Button>
              <Button
                disabled={!businessclient || !currency || !amount}
                primary
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

export default ViewTransfer;
