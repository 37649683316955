import { CREATE_VAT, GET_ALL_VAT } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError } from "./error";

export const createVat = body => dispatch => {
  const url = `${process.env.REACT_APP_API}/settings/vat`;
  apiCall("post", url, body)
    .then(resp => dispatch({ type: CREATE_VAT, payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};

export const getAllVat = country => dispatch => {
  const filtersUrl = country ? `/?country=${country}` : "";
  const url = `${process.env.REACT_APP_API}/settings/vat${filtersUrl}`;
  apiCall("get", url)
    .then(resp => dispatch({ type: GET_ALL_VAT, payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};
