import React, { useState } from "react";
import { Input, Button, Header, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";

import { createWarehouse, updateWarehouse } from "../../actions/warehouses";
import CountryFilter from "../Filters/CountryFilter";

const requieredFields = ["name"];

function WarehouseModal({
  initFormValues,
  isEditModeOn,
  handleClose,
  createWarehouse,
  updateWarehouse,
}) {
  const [formValues, setFormValues] = useState(initFormValues);
  const [touched, setTouched] = useState([]);
  const [validationError, setValidationError] = useState(false);

  //   console.log({ formValues, touched, validationError, isEditModeOn });

  const handleChange = (e, { name, value }) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (touched.includes(name)) return;
    setTouched((prev) => [...prev, name]);
  };

  const handleCheckbox = (e, { name, checked }) => {
    setFormValues((prev) => ({ ...prev, [name]: checked }));
    if (touched.includes(name)) return;
    setTouched((prev) => [...prev, name]);
  };
  const handleSubmit = () => {
    const hasEmptyRequierdField = requieredFields.find(
      (field) => !formValues[field]
    );
    if (hasEmptyRequierdField) {
      setValidationError(true);
      return;
    }
    const query = {};
    for (let field of touched) {
      query[field] = formValues[field];
    }
    // console.log(query);
    isEditModeOn
      ? updateWarehouse({ id: formValues._id, updates: query })
      : createWarehouse(query);
    handleClose();
  };

  return (
    <section>
      <Header as="h4">{!isEditModeOn ? "Add new" : "Edit"} warehouse:</Header>
      <div className="warehouse-modal">
        <div className="warehouse-modal__grid">
          <label className="warehouse-modal__label">
            Name<span className="new-ticket-form__required-symbol">*</span>:
          </label>
          <Input
            type="text"
            value={formValues.name}
            name="name"
            onChange={handleChange}
            error={validationError && !formValues.name.length}
          />
        </div>

        <div className="warehouse-modal__grid">
          <label className="warehouse-modal__label">Countries supplying:</label>
          <CountryFilter
            multiple
            useFilter={false}
            style={{ maxWidth: "220px" }}
            value={formValues.countries_supply}
            name="countries_supply"
            handleChange={(value) =>
              handleChange(null, { value, name: "countries_supply" })
            }
          />
        </div>

        <label className="warehouse-modal__address-label">Address</label>
        <div className="warehouse-modal__grid">
          <label className="warehouse-modal__label warehouse-modal__label_light">
            Country:
          </label>
          <CountryFilter
            useFilter={false}
            style={{ maxWidth: "220px" }}
            value={formValues["address.country"]}
            name="address.country"
            handleChange={(value) =>
              handleChange(null, { value, name: "address.country" })
            }
          />

          <label className="warehouse-modal__label warehouse-modal__label_light">
            Company name:
          </label>
          <Input
            type="text"
            value={formValues["address.company"]}
            name="address.company"
            onChange={handleChange}
          />
          <label className="warehouse-modal__label warehouse-modal__label_light">
            City:
          </label>
          <Input
            type="text"
            value={formValues["address.city"]}
            name="address.city"
            onChange={handleChange}
          />

          <label className="warehouse-modal__label warehouse-modal__label_light">
            Street:
          </label>
          <Input
            type="text"
            value={formValues["address.street"]}
            name="address.street"
            onChange={handleChange}
          />

          <label className="warehouse-modal__label warehouse-modal__label_light">
            Street Nr.:
          </label>
          <Input
            type="text"
            value={formValues["address.streetNr"]}
            name="address.streetNr"
            onChange={handleChange}
          />

          <label className="warehouse-modal__label warehouse-modal__label_light">
            Postcode:
          </label>
          <Input
            type="text"
            value={formValues["address.post"]}
            name="address.post"
            onChange={handleChange}
          />
          <label className="warehouse-modal__label warehouse-modal__label_light">
            Email:
          </label>
          <Input
            type="text"
            value={formValues["address.email"]}
            name="address.email"
            onChange={handleChange}
          />
          <label className="warehouse-modal__label warehouse-modal__label_light">
            Phone:
          </label>
          <Input
            type="phone"
            value={formValues["address.phone"]}
            name="address.phone"
            onChange={handleChange}
          />
          {/* <label className="warehouse-modal__label warehouse-modal__label_light">
            Name:
          </label>
          <Input
            type="text"
            value={formValues["address.name"]}
            name="address.name"
            onChange={handleChange}
          /> */}
        </div>
        <Checkbox
          label="Partner Warehouse"
          checked={formValues.isPartner}
          name="isPartner"
          onChange={handleCheckbox}
        />
      </div>

      {validationError && !formValues.name.length && (
        <span className="user-form__error-msg">
          Fill in the required fields!
        </span>
      )}
      <Button
        floated="right"
        primary
        content={!isEditModeOn ? "Create" : "Update"}
        onClick={() => handleSubmit({ formValues })}
      />
      <Button floated="right" content="Cancel" onClick={handleClose} />
    </section>
  );
}

export default connect(null, { createWarehouse, updateWarehouse })(
  WarehouseModal
);
