import React from "react";
import { Header, Button, Input, Divider } from "semantic-ui-react";
import { useState } from "react";
import SelectBclients from "./SelectBclients";
import "../courier.scss";
import {createCourierUser} from '../../../actions/couriers'
import {Alert} from 'rsuite'

const initCourierUserFormValues = {
  username: "",
  password: "",
  businessclients: [],
  account:""
};

function UserAddModal(props) {
  const {
    handleCloseModal,
    courierId,
  } = props;


  const [user, setUser]                               = useState(initCourierUserFormValues);
  const [repeatPassword, setRepeatPassword]           = useState("");
  const [businessclients, setAvailableBClients]       = useState([]);
  const { username, password, userBusinessclients, _id: userId,account } = user;




  const handleFieldChange = (e, { name, value }) =>
    setUser((r) => ({ ...r, [name]: value }));

  const handleRepeatPasswordChange = (e, { value }) => setRepeatPassword(value);

  const addClient = (
    // listofClientIds,
    listOfClientsObj // [{_id, name}]
  ) =>
    setUser((s) => ({
      ...s,
      businessclients: [...s.businessclients, ...listOfClientsObj],
    }));
  const deleteClient = (clientId) =>
    setUser((s) => ({
      ...s,
      businessclients: s.businessclients.filter((bc) => bc._id !== clientId),
    }));

  const handleCancel = () => handleCloseModal();

  const handleSubmit = () => {
    const bclients = businessclients.map((bc) => bc._id);
    user.businessclients = bclients;
    createCourierUser({ id: courierId, userId, query: user }).then(resp=>Alert.success("User added")).catch(err=>Alert.error(err.message));
    handleCloseModal();
  };

  return (
    <section className="courier-report-template">
      <Header as="h3">New Courier User</Header>
      <Divider />

      <form autoComplete="off" className="courier-users__grid">
        {/* <div className="courier-users__field"> */}
        <label className="courier-users__label">Username:</label>
        <Input
          type="text"
          placeholder="Username..."
          name="username"
          value={username}
          onChange={handleFieldChange}
        />
        <label className="courier-users__label">Account:</label>
        <Input
          type="text"
          placeholder="account nr. with courier"
          name="account"
          value={account}
          onChange={handleFieldChange}
        />

        <label className="courier-users__label">Password:</label>
        <Input
          type="password"
          placeholder="Password..."
          name="password"
          value={password}
          onChange={handleFieldChange}
        />

        <label className="courier-users__label">Repeat password:</label>
        <div style={{ width: "100%" }}>
          <Input
            fluid
            type="password"
            placeholder="Password..."
            name="repeatPassword"
            value={repeatPassword}
            onChange={handleRepeatPasswordChange}
          />
          {repeatPassword && repeatPassword !== password && (
            <p className="user-form__error-msg">Passwords do not match</p>
          )}
        </div>
      </form>

      <label className="courier-users__label">Businessclients:</label>

      <SelectBclients
        // allBusinessclients={allAvailableBClients}
        businessclients={businessclients}
        // isLoading={isLoading}
        deleteAction={deleteClient}
        add={addClient}
      />

      <div className="flex--right">
        <Button onClick={handleCancel}>Cancel</Button>

        <Button
          color="blue"
          onClick={handleSubmit}
          disabled={password !== repeatPassword}
        >
          Save
        </Button>
      </div>
    </section>
  );
}


export default (UserAddModal);
