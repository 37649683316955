import React, { useState, useEffect } from "react";
import {deliveryrateReport} from '../../../actions/reports'
 
const Deliveryrate = ({dateFrom,dateTo}) => {
    
    useEffect(() => {
    deliveryrateReport({ dateFrom, dateTo })
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err));
  }, []);

  return <div>Hello</div>;
};

export default Deliveryrate;
