import React, { useState } from "react";
import {
  Button,
  Header,  
} from "semantic-ui-react";
import Modal from "react-responsive-modal";
import UserFilter from '../../Filters/UserFilter'
import { Alert } from "rsuite";


function AssignUserModal(props) {
  const {
    handleCloseModal,
    isModalOpen,
    checkedOrders,
    manualAssing,
    
  } = props;


  const [selectedUser , setUser] = useState()

  const submitUserAssignment = e => {
    e.preventDefault();
    manualAssing({ orderIds: checkedOrders, toUserId:selectedUser }).then(resp=>Alert.success('Orders assinged.',3000))
    handleCloseModal();
  };

  return (
    <Modal
      open={isModalOpen}
      // open={true}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{
        modal: ""
      }}
    >
      <Header as="h4">Assign user:</Header>
      <div
        style={{
          padding: "0.8em 0.4em 0.1em"
        }}
        className="change-status-form"
      >
        <UserFilter
              useFilter={false}
              style={{width:'300px'}}
              // disabled={isLoading}
              multiple={false}
              selectOnBlur={false}
              clearable
              selection
              closeOnChange={true}
              name="user"
              value={selectedUser}
              handleChange={(value)=>setUser(value)}

            />


        <div style={{ marginTop: "2.7em" }} className="right--text">
          <Button size="large" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            size="large"
            color="orange"
            disabled={!selectedUser}
            onClick={submitUserAssignment}
          >
            Assign User
          </Button>
        </div>
      </div>
    </Modal>
  );
}


export default (AssignUserModal)
