import React, { useState } from "react";
import { Table, Checkbox, Loader, Button } from "semantic-ui-react";
import {Link} from 'react-router-dom'

function ProductList(props) {
  const {
    products = [],
    isLoading,
    button: { action, text, color, icon }
  } = props;
  const [checkedItems, setCheckedItems] = useState([]);
  const [isHeaderChecked, setHeaderChecked] = useState(false);

  const handleSingleCheck = _id => {
    let newArr = [];
    if (checkedItems.includes(_id)) {
      newArr = checkedItems.filter(item => item !== _id);
    } else {
      newArr = [...checkedItems, _id];
    }
    setCheckedItems(newArr);
  };
  const handleMultipleCheck = () => {
    if (isHeaderChecked) {
      setCheckedItems([]);
      setHeaderChecked(false);
    } else {
      setCheckedItems(products.map(({ _id }) => _id));
      setHeaderChecked(true);
    }
  };

  const tableHead = (
    <Table.Row>
      <Table.HeaderCell verticalAlign="bottom">
        <Checkbox
          style={{ margin: 0 }}
          onChange={handleMultipleCheck}
          checked={isHeaderChecked}
        />
      </Table.HeaderCell>
      <Table.HeaderCell>Product Name - SKU</Table.HeaderCell>
      <Table.HeaderCell textAlign="right" width="5">
        <Button
          compact
          color={color}
          size="tiny"
          icon={icon}
          disabled={!checkedItems.length}
          onClick={() => {
            const selectedProducts = products
              .filter(({ _id }) => checkedItems.includes(_id))
              .map(({ name, _id, codes }) => ({ name, sku: codes.sku, _id }));
            action(checkedItems, selectedProducts);
            setCheckedItems([]);
            setHeaderChecked(false);
          }}
        >
          {text}{" "}
          {checkedItems.length ? `( x ${checkedItems.length} )` : "checked"}
        </Button>
      </Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = products.map(
    ({ _id, name, codes = { sku: "" }, businessclient = { name: "" } }) => (
      <Table.Row key={_id}>
        <Table.Cell verticalAlign="middle">
          <Checkbox
            style={{ margin: 0 }}
            onChange={() => handleSingleCheck(_id)}
            checked={checkedItems.includes(_id)}
          />
        </Table.Cell>
        <Table.Cell verticalAlign="middle">
          <span>
            <Link to={`/products/${_id}`} target='_blank'>{name}</Link>
          </span>{" "}
          - <span className="grey--text">{codes.sku}</span>
          <br />
          <span className="grey--text small--text">
            {businessclient && businessclient.name}
          </span>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            size="tiny"
            color={color}
            basic
            icon={icon}
            onClick={() => {
              action([_id], [{ name, sku: codes.sku, _id }]);
              if (checkedItems.includes(_id)) {
                let newArr = [];
                newArr = checkedItems.filter((item) => item !== _id);
                setCheckedItems(newArr);
              }
            }}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  if (isLoading) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else if (!products.length) {
    return null;
  }
  return (
    <Table unstackable selectable>
      <Table.Header>{tableHead}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default ProductList;
