import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as Chartjs, defaults } from "chart.js/auto";
import { Segment } from "semantic-ui-react";
import { getStockbalanceLine } from "../../actions/dashboard.js";
import { Alert } from "rsuite";

const example = [
  {
    label: "test1",
    borderColor: ["rgba(0, 210, 10, 0.2)"],
    tension: 0.2,
    data: [
      { x: "2016-12-25", y: 20 },
      { x: "2016-12-26", y: 10 },
      { x: "2016-12-27", y: 50 },
      { x: "2016-12-28", y: 80 },
      { x: "2016-12-29", y: 80 },
    ],
  },

  {
    label: "test2",

    borderColor: ["rgba(100, 120, 10, 0.2)"],
    tension: 0.2,
    data: [
      { x: "2016-12-25", y: 10 },
      { x: "2016-12-26", y: 15 },
      { x: "2016-12-27", y: 42 },
      { x: "2016-12-28", y: 10 },
      { x: "2016-12-29", y: 33 },
    ],
  },
  {
    label: "test3",

    borderColor: ["rgba(100, 0, 220, 0.2)"],
    tension: 0.2,
    data: [
      { x: "2016-12-25", y: 1 },
      { x: "2016-12-26", y: 105 },
      { x: "2016-12-27", y: 49 },
      { x: "2016-12-28", y: 100 },
      { x: "2016-12-29", y: 83 },
    ],
  },
];

function Linestockbalance({ filters,update }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { product } = filters;

  useEffect(() => {
    setLoading(true);

    if (
      (!product || (product && product.length === 0)) &&
      localStorage.getItem("dashProducts")
    )
      getStockbalanceLine({
        ...filters,
        product: localStorage.getItem("dashProducts").split(","),
      })
        .then((resp) => {
          setData(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });

    if (product && product.length > 0)
      getStockbalanceLine(filters)
        .then((resp) => {
          setData(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });
  }, [update]);

  return (
    <>
      {" "}
      {/* <h2 style={{ textAlign: "center" }}>Product sales</h2> */}
      <Segment
        style={{width:"90%", maxWidth:"1000px", margin: "40px 0px 0px 20px" }}
        loading={loading}
      >
        <Line
          data={{
            // labels: data.labels,
            // datasets: example,
            datasets: data,
          }}
          options={{
            plugins: {
              title: {
                display: true,
                text: `Stock balances days`,
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </Segment>
    </>
  );
}
export default Linestockbalance;
