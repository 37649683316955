import {
    apiCall
} from "../services/api";
import {addError} from './error'

export const getServiceTypes =({country,onlyRelative,onlyShipping})=>{
  return new Promise((resolve,reject)=>{
       const url = `${process.env.REACT_APP_API}/services/types?country=${country}&billing_type=${onlyRelative}&category=${onlyShipping}`;

       apiCall('get', url)
         .then(resp => resolve(resp))
         .catch(err => {
           reject(err.message)
         });
  })
   
  }

export const createServiceType =(data)=>{
  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/services/types`;

      apiCall('post', url, data)
        .then(resp => resolve(resp))
        .catch(err => {
          reject(err.message)
        });
  })
  }

export const updateServiceType =({id,body})=>{
  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/services/types/${id}`;

      apiCall('put', url, body)
        .then(resp => resolve(resp))
        .catch(err => {
          reject(err.message)
        });
  })
    
  
  }
export const deleteServiceType =(id)=>{

  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/services/types/${id}`;

      apiCall('delete', url)
        .then(resp => resolve(resp))
        .catch(err => {
          reject(err.message)
        });
  })
    
  
  }

  export const getEnumValues=(key)=>(dispatch=>{
    const url = `${process.env.REACT_APP_API}/services/types/enums`;

      return apiCall('post',url,{key})
      .then(resp=>resp)
      .catch(err => {dispatch(addError(err.message))});

}
  )