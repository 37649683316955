import React from "react";
import { Checkbox } from "semantic-ui-react";

function RouteItem({ adminRouteName, stateRouteValues, handleRouteCheck }) {
  return (
    <li className="filter-item route-item">
      <span
        className={`route-item__name small--text ${
          stateRouteValues && "bold--text"
        }`}
      >
        {adminRouteName}
      </span>
      <div className="route-item__options">
        {["c", "r", "u", "d"].map((action) => (
          <div
            className="filter-item__all-check route-item__checkbox"
            key={action}
          >
            <span>{action.toUpperCase()}</span>
            <Checkbox
              // disabled
              // readOnly
              checked={!!stateRouteValues && stateRouteValues.includes(action)}
              onChange={(e, { checked }) =>
                handleRouteCheck({
                  route: adminRouteName,
                  action,
                  checked,
                })
              }
            />
          </div>
        ))}
      </div>
    </li>
  );
}

export default RouteItem;
