import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import ExportExcel from "../../ExportExcel";

function ExportButton({ stocks }) {
  const [dataSet, setData] = useState();

  const colNames = [
    { label: "Sku", value: "sku" },
    { label: "Name", value: "name" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Balance", value: "balance" },
    { label: "Turnover", value: "turnover" },
    { label: "Days of inventory", value: "days_of_inventory" },
  ];


  useEffect(() => {
    let tempDataSet = [];

    for(const key in stocks){
      const item=stocks[key]
      tempDataSet.push({
        sku: item.product_sku,
        name: item.product_name,
        warehouse: item.warehouse_name,
        balance: item.stock,
        turnover: item.turnover,
        days_of_inventory: item.days_of_inventory,
      });
    }

 
    // console.log('tempData:',tempDataSet)
    setData(tempDataSet);
  }, [stocks]);

  return (
    <ExportExcel
      filename="turvover"
      excelSheetName="xdays"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button basic color="grey">
          <Icon name="excel file" />
          Export
        </Button>
      }
    />
  );
}

//
export default ExportButton;
