import {
  ADD_PACKAGES,
  UPDATE_ORDER,
  GET_PACKER_ORDERS,
  GET_PACKER_DRAFT_ORDERS,
  GET_PACKER_ASSIGNED_ORDERS,
  PRINT_AWB_SUCCESS,
  PRINT_AWB_START,
  PRINT_AWB_STOP,
} from "../actions/actionTypes";

const initialState = {
  list: [],
  total: null,
  draftList: [],
  assignedList: [],
  openOrder: {
    clientOrderNr:'',
    payment:{COD:{amount:0}},
    processing:{
      instrutions:[]
    },
    errMsgs:[],
    _id: null,
    items: [],
    shipping: {
      shipTo: "",
      courier:{_id:'',
                settings:{sticker_pdf_local:false,
                          printBarcodeOrder:false}},
      client:{firstName:'',lastName:''}
    },
    printInvoice: false,
  },
  isLoading: false,
  isAWBPrinting: false,
  isBarcodePrinting: false,
  isAWBPrinted: false,
  isBarcodePrinted: false,
  isPacked: false,
  isDraft: false,
};

function packing(state = initialState, action) {
  switch (action.type) {
    case "CLEAR_STATE":
      return {
        ...initialState,
      };
    case ADD_PACKAGES:
      return {
        ...state,
        isLoading: false,
        openOrder: {
          ...action.payload.foundOrder,
          printInvoice: action.payload.printInvoice,

        },
        isAWBPrinted: false,
        isBarcodePrinted: false,
        isPacked: action.payload.foundOrder.status === "Packed",
        isDraft: action.payload.foundOrder.status === "Draft",
      };
    case GET_PACKER_ORDERS:
      return {
        ...initialState,
        list: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case GET_PACKER_DRAFT_ORDERS:
      return {
        ...initialState,
        draftList: action.payload,
        isLoading: false,
      };
    case GET_PACKER_ASSIGNED_ORDERS:
      return {
        ...initialState,
        assignedList: action.payload,
        isLoading: false,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        openOrder: action.payload,
        isLoading: false,
      };

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    case PRINT_AWB_START:
      return { ...state, isAWBPrinting: true };
    case PRINT_AWB_STOP:
      return { ...state, isAWBPrinting: false, isAWBPrinted: false };
    case PRINT_AWB_SUCCESS:
      return { ...state, 
          openOrder:{...state.openOrder,
                  shipping:{...state.openOrder.shipping,
                    trackingId:action.payload.trackingId
                  }},
          isAWBPrinted: true, isAWBPrinting: false };

    case "PRINT_BARCODE_START":
      return { ...state, isBarcodePrinting: true };
    case "PRINT_BARCODE_STOP":
      return {
        ...state,
        isBarcodePrinting: false,
        isBarcodePrinted: false,
      };
    case "PRINT_BARCODE_SUCCESS":
      return { ...state, isBarcodePrinted: true, isBarcodePrinting: false };

    case "PRINT_INVOICE_START":
      return { ...state, isInvoicePrinting: true };
    case "PRINT_INVOICE_STOP":
      return {
        ...state,
        isInvoicePrinting: false,
        isInvoicePrinted: false,
      };
    case "PRINT_INVOICE_SUCCESS":
      return { ...state, isInvoicePrinted: true, isInvoicePrinting: false };
    
    case "PRINT_ERROR":
      let error = state.openOrder.errMsgs
      error.push(action.message)
      return {...state,openOrder:{...state.openOrder,status:"PackingError",
                                      errMsgs:error
                                    }}
    case "SET_ORDER_PACKED":
        return {
          ...state,
          openOrder:{...state.openOrder,status:'Packed'}
        }
      default:
      return state;
  }
}

export default packing;
