import React, { useState } from "react";
import { Input, Grid, Button } from "semantic-ui-react";
import CountryFilter from "../../Filters/CountryFilter";
import CurrencyFilter from "../../Filters/CurrencyFilter";

const initFormValues = {
  bankname: "",
  addressCountry: "",
  addressCity: "",
  currency: "",
  accountNr: "",
  banckCode: "",
  IBAN: "",
};

function BankAccount({ addBankAccount }) {
  const [bankAccount, setBankAccountForm] = useState(initFormValues);

  const handleChange = (e, { name, value }) =>
    setBankAccountForm((prev) => ({ ...prev, [name]: value }));

  const clearForm = () => setBankAccountForm(initFormValues);

  return (
    <div className="businessclient__form">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <label>Bank name</label>
            <Input
              fluid
              type="text"
              name="bankname"
              value={bankAccount.bankname}
              placeholder="bank name..."
              onChange={handleChange}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <label>Country</label>
            <CountryFilter
              fluid
              name="addressCountry"
              value={bankAccount.addressCountry}
              placeholder="bank address..."
              handleChange={handleChange}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>City</label>
            <Input
              fluid
              type="text"
              name="addressCity"
              value={bankAccount.addressCity}
              placeholder="bank Address..."
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={2}>
            <label>Currency</label>
            <CurrencyFilter
              fluid
              name="currency"
              value={bankAccount.currency}
              placeholder=" "
              handleChange={(value)=>handleChange(null,{value,name:"currency"})}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>Account Nr.</label>
            <Input
              fluid
              type="text"
              name="accountNr"
              value={bankAccount.accountNr}
              placeholder="account nr..."
              onChange={handleChange}
            />
          </Grid.Column>

          <Grid.Column width={3}>
            <label>Bank Code</label>
            <Input
              fluid
              type="text"
              name="bankCode"
              value={bankAccount.bankCode}
              placeholder="bank code..."
              onChange={handleChange}
            />
          </Grid.Column>

          <Grid.Column width={5}>
            <label>IBAN</label>
            <Input
              fluid
              type="text"
              name="IBAN"
              value={bankAccount.IBAN}
              placeholder="IBAN..."
              onChange={handleChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={15}>
            <Button
              style={{ marginTop: "1em" }}
              compact
              basic
              floated="right"
              content="Clear"
              onClick={clearForm}
            />
            <Button
              style={{ marginTop: "1em" }}
              compact
              floated="right"
              content="Add"
              onClick={() => {
                addBankAccount(bankAccount);
                clearForm();
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default BankAccount;
