import React, { useEffect, useState } from "react";
import {
  Divider,
  Header,
  Button,
  Pagination,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getShipments, deleteShipment } from "../../../actions/inboundshipment";
import CanViewPage from "../../../role-based-access/CanViewPage";

import { ShipmentTable } from "./ShipmentTable";
import Filters from "./Filters";
import "../inbound.scss";
import { Alert } from "rsuite";
import { useUpdateContext } from "../../../context/UpdateContext";

const Inboundshipments = (props) => {
  const {
    businessclient,
    warehouse,
    product,
    dateFrom,
    dateTo,
    handleDateFromChange,
    handleDateToChange,
  } = useUpdateContext();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsloading] = useState(false);
  const [data, setList] = useState({ list: [], total: 0 });

  const handleDelete = (id) => {
    setIsloading(true);
    deleteShipment(id)
      .then((resp) => {
        getShipments({
          businessclient,
          warehouse,
          product,
          dateFrom,
          dateTo,
          currentPage,
          itemsPerPage,
        })
          .then((data) => {
            setList(data);
            setIsloading(false);
          })
          .catch((err) => {
            setIsloading(false);
            Alert.error(err);
          });
      })
      .catch((error) => Alert.error(error));
  };

  useEffect(() => {
    setIsloading(true);
    getShipments({
      businessclient,
      warehouse,
      product,
      dateFrom,
      dateTo,
      currentPage,
      itemsPerPage,
    })
      .then((data) => {
        setList(data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err);
      });
  }, [
    businessclient,
    warehouse,
    product,
    currentPage,
    itemsPerPage,
    dateFrom,
    dateTo,
  ]);

  // const loading = <div>Loading...</div>;

  // if (data.list.length===0) return { loading };

  return (
    <CanViewPage path="/shipments/inbound">
      <div  style={{maxWidth:'1200px',margin:'20px'}}>
        <div className="flex_spaceBetween">
          <Header as="h3">Received Goods</Header>
          <Link to="/shipments/inbound/new">
            <Button>+Add New</Button>
          </Link>
        </div>
        <Divider />

        <Filters
          handleDateFromChange={handleDateFromChange}
          handleDateToChange={handleDateToChange}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />

        <Segment padded>
          <ShipmentTable
            isLoading={isLoading}
            shipments={data.list}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            setCurrentPage={setCurrentPage}
            deleteShipment={(id) => handleDelete(id)}
            total={data.total}
          />

          {data.list.length > 0 && data.total > itemsPerPage && (
            <Pagination
              style={{ marginTop: "1em" }}
              activePage={currentPage}
              totalPages={Math.ceil(data.total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
            />
          )}
        </Segment>
      </div>
    </CanViewPage>
  );
};

export default Inboundshipments;
