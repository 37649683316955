import React, { } from "react";
import Moment from "react-moment"
import EditableText from "../../EditableText";


function ShipmentInfo(props) {
  const {
    shipmentWithUpdates,
    openShipment,
    handleFieldChange,
  
  } = props;

  return (
    <div className="edit-inbound-shipment__grid">
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Warehouse:</label>
       
          <div>{openShipment.warehouse.name}</div>
        
        </div>
        <div className="edit-inbound-shipment__field">
          <label>BusinessClient:</label>
          <div>{openShipment.businessclient?openShipment.businessclient.name:''}</div>
         

      
        </div>
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Transporter:</label>
          <EditableText
            placeholder="transporter company ..."
            name="transporter"
            value={shipmentWithUpdates.transporter}
            handleChange={handleFieldChange}
          />
        </div>
        {/* <div className="edit-inbound-shipment__field">
          <label>Nr. of packs:</label>
          <EditableText
            placeholder="nr of packs ..."
            name="nrOfPacks"
            value={shipmentWithUpdates.nrOfPacks}
            handleChange={handleFieldChange}
          />
        </div> */}
        {/* <div className="edit-inbound-shipment__field">
          <label>Weight (kg):</label>
          <EditableText
            placeholder="weight in kg ..."
            name="weightKg"
            value={shipmentWithUpdates.weightKg}
            handleChange={handleFieldChange}
          />
        </div> */}
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>Updated at:</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.updatedAt}</Moment>
          </span>
        </div>
        <div className="edit-inbound-shipment__field">
          <label>Created at:</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.createdAt}</Moment>
          </span>
        </div>
      </div>
    </div>
  );
}



export default (ShipmentInfo);
