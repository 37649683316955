import React,{useState,useEffect} from 'react'
import {Icon} from 'semantic-ui-react'

export default function Reveal({ header, subHeader, body,open=false }) {
    const [opened, setOpened] = useState(false);
    const toggleState = () => setOpened((prev) => !prev);
  
    useEffect(()=>{setOpened(open)},[open])

    return (
      <div className="reveal">
        <div className="reveal__header" onClick={toggleState}>
          <div>
            <p className="large--text"> {header}</p>
            <span className="small--text grey--text">{subHeader}</span>
          </div>
          {/* <span className="reveal__header-text">Priority</span> */}
          <Icon
            name={`angle ${opened ? "up" : "down"}`}
            size="large"
            color="grey"
            className="reveal__header-toggle"
            //   onClick={toggleState}
          />
        </div>
  
        {opened && <div className="reveal__body">{body}</div>}
      </div>
    );
  }