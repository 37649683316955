const initialState = {
  list: [],
  filters: {
    activeTab: "all",
    status: "Open",
    priority: "All",
    category: "",
    assignedTo: "",
    subcategory: "",
    searchTerm: "",
    sortBy: "createdAtUp", // priorityUp/Down , updatedAtUp/Down
    currentPage: 1,
    itemsPerPage: 10,
    dateFrom: null,
    dateTo: null,
  },
  searchState: "",
  total: null, // number of items with current filters applied (without  itemsPerPage and currentPage)
  openTicket: {},
  totals: {
    // all totals by category / subcetgory / status /etc
    tabs: { all: null, openBy: null, assignedTo: null, participants: null },
    priority: { All: null, High: null, Medium: null, Low: null },
  },
  categories: [],
  departments: [],
  isLoading: false,
  isCommentLoading: false,
};

const tickets = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };

    case "GET_TOTALS":
      return {
        ...state,
        totals: action.payload,
        isLoading: false,
      };

    case "UPDATE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case "GET_TICKET":
      return { ...state, openTicket: action.payload, isLoading: false };

    case "CREATE_TICKET":
      return { ...state, list: [...state.list, action.payload] };

    case "UPDATE_TICKET":
      //   const newList = state.list.map(ticket =>
      //     ticket._id === action.payload._id ? action.payload : ticket
      //   );
      return { ...state, isLoading: false, openTicket: action.payload };

    case "DELETE_TICKET":
      return {
        ...state,
        list: state.list.filter(({ _id }) => _id !== action.payload),
      };

    case "ADD_TICKET_COMMENT":
      return {
        ...state,
        isCommentLoading: false,
        openTicket: {
          ...state.openTicket,
          comments: [...state.openTicket.comments, action.payload],
        },
      };

    case "ADD_TICKET_REPLY":
      // replace comment with the new comment with reply
      return {
        ...state,
        openTicket: {
          ...state.openTicket,
          comments: state.openTicket.comments.map((comment) =>
            comment._id === action.payload._id ? action.payload : comment
          ),
        },
      };

    case "GET_TICKET_CATEGORIES":
      return { ...state, categories: action.payload, isLoading: false };

    case "CREATE_TICKET_CATEGORY":
      return { ...state, categories: action.payload, isLoading: false };

    case "UPDATE_TICKET_CATEGORY":
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === action.payload._id ? action.payload : category
        ),
        isLoading: false,
      };

    case "GET_DEPARTMENTS":
      return {
        ...state,
        departments: action.payload,
        isLoading: false,
      };

    case "SET_SEARCH_STATE":
      return {
        ...state,
        searchState: action.payload,
      };

    case "START_LOADING_COMMENT":
      return { ...state, isCommentLoading: true };
    case "STOP_LOADING_COMMENT":
      return { ...state, isCommentLoading: false };

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
export default tickets;
