import React, { useState, useEffect } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

function EditableDropdown({ placeholder, value, name, options=[], handleChange,disabled=false }) {
  
  const [isHovered, setHovered] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [text, setText] = useState("");

  const handleEdit = () => {
   setIsEditable(!disabled);
  };

  useEffect(() => {
    if (isEditable)
      document
        .querySelector(
          "div.ui.active.selection.dropdown.visible > input.search"
        )
        .focus();
  }, [isEditable]);

  const hadleInternChange = (e, d) => {
    setIsEditable(true);
    // setHovered(!disabled);
    handleChange(e,d);
  };

  const handleBlur = () => {
    setIsEditable(!disabled);
    setHovered(!disabled);
  };

  useEffect(() => {
    if(disabled) setIsEditable(!disabled)
    if (options.length>0 && value) {
     
      const obj = options.find((o) => value == o.value);
      const tempText = obj?obj.text:'';
      setText(tempText);
    }
  else    setText("");
  }, [value, options]);

  if (!isEditable) {
    return (
      <div
        className="editable editable-field__text "
        onClick={handleEdit}
        onMouseEnter={() => setHovered(!disabled)}
        onMouseLeave={() => setHovered(!disabled)}
      >
        {text} 
        <span
          className={`editable-field__icons ${
            !isHovered && "editable-field__icons_invisible"
          }`}
        >
          <Icon
            name="pencil alternate"
            color="grey"
            style={{ marginRight: "0px" }}
            onClick={handleEdit}
          />
        </span>
      </div>
    );
  } else {
    return (
      <Dropdown
        disabled={disabled}
        open
        search
        selection
        fluid
        selectOnBlur={false}
        closeOnChange={true}
        closeOnBlur={true}
        openOnFocus={true}
        placeholder={placeholder}
        name={name}
        value={value}
        options={options}
        onChange={hadleInternChange}
        // onBlur={handleBlur}
      />
    );
  }
}

export default EditableDropdown;
