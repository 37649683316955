import React, { useState, useEffect } from "react";
import { Header, Button, Input, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import { getFilteredProductList } from "../../../actions/product";

import ItemsPerPage from "../../ItemsPerPage";
import ProductList from "./ProductList";

function AddProducts(props) {
  const {
    getFilteredProductList,
    total,
    products,
    isLoading,
    addProducts,
    withHeader = true,
    withoutItemsPerPage = false,
    productsFromPG = [],
    businessclient =null
  } = props;

  const {
    searchTerm,
    itemsPerPage,
    currentPage,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange
  } = useProductFilters(
    getFilteredProductList,
    withoutItemsPerPage ? "All" : 10,
    businessclient
  );

  return (
    <section className="pg-add-products">
      {withHeader && <Header>Add products to the group</Header>}

      <div className="flex--between" style={{ marginBottom: "1em" }}>
        <Input
          loading={isLoading}
          disabled={!businessclient}
          placeholder="Search..."
          icon="search"
          value={searchTerm}
          onChange={handleSearch}
          action={{
            icon: "eraser", // "times"
            onClick: e => handleSearch(e, { value: "" })
          }}
          iconPosition="left"
        />

        {!withoutItemsPerPage &&
          searchTerm.length > 0 &&
          products.length > 0 && (
            <ItemsPerPage
              total={total}
              style={{ height: "1em", marginLeft: "1em" }}
              value={itemsPerPage}
              options={[10, 20, 30, 50, "All"]}
              handleChange={handleItemsPerPageChange}
            />
          )}
      </div>

      {searchTerm.length > 0 && (
        <div className="pg-add-products-list-wrapper">
          <ProductList
            products={products.filter(
              ({ _id }) => !productsFromPG.includes(_id)
            )}
            isLoading={isLoading}
            button={{
              action: addProducts,
              text: "Add",
              color: "teal",
              icon: "plus"
            }}
          />
        </div>
      )}

      {searchTerm.length > 0 &&
        products.length > 0 &&
        total > itemsPerPage &&
        !isLoading && (
          <div style={{ marginTop: "1em" }}>
            <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={handlePageChange}
            />
          </div>
        )}
    </section>
  );
}

const mapStateToProps = reduxState => ({
  products: reduxState.inventory.products,
  total: reduxState.inventory.productsTotal,
  isLoading: reduxState.loading.loading
});
export default connect(mapStateToProps, { getFilteredProductList })(
  AddProducts
);

export function useProductFilters(
  getFilteredProductList,
  initialItemsPerPage = 10,
  businessclient,
) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm.length) {
      getFilteredProductList({
        currentPage,
        itemsPerPage,
        searchTerm,
        selectedBclients: [businessclient]
      });
    }
  }, [currentPage, itemsPerPage, businessclient]);

  useEffect(() => {
    if (searchTerm.length) {
      setCurrentPage(1);
      getFilteredProductList({
        currentPage: 1,
        itemsPerPage,
        searchTerm,
        selectedBclients: [businessclient]
      });
    }
  }, [searchTerm,businessclient]);

  const handleSearch = (e, { value }) => setSearchTerm(value);
  const handleItemsPerPageChange = (e, { value }) => setItemsPerPage(value);
  const handlePageChange = (e, { activePage }) => setCurrentPage(activePage);

  return {
    searchTerm,
    itemsPerPage,
    currentPage,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange
  };
}
