import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as Chartjs, defaults } from "chart.js/auto";
import { Segment } from "semantic-ui-react";
import { getBarchartProductSales } from "../../actions/dashboard.js";
import { Alert } from "rsuite";

function Barchart({ filters, update }) {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);

  const { product } = filters;

  useEffect(() => {
    setLoading(true);

    if (
      (!product || (product && product.length === 0)) &&
      localStorage.getItem("dashProducts")
    )
      getBarchartProductSales({
        ...filters,
        product: localStorage.getItem("dashProducts").split(","),
      })
        .then((resp) => {
          setDatasets(resp.datasets);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });

    if (product && product.length > 0)
      getBarchartProductSales(filters)
        .then((resp) => {
          setDatasets(resp.datasets);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });
  }, [update]);

  return (
    <>
      {" "}
      {/* <h2 style={{ textAlign: "center" }}>Product sales</h2> */}
      <Segment
        style={{width:"45%",maxWidth:"500px",margin: "40px 0px 0px 20px" }}
        loading={loading}
      >
        <Bar
          data={{
            // labels: labels,
            datasets: datasets,
          }}
          options={{
            indexAxis: "y",
            plugins: {
              title: {
                display: true,
                text: `Product sales per period`,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </Segment>
    </>
  );
}
export default Barchart;
