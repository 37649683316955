import React from "react";
import { Dropdown, Input } from "semantic-ui-react";
import BusinessclientFilter from "../../../../Filters/BusinessclientFilter";
import CurrencyFilter from "../../../../Filters/CurrencyFilter";
import CountryFilter from "../../../../Filters/CountryFilter";

const itemStyle = {
  margin: "2px",
  minWidth: "330px",
  maxWidth: "360px",
  border: "none !important",
  color: "#3F72AF !important",
};

function Filters({ setFilters, filters, search, setSearch }) {
  const handleFilterChange = (e, { name, value }) =>
    setFilters({ ...filters, [name]: value, currentPage: 1 });

  return (
    <>
      <div style={{ display: "inline-block", marginRight: "1em" }}>
        <BusinessclientFilter style={itemStyle} />
      </div>
      <CurrencyFilter
        name="currency"
        value={filters.currency}
        handleChange={(value)=>handleFilterChange(null,{value, name:"currency"})}
        style={itemStyle}
      ></CurrencyFilter>
      <CountryFilter style={itemStyle}></CountryFilter>

      <Dropdown
      style={itemStyle}
        placeholder="status..."
        name="status"
        value={filters.status}
        clearable
        selection
        selectOnBlur={false}
        closeOnChange={true}
        onChange={handleFilterChange}
        options={["draft", "sent"].map((option, i) => ({
          key: i,
          value: option,
          text: option,
        }))}
      />

      <Input
        placeholder="search id"
        onChange={(e, { value }) => setSearch(value)}
        value={search}
      ></Input>
    </>
  );
}

export default Filters;
