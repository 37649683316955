import {
  DELETE_FULFILMENT_QUOTATIONS,
  DELETE_COURIER_QUOTATIONS,
  SET_ACTIVE_FULFILMENT_QUOTATION,
  SET_ACTIVE_COURIER_QUOTATION,
} from "./actionTypes";
import {
  apiCall
} from "../services/api";
import {
  addError,
  removeError
} from "./error";
import {
  Alert
} from 'rsuite'

export const getBusinessClients = (filters) => {
  const filtersUrl = filters ?
    "/?" +
    Object.entries(filters)
    .filter(
      ([k, value]) => value || (Array.isArray(value) && value.length > 0)
    )
    .map(([key, value]) =>
      Array.isArray(value) ?
      value.map((v) => key + "=" + v).join("&") :
      key + "=" + value
    )
    .join("&") :
    "";

  const url = `${process.env.REACT_APP_API}/businessclients${filtersUrl}`;

  return apiCall('get', url)
    .then(resp => (resp))
    .catch(error => (error))
}

export const getBusinessClient = (id) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })

};

export const createBusinessClient = ({
  history,
  query
}) => {
  return new Promise((resolve,reject)=>{
      const url = `${process.env.REACT_APP_API}/businessclients`;
      apiCall("post", url, query)
        .then((resp) => {
          
          history.push(`/businessclients/${resp._id}`);
        })
        .catch((error) => {
          reject(error.message)
        });
  })
  
};

export const updateBusinessClient = ({
  id,
  query,
  type
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
    apiCall("put", url, {
        query,
        type
      })
      .then((resp) => resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
  })

};

export const updateBusinessClientContact = ({
  bclientId,
  contactId,
  updatedContact,
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${bclientId}/contacts/${contactId}`;
    apiCall("put", url, updatedContact)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })
 
};

export const updateBusinessClientBankAccount = ({
  bclientId,
  accountId,
  updatedBankAccount,
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${bclientId}/bankaccounts/${accountId}`;
    apiCall("put", url, updatedBankAccount)
      .then((resp) =>resolve(resp))
      .catch((error) => reject(error.message));
  })

};

export const addClientIds = (newClientIds) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${newClientIds.businessclient}/companyids`;
    apiCall("post", url, newClientIds)
      .then((resp) => resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
  })

};


export const getBClientIds = (id) => {

  const url = `${process.env.REACT_APP_API}/businessclients/${id}/companyids`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {

    });
};

export const updateClientIds = ({
  clientId,
  id,
  update
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${clientId}/companyids/${id}`;
    apiCall("put", url, update)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })
 
};

export const deleteClientIds = ({
  clientId,
  id
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${clientId}/companyids/${id}`;
    apiCall("delete", url)
      .then((resp) =>resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
  })

};

// BC Profile Settings
export const getBusinessProfile = (id) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject((error.message))
      });
  })

};

export const createBusinessProfile = (id) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
    apiCall("post", url)
      .then((resp) =>
        resolve(resp)
      )
      .catch((error) => {
        // dispatch(stopLoading);
        reject(error.message)
      });
  })


};

export const updateBusinessProfile = ({
  id,
  update
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
    apiCall("put", url, update)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })

};


//BIlling settings

export const getBillingSettings = ({
  id
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/billing`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)

    });
};

export const updateBillingSettings = ({
  id,
  bill_set_id,
  query
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/billing/${bill_set_id}`;
  return apiCall("put", url, query)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)

    });
};


export const addBilling = ({
  id,
  query
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/billing`;
  return apiCall("post", url, query)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)
    });
};


export const deleteBilling = ({
  id,
  bill_set_id
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/billing/${bill_set_id}`;
  return apiCall("delete", url)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)
    });
};
//END -billing settings


//Storage settings

export const getStoragesettings = ({
  id
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/storagesettings`;
  return apiCall("get", url)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)

    });
};

export const updateStoragesetting = ({
  id,
  set_id,
  query
}) => {
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/storagesettings/${set_id}`;
  return apiCall("put", url, query)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)

    });
};


export const addStoragesetting = ({
  id,
  query
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/storagesettings`;
  return apiCall("post", url, query)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)
    });
};


export const deleteStoragesetting = ({
  id,
  set_id
}) => {
  // dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/storagesettings/${set_id}`;
  return apiCall("delete", url)
    .then((resp) => resp)
    .catch((error) => {
      alert(error.message)
    });
};
//END -storage settings


// Sequences
export const getSequences = (client) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/settings/sequences?client=${client}`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })

};
export const createSequence = (sequence) =>  {
return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/settings/sequences`;
  apiCall("post", url, sequence)
    .then((resp) => resolve(resp))
    .catch((error) => {
      reject(error.message)
    });
})
 
};
export const updateSequence = ({
  id,
  update
}) => {
return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/settings/sequences/${id}`;
  apiCall("put", url, update)
    .then((resp) => resolve(resp))
    .catch((error) => {
     reject(error.message)
    });
})

};


export const deleteSequence = (id) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/settings/sequences/${id}`;
    apiCall("delete", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
  })
 
};

// Quotations
export const createFulfilmentQuotation = ({
  client,
  query
}) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${client}/fulfilmentquotation`;
    apiCall("post", url, query)
      .then((resp) =>
       resolve(resp)
      )
      .catch((error) => {
        reject(error.message)
      });
  })

};


export const createCourierQuotation = ({
  client,
  query
}) =>  {
 return new Promise((resolve,reject)=>{
  const url = `${process.env.REACT_APP_API}/businessclients/${client}/courierquotation`;
  apiCall("post", url, query)
    .then((resp) => resolve(resp))
    .catch((error) => {
    reject(error.message)
    });
 })

};

export const getFulfilmentQuotations = (client) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${client}/fulfilmentquotation`;
    apiCall("get", url)
      .then((resp) =>
        resolve(resp)
      )
      .catch((error) => {
       reject(error.message)
      });
  })
 
};
export const getCourierQuotations = (client) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${client}/courierquotation`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })
  
};

export const getFulfilmentQuotation = ({
  client,
  id
}) =>  {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${client}/fulfilmentquotation/${id}`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
        reject(error.message)
      });
  })

};
export const getCourierQuotation = ({
  client,
  id
}) => {
  return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/businessclients/${client}/courierquotation/${id}`;
    apiCall("get", url)
      .then((resp) => resolve(resp))
      .catch((error) => {
       reject(error.message)
      });
  })
  
};

export const setActiveFulfilmentQuotations = ({
  client,
  id,
  isActive
}) => (
  dispatch
) => {
  dispatch({
    type: "START_FULFILMENT_QUOTATIONS_LOADING"
  });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${client}/fulfilmentquotation/${id}`;
  apiCall("put", url, {
      isActive
    })
    .then((resp) =>
      dispatch({
        type: SET_ACTIVE_FULFILMENT_QUOTATION,
        payload: resp
      })
    )
    .catch((error) => {
      dispatch({
        type: "STOP_FULFILMENT_QUOTATIONS_LOADING"
      });
      dispatch(addError(error.message));
    });
};
export const setActiveCourierQuotations = ({
  client,
  id,
  isActive
}) => (
  dispatch
) => {
  dispatch({
    type: "START_COURIER_QUOTATIONS_LOADING"
  });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${client}/courierquotation/${id}`;
  apiCall("put", url, {
      isActive
    })
    .then((resp) =>
      dispatch({
        type: SET_ACTIVE_COURIER_QUOTATION,
        payload: resp
      })
    )
    .catch((error) => {
      dispatch({
        type: "STOP_COURIER_QUOTATIONS_LOADING"
      });
      dispatch(addError(error.message));
    });
};

export const deleteCourierQuotations = ({
  client,
  id
}) => (dispatch) => {
  dispatch({
    type: "START_COURIER_QUOTATIONS_LOADING"
  });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${client}/courierquotation/${id}`;
  apiCall("delete", url)
    .then((resp) =>
      dispatch({
        type: DELETE_COURIER_QUOTATIONS,
        payload: resp
      })
    )
    .catch((error) => {
      dispatch({
        type: "STOP_COURIER_QUOTATIONS_LOADING"
      });
      dispatch(addError(error.message));
    });
};
export const deleteFulfilmentQuotations = ({
  client,
  id
}) => (dispatch) => {
  dispatch({
    type: "START_FULFILMENT_QUOTATIONS_LOADING"
  });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${client}/fulfilmentquotation/${id}`;
  apiCall("delete", url)
    .then((resp) =>
      dispatch({
        type: DELETE_FULFILMENT_QUOTATIONS,
        payload: resp
      })
    )
    .catch((error) => {
      dispatch({
        type: "STOP_FULFILMENT_QUOTATIONS_LOADING"
      });
      dispatch(addError(error.message));
    });
};


//GET SHIPTO ADDRESSES
// export const getShipToAddress = ({
//   clients
// }) =>  {
// return new Promise((resolve,reject)=>{
//   const url = `${process.env.REACT_APP_API}/shiptos?businessclients=${clients}`;
//   apiCall('get', url)
//     .then(list =>resolve(list))
//     .catch((error) => {
//       reject(error.message)
//     });
// })}


export const getShipToAddress_v2 = ({
  clients
}) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/shiptos?businessclients=${clients}`;
    apiCall('get', url)
      .then(list => resolve(list))
      .catch((error) => reject(error))
  })
}


export const addShipToAddress = (data) => {

  const url = `${process.env.REACT_APP_API}/shiptos`;
  return apiCall('post', url, data)
    .then(data => data)
    .catch((error) => {
      Alert.error(error.message)
    })
}

export const addShipfromsetting =(data) =>{
  const url = `${process.env.REACT_APP_API}/settings/shipfrom`;
  return apiCall('post', url, data)
    .then(data => data)
    .catch((error) => {
      Alert.error(error.message)
    })
}
export const getShipfromsettings =({businessclient}) =>{
  const url = `${process.env.REACT_APP_API}/settings/shipfrom?businessclient=${businessclient}`;
  return apiCall('get', url)
    .then(data => data)
    .catch((error) => {
      Alert.error(error.message)
    })
}
export const deleteShipfromsetting =(id) =>{
  const url = `${process.env.REACT_APP_API}/settings/shipfrom/${id}`;
  return apiCall('delete', url)
    .then(data => data)
    .catch((error) => {
      Alert.error(error.message)
    })
}