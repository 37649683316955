import {apiCall} from "../services/api"
import {Alert} from 'rsuite'

export const getBillingCycles = () => {
 
    const url = `${process.env.REACT_APP_API}/enums/get_billing_cycles`
    return  apiCall('get', url) //upload file to fileserver 
                .then(enums => (enums))
                .catch(err => (Alert.error(err.message)))

            }

export const getOrderStatuses = () => {
 
    const url = `${process.env.REACT_APP_API}/enums/orderstatuses`
    return  apiCall('get', url) //upload file to fileserver 
                .then(enums => (enums))
                .catch(err => (Alert.error(err.message)))

            }

