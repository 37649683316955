import React from 'react';
import ProductItem from './ProductItem';
import {Table,TableHeaderCell,Segment} from 'semantic-ui-react';

const ProductList = ({ products, onDelete ,total=0,loading}) => {
  

  return (
    <Segment loading={loading}>
      {products.length === 0 ? (
        <p>No products scanned</p>
      ) : (
        <Table color={'grey'} basic >
          <thead>
            <Table.Row>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>Barcode</TableHeaderCell>
              <TableHeaderCell width={5}>Name</TableHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell>Unit Price</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </Table.Row>
          </thead>
          <tbody>
            {products.map((item, index) => (
              <ProductItem key={item._id} item={item} index={index + 1} onDelete={onDelete} />
            ))}
          </tbody>
          <tfoot>
            <Table.Row>
            
              <td colSpan="4"></td>
              <td colSpan="0">Total</td>
              <td>{total.toFixed(2)}</td>
            </Table.Row>
          </tfoot>
        </Table>
      )}
    </Segment >
  );
};

export default ProductList;
