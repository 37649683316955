import React, { useEffect } from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import {
  getRestrictions,
  updateFilter,
  createRestriction,
  updateRestriction,
  deleteRestriction,
} from "../../actions/restrictions";
import { useToggleModalNewEdit } from "../../hooks/useToggleModalNewEdit";
import ItemsPerPage from "../ItemsPerPage";
import Pagination from "../Filters/Pagination";
import RestrictionsTable from "./restrictionstable";
import RestrictionModal from "./RestrictionModal";
import CanViewPage from "../../role-based-access/CanViewPage";

const defaultInitFormValues = {
  country: "",
  sku_allowed: [],
  sku_restricted: [],
  businessclient: "",
  currency: "",
  limit12m: "",
};

function Restrictions({
  getRestrictions,
  updateFilter,
  isLoading,
  filters,
  total,
  restrictions,
  createRestriction,
  updateRestriction,
  deleteRestriction,
  history,
  
}) {
  const { itemsPerPage, currentPage } = filters;
  useEffect(() => getRestrictions(filters), [currentPage, itemsPerPage]);

  const {
    isModalOpen,
    isEditModeOn,
    initFormValues,
    openModal,
    closeModal,
  } = useToggleModalNewEdit(defaultInitFormValues);

  return (
    <CanViewPage path="/settings/restrictions">
      <main className="page-wrapper a-wrapper">
        <div className="flex--between">
          <Header>Restrictions list</Header>
          <Button onClick={() => openModal(false)}>+ Add</Button>
        </div>

        <Modal
          open={isModalOpen}
          showCloseIcon={true}
          closeOnEsc={false}
          onClose={closeModal}
          center
          classNames={{ modal: "" }}
        >
          <RestrictionModal
            initFormValues={initFormValues}
            isEditModeOn={isEditModeOn}
            handleClose={closeModal}
            createRestriction={createRestriction}
            updateRestriction={updateRestriction}
          />
        </Modal>

        <Segment>
          <div className="flex--right">
            <ItemsPerPage
              total={total}
              value={itemsPerPage}
              handleChange={(e, { value }) =>
                updateFilter({ itemsPerPage: value, currentPage: 1 })
              }
            />
          </div>
          <RestrictionsTable
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            isLoading={isLoading}
            restrictions={restrictions}
            deleteRestriction={deleteRestriction}
            history={history}
            openEditModal={openModal}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              total={total}
              updateFilter={updateFilter}
            />
          )}
        </Segment>
      </main>
    </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  restrictions: reduxState.restrictions.list,
  filters: reduxState.restrictions.filters,
  total: reduxState.restrictions.total,
  isLoading: reduxState.restrictions.isLoading,
});
export default connect(mapStateToProps, {
  getRestrictions,
  updateFilter,
  deleteRestriction,
  createRestriction,
  updateRestriction,
})(Restrictions);
