import React, { Component } from "react";
import { Table, Header, Icon, Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import _ from "lodash";

export class Inventorylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortSequence: [],
      hoverCol: "",
    };
  }

  render() {
    const { inventory, currentPage, itemsPerPage, setSortTotal, sortTotal } =
      this.props;

    const Sort = ({ column, type }) => (
      <Icon
        name={`sort ${type} ${sortTotal === -1 ? "down" : "up"}`}
        color="grey"
        // disabled={!!!sortDirection[column]}
        onClick={() => setSortTotal(sortTotal * -1)}
      />
    );

    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
        {/* <Table.HeaderCell>
          <Checkbox onChange={handleMultipleCheck} checked={headerIsChecked} />
        </Table.HeaderCell> */}
        <Table.HeaderCell className="table_header" textAlign="center">
          SKU
        </Table.HeaderCell>
        <Table.HeaderCell className="table_header">
          Product
          {/* <Sort column="name" type="alphabet" /> */}
        </Table.HeaderCell>
        <Table.HeaderCell className="table_header">
          BusinessClient
        </Table.HeaderCell>
        <Table.HeaderCell>Warehouse</Table.HeaderCell>
        <Table.HeaderCell className="table_header">
          Available
          {/* <Sort column="available" type="numeric" /> */}
        </Table.HeaderCell>
        <Table.HeaderCell>
          Blocked
          {/* <Sort column="blocked" type="numeric" /> */}
        </Table.HeaderCell>
        <Table.HeaderCell className="table_header">
          Total
          <Sort column="total" type="numeric" />
        </Table.HeaderCell>

        {/* <Table.HeaderCell className="table_header" textAlign="right">
          Actions
        </Table.HeaderCell>  */}
      </Table.Row>
    );

    if (!inventory) return <div>No data</div>;

    const tableBody = inventory.map((item, index) => {
      let product = {};
      let url = "";
      if (!item.product_details) {
        product = { sku: "unpaired", name: "NA" };
        url = "/shipments/inbound/lists/unpaired";
      } else {
        url = `/products/${item.product_details._id}`;
        product = {
          sku: item.product_details.codes.sku,
          name: item.product_details.name,
        };
      }

      if (!inventory) {
        return <div>Loading...</div>;
      }
      if (inventory.length === 0) {
        return (
          <Header
            style={{ paddingTop: "2em", paddingBottom: "1em" }}
            as="h4"
            textAlign="center"
            color="grey"
          >
            Not Found
          </Header>
        );
      }
      return (
        <Table.Row key={index} verticalAlign="middle">
          <Table.Cell verticalAlign="middle" textAlign="center" collapsing>
            {index + 1 + (currentPage - 1) * itemsPerPage}
          </Table.Cell>
          {/* <Table.Cell verticalAlign="middle" collapsing>
            <Checkbox
              onChange={() =>
                handleSingleCheck(item._id + " " + item.warehouse._id)
              }
              checked={checkedItems.includes(
                item._id + " " + item.warehouse._id
              )}
            />
          </Table.Cell> */}

          <Table.Cell textAlign="center" verticalAlign="middle" collapsing>
            <Link to={url}>{product.sku}</Link>
          </Table.Cell>
          <Table.Cell verticalAlign="middle">{product.name}</Table.Cell>
          <Table.Cell verticalAlign="middle">
            {item.businessclient.name}
          </Table.Cell>
          <Table.Cell verticalAlign="middle">{item.warehouse.name}</Table.Cell>
          <Table.Cell verticalAlign="middle">{item.available}</Table.Cell>
          <Table.Cell verticalAlign="middle">{item.blocked}</Table.Cell>
          <Table.Cell verticalAlign="middle">{item.total}</Table.Cell>

          {/* <Table.Cell textAlign="right">
            <Button size="tiny" compact>
              Count
            </Button>
          </Table.Cell> */}
        </Table.Row>
      );
    });

    return (
      <Form loading={this.props.isLoading}>
        <Table
          basic="very"
          unstackable
          // selectable
          // striped
        >
          <Table.Header>{tableHeader}</Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      </Form>
    );
  }
}
