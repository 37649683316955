import React, { useState } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CanViewMenuLink from "../../role-based-access/CanViewMenuLink";

const menuLinks = [
  { name: "DASHBOARD", path: "/dashboard" },
  { name: "Orders", path: "/orders" },
  { name: "Invoices", path: "/invoices" },
  { name: "Warehouse", path: "/warehouse" },
  {
    name: "Shipments",
    submenu: [
      { name: "Received Goods", path: "/shipments/inbound" },
      { name: "Received Parcels", path: "/parcels/inbound" },
      { name: "Outbound Shipments", path: "/shipments/outbound" },
      { name: "POS", path: "/pos" },
    ],
  },
  { name: "Products", path: "/products/list", link: "/products" },
  { name: "Picker", path: "/picker/orders" },
  { name: "Packer", path: "/packer/orders" },
  { name: "Returns", path: "/packer/returns" },
  { name: "Client Refunds", path: "/refunds" },
  // { name: "Tickets", path: "/tickets" },

  // {
  //   name: "Financials",
  //   submenu: [
  //     { name: "Bank", path: "/bank" },
  //     { name: "Invoices", path: "/reports/invoices" },
  //     { name: "Transfers", path: "/reports/transfers" },
  //   ],
  // },
  {
    name: "Billing",
    submenu: [
      { name: "Bills", path: "/billing" },
      { name: "Services", path: "/services" },
    ],
  },
  {
    name: "Reports",
    submenu: [
      { name: "Stockturnover", path: "/reports/stockturnover" },
      { name: "Lead Stock", path: "/reports/leadstock" },
      { name: "No goods", path: "/reports/nogoods" },
      { name: "Finance", path: "/reports/finance" },
    ],
  },

  {
    name: "Settings",
    submenu: [
      { name: "Businessclients", path: "/businessclients" },
      { name: "Accountbalances", path: "/accountbalances" },
      { name: "ServiceTypes", path: "/servicetypes" },
      { name: "Quotations", path: "/quotations" },
      { name: "Couriers", path: "/couriers" },
      { name: "Users", path: "/users" },
      { name: "Roles", path: "/roles" },
      // { name: "Distributor",    path: "/distributor" },
      // { name: "Restrictions",   path: "/settings/restrictions" },
      // { name: "Shavers",        path: "/settings/shavers/list" },
      { name: "Warehouses", path: "/settings/warehouses" },
      // { name: "Fulfil-rules",   path: "/settings/fulfilproducts" },
      { name: "Update profile", path: "/settings/profile" },
    ],
  },
  {
    name: "DRIVER",
    submenu: [
      { name: "SCANNER", path: "/barcodescanner" },
      { name: "Receive Packages", path: "/products" },
    ],
  },
  // {
  //   name: "Health",
  //   submenu: [{ name: "Errors", path: "/health/errors" }],
  // },
];

function Navigation({ handleClick }) {
  const [openedSubmenu, setSubmenuOpen] = useState(null);

  return (
    <>
      {menuLinks.map(({ name, path, link, submenu }) => {
        if (!submenu)
          return (
            <CanViewMenuLink path={path}>
              <Menu.Item as="a">
                <Link
                  to={link || path}
                  className="menu__link"
                  onClick={handleClick}
                >
                  {name}
                </Link>
              </Menu.Item>
            </CanViewMenuLink>
          );
        else
          return (
            <CanViewMenuLink pathList={submenu.map(({ path }) => path)}>
              <Menu.Item
                as="a"
                onClick={() =>
                  setSubmenuOpen((prev) => (prev === name ? null : name))
                }
              >
                <div className="navigation-submenu__header">
                  <span>{name}</span>
                  <Icon
                    name={`angle ${openedSubmenu === name ? "up" : "down"}`}
                    color="grey"
                    className="navigation-submenu__toggle-arrow"
                  />
                </div>
              </Menu.Item>
              {openedSubmenu === name && (
                <div className="navigation-submenu__body">
                  {submenu.map(({ name, path }, i) => (
                    <CanViewMenuLink path={path} key="i">
                      <Menu.Item as="a">
                        <Link
                          to={link || path}
                          className="menu__link"
                          onClick={handleClick}
                        >
                          {name}
                        </Link>
                      </Menu.Item>
                    </CanViewMenuLink>
                  ))}
                </div>
              )}
            </CanViewMenuLink>
          );
      })}
    </>
  );
}

export default Navigation;
