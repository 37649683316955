import React, { useEffect, useState } from "react";
import { Pagination, Segment, Header, Divider } from "semantic-ui-react";

import { getShipments, deleteShipment } from "../../../actions/shipments";

import CanViewPage from "../../../role-based-access/CanViewPage";
import ItemsPerPage from "../../ItemsPerPage";

import { ShipmentTable } from "./ShipmentTable";
import Filters from "./Filters";
import "../shipment.scss";
import { Alert } from "rsuite";
import { useUpdateContext } from "../../../context/UpdateContext";

function ListOS(props) {
  const {
    businessclient,
    warehouse,
    shipTo,
    dateFrom,
    dateTo,
    handleShipToChange,
    handleDateFromChange,
    handleDateToChange,
  } = useUpdateContext();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrenPage] = useState(1);
  const [shipments, setShipments] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const handleDeleteShipment = (id) => {
    setLoading(true);
    deleteShipment(id)
      .then((resp) => {
        getShipments({
          businessclient,
          dateFrom,
          dateTo,
          shipFrom: warehouse,
          shipTo,
          itemsPerPage,
          currentPage,
        })
          .then((data) => {
            setLoading(false);

            setShipments(data.list);
            setTotal(data.total);
          })
          .catch((error) => {
            setLoading(false);
            Alert.error(error, 5000);
          });
      })
      .catch((err) => {
        setLoading(false);
        Alert.error(err, 5000);
      });
  };

  useEffect(() => {
    setLoading(true);

    getShipments({
      businessclient,
      shipFrom: warehouse,
      shipTo,
      itemsPerPage,
      currentPage,
      dateFrom,
      dateTo,
    })
      .then((data) => {
        setLoading(false);

        setShipments(data.list);
        setTotal(data.total);
      })
      .catch((error) => {
        setLoading(true);

        Alert.error(error);
      });
  }, [
    businessclient,
    warehouse,
    shipTo,
    itemsPerPage,
    currentPage,
    dateFrom,
    dateTo,
  ]);

  return (
    <CanViewPage path="/shipments/outbound">
      <div className="page-wrapper">
        <Header as="h1">Outbound Shipments {total && " - " + total}</Header>
        <Divider />

        <Filters
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleDateFromChange={handleDateFromChange}
          handleDateToChange={handleDateToChange}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrenPage={setCurrenPage}
          setItemsPerPage={setItemsPerPage}
          handleShipToChange={handleShipToChange}
        />

        <section className="inventorylist">
          <Segment>
            <div className="flex--right mb--1">
              <ItemsPerPage
                total={total}
                value={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
            </div>

            <ShipmentTable
              isLoading={isLoading}
              index={(currentPage - 1) * itemsPerPage}
              shipments={shipments}
              // loading={loading}
              deleteShipment={handleDeleteShipment}
              // handleSelected={handleSelected}
            />

            {shipments.length > 0 && total > itemsPerPage && (
              <Pagination
                style={{ marginTop: "1em" }}
                activePage={currentPage}
                totalPages={Math.ceil(total / itemsPerPage)}
                boundaryRange={1}
                siblingRange={1}
                firstItem={null}
                lastItem={null}
                onPageChange={(e, { activePage }) => setCurrenPage(activePage)}
              />
            )}
          </Segment>
        </section>
      </div>
    </CanViewPage>
  );
}

export default ListOS;
