import React from "react";
import ReactDOM from "react-dom";

// import { Icon } from "semantic-ui-react";

function Notification(props) {
  const { type, trigger, removeNotification } = props; //type: "error", "warning", "success"
  const [showNotification, setShowNotification] = React.useState(true);
  const notificationContainer = document.getElementById("notification");

  const handleFadeOut = () => {
    setShowNotification(false);
    removeNotification();
  };
  const handleFadeIn = () => {
    setShowNotification(true);
    const duration = type === "success" ? 5000 : 15000;
    setTimeout(() => {
      setShowNotification(false);
      removeNotification();
    }, duration);
  };

  React.useEffect(() => {
    if (trigger) handleFadeIn();
  }, [trigger]);

  if (showNotification && trigger) {
    return ReactDOM.createPortal(
      <div
        className={`notification ${
          showNotification ? "fade-in" : ""
        } notification_${type}`}
      >
        <div className={showNotification ? "notification--text-on" : ""}>
          <button className="notification--button" onClick={handleFadeOut}>
            {/* <Icon name="times" /> */}✖
          </button>
          <p>{typeof trigger === "object" ? trigger.message : trigger}</p>
        </div>
      </div>,
      notificationContainer
    );
  } else return null;
}

export default Notification;
