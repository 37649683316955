import React from "react";
import { Button } from "semantic-ui-react";
import ExportExcel from "../../ExportExcel";

function ExportMovementsButton({ items, dataSet }) {
  const colNames = [
    { label: "Description", value: "description" },
    { label: "Trigger type", value: "triggredBy" },
    { label: "Local ID", value: "triggerId" },
    { label: "external ID", value: "clientOrderNr" },
    { label: "Inventory Card", value: "inventory" },
    { label: "Date", value: "date" },
    { label: "Quantity", value: "quantity" },
    { label: "Quantity available", value: "quantity_available" },
    { label: "Warehouse", value: "warehouse" }
  ];

  dataSet = items.map(
    ({
      description,
      warehouse,
      triggredBy,
      triggerId,
      inventory,
      createdAt,
      quantity,
      quantity_available,
      actionSource
    }) => ({
      description,
      triggredBy,
      triggerId: triggerId && triggerId._id ? triggerId._id : actionSource&&actionSource._id?actionSource._id:null,
      clientOrderNr:
      triggerId && triggerId.clientOrderNr ? triggerId.clientOrderNr : actionSource&&actionSource.clientOrderNr?actionSource.clientOrderNr:null,
      inventory: inventory?inventory._id:"NA",
      date: new Date(createdAt).toLocaleString(),
      quantity,
      quantity_available,
      warehouse: warehouse && warehouse.name,
    })
  );

  return (
    <ExportExcel
      filename="product-stockmovements"
      excelSheetName="product-stockmovements"
      dataSet={dataSet}
      colNames={colNames}
      button={<Button>Export</Button>}
    />
  );
}

export default ExportMovementsButton;
