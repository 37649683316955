import React, { useEffect } from "react";
import { Segment,  Loader, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  getRelatedProductGroup,
  updateRelatedProductGroup,
  addProductsRelatedProduct,
  removeProductsRelatedProduct
} from "../../../../actions/product";

import CanViewPage from "../../../../role-based-access/CanViewPage";
import AddProducts from "./AddProducts";
import ProductGroupInfo from "./ProductGroupInfo";
import "../../productGroup.scss";

function EditViewRP(props) {
  const {
    productGroup = { products: [] },
    isLoading,
    getRelatedProductGroup,
    updateRelatedProductGroup,
    addProductsRelatedProduct,
    removeProductsRelatedProduct,
    match,
    history,
    businessclient,
  } = props;

  useEffect(() => getRelatedProductGroup(match.params.id), []);

  const addProducts = products =>
    addProductsRelatedProduct({ _id: match.params.id, products });
  const removeProduct = products =>
    removeProductsRelatedProduct({ _id: match.params.id, products });
  const productsFromPG =
    productGroup.products && productGroup.products.length > 0
      ? productGroup.products.map(({ _id }) => _id)
      : [];

  // console.log({ productsFromPG });

  if (!productGroup) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else {
    return (
      <CanViewPage path="/relatedproduct/:id">
        <main className="a-wrapper product-group-container">
          <Link to="/products/list/relatedproduct">
            <Icon name="arrow left" /> Product Related List
          </Link>
          <Segment>
            <div className="product-group-columns">
              <div className="product-group-columns__info">
                <ProductGroupInfo
                  productGroup={productGroup}
                  removeProduct={removeProduct}
                  updateRelatedProductGroup={updateRelatedProductGroup}
                  history={history}
                />
              </div>
              <div className="product-group-columns__add-prod">
                <AddProducts
                  addProducts={addProducts}
                  productsFromPG={productsFromPG}
                  businessclient={businessclient}
                />
              </div>
            </div>
          </Segment>
        </main>
      </CanViewPage>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  productGroup: reduxState.inventory.openProductGroup,
  isLoading: reduxState.loading.loading,
  businessclient: reduxState.inventory.openProductGroup.businessclient,
});
export default connect(mapStateToProps, {
  getRelatedProductGroup,
  updateRelatedProductGroup,
  addProductsRelatedProduct,
  removeProductsRelatedProduct
})(EditViewRP);
