import React from "react";
import { Form, Header, Divider, Checkbox, Dropdown } from "semantic-ui-react";

import Adress from "./Adress";
// import ClientForm from "./ClientForm";
import CompanyForm from "./CompanyForm";
import { countries } from "../../../countries";
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

const optionsCountries = [
  { key: "No country", value: null, text: "" },
  ...countries.map((c) => ({ key: c, value: c, text: c })),
];

class ClientInfoForm extends React.Component {
  render() {
    const {
      isAdressAsShipping,
      validationErrors = {},
      handleChange,
      handleCheck,
      handleAdressCheck,
      handleBClientChange,
      businessclient,
    } = this.props;

    return (
      <Form className="form-wrapper">
        <Header className="form__sub-header">Business client:</Header>
        <Divider />
        {/* <Divider horizontal className="create-form__sub-header">
          Business client
        </Divider> */}
        <Form.Group widths={3}>
          <div className="flex--column form__client-field">
            <label className="client-label">Client:</label>
            <BusinessclientFilter
              useFilter={false}
              value={businessclient}
              name="businessclient"
              handleChange={handleBClientChange}
            />
          </div>
          <Form.Input
            name="clientOrderNr"
            label="Client Order Nr:"
            placeholder="order number..."
            width={4}
            value={this.props["clientOrderNr"]}
            onChange={handleChange}
          />
        </Form.Group>
        <Header className="form__sub-header">Client Info:</Header>
        <Divider />
        {/* <Divider horizontal className="create-form__sub-header">
          Client Info
        </Divider> */}

        <Form.Group widths={3}>
          <Form.Input
            autoComplete="nope"
            name="shipping.shipTo.client.firstName"
            label="First Name"
            placeholder="First Name"
            value={this.props["shipping.shipTo.client.firstName"]}
            onChange={handleChange}
          />
          <Form.Input
            autoComplete="nope"
            name="shipping.shipTo.client.lastName"
            label="Last Name"
            placeholder="Last Name"
            value={this.props["shipping.shipTo.client.lastName"]}
            onChange={handleChange}
          />
          <Form.Input
            required
            autoComplete="nope"
            name="shipping.shipTo.phone"
            label="Phone"
            placeholder="Phone"
            value={this.props["shipping.shipTo.client.phone"]}
            onChange={handleChange}
          />
        </Form.Group>

        <Header className="form__sub-header">Shipping Adress:</Header>
        <Divider />
        {/* <Divider horizontal className="create-form__sub-header">
          Shipping Adress
        </Divider> */}
        <Form.Group>
          <Form.Field required>
            <label>Country</label>
            <Dropdown
              search
              selection
              error={validationErrors.sh_country}
              name="shipping.shipTo.country"
              label="Country"
              options={optionsCountries}
              placeholder="Country"
              width={6}
              value={this.props["shipping.shipTo.country"]}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Input
            autoComplete="nope"
            name="shipping.shipTo.province"
            label="Province"
            placeholder="Province"
            width={6}
            value={this.props["shipping.shipTo.province"]}
            onChange={handleChange}
          ></Form.Input>
          <Form.Input
            autoComplete="nope"
            required
            error={validationErrors.sh_city}
            name="shipping.shipTo.city"
            label="City"
            placeholder="City"
            width={6}
            value={this.props["shipping.shipTo.city"]}
            onChange={handleChange}
          ></Form.Input>
        </Form.Group>

        <Form.Group>
          <Form.Input
            autoComplete="nope"
            required
            error={validationErrors.sh_street}
            label="Street"
            placeholder="street"
            name="shipping.shipTo.street"
            width={11}
            value={this.props["shipping.shipTo.street"]}
            onChange={handleChange}
          />
          <Form.Input
            autoComplete="nope"
            label="Nr"
            placeholder="Nr"
            name="shipping.shipTo.streetNr"
            width={2}
            onChange={handleChange}
            value={this.props["shipping.shipTo.streetNr"]}
          />
          <Form.Input
            autoComplete="nope"
            required
            error={validationErrors.sh_postcode}
            name="shipping.shipTo.postcode"
            label="Post Code"
            placeholder="Post Code"
            width={5}
            value={this.props["shipping.shipTo.postcode"]}
            onChange={handleChange}
          ></Form.Input>
        </Form.Group>

        {/* <div className="form-columns">
          <ClientForm
            fName={fName}
            lName={lName}
            phone={phone}
            handleChange={handleChange}
          />
          <Adress
            header="Shipping Adress:"
            displayHeader={true}
            isShippingAdress={true}
            country={country}
            province={province}
            city={city}
            street={street}
            streetNr={streetNr}
            postcode={postcode}
            country_name="country"
            province_name="province"
            city_name="city"
            street_name="street"
            streetNr_name="streetNr"
            postcode_name="postcode"
            validationErrors={validationErrors}
            handleChange={handleChange}
            handleAdressCheck={handleAdressCheck}
          />
        </div> */}

        <Form.Group>
          <Checkbox
            onChange={handleCheck}
            checked={this.props["shipping.shipTo.isCompany"]}
            className="uppercase"
            label="Is Company"
            name="shipping.shipTo.isCompany"
            value={this.props["shipping.shipTo.isCompany"]}
          />
        </Form.Group>

        {this.props["shipping.shipTo.isCompany"] && (
          <>
            <Header className="form__sub-header">Company Information:</Header>
            <Divider />
            {/* <Divider horizontal className="create-form__sub-header">
              Company Information
            </Divider> */}
            <Checkbox
              label="Company adress same as the shipping adress"
              checked={isAdressAsShipping}
              name="isAdressAsShipping"
              value={isAdressAsShipping}
              onChange={handleAdressCheck}
            />

            <div className="form-columns">
              <CompanyForm
                isShippingAdress={true}
                isAdressAsShipping={isAdressAsShipping}
                phone={this.props["shipping.shipTo.client.phone"]}
                companyName={this.props["shipping.shipTo.client.companyName"]}
                taxId={this.props["shipping.shipTo.client.taxId"]}
                handleChange={handleChange}
              />
              <Adress
                header="Adress:"
                displayHeader={false}
                isShippingAdress={false}
                country={this.props["shipping.shipTo.client.country"]}
                province={this.props["shipping.shipTo.client.province"]}
                city={this.props["shipping.shipTo.client.city"]}
                street={this.props["shipping.shipTo.client.street"]}
                streetNr={this.props["shipping.shipTo.client.streetNr"]}
                postcode={this.props["shipping.shipTo.client.postcode"]}
                country_name="shipping.shipTo.client.country"
                province_name="shipping.shipTo.client.province"
                city_name="shipping.shipTo.client.city"
                street_name="shipping.shipTo.client.street"
                streetNr_name="shipping.shipTo.client.streetNr"
                postcode_name="shipping.shipTo.client.postcode"
                handleAdressCheck={handleAdressCheck}
                handleChange={handleChange}
              />
            </div>
          </>
        )}
      </Form>
    );
  }
}

export default ClientInfoForm;
