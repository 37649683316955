import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as Chartjs, defaults } from "chart.js/auto";
import { Segment } from "semantic-ui-react";
import { getBar2periods } from "../../actions/dashboard.js";
import { Alert } from "rsuite";

const example = [
  // array

  {
    label: "Date 1",
    backgroundColor: ["rgba(255, 99, 132, 0.2)"],
    data: [
      { x: "product1", y: 20 },
      { x: "product2", y: 10 },
      { x: "product3", y: 33 },
      { x: "product4", y: 55 },
      { x: "product5", y: 111 },
      { x: "product6", y: 10 },
      { x: "product7", y: 101 },
    ],
  },
  {
    label: "Date 2",
    backgroundColor: ["rgba(0, 110, 1, 0.2)"],

    data: [
      { x: "product1", y: 10 },
      { x: "product2", y: 30 },
      { x: "product3", y: 13 },
      { x: "product4", y: 50 },
      { x: "product5", y: 41 },
      { x: "product6", y: 100 },
      { x: "product7", y: 11 },
    ],
  },
];

function Bar2periods({ filters,update }) {
  const { product } = filters;

  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (
      (!product || (product && product.length === 0)) &&
      localStorage.getItem("dashProducts")
    )
    getBar2periods({
      ...filters,
      product: localStorage.getItem("dashProducts").split(","),
    })
      .then((resp) => {
        setDatasets(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Alert.error(err);
      });


      if (product && product.length > 0)
      getBar2periods({
        ...filters
      })
        .then((resp) => {
          setDatasets(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });
  
  }, [update]);

  return (
    <>
      {" "}
      {/* <h2 style={{ textAlign: "center" }}>Product sales</h2> */}
      <Segment
        style={{width:"45%",maxWidth:"500px", margin: "40px 0px 0px 20px" }}
        loading={loading}
      >
        <Bar
          data={{
            datasets: datasets,
            // datasets: example,
          }}

          options={{
            // indexAxis: 'y',
            plugins: {
              title: {
                display: true,
                text: `Product stock balances 2 periods `,
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </Segment>
    </>
  );
}
export default Bar2periods;
