import React from "react";
import {Icon, Button } from "semantic-ui-react";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";

import PickedTable from "../PickedTable";
import PickedTablePrint from "../PickedTablePrint";
import ModalForEdit from "./ModalForEdit";

class PickedListTable extends React.Component {
  state = {
    modalIsOpen: false,
    isHovered: false
  };

  render() {
    const { item, isLastAdded } = this.props;
    const { modalIsOpen, isHovered } = this.state;

    return (
      <div
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <div className="d-flex--jc-between">
          <div>
            <span className="smallinfo">
              Created at:{" "}
              <Moment style={{ color: "#565656" }} format="DD-MM-YY HH:mm">
                {item.createdAt}
              </Moment>
            </span>

            {item.createdAt !== item.updatedAt && (
              <span
                className="smallinfo"
                style={{ marginLeft: "2em", color: "#b39237" }}
              >
                Updated at:{" "}
                <Moment format="DD-MM-YY HH:mm">{item.updatedAt}</Moment>
              </span>
            )}
            <span style={{ marginLeft: "2em" }} className="smallinfo">
              by{" "}
            </span>
            <sapn>{item.user.username}</sapn>
          </div>

          <div>
            <div
              style={{
                visibility: isHovered ? "visible" : "hidden",
                display: "inline"
              }}
            >
              {!item.editable ? (
                <Icon name="lock" color="grey" />
              ) : (
                <>
                  <Icon
                    name="pencil alternate"
                    color="grey"
                    onClick={() => this.setState({ modalIsOpen: true })}
                    style={{ cursor: "pointer" }}
                  />

                  <ModalForEdit
                    modalIsOpen={modalIsOpen}
                    closeModal={() => this.setState({ modalIsOpen: false })}
                    _id={item._id}
                  />
                </>
              )}
            </div>

            {isLastAdded && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{ marginLeft: "1em" }}
                      color="teal"
                      disabled={this.state.isPicking}
                    >
                      <Icon color="white" name="print" />
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                  // pageStyle="padding: 20px"
                />

                <div style={{ display: "none" }}>
                  <PickedTablePrint ref={el => (this.componentRef = el)} />
                </div>
              </>
            )}
          </div>
        </div>

        <PickedTable item={this.props.item} />
      </div>
    );
  }
}

export default PickedListTable;
